export const DATA_SOURCE_SCHRIFTSATZ = 'schriftsaetze';
export const DATA_SOURCE_BETEILIGTE = 'beteiligte';
export const DATA_SOURCE_DOKUMENTE = 'dokumente';
export const DATA_SOURCE_TITEL_KOSTEN = 'titel-kosten';

/**
 * this class is used as a bridge between a react dataSource and the makeRestCall function to
 * reload data if the client receives a 205 http status code
 */
class RestReloadBridge {
  /**
   * initialization
   */
  constructor() {
    this.subscribers = [];
  }

  /**
   * adds a dataSource to the list of subscribers
   * @param {String} dataSource dataSource/dataSource to reload data for
   * @param {Function} callback reload data callback
   * @returns {undefined}
   */
  add(dataSource, callback) {
    if (!callback) {
      console.error(`No reload callback found for dataSource: ${dataSource}`); // eslint-disable-line
    }

    const idx = this.subscribers.findIndex((s) => s.dataSource === dataSource);
    if (idx === -1) {
      this.subscribers.push({ dataSource, callback });
    } else {
      this.subscribers.splice(idx, 1, { dataSource, callback });
    }
  }

  /**
   * removes a dataSource from the list of subscribers
   * @param {String} dataSource dataSource/dataSource to reload data for
   * @returns {undefined}
   */
  remove(dataSource) {
    const idx = this.subscribers.findIndex((s) => s.dataSource === dataSource);
    if (idx !== -1) {
      this.subscribers.splice(idx, 1);
    } else {
      console.error(`No subscriber found for dataSource: ${dataSource}`); // eslint-disable-line
    }
  }

  /**
   * parse the requested url and get the corresponding subscriber to reload data
   * @param {String} url request url
   * @returns {undefined}
   */
  reload(url) {
    let subscriberDataSource = null;

    if (url.indexOf(DATA_SOURCE_DOKUMENTE) !== -1) {
      subscriberDataSource = DATA_SOURCE_DOKUMENTE;
    } else if (url.indexOf(DATA_SOURCE_BETEILIGTE) !== -1) {
      subscriberDataSource = DATA_SOURCE_BETEILIGTE;
    } else if (url.indexOf(DATA_SOURCE_SCHRIFTSATZ) !== -1) {
      subscriberDataSource = DATA_SOURCE_SCHRIFTSATZ;
    } else if (url.indexOf(DATA_SOURCE_TITEL_KOSTEN) !== -1) {
      subscriberDataSource = DATA_SOURCE_TITEL_KOSTEN;
    }

    if (subscriberDataSource) {
      const idx = this.subscribers.findIndex((s) => s.dataSource === subscriberDataSource);
      const callback = this.subscribers[idx].callback;
      callback();
    }
  }
}

const restReloadBridge = new RestReloadBridge();

export default restReloadBridge;
