import filesaver from 'file-saver';
import REST from '../../../../common/Rest';
import modalFactory from '../../../../common/Modal/modalFactory';
import ConfirmationModal from '../../../../common/Modal/ConfirmationModal';
import getFormattedMessage from '../../../../common/FormatMessage/formattedMessage';
import getUrl, { getRestRoot } from '../../../../common/Rest/rest.utils';
import { OK } from '../../../../common/Rest/http.status.codes';
import { handleRestError } from '../../../../common/Rest/RestError.utils';

/**
 * downloadFBAuszugPDF
 * @param {Object} pdf pdf
 * @return {Promise<void>} undefined
 */
export async function downloadFBAuszugPDF(pdf) {
  filesaver.saveAs(pdf, 'Firmenbuch Auszug.pdf');
}

/**
 * getFBAuszugHtml
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<string>} fb auszug pdf
 */
export async function getFBAuszugHtml(schriftsatzId) {
  const url = getUrl(REST.urls.schriftsaetzeFbAusgangHtml, { schriftsatzId });

  const restUrl = `${getRestRoot()}${url}`;

  return await fetch(restUrl, {
    method: 'get',
    // eslint-disable-next-line no-shadow
  }).then((response) => response.text())
    .catch(() => {
      handleRestError(restUrl);
    });
}

/**
 * getFBAuszugPDF
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<Blob|null>} fb auszug pdf
 */
export async function getFBAuszugPDF(schriftsatzId) {
  const restRoot = getRestRoot();
  const url = `${restRoot}${getUrl(REST.urls.schriftsaetzePrintFbAuszug, { schriftsatzId })}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache', // eslint-disable-line
    },
  });

  if (response.status === OK) {
    return await response.blob();
  }

  let json;
  try {
    json = await response.json();
  } catch (e) {
    json = null;
  }
  handleRestError(url, response.status, json);

  return null;
}

/**
 * copyHtml
 * @param {String} elementId elementId
 * @return {undefined}
 */
export function copyFBAuszugHtml(elementId) {
  const copyData = document.getElementById(elementId).innerText;
  navigator.clipboard.writeText(copyData);
  modalFactory.openWithPromise(ConfirmationModal, {
    header: getFormattedMessage({ id: 'hinweis.header' }),
    body: getFormattedMessage({ id: 'fb.aenderungsauszug.copied' }),
    disableClose: true,
  });
}

/**
 * printFBAuszugPDF
 * @param {Object} html html
 * @return {Promise<void>} undefined
 */
export function printFBAuszugHTML(html) {
  const myWindow = window.open('', '', 'width=900,height=600');
  myWindow.document.write(html);
  myWindow.print();
}
