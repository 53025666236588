import format from 'string-template';
import msg from '../../constants/messages.json';

/**
 * get message id
 * @param {String} id id
 * @return {*} message id
 */
function _getMsgObject(id) { // eslint-disable-line
  let msgId = msg[id];
  let param = null;
  if (!msgId) {
    const extraId = formatErrorCode(id);
    const { newMsgId, newParam } = _extraWithExtraParamsInErrorCode(extraId);
    if (newMsgId) {
      msgId = msg[newMsgId];
    }
    if (newParam) {
      param = newParam;
    }
  }
  return {
    msgId,
    param,
  };
}

/**
 * extra msg id with extra params in error code, template: {params:}
 * @param {String} newId newId
 * @return {Object} new msg id object
 */
function _extraWithExtraParamsInErrorCode(newId) { // eslint-disable-line
  let newMsgId = newId;
  let newParam = null;
  if (newId?.includes && newId?.includes('{param:')) {
    newMsgId = newId.split('{param:')[0];
    newParam = newId.split('{param:')[1].split('}')[0];
  }
  return {
    newMsgId,
    newParam,
  };
}

/**
 * remove string by pattern
 * @param {String} str str
 * @return {null} new string
 */
export function formatErrorCode(str) {
  return /\[[0-9]*\]/g.test(str) ? str.replace(/\[[0-9]*\]/g, '') : str;
}

/**
 * check if it is unknown error
 * @param {String} id error message id
 * @return {Boolean} yes or no
 */
export function isUnknownError(id) {
  return !_getMsgObject(id).msgId;
}

/**
 * a simple function that returns the value of a message defined in the messageProperties.json
 * it also replaces variables curly braces defined in the template with the params passed to the
 * component.
 * NOTE: the variable names defined in the template must match with the property key of the params obj
 * @param {Object} errResponse errResponse
 * @returns {String} message property
 */
function getFormattedMessage(errResponse) {
  const { id, params } = errResponse;
  let formattedMessage = null;

  const { msgId, param } = _getMsgObject(id);

  if (!id) {
    console.warn('FormatMessage - Code for message found'); // eslint-disable-line
    return null;
  }

  if (!msgId) {
    console.warn(`FormatMessage - No msg for ${id} found. Falling back to code instead`); // eslint-disable-line
    formattedMessage = id;
    return formattedMessage;
  }

  if (params && Object.keys(params).length) {
    formattedMessage = format(msgId, params);
  } else {
    formattedMessage = msgId;
  }

  if (param) {
    formattedMessage = format(msgId, { param });
  }

  return formattedMessage;
}

export default getFormattedMessage;
