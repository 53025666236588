import qs from 'qs';
import moment from 'moment';
import clone from 'clone';

/**
 * returns the last path of a url from the react router history obj
 * i.e. returns /new for a /schriftsatz/new url
 * @param {Object} props component properties including match from react router
 * @returns {String} the subPath
 */
function getUrlSubPathFromRouter(props) {
  if (!props) {
    return '';
  }

  const { match, location } = props;

  const matchUrl = match.url;
  const { pathname } = location;
  return pathname.replace(matchUrl, '');
}

/**
 * util function to push a new history state depending on the selected tab
 * @param {Object} props component properties
 * @param {object} selectedTab a selectedTab object capable of holding an additional url
 * @returns {undefined}
 */
export function pushSelectedTabUrlInHistory(props, selectedTab) {
  const {
    match,
    history,
    location,
    onInterceptChangeTab,
  } = props;
  const search = location.search;
  const url = selectedTab.url ? selectedTab.url : match.url;
  history.push({
    pathname: url,
    search,
  });
  if (onInterceptChangeTab) {
    onInterceptChangeTab();
  }
}

/**
 * util function to check if the user wants to create a new element (i.e. document),
 * or to edit an existing one. if the user wants to edit an existing one,
 * there must be a url query param
 * @param {Object} props component properties
 * @returns {boolean} true if a query param is present
 */
export function isNewElement(props) {
  let isNew = true;
  const { location } = props;
  if (location.search) {
    isNew = false;
  }
  return isNew;
}

/**
 * util function to check if the user wants to create a new element
 * or to edit an existing one. if the user wants to edit an existing one,
 * there must be a url query param
 * @param {Object} props component properties
 * @returns {boolean} true if a query param is present
 */
export function isNewBegehren(props) {
  let isNew = true;
  const { location } = props;
  if (location.search) {
    const q = qs.parse(props.location.search.slice(1));
    if (q.begehrenId) {
      isNew = false;
    }
  }
  return isNew;
}

/**
 * util function to check if the user wants to create a new element
 * or to edit an existing one. if the user wants to edit an existing one,
 * there must be a url query param
 *
 * for Wohnungseigentum BegruendungsZeile or AenderungsZeile
 *
 * @param {Object} props component properties
 * @returns {boolean} true if a query param is present
 */
export function isNewWohnungseigentumZeile(props) {
  let isNew = true;
  const { location } = props;
  if (location.search) {
    const q = qs.parse(props.location.search.slice(1));
    if (q.zeileId) {
      isNew = false;
    }
  }
  return isNew;
}

/**
 * get query params either from location.search or searchParams props
 * for search kontakt rest call
 * @param {Object} props props
 * @returns {Object} parsed query params
 */
export function getQueryParams(props) {
  const { location, searchParams, isTldz } = props;
  if (searchParams && typeof searchParams !== 'string') {
    return searchParams;
  }
  let queryParams = null;
  if (searchParams) {
    queryParams = qs.parse(searchParams.slice(1));
    if (isTldz) {
      queryParams.notPolizeistellen = true;
    }
  } else if (location && location.search) {
    queryParams = qs.parse(location.search.slice(1));
    if (isTldz) {
      queryParams.notPolizeistellen = true;
    }
  }
  return queryParams;
}

/**
 * helper method to get url conform parameters. also transforms moment date objects into YYYY-MM-DD strings
 * @param {Object} parameters parameters to get the url params from
 * @return {String} url string
 */
export function getUrlParamString(parameters) {
  const params = clone(parameters);
  const keys = Object.keys(parameters);

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const val = params[key];
    if (moment.isMoment(val)) {
      params[key] = val.format('YYYY-MM-DD');
    }
  }

  return qs.stringify(params);
}

export default getUrlSubPathFromRouter;
