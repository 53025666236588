import invariant from 'invariant';

/**
 * contains infos about a component and stores its pending requests
 */
class ComponentInfo {
  /**
   * init component info
   * @param {Object} componentRef component reference
   */
  constructor(componentRef) {
    this.componentRef = componentRef;
    this.pendingRequests = [];
  }

  /**
   * adds a request to the list of pending requests, returns a list of requests to cancel
   * @param {number} requestId id of the request
   * @param {String} key the component property key
   * @param {String} compiledUrl complete url with params set
   * @param {Object} params url params
   * @returns {Array} request list to cancel
   */
  addPendingRequest(requestId, key, compiledUrl, params) {
    // find requests to be cancelled
    const toCancel = this.pendingRequests.reduce((prev, curr) => {
      const p = prev;
      if (curr.compiledUrl !== compiledUrl && curr.key === key) {
        p.push({
          compiledUrl: curr.compiledUrl,
          requestId: curr.requestId,
        });
      }
      return p;
    }, []);

    // clean up own pending requests array
    for (let i = this.pendingRequests.length - 1; i >= 0; i -= 1) {
      const pr = this.pendingRequests[i];
      if (pr.compiledUrl !== compiledUrl && pr.key === key) {
        this.pendingRequests.splice(i, 1);
      }
    }

    // save the pending request
    this.pendingRequests.push({
      requestId,
      key,
      compiledUrl,
      params,
    });
    return toCancel;
  }

  /**
   * after rest request was executed the component will receive the rest result
   * @param {number} requestId id of the request
   * @param {Object} result rest result object
   * @returns {undefined}
   */
  receiveResult(requestId, result) {
    let request;
    for (let i = this.pendingRequests.length - 1; i >= 0; i -= 1) {
      const pr = this.pendingRequests[i];
      if (pr.requestId === requestId) {
        request = pr;
        this.pendingRequests.splice(i, 1);
        break;
      }
    }
    invariant(!!request, 'RequestScheduler ComponentInfo.receiveResult: '
      + 'received a result for an unknown requestId');
    if (result.error) {
      this.componentRef.receiveError(request.key, request.compiledUrl, request.params, result.data);
    } else {
      this.componentRef.receiveQuery(request.key, request.compiledUrl, request.params, result.data);
    }
  }

  /**
   * cancel all requests and get the ids and urlStrings to cancel in
   * the scheduler
   * @returns {undefined}
   */
  cancelAll() {
    const toCancel = this.pendingRequests.map((pr) => ({
      compiledUrl: pr.compiledUrl,
      requestId: pr.requestId,
    }));
    this.pendingRequests.splice(0, this.pendingRequests.length);
    return toCancel;
  }
}

export default ComponentInfo;
