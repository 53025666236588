import React from 'react';
import PropTypes from 'prop-types';
import { inViewport } from '../utils/landing.page.utils';
import s from './Home.module.scss';
import { ReactComponent as ScrollUpSVG } from '../assets/icons/mz-arrow-up.svg';

/**
 * Pfeil nach oben
 *
 * @param {props} props parameters
 * @returns {JSX} component
 */
function ScrollUp(props) {
  return (
    <div className={`${inViewport(props.startSection) ? s.hidden : ''} ${s.scrollUp}`}>
      <div className={s.center}>
        <a href={`#${props.startSection}`} title="Scrollen" aria-label="Scrollen" className="manz-link">
          <ScrollUpSVG
            title="Scrollen"
            className="icon"
          />
        </a>
      </div>
    </div>
  );
}

ScrollUp.defaultProps = {
  startSection: 'start',
};

ScrollUp.propTypes = {
  startSection: PropTypes.string,
};

export default ScrollUp;
