const STORE_ANTRAGSGRUPPE_ID = 'store.antragsgruppe.id';
const CLEAR_ANTRAGSGRUPPE_ID = 'clear.antragsgruppe.id';

const initial = {
  antragsgruppeId: null,
};

/**
 * gbAntragsgruppe reducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{activeKeys: Array}} state
 */
export function gbAntragsgruppeReducer(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_ANTRAGSGRUPPE_ID:
      return {
        ...state,
        antragsgruppeId: payload.antragsgruppeId,
      };
    case CLEAR_ANTRAGSGRUPPE_ID:
      return {
        ...state,
        antragsgruppeId: null,
      };
    default:
      return state;
  }
}

/**
 * store gb antragsgruppe id
 * @param {Number} antragsgruppeId antragsgruppeId
 * @return {Object} redux store
 */
export function storeGBAntragsgruppeId(antragsgruppeId) {
  return {
    type: STORE_ANTRAGSGRUPPE_ID,
    payload: {
      antragsgruppeId,
    },
  };
}

/**
 * clear gb antragsgruppe id
 * @return {Object} redux store
 */
export function clearGBAntragsgruppeId() {
  return {
    type: CLEAR_ANTRAGSGRUPPE_ID,
  };
}
