import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/de';
import numeral from 'numeral';
import 'numeral/locales';
import { oidcIntegrationApi } from '@auth/auth-oidc-integration';
import Main from './Main';
import { unregister } from './registerServiceWorker';
import configureReduxStore from './store';
import getUrl from './common/Rest/rest.utils';
import Rest from './common/Rest';
import makeRestCall from './common/Rest/makeRestCall';
import PollingEventEmitter from './common/Events/PollingEventEmitter';
import { EVENT_COUNT_GS_AUSGANG_UG_EINGANG } from './constants/js/events.constants';
import { FORBIDDEN, OK, PRECONDITION_FAILED } from './common/Rest/http.status.codes';
import { displayNewsModal } from './News/news.utils';
import { config } from './config';
import modalFactory from './common/Modal/modalFactory';
import NotAuthorizedModal from './common/Modal/NotAuthorizedModal';

numeral.localeData('de').delimiters.thousands = '.';
numeral.locale('de');
moment.localeData('de');
window.debug_moment = moment;
const store = configureReduxStore();

/**
 * application action to do after login at cas was successful
 * @param {function} resultCallbackFunction function to call with result of action if appUserObj ist relevant
 * @return {any} nothing
 */
export async function postProcessLogin(resultCallbackFunction) {
  const url = getUrl(Rest.urls.benutzerLogin);

  const retVal = await makeRestCall('POST', url);

  const { status, body } = retVal;

  /**
   * option 1: login successful
   */
  if (status === OK) {
    resultCallbackFunction(body);
    PollingEventEmitter.emit(EVENT_COUNT_GS_AUSGANG_UG_EINGANG);
    await displayNewsModal(body.newsRead);
  }

  /**
   * option 2: login not fully acceptable from application
   */
  if (status === PRECONDITION_FAILED) {
    resultCallbackFunction(body);
  }

  /**
   * option 3: use ist not authorized to use the webERV Software
   */
  if (status === FORBIDDEN) {
    await modalFactory.openWithPromise(
      NotAuthorizedModal,
      {},
    );
  }
}

oidcIntegrationApi.initOidcClient(store, config, postProcessLogin);

const mainProps = {
  store,
};

/* eslint-disable-next-line react/jsx-filename-extension */
ReactDOM.render(<Main {...mainProps} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./Main', () => {
    ReactDOM.render(<Main {...mainProps} />, document.getElementById('root'));
  });
}

/**
 * HAHA - 19.2.2019
 * disabled service worker due to caching. like stated in registerServiceWorker.js updates
 * are loaded after another refresh of the page!
 * Might be of interest later in project but not right now during the heavy development phase.
 * Also check out the webpack prod config!! SWPrecachePlugin has been removed from there!!
 */
unregister();

// export store in case we need it somewhere in the application
export default store;
