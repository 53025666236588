import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Empfangen from '../SendenEmpfangen/Empfangen';


/**
 * react component for empfangen-btn in navigation
 */
class ErledigungenStatus extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);
    this.setTimeAndDate = this.setTimeAndDate.bind(this);
  }

  /**
   * sets last check date and time
   * @param {object} erledigungenStatus - vom REST call
   * @returns {object} date and time
   */
  setTimeAndDate(erledigungenStatus) { // eslint-disable-line
    if (erledigungenStatus && erledigungenStatus.lastCheck) {
      return {
        lastCheckDate: moment(erledigungenStatus.lastCheck).format('DD.MM.YYYY'),
        lastCheckTime: moment(erledigungenStatus.lastCheck).format('HH:mm:ss'),
      };
    }
    return null;
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { erledigungenStatus, onClick } = this.props;
    let checkText = 'Rückverkehr wurde nicht abgerufen';
    let isRVActive = true;

    if (erledigungenStatus) {
      if (erledigungenStatus.countRVEinbringer === 0) {
        isRVActive = false;
        checkText = 'Rückverkehr ist nicht aktiv.';
      } else {
        const dateAndTime = this.setTimeAndDate(erledigungenStatus);
        if (dateAndTime) {
          checkText = `Rückverkehr wurde am ${dateAndTime.lastCheckDate} um ${dateAndTime.lastCheckTime} abgerufen`;
        }
      }
    }

    return (
      <div className="poll-btn-container">
        <Empfangen
          key="empfangen-element"
          onClick={onClick}
          className="mb-3 btn-blue"
          title="Jetzt abrufen"
          disabled={!isRVActive}
        />
        <div className="last-check">
          {checkText}
        </div>
      </div>
    );
  }
}

ErledigungenStatus.defaultProps = {
  erledigungenStatus: null,
  onClick: () => {},
};

ErledigungenStatus.propTypes = {
  erledigungenStatus: PropTypes.object,
  onClick: PropTypes.func,
};

export default ErledigungenStatus;
