import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { withConnectedUserData } from '@auth/auth-oidc-integration';
import qs from 'qs';
import { HELP_URL_OVERVIEW } from '../constants/js/help.url.constaints';
import Help from '../common/Form/Help';
import SchriftverkehrTables from '../common/SchriftverkehrTables/SchriftverkehrTables';
import SchriftsatzBadge from '../common/Badge/SchriftsatzBadge';
import {
  AUSGANG,
  EINGANG,
  EINGANG_DEFAULT_SORT,
  OVERVIEW_AUSGANG_TABLE_COLS,
  OVERVIEW_DEFAULT_SORT,
  OVERVIEW_EINGANG_TABLE_COLS,
  OVERVIEW_PAGE_SIZES,
  OVERVIEW_RESPONSIVE_AUSGANG_TABLE_COLS,
  OVERVIEW_RESPONSIVE_EINGANG_TABLE_COLS,
  SCHRIFTVERKEHR_SEARCH_TEMPLATE,
} from '../constants/js/schriftsatz.constants';
import {
  checkZPO112WithErvBox,
  createTooltipBySchriftsatzStatus,
  formatPagingParameters,
  getErvboxOutdatedDokumenteList,
  isNotInitialedSchriftsatzSearchParameters,
  openCheckErvboxOutdatedListHinweis,
  openZPO112WithErvBoxHinweis,
  parseSchriftsatzSearchInitialParametersByType,
} from '../utils/schriftsatz.utils';
import { setPagingAndSort } from '../common/Table/utils/table.utils';
import { getUrlParamString } from '../utils/url.utils';
import SCHRIFTSATZ_INHALT_ART from '../constants/schriftsatz.inhalt.art';
import ERLEDIGUNG_INHALT_ART from '../constants/erledigung.inhalt.art';
import 'whatwg-fetch';
import PATH from '../constants/path';
import { arrayHasItem, convertFromGMTDateToString } from '../utils/general.utils';
import { SORT_ASC, SORT_DESC } from '../constants/js/sort.constants';
import PollingEventEmitter from '../common/Events/PollingEventEmitter';
import TABLE_TYPE from '../constants/table.type.json';
import { EVENT_COUNT_GS_AUSGANG_UG_EINGANG, EVENT_RELOAD_AUSGANG_EINGANG_LIST } from '../constants/js/events.constants';
import getUrl from '../common/Rest/rest.utils';
import makeRestCall from '../common/Rest/makeRestCall';
import { EXPECTATION_FAILED, OK } from '../common/Rest/http.status.codes';
import SCHRIFTSATZ_STATUS from '../constants/schriftsatz.status.json';
import ErrorInfo from './ErrorInfo';
import { TIMEOUT_TYPE } from '../common/Rest/fetchWithTimeout';
import { createSimpleConfirmationModal } from '../common/Modal/ConfirmationModal';
import REST from '../common/Rest';
import { handleRestError } from '../common/Rest/RestError.utils';
import {
  existErledigungEmpfangenJob,
  initErledigungEmpfangenJob,
} from '../common/Sessions/erledigung.empfangen.session';

/**
 * Overview component
 * @returns {JSX} component
 * @constructor
 */
class Overview extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    const searchParams = {
      page: 0,
      size: 5,
    };

    const preAusgangSearchParams = parseSchriftsatzSearchInitialParametersByType(this.props, AUSGANG);
    const preEingangSearchParams = parseSchriftsatzSearchInitialParametersByType(this.props, EINGANG);

    let ausgangSearchParams = {
      ...searchParams,
      sort: `status,${SORT_ASC}`,
      template: SCHRIFTVERKEHR_SEARCH_TEMPLATE.WORK_IN_PROGRESS,
    };
    if (!isNotInitialedSchriftsatzSearchParameters(preAusgangSearchParams)) {
      ausgangSearchParams = preAusgangSearchParams;
    }

    let eingangSearchParams = {
      ...searchParams,
      sort: `createDate,${SORT_DESC}`,
      template: SCHRIFTVERKEHR_SEARCH_TEMPLATE.TODO,
    };
    if (!isNotInitialedSchriftsatzSearchParameters(preEingangSearchParams)) {
      eingangSearchParams = preEingangSearchParams;
    }

    this.state = {
      ausgangSearchParams,
      eingangSearchParams,
      displayLoadingBySendUFH: false,
      uebertragungsfehlerSchriftsatzIds: [],
    };

    PollingEventEmitter.emit(EVENT_COUNT_GS_AUSGANG_UG_EINGANG);

    PollingEventEmitter.on(EVENT_RELOAD_AUSGANG_EINGANG_LIST, () => this.handleReloadAusgaengeAndEingaenge());

    this.handleChangeTable = this.handleChangeTable.bind(this);
    this.handleGetEingangTableData = this.handleGetEingangTableData.bind(this);
    this.handleGetAusgangTableData = this.handleGetAusgangTableData.bind(this);
    this.handleReloadAusgaengeAndEingaenge = this.handleReloadAusgaengeAndEingaenge.bind(this);
    this.handleSendUebertragungsfehlerSchriftsaetze = this.handleSendUebertragungsfehlerSchriftsaetze.bind(this);
  }

  /**
   * componentDidMount
   * @return {Promise<void>} undefined
   */
  async componentDidMount() {
    const { appAuthorized } = this.props;

    if (appAuthorized) {
      const url = getUrl(REST.urls.schriftverkehr, { type: 'SCHRIFTSATZ' }, {
        page: 0,
        size: 99999,
        schriftsatzStatus: SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value,
      });

      const result = await makeRestCall('GET', url);

      if (result.status === OK && arrayHasItem(result.body.content)) {
        const schriftsatzIds = result.body.content.map((v) => v.id);
        this.setState({
          uebertragungsfehlerSchriftsatzIds: schriftsatzIds,
        });
      }
    }

    await initErledigungEmpfangenJob();

    console.log(`Overview -> existErledigungEmpfangenJob: ${await existErledigungEmpfangenJob()}`); // eslint-disable-line
  }

  /**
   * componentWillUnmount
   * @return {undefined}
   */
  componentWillUnmount() {
    PollingEventEmitter.off(EVENT_RELOAD_AUSGANG_EINGANG_LIST, () => this.handleReloadAusgaengeAndEingaenge());
  }

  /**
   * get ausgang table data by schriftsaetze
   * @param {Array} schriftsaetze schriftsaetze
   * @return {Array} ausgang table data
   * @private
   */
  handleGetAusgangTableData(schriftsaetze) { // eslint-disable-line
    const ausgangTableData = [];
    if (schriftsaetze && schriftsaetze.content) {
      schriftsaetze.content.forEach((ausgang, idx) => {
        const ausgangInhaltArt = SCHRIFTSATZ_INHALT_ART[ausgang.inhaltArt];
        const data = {};
        data.id = idx + 1;
        data.typ = 'Ausgang';
        data.schriftsatzId = ausgang?.id;
        data.inhaltArt = ausgang?.inhaltArt;
        data.aktenzeichen = ausgang?.aktenzeichen;
        data.einbringerLabel = ausgang?.einbringerLabel;
        data.art = ausgangInhaltArt ? ausgangInhaltArt.label : '';
        data.tooltip = createTooltipBySchriftsatzStatus(ausgang?.status);
        data.erstellt = convertFromGMTDateToString(ausgang?.erstellungsZeitpunkt);
        data.status = (
          <SchriftsatzBadge
            schriftsatzStatus={ausgang?.status}
          />
        );
        data.statusValue = ausgang?.status;
        ausgangTableData.push(data);
      });
    }
    return ausgangTableData;
  }

  /**
   * get eingang table data by erledigungen
   * @param {Array} erledigungen erledigungen
   * @return {Array} eingang table data
   * @private
   */
  handleGetEingangTableData(erledigungen) { // eslint-disable-line
    const eingangTableData = [];
    if (erledigungen && erledigungen.content) {
      erledigungen.content.forEach((eingang, idx) => {
        const schriftsatzInhaltArt = SCHRIFTSATZ_INHALT_ART[eingang.inhaltArt];
        const erledigungInhaltArt = ERLEDIGUNG_INHALT_ART[eingang.inhaltArt];
        // eslint-disable-next-line no-nested-ternary
        const inhaltArt = schriftsatzInhaltArt ? schriftsatzInhaltArt.label : (erledigungInhaltArt ? erledigungInhaltArt.label : '');

        const data = {};
        data.id = idx + 1;
        data.art = inhaltArt;
        data.gz = eingang.gz;
        data.isEingang = true;
        data.substringLength = 40;
        data.isSubstringContent = true;
        data.schriftsatzId = eingang?.id;
        data.statusValue = eingang?.status;
        data.inhaltArt = eingang?.inhaltArt;
        data.gerichtLabel = eingang?.gerichtLabel;
        data.aktenzeichen = eingang?.aktenzeichen;
        data.einbringerLabel = eingang?.einbringerLabel;
        data.sender = eingang?.sender || eingang?.gerichtLabel || '';
        data.erstellt = convertFromGMTDateToString(eingang?.bereitstellZeitpunkt);
        data.einbringungsZeitpunkt = convertFromGMTDateToString(eingang?.einbringungsZeitpunkt);
        data.status = (
          <SchriftsatzBadge
            schriftsatzStatus={eingang?.status}
          />
        );

        eingangTableData.push(data);
      });
    }
    return eingangTableData;
  }

  /**
   * change table
   * @param {number} page page
   * @param {number} size size
   * @param {Object} sort sort
   * @param {String} type ausgang or eingang
   * @return {undefined}
   */
  handleChangeTable(page, size, sort, type) {
    const { location, history, match } = this.props;
    let newSort = sort;
    if (sort && !sort.id) {
      newSort = type === AUSGANG ? OVERVIEW_DEFAULT_SORT : EINGANG_DEFAULT_SORT;
    }

    const params = qs.parse(location.search.slice(1));
    const payload = setPagingAndSort(params, page, size, newSort, type);

    payload.template = type === AUSGANG
      ? SCHRIFTVERKEHR_SEARCH_TEMPLATE.WORK_IN_PROGRESS
      : SCHRIFTVERKEHR_SEARCH_TEMPLATE.TODO;

    history.push({
      pathname: match.path,
      search: getUrlParamString(payload),
    });

    if (type === AUSGANG) {
      this.setState({ ausgangSearchParams: formatPagingParameters(payload, AUSGANG) });
    } else {
      this.setState({ eingangSearchParams: formatPagingParameters(payload, EINGANG) });
    }
  }

  /**
   * reload ausgaenge and eingaenge
   * @return {undefined}
   */
  handleReloadAusgaengeAndEingaenge() {
    const { ausgangSearchParams, eingangSearchParams } = this.state;
    this.handleChangeTable(ausgangSearchParams.page, ausgangSearchParams.size, ausgangSearchParams.sort, AUSGANG);
    this.handleChangeTable(eingangSearchParams.page, eingangSearchParams.size, eingangSearchParams.sort, EINGANG);
  }

  /**
   * handleSendUebertragungsfehlerSchriftsaetze
   * @return {Promise<void>} undefined
   */
  async handleSendUebertragungsfehlerSchriftsaetze() {
    const { uebertragungsfehlerSchriftsatzIds } = this.state;

    this.setState({ displayLoadingBySendUFH: true });

    if (arrayHasItem(uebertragungsfehlerSchriftsatzIds)) {
      for (let i = 0; i < uebertragungsfehlerSchriftsatzIds.length; i += 1) {
        const schriftsatzId = uebertragungsfehlerSchriftsatzIds[i];

        const checkedZpo112WithErvBox = await checkZPO112WithErvBox(schriftsatzId);

        // TODO fuer aktuelle Loesung brauchen wir diese Funktion nicht mehr, weil wir diese Validierung einfach bei BRZ lassen, siehe ERVSW-3632.
        const ervboxOutdatedList = await getErvboxOutdatedDokumenteList(schriftsatzId); // eslint-disable-line

        if (!checkedZpo112WithErvBox) {
          openZPO112WithErvBoxHinweis();
        } else if (arrayHasItem(ervboxOutdatedList)) {
          openCheckErvboxOutdatedListHinweis(ervboxOutdatedList, schriftsatzId);
        } else {
          await this._transferSchriftsatz(schriftsatzId); // eslint-disable-line
        }
      }
    }

    this.setState({
      displayLoadingBySendUFH: false,
      uebertragungsfehlerSchriftsatzIds: [],
    });

    await createSimpleConfirmationModal('hinweis.header', 'schriftsatz.transfer.uebertragungsfehler.schriftsaetze');
  }

  /**
   * _transferSchriftsatz
   * @param {Number} schriftsatzId schriftsatzId
   * @return {Promise<void>} _transferSchriftsatz
   * @private
   */
  async _transferSchriftsatz(schriftsatzId) { // eslint-disable-line
    const url = getUrl(REST.urls.schriftsaetzeTransfer, { schriftsatzId });

    const result = await makeRestCall('POST', url, null, null, true, null, TIMEOUT_TYPE.SENDEN);

    if (result.status === EXPECTATION_FAILED) {
      handleRestError(url, result.status, result);
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { appUserObj, appAuthorized } = this.props;
    const {
      eingangSearchParams,
      ausgangSearchParams,
      displayLoadingBySendUFH,
      uebertragungsfehlerSchriftsatzIds,
    } = this.state;

    const kundeAbteilungSetupDone = appUserObj && appUserObj.kundeAbteilungSetupDone;
    if (kundeAbteilungSetupDone === false) {
      return <Redirect to={PATH.KONFIGURATION.value} />;
    }

    return (
      <div>
        <ErrorInfo
          isOverview
          sending={displayLoadingBySendUFH}
          onSend={this.handleSendUebertragungsfehlerSchriftsaetze}
          existUebertragungsfehler={arrayHasItem(uebertragungsfehlerSchriftsatzIds)}
        />

        <Help
          key="help"
          href={HELP_URL_OVERVIEW}
        />

        {ausgangSearchParams && eingangSearchParams && appUserObj && appAuthorized && (
          <SchriftverkehrTables
            startPolling
            tableType={TABLE_TYPE.OVERVIEW}
            pageSizes={OVERVIEW_PAGE_SIZES}
            onChangeTable={this.handleChangeTable}

            eingangSearchParams={eingangSearchParams}
            ausgangSearchParams={ausgangSearchParams}

            eingangTableCols={OVERVIEW_EINGANG_TABLE_COLS}
            ausgangTableCols={OVERVIEW_AUSGANG_TABLE_COLS}

            onFormatEingangTableData={this.handleGetEingangTableData}
            onFormatAusgangTableData={this.handleGetAusgangTableData}

            responsiveEingangTableCols={OVERVIEW_RESPONSIVE_EINGANG_TABLE_COLS}
            responsiveAusgangTableCols={OVERVIEW_RESPONSIVE_AUSGANG_TABLE_COLS}
          />
        )}
      </div>
    );
  }
}

Overview.defaultProps = {
  appUserObj: null,
  appAuthorized: false,
};

Overview.propTypes = {
  appUserObj: PropTypes.object,
  appAuthorized: PropTypes.bool,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withConnectedUserData(withRouter(Overview));
