import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinnerInputField from './LoadingSpinnerInputField';

/**
 * LoadingSpinner
 * @param {Object} props props
 * @return {JSX} component
 * @constructor
 */
function LoadingSpinner(props) {
  const { classNames } = props;
  return (
    <div className={`loadingSpinnerBig ${classNames}`}>
      <LoadingSpinnerInputField width="50" height="50" />
    </div>
  );
}

LoadingSpinner.defaultProps = {
  classNames: '',
};

LoadingSpinner.propTypes = {
  classNames: PropTypes.string,
};

export default LoadingSpinner;
