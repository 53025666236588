import qs from 'qs';
import { arrayHasItem } from '../../../utils/general.utils';

/**
 * set page, size, sort in paging object
 * @param {Object} pagingObj pagingObj
 * @param {Integer} page page
 * @param {Integer} size size
 * @param {Object} sort sort
 * @param {String} prefix prefix
 * @return {Object} pagingObj
 */
export function setPagingAndSort(pagingObj, page, size, sort, prefix) {
  const updatedObj = pagingObj;
  if (updatedObj) {
    updatedObj[prefix ? `${prefix}page` : 'page'] = page || 0;
    updatedObj[prefix ? `${prefix}size` : 'size'] = size;
    if (sort?.id && sort?.direction) {
      updatedObj[prefix ? `${prefix}sort` : 'sort'] = `${sort.id},${sort.direction}`;
    }
  }
  return updatedObj;
}

/**
 * helper function go retrieve paging information about page and pageSize as well as an infotext
 * from browser location search params
 * @param {Object} location browser location
 * @param {number} total total elements
 * @param {String} prefix schriftatz type (ausgang or eingang)
 * @returns {Object} paging info
 */
export function getPagingInfoFromLocation(location, total, prefix) {
  const obj = qs.parse(location.search.slice(1));

  const page = obj[`${prefix}page`] || 0;
  const objSize = obj[`${prefix}size`];
  const size = ((typeof objSize === 'string' || objSize instanceof String) ? parseInt(objSize, 10) : objSize) || 0;

  let from = 0;
  let to = 0;
  if (page >= 0) {
    from = (page * size);
    if (from === 0) {
      to = size;
    } else {
      to = from + size;
    }

    if (to > total) {
      to = total;
    }

    from += 1; // since it's 1 and not 0 based
  }

  let pageInfo = `${from}-${to}`;
  if (total || total === 0) {
    pageInfo += ` von ${total}`;
  }

  const retval = {};
  retval[`${prefix}Page`] = page;
  retval[`${prefix}Size`] = size;
  retval[`${prefix}PageInfo`] = pageInfo;
  return retval;
}

/**
 * helper function go retrieve paging information about page and pageSize as well as an infotext
 * from search params
 * @param {Object} searchParams searchParams
 * @param {number} total total elements
 * @param {String} prefix schriftatz type (ausgang or eingang)
 * @returns {Object} paging info
 */
export function getPagingInfoFromSearchParams(searchParams, total, prefix) {
  const page = searchParams.page;
  const size = searchParams.size;
  let from = 0;
  let to = 0;

  if (page >= 0) {
    from = (page * size);
    if (from === 0) {
      to = size;
    } else {
      to = from + size;
    }

    if (to > total) {
      to = total;
    }

    from += 1; // since it's 1 and not 0 based
  }

  /*
  let pageInfo = `${from}-${to}`;
  if (total || total === 0) {
    pageInfo += ` von ${total}`;
  }
  */
  const pageInfo = total;

  const retval = {};
  retval[`${prefix}Page`] = page;
  retval[`${prefix}Size`] = size;
  retval[`${prefix}PageInfo`] = pageInfo;
  return retval;
}

/**
 * getExtraSearchParamByLocation
 * @param {Object} location location
 * @param {String} searchParam searchParam
 * @return {string|null} getExtraSearchParamByLocation
 */
export function getExtraSearchParamByLocation(location, searchParam) {
  const params = qs.parse(location.search.slice(1));
  if (location && arrayHasItem(searchParam)) {
    if (searchParam.length === 2) {
      const p1 = params[searchParam[0]];
      const p2 = params[searchParam[1]];
      if (p1 && p2) {
        return `&${searchParam[0]}=${p1}&${searchParam[1]}=${p2}`;
      }
      if (p1) {
        return `&${searchParam[0]}=${p1}`;
      }
      if (p2) {
        return `&${searchParam[1]}=${p2}`;
      }
    }
    if (searchParam.length === 1) {
      return `&${searchParam}=${params[searchParam]}`;
    }
  }
  return null;
}
