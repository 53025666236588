import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const DropdownIndicator = (props) => components.DropdownIndicator && (
  <components.DropdownIndicator {...props}>
    <div className="react-select-icon-custom" />
  </components.DropdownIndicator>
);

/**
 * ReactSelectWrapper component
 * @param {Object} props props
 * @return {JSX} component
 * @constructor
 */
function ReactSelectWrapper(props) {
  const forwardedProps = {
    ...props,
    noOptionsMessage: () => props.noOptionsMessage || 'Keine Ergebnisse',
    placeholder: props.placeholder || 'Auswählen...',
  };

  const options = [];
  props.options.forEach((v) => {
    if (!v.isHide) {
      options.push(v);
    }
  });

  return (
    <div className={props.isMulti ? 'react-select-container-multi' : 'react-select-container'} title={props.title}>
      <Select
        {...forwardedProps}
        options={options}
        isMulti={props.isMulti}
        aria-label={props.ariaLabel}
        onKeyDown={props.onKeyDown}
        isSearchable={props.isSearchable}
        onInputChange={props.onInputChange}
        components={{ DropdownIndicator }}
        isOptionDisabled={(option) => option.disabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: props.isMulti ? 50 : 0,
          colors: {
            ...theme.colors,
            primary: '#eef4ff',
          },
        })}
      />
    </div>
  );
}

ReactSelectWrapper.defaultProps = {
  title: null,
  options: [],
  isMulti: false,
  placeholder: '',
  defaultValue: '',
  ariaLabel: null,
  onKeyDown: null,
  isSearchable: true,
  onInputChange: null,
  noOptionsMessage: null,
  classNamePrefix: 'react-select',
  className: 'react-select-container',
};

ReactSelectWrapper.propTypes = {
  title: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  ariaLabel: PropTypes.string,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onInputChange: PropTypes.func,
  classNamePrefix: PropTypes.string,
  noOptionsMessage: PropTypes.string,
};

export default ReactSelectWrapper;
