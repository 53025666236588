import React from 'react';
import FetchErrorModal from './FetchErrorModal';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function InternetDisconnectErrorModal(props) {
  return (
    <FetchErrorModal
      {...props}
    />
  );
}

export default InternetDisconnectErrorModal;
