import { mdBreakPoint } from './utils.module.scss';
import {
  SECTION_START,
} from '../LandingPage/sections';

/**
 * checks view size whether mobile
 * @returns {boolean} is width smaller then break mobile device breakpoint
 */
export function isMobileView() {
  const breakPoint = parseInt(mdBreakPoint.replace('px', ''), 10);
  return window.innerWidth < breakPoint;
}

/**
 * @param {String} section parameter
 * @returns {boolean} is in view port
 */
export function inViewport(section) {
  const locationHash = window.location.hash.substr(1);
  if (locationHash === '' && section === SECTION_START) {
    return true;
  }
  return locationHash === section;
}

export const isIE = /* @cc_on!@ */ !!document.documentMode;
