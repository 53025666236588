import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import s from './ContactForm.module.scss';
import sHome from '../Home.module.scss';
import { makeExternalRestCall } from '../../common/Rest/makeRestCall';
import Rest from '../../common/Rest';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-oPadding.svg';
import FriendlyCaptcha from './FriendlyCaptcha';

const alphaNumeric = (value) => (value && /[^a-zA-Z0-9 ]/i.test(value)
  ? 'Only alphanumeric characters'
  : undefined);

const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Ungültige E-Mail-Adresse'
  : undefined);

const required = (value) => (value || typeof value === 'number' ? undefined : 'Required');

// eslint-disable-next-line
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div className={`${s['form-control-wrapper']}`}>
    <input
      {...input}
      type={type}
      aria-label={label}
      placeholder={label}
      className={`form-control ${touched && (error || warning) ? s.error : ''}`}
    />
    {touched && (warning
      && <span className={`${s.errorMessage}`}>{warning}</span>
    )}
  </div>
);

// eslint-disable-next-line react/prop-types
const renderTextarea = ({ input, label }) => (
  <div className={`${s['form-control-wrapper']}`}>
    <textarea
      {...input}
      aria-label={label}
      placeholder={label}
      className={`form-control ${s.textArea}`}
    />
  </div>
);

const submit = async (values) => {
  const res = await makeExternalRestCall('POST', Rest.urls.contactForm, values);
  if (res.error) {
    throw new SubmissionError({
      _error: 'Die Nachricht konnte nicht versendet werden.',
    });
  }
};

const createCookieAdministration = () => cookieConfiguration.createCookieAdministrationPage(true); // eslint-disable-line

const ContactForm = (props) => {
  const {
    error,
    change,
    pristine,
    submitting,
    handleSubmit,
    submitSucceeded,
  } = props;

  return (
    <div>
      {
        submitSucceeded
          ? (
            <p className={`${sHome.paragraph} ${sHome.paragraphCenter}`}>
              Danke für Ihre Anfrage. Wir werden Ihnen in Kürze ein attraktives Angebot zukommen lassen.
            </p>
          ) : (
            <form onSubmit={handleSubmit(submit)} className={`${s.contactForm} contact-form`}>
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <Field
                      type="text"
                      label="Firma"
                      name="company"
                      component={renderField}
                    />
                    <Field
                      type="text"
                      name="firstName"
                      label="Vorname *"
                      validate={required}
                      component={renderField}
                    />
                    <Field
                      name="lastName"
                      type="text"
                      label="Nachname *"
                      validate={required}
                      component={renderField}
                    />
                    <Field
                      name="email"
                      type="email"
                      label="E-Mail *"
                      component={renderField}
                      validate={[email, required]}
                    />
                    <Field
                      type="phone"
                      name="phone"
                      label="Telefonnummer"
                      validate={alphaNumeric}
                      component={renderField}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <Field
                      type="text"
                      name="customerNumber"
                      component={renderField}
                      label="MANZ Kundennummer"
                    />
                    <Field
                      name="message"
                      label="Ihre Nachricht"
                      component={renderTextarea}
                    />
                  </div>
                  <div className={`${s.requiredFields}`}>* Pflichtfelder</div>
                </div>
              </div>
              <div className="row  justify-content-center">
                <div className={`${s.captcha} col-4 text-center`}>
                  <FriendlyCaptcha onSolutionSolved={(response) => { change('solution', response); }} />
                </div>
              </div>
              <div className="row justify-content-md-center col-sm-12">
                <p className={sHome.paragraph}>
                  Bitte beachten Sie unsere
                  {' '}
                  <button
                    type="button"
                    className={s.buttonasa}
                    aria-label="Cookie Policy Button"
                    onClick={() => createCookieAdministration()}
                  >
                    Cookie Policy
                  </button>
                  {' '}
                  und
                  {' '}
                  <a
                    target="_blank"
                    className="manz-link"
                    rel="noopener noreferrer"
                    title="Datenschutzerklärung"
                    aria-label="Datenschutzerklärung"
                    href="http://www.manz.at/wir_ueber_uns/agb/datenschutzerklaerung.html"
                  >
                    {' '}
                    Datenschutzerklärung
                  </a>
                  .
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-3">
                  <button
                    type="submit"
                    aria-label="Absenden"
                    disabled={pristine || submitting}
                    className={`btn-lg ${s.btnSubmit}`}
                  >
                    Absenden
                  </button>
                </div>
              </div>
            </form>
          )
      }
      {
        error ? (
          <div className="row justify-content-center">
            <span className="error">
              <InfoSVG title="" />
              {' '}
              {error}
            </span>
          </div>
        ) : ''
      }
    </div>
  );
};


ContactForm.defaultProps = {
  error: null,
  change: null,
  pristine: false,
  submitting: false,
  handleSubmit: null,
  submitSucceeded: false,
};

ContactForm.propTypes = {
  change: PropTypes.func,
  error: PropTypes.string,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitSucceeded: PropTypes.bool,
};

export default reduxForm({
  form: 'contactFormLandingPage', // a unique identifier for this form
  initialValues: {
    applicationType: 'WEBERVSW',
  },
})(ContactForm);
