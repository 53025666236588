import format from 'string-template';
// eslint-disable-next-line import/no-cycle
import getUrl from './rest.utils';

/**
 * simple class to build urls for a rest call
 */
class RestQuery {
  /**
   * create a rest query url
   * @param {String} urlTemplate url template string
   * @param {Object} parameters rest request parameters
   */
  constructor(urlTemplate, parameters) {
    this.parameters = { ...parameters }; // create a new object
    this.urlTemplate = urlTemplate;
    this.value = null;
    this.compileQuery();
  }

  /**
   * adds parameters to a rest request url template
   * @returns {undefined}
   */
  compileQuery() {
    let url = (this.urlTemplate && this.parameters) ? format(this.urlTemplate, this.parameters) : null;
    if (this.parameters && this.parameters.optionalParams) {
      url = getUrl(url, null, this.parameters.optionalParams);
    }
    this.value = url;
  }

  /**
   * get the compiled query
   * @returns {String} compiled query
   */
  getQuery() {
    return this.value;
  }

  /**
   * get parameters from a query
   * @returns {Object} parameters
   */
  getParameters() {
    return this.parameters;
  }

  /**
   * set new parameters for a query
   * @param {Object} newParameters new parameters
   * @returns {undefined}
   */
  setParameters(newParameters) {
    this.parameters = {
      ...this.parameters,
      ...newParameters,
    };
    this.compileQuery();
  }
}

export default RestQuery;
