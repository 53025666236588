import React from 'react';
import PropTypes from 'prop-types';
import ModalHeader from './ModalHeader';
import modalFactory from './modalFactory';
import getFormattedMessage from '../FormatMessage/formattedMessage';

/**
 * confirmation modal. has an ok button that executes an action and a cancel button,
 * to close the modal
 */
class ConfirmationModal extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  /**
   * confirm button action either executes a given callback or resolves the promise
   * @returns {undefined}
   */
  handleConfirm() {
    const {
      resolve,
      params,
      onClose,
      onConfirm,
    } = this.props;

    if (resolve) {
      resolve(params || true);
    }
    if (onConfirm) {
      onConfirm(params || true);
    }
    onClose();
  }

  /**
   * confirmation modal render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      body,
      header,
      noText,
      onClose,
      yesText,
      disableClose,
      manuellFormat,
    } = this.props;

    let bodyText = body;
    if (Array.isArray(body)) {
      const list = [];
      for (let i = 0; i < body.length; i += 1) {
        const key = i;
        if (manuellFormat) {
          list.push(<div key={key}>{body[i]}</div>);
        } else {
          list.push(<li key={key}>{body[i]}</li>);
        }
        bodyText += body[i];
      }
      if (manuellFormat) {
        bodyText = (<div>{list}</div>);
      } else {
        bodyText = (<ul>{list}</ul>);
      }
    }

    return [
      <ModalHeader
        key="modal-header"
        onClose={onClose}
      >
        {header}
      </ModalHeader>,
      <div
        key="modal-body"
        className="manz-modal-body"
      >
        {bodyText}
      </div>,
      <div
        key="modal-footer"
        className="manz-modal-footer"
      >
        {!disableClose && (
          <button
            type="button"
            onClick={onClose}
            aria-label="Nein Button"
            id="confirmation-cancel-btn"
            className="btn btn-sm btn-secondary"
          >
            {noText}
          </button>
        )}
        <button
          type="button"
          aria-label="Ja Button"
          id="confirmation-ok-btn"
          onClick={this.handleConfirm}
          className="btn btn-sm btn-primary"
        >
          {yesText}
        </button>
      </div>,
    ];
  }
}

ConfirmationModal.defaultProps = {
  body: null,
  header: null,
  resolve: null,
  params: null,
  yesText: 'OK',
  onConfirm: null,
  disableClose: false,
  noText: 'Abbrechen',
  manuellFormat: false,
};

ConfirmationModal.propTypes = {
  resolve: PropTypes.func,
  header: PropTypes.string,
  noText: PropTypes.string,
  params: PropTypes.object,
  yesText: PropTypes.string,
  onConfirm: PropTypes.func,
  disableClose: PropTypes.bool,
  manuellFormat: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

/**
 * create simple confirmation modal
 * @param {String} headerMsgId headerMsgId
 * @param {String} bodyMsgId bodyMsgId
 * @param {Object} bodyMsgParams bodyMsgParams
 * @param {Boolean} manuellFormat manuellFormat
 * @param {String} yesText yesText
 * @param {String} noText noText
 * @return {Promise<void>} result
 */
export async function createSimpleConfirmationModal(headerMsgId, bodyMsgId, bodyMsgParams, manuellFormat = false, yesText, noText) {
  return await modalFactory.openWithPromise(
    ConfirmationModal,
    {
      header: getFormattedMessage({ id: headerMsgId }),
      body: getFormattedMessage({ id: bodyMsgId, params: bodyMsgParams }),
      manuellFormat,
      yesText,
      noText,
    },
  );
}

/**
 * create simple confirmation modal
 * @param {String} headerStr headerStr
 * @param {String} bodyMsg bodyMsg
 * @param {Object} bodyMsgParams bodyMsgParams
 * @param {Boolean} manuellFormat manuellFormat
 * @param {Boolean} disableClose disableClose
 * @return {Promise<void>} result
 */
export async function createSimpleConfirmationModalByString(headerStr, bodyMsg, bodyMsgParams, manuellFormat = false, disableClose = false) {
  return await modalFactory.openWithPromise(
    ConfirmationModal,
    {
      header: headerStr,
      body: bodyMsg,
      manuellFormat,
      disableClose,
    },
  );
}

export default ConfirmationModal;
