/**
 * get the selected option from the array options
 * @param {String} value value to look for in the array of select options
 * @param {Array} options array of select options
 * @returns {{label: string, value: string}|*} select option
 */
export function getSelectedOption(value, options) {
  let selectedOption = null;

  if (value) {
    for (let i = 0; i < options.length; i += 1) {
      const option = options[i];

      if (option.value === value) {
        selectedOption = option;
        break;
      }
    }
  }

  return selectedOption;
}
