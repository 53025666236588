import moment from 'moment';
import format from 'string-template';
import { config } from '../../config';

/**
 * get rest root
 * @returns {string} rest root string
 * @returns {undefined}
 */
export function getRestRoot() {
  return config.backend.rest_api_base_url || '/__api/';
}

/**
 * get mrs rest root
 * @returns {string} rest root string
 * @returns {undefined}
 */
export function getMrsRestRoot() {
  return config.backend.mrs_rest_api_base_url;
}

/**
 * get shared resource service url prefix
 * @returns {string} shared resource service url prefix
 * @returns {undefined}
 */
export function getMrsUrlPrefix() {
  return config.backend.mrs_url_prefix;
}

/**
 * get shared resource service href prefix
 * @returns {string} shared resource service href prefix
 * @returns {undefined}
 */
export function getMrsHrefPrefix() {
  return config.backend.mrs_href_prefix;
}

/**
 * create a url with parameters
 * @param {Object} url url
 * @param {Object} pathParams pathParams
 * @param {Object} queryParams queryParams
 * @return {string} urls with parameters
 */
function getUrl(url, pathParams, queryParams) {
  if (!url) {
    console.error('no url specified for getUrl function call'); // eslint-disable-line no-console
    return null;
  }
  let urlWithParamsSet = url;

  if (pathParams) {
    urlWithParamsSet = format(url, pathParams);
  }

  if (queryParams) {
    const qParams = Object.keys(queryParams);
    for (let i = 0; i < qParams.length; i += 1) {
      const paramKey = qParams[i];
      let value = queryParams[paramKey];

      if (value || value === 0) {
        if (moment.isMoment(value)) {
          value = value.format('YYYY-MM-DD');
        }

        if (urlWithParamsSet.indexOf('?') === -1) {
          urlWithParamsSet += '?';
        } else if (url[url.length - 1] !== '&') {
          urlWithParamsSet += '&';
        }

        urlWithParamsSet += `${paramKey}=${encodeURIComponent(value)}`;
      }
    }
  }

  return urlWithParamsSet;
}

export default getUrl;
