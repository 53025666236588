import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DashboardSVG } from '../../assets/icons/mz-dashboard.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function DashboardIcon(props) {
  const { className, onClick } = props;
  return (
    <DashboardSVG // eslint-disable-line
      title="Dashboard"
      onClick={onClick}
      alt="dashboard icon"
      id="manz-plus-circle"
      aria-label="Dashboard"
      className={className}
    />
  );
}

DashboardIcon.defaultProps = {
  onClick: null,
  className: null,
};

DashboardIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default DashboardIcon;
