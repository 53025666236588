// #######################################
// ####           Generell            ####
// #######################################
export const aktenzeichenTooltip = 'Internes Aktenzeichen, Auswahl aus der Liste oder Neueingabe (25 Zeichen).';
export const einbringerTooltip = 'Wählen Sie aus der Liste der vorhandenen Profile. Neuanlage unter „Konfiguration“.';
export const weiterleitenTooltip = 'Sie können diese Nachricht an einen anderen ERV-Teilnehmer per Direkt- oder eZustellung weiterleiten.\x0AAlle Daten und Dokumente werden übernommen.\x0ADie Informationen können vor dem Versenden noch geändert bzw. ergänzt werden.';
export const antwortenTooltip = 'Sie können dem Absender mit einer Direkt- oder eZustellung antworten.\x0AAlle Daten und Dokumente werden übernommen.\x0ADie Informationen können vor dem Versenden noch geändert bzw. ergänzt werden.';
export const tabFlagTooltipStar = 'Daten vorhanden';
export const tabFlagTooltipNumber = 'Anzahl der erfassten Daten';

// #######################################
// ####              GZ               ####
// #######################################
export const gzDienststelleTooltip = 'Dienststelle (3 Zeichen)';
export const gzAbteilungsnummerTooltip = 'Abteilungsnummer (muss 3-stellig sein; z.B: Abt. "5" Sie geben "005" ein)';
export const gzGattungTooltip = 'Gattung (aus der Liste wählen)';
export const gzLaufendenummerTooltip = 'Laufenden Nummer (kann 5 Zeichen haben)';
export const gzJahrkurzTooltip = 'Jahr (muss 4-stellig sein z.B. 2021)';
export const gzJahrkurzEUMTooltip = 'Jahr: 19 (kann 2- oder 4-Stellig sein; 19 oder 2019;  wird meist 2-stellig verlangt)';
export const gzPruefkennzahlTooltip = 'Prüfkennzahl (1 Zeichen, Groß-/Kleinschreibung beachten)';

// #######################################
// ####           Kontakt        ####
// #######################################
export const kontakteCodeTooltip = 'Es wird automatisch ein Kürzel aus 3 Buchstaben Nach- und 3 Buchstabe Vornamen vorgeschlagen.\x0ASie können das Kürzel auch nach Ihren Wünschen verändern (16 Zeichen).';
export const kontakteSearchModelKontaktTypTooltip = 'Wählen Sie aus ob Sie in allen vorhandenen Kontakten suchen wollen, oder nur bestimmte zB Natürliche Personen, Gerichte,...';
export const kontakteSearchModelKurzTooltip = 'Suche nach der Kurzbezeichnung.';
export const kontakteSearchModelNameTooltip = 'Geben Sie den Namen oder einen Teil des Namens ein. Sie können hier auch mit „*“ arbeiten, zB „*waren*“. Groß- und Kleinschreibung haben keinen Einfluß auf das Ergebnis.';
export const kontakteSearchModelStrasseTooltip = 'Geben Sie die Straße oder einen Teil der Straße ein. Sie können hier auch mit „*“ arbeiten, zB „*Wiener*“. Groß- und Kleinschreibung haben keinen Einfluß auf das Ergebnis.';
export const kontakteSearchModelPlzTooltip = 'Postleitzahl';
export const kontakteSearchModelGeburtsdatumTooltip = 'Geburtsdatum, zB 01.01.1969';
export const kontakteSearchModelFbnrTooltip = 'Firmenbuchnummer, zB 123456d';
export const kontakteArtTooltip = 'Art des Kontaktes, zB Telefon, Fax,...';
export const kontakteDatenTooltip = 'Telefonnummer, E-Mail Adresse,... (80 Zeichen)';
export const kontaktBemerkungTooltip = 'Zusätzliche Anmerkungen, zB. Ansprechpartner. (128 Zeichen)';
export const kontakteGenderTooltip = 'Dies wird in weiterer Folge zur Steuerung der Anschrift verwendet (Männlich =\' Herr, Weiblich =\' Frau).';
export const kontakteStrasseTooltip = 'Straße ohne Hausnummer (200 Zeichen).';
export const kontakteNummerTooltip = 'Hausnummer / Stiege / Tür (50 Zeichen).';
export const kontakteLandTooltip = 'Land';
export const kontaktePlzTooltip = 'Postleitzahl (6 Zeichen)';
export const kontakteOrtTooltip = 'Ort (95 Zeichen)';
export const kontakteAffixTooltip = 'Titel oder Akademischer Grad, zB. „Dr.“, „Mag.“, „Dipl. Ing.“ (15 Zeichen).';
export const kontakteSuffixTooltip = 'Titel oder Akademischer Grad welcher nach dem namen geführt wird, zB. „MSc“, „Bakk. phil.“ (15 Zeichen).';
export const kontakteVornameTooltip = 'Der Vornamen der Person (250 Zeichen).';
export const kontakteNachnameTooltip = 'Der Nachname der Person (250 Zeichen).';
export const kontakteNameTooltip = 'Erfassen Sie in den 2 Zeilen den kompletten Firmenwortlaut.';
export const kontakteBirthdayTooltip = 'Geburtsdatum der Person, wird in manchen Schriftsätzen benötigt.';
export const kontakteJobTooltip = 'Beruf der Person (37 Zeichen).';
export const kontakteNationTooltip = 'Nationalität';
export const kontakteNationStatusTooltip = 'Status des Staats';
export const kontakteRegisterArtTooltip = 'Das Register in dem die Person eingetragen ist.';
export const kontakteRegisterTooltip = 'Firmenbuchnummer (zB. 123456d), ZVR-Zahl, Sonstiges Register.';
export const kontakteNotizenTooltip = 'Interne Notizen und Anmerkunden zur Person (wird nicht übermittelt).';
export const kontakteBankArtTooltip = 'Wenn Sie für einen Klienten ein eigenes Gebühren- oder Zahlungskonto eingerichtet haben, können Sie dieses hier erfassen. Wenn hier kein Konto hinterlegt ist, dann werden die Konten des Einbringers (siehe Konfiguration - Profile) herangezogen. Es kann immer nur 1 Gebühren- und 1 Zahlungskonto geben.';
export const kontakteBankBezeichnungTooltip = 'Bankbezeichnung, zB. Sparkasse,... (32 Zeichen)';
export const kontakteBankIbanTooltip = 'Die International Bank Account Number (IBAN) ist eine internationale, standardisierte Notation für Bankkontonummern.\x0ADie Notation wird durch die ISO-Norm ISO 13616:2003 beschrieben. (34 Zeichen)';
export const kontakteBankIbanInfo = '(International Bank Account Number, zB. AT772011100123456789)';
export const kontakteBankBicTooltip = 'Der SWIFT-BIC (BIC ist die Abkürzung für Bank Identifier Code) wird umgangssprachlich auch BIC-Code oder SWIFT-Code genannt,\x0Akorrekt wären aber SWIFT-Adresse oder BIC. (11 Zeichen)';
export const kontakteBankBicInfo = '(Bank Identifier Code, zB.  RZTIAT22263)';
export const kontakteAsyllandTooltip = 'Herkunftsland einer Person im Asyl. (37 Zeichen)';
export const kontakteSonstigesAnschriftcodeTooltip = 'Wird bei einem Grundbuchsantrag übermittelt und zur Adressierung von zB. Finanzämtern (U123456),\x0AAnwälten (R123456) oder Gemeinden (E123456) verwendet. (7 Zeichen)';

// #######################################
// ####           Profil        ####
// #######################################
export const einbringerInitialenTooltip = 'Internes Kürzel (10 Zeichen)';
export const einbringerNameTooltip1 = 'Name des Einbringers (100 Zeichen)';
export const einbringerNameTooltip2 = 'Name des Einbringers (50 Zeichen)';
export const einbringerNameTooltip3 = 'Name des Einbringers (50 Zeichen)';
export const einbringerStrasseTooltip = 'Straße ohne Hausnummer. (200 Zeichen)';
export const einbringerStrasseNrTooltip = 'Hausnummer / Stiege / Tür (50 Zeichen)';
export const einbringerLandTooltip = 'Land';
export const einbringerPlzTooltip = 'Postleitzahl (10 Zeichen)';
export const einbringerOrtTooltip = 'Ort (100 Zeichen)';
export const einbringerTelefonTooltip = 'Telefonnummer des Profils (30 Zeichen)';
export const einbringerEmailTooltip = 'E-Mail des Profils (100 Zeichen)';
export const einbringerAdvmcodeTooltip = 'ERV Code bei Rechtsanwäten zB. R123456, Kanzleien P123456, Wirtschaftstreuhänder T123456,... (7 Zeichen)';
export const einbringerBankBezeichnungTooltip = 'Bankbezeichnung, zB. Sparkasse,... (32 Zeichen)';
export const einbringerBankIbanTooltip = 'Die International Bank Account Number (IBAN) ist eine internationale, standardisierte Notation für Bankkontonummern.\x0ADie Notation wird durch die ISO-Norm ISO 13616:2003 beschrieben. (34 Zeichen)';
export const einbringerBankBicTooltip = 'Der SWIFT-BIC (BIC ist die Abkürzung für Bank Identifier Code) wird umgangssprachlich auch BIC-Code oder SWIFT-Code genannt,\x0Akorrekt wären aber SWIFT-Adresse oder BIC. (11 Zeichen)';

// #######################################
// ####         Schriftsaetze Basisdaten         ####
// #######################################
export const vertretungsvollmachtTooltip = 'Vertretungsvollmacht';
export const eigentumsrechtEintragen = 'Eigentumsrecht eintragen';
export const inVerbindungMitWohnungseigentum = 'Verbindung Wohnungseigentum';
export const bezeichnungsTooltip = 'Geben Sie eine Bezeichnung für das Dokument ein, zB. Kaufvertrag, etc.';
export const gebuehrenTooltip = 'Optionen für die Pauschalgebühr. Standard ist der Eintrag „(1) Gebührenpflicht der 1. Partei“.';
export const gzAngefochtenTooltip = 'Die Geschäftszahl des aulösenden Rechtsakts von einer Behörde für den aktuellen Geschäftsfall beim Gerichtshof (50 Zeichen).';
export const erlassDatumTooltip = 'Das Erlassdatum für den angefochtenen Rechtsakt.';
export const zustellDatumTooltip = 'Das Datum, zu dem die angefochtene Entscheidung an den Antragsteller ausgehändigt wurde.';
export const schriftsatzAttachmentsBezeichnungVfTooltip = 'Erfassen Sie immer eine Bezeichnung zu dem Dokument, und diese Bezeichnung dar maximal 50 Zeichen lang sein!';
export const schriftsatzBaseSachgebietTooltip = 'Sachgebiet';
export const schriftsatzBaseVerfahrensartTooltip = 'Antrag nach Artikel __B-VG';
export const schriftsatzBaseVerfahrenshilfeTooltip = 'Verfahrenhilfe';
export const schriftsatzBaseAnmerkungTooltip = 'Ergänzender Freitext zum Antrag (254 Zeichen)';
export const schriftsatzBaseBezugszahlenTooltip = 'Definiert eine Aktenzahl vom Gericht, die zum aktuellen Geschäftfall einen bestimmten Bezug hat. (zB. eine Vor- od. Nachzahl) (30 Zeichen)';
export const schriftsatzBaseAsylghAisidTooltip = 'Die eindeutige Identifikation für das aktuelle Asylverfahren (min. 2o Zeichen, max. 24 Zeichen).';
export const schriftsatzAttachmentsDokumenttypTooltip = 'Sie müssen einen Dokumententyp auswählen. Erfassen Sie immer ein Dokument mit dem Typ „Antrag“. Ausnahmen sind bei den Typen 27, 36, 37, 48, 49, 51, 69, 70, 72, 73, 118.';
export const schriftsatzAngefochteneRechtsnormTooltip = 'Die angefochtene Rechtsnorm.';
export const schriftsatzAttBilanzstichtagTooltip = 'Erfassen Sie den Bilanzstichtag. Es dürfen ein Konzern- und ein Jahresabschluss mit unterschiedlichen Bilanzstichtagen zur selben FR eingebracht werden, allerdings nicht in der gleichen Urkunde, dafür sind 2 Dokumente getrennt einzubringen.';
export const schriftsatzAttachmentsBemerkungTooltip = 'Erfassen Sie immer eine nähere Beschreibung zu dem Dokument. Beim Typ „Musterzeichnung“ erfassen Sie hier den Vor- und Zunamen des Zeichnungsberechtigten (50 Zeichen).';
export const schriftsatzAttArtdereinreichungTooltip = 'Dieses Feld beschreibt, ob die Bilanz eine Erstvorlage, eine Berichtigung einer schon vorgelegten Bilanz oder eine Liquidationsschlussbilanz ist.';
export const schriftsatzAttachmentsZeichenTooltip = 'Zeichen (50 Zeichen)';
export const schriftsatzAttachmentsUnterschriftenDatumTooltip = 'Datum des Dokumentes, nicht das aktuelle Datum.';
export const schriftsatzAttGroessenklasseTooltip = 'W) Kleinstkapitalgesellschaft nach § 221 UGB (1a): Kleinstkapitalgesellschaft EUR 350.00 Bilanzsumme; EUR 700.000 Umsatzerlöse in den zwölf Monaten vor dem Abschlussstichtag; im Jahresdurchschnitt 20 Arbeitnehmer.\x0A\x0A\n'
  + 'K) Kleine Kapitalgesellschaft nach § 221 UGB (1): Kleine Kapitalgesellschaft EUR 5 Millionen Bilanzsumme; EUR 10 Millionen Umsatzerlöse in den zwölf Monaten vor dem Abschlussstichtag; im Jahresdurchschnitt 50 Arbeitnehmer.\x0A\x0A\n'
  + 'M) Mittelgroße Kapitalgesellschaft nach § 221 UGB (2): Mittelgroße Kapitalgesellschaft EUR 20 Millionen Bilanzsumme; EUR 40 Millionen Umsatzerlöse in den zwölf Monaten vor dem Abschlussstichtag; im Jahresdurchschnitt 250 Arbeitnehmer.\x0A\x0A\n'
  + 'G) Große Kapitalgesellschaft nach § 221 UGB (3): Große Kapitalgesellschaften sind solche, die mindestens zwei der drei in Abs. 2 bezeichneten Merkmale überschreiten. Ein Unternehmen von öffentlichem Interesse (§ 189a Z 1) gilt stets als große Kapitalgesellschaft.';
export const schriftsatzAttachmentsArchivTooltip = 'PDF: PDF-Datei Archivium: Urkundenarchiv für Rechtsanwälte Cyberdoc: Urkundenarchiv für Notare';
export const schriftsatzVjDokumentPdfTooltip = 'Verweis auf die PDF-Datei. Diese wird nach Erfassung zentral gespeichert und verliert den Bezug zur Originaldatei.';

export const schriftsatzBaseFirmensitzTooltip = 'Geben Sie die politische Gemeinde an, in der die Firma ihren Sitz haben wird.';
export const schriftsatzBaseFirmenwortlautTooltip = 'Erfassen Sie den genauen Firmenwortlaut mit Straße, PLZ und Ort in 3 Zeilen. Zulässige Sonderzeichen: a-zA-Z0-9äöüßÄÖÜ.(+!*);-%&_?:§="@,\'$ (38 Zeichen)';
export const schriftsatzBaseFirmenbuchnummerTooltip = 'Vorhandene Firmenbuchnummer, Erfassung ohne die Buchstaben „FN“, zB. 123456d';
export const schriftsatzBaseReferenzIbanTooltip = 'Wenn während der Gründung noch keine Firmenbuchnummer bekannt ist, die Bankbestätigung des Firmenkontos,\x0Adie Identitätsfeststellung des Kontoinhabers und die Musterzeichnung zu einem ReferenzIBAN einbringen (34 Zeichen).';
export const schriftsatzBaseLaufendenummerTooltip = 'lfd. Nummer max. 5-stellig (5 Zeichen)';
export const schriftsatzBaseJahrTooltip = 'Jahr (2 Zeichen)';
export const schriftsatzBasePruefenKennzeichenTooltip = 'Prüfkennzeichen (1 Zeichen)';

export const schriftsatzBaseGerichtTooltip = 'Wählen Sie das zuständige Gericht aus.';
export const schriftsatzBaseStreitwertTooltip = 'Streitwert (gilt als Bemessung für die Kosten und Gerichtsgebühren) in Euro.\x0AWenn Streitwert größer als 36.340, dann muss Tarif ="K) Keine Kosten nach NKT" sein (Regel BR-0425).';
export const schriftsatzBaseBetriebenerAnspruchTooltip = 'Kapitalbetrag ohne Zinsen und Kosten und ohne Nebenforderungen gem §54 Abs. 2 JN';
export const schriftsatzBaseNebenforderungTooltip = 'Streitwert ist die Klagsforderung abzüglich allfälliger Nebenforderungen (zB. Inkassokosten, Mahnspesen...)';
export const schriftsatzBaseTarifTooltip = 'Abhängig vom Verfahren wählen Sie hier die Kosten. zB. „Aufwandsersatz“: für Interessenvertretungen im arbeitsgerichtlichen Verfahren,\x0A„Keine Kosten nach NKT“: wenn die Bemessungsgrundlage über Euro 36.340,00 ist, dann müssen Sie die Kosten bei den „Sonstigen Kosten“ erfassen.';
export const schriftsatzBaseGebuehrenTooltip = 'Optionen für die Pauschalgebühr. Standard ist der Eintrag „(1) Gebührenpflicht der 1. Partei“.';
export const schriftsatzBaseMitteilungTooltip = 'Mitteilung an das Gericht, für die Übermittlung von zusätzlichen Informationen.';
export const schriftsatzBaseMitteilungPlaceholder = 'Bei der Folgeeingabe werden keine Gebühren- bzw. Zahlungskonten übermittelt.\x0AFalls Sie z.B. eine Forderungsanmeldung übermitteln, können Sie die Angabe zum Gebühreneinzug hier erfassen.';
export const schriftsatzBaseWegenTooltip = 'Geben Sie zusätzlich zum Fallcode einen Text ein. (150 Zeichen)';
export const schriftsatzBaseKuendigungWegenTooltip = 'Begründung der Kündigung (150 Zeichen)';
export const schriftsatzBaseVerfuegungWegenTooltip = 'Begründung der Einstweilige Verfügung (150 Zeichen)';
export const schriftsatzBaseFallcodeTextTooltip = 'Fallcodetext (150 Zeichen)';
export const schriftsatzBaseAusfertigungenTooltip = 'Anzahl der Ausfertigungen, maximal 3-fach';
export const schriftsatzBaseGebuehrFreitext = 'Freitext für Gebühren';
export const schriftsatzBaseBegruendung = 'Begründung Gebührenbefreiung';
export const schriftsatzBaseGesetzesgrundlageTooltip = 'Hinweis auf eine gesetzliche Regelung, die für das gesamte Gesuch relevant ist, zB. „Gemäß 16 LTG wird bestätigt, dass...“';
export const schriftsatzBaseBevGeschaeftsfallNummerTooltip = 'Diese Daten befinden sich auf der Planbescheinigung vom BEV. Da der zugrundeliegende Plan beim BEV schon elektronisch vorhanden ist, ist keine Planübersendung an das Gericht notwendig.';
export const schriftsatzBaseAntragsrueckmeldungTooltip = 'Legt fest ob bei der Aktenzeichenrückmeldung die Visualisierung dieses Antrags mitgeschickt werden soll.';
export const schriftsatzAnteilsgrosseZaehlerTooltip = 'Zähler: Die Anteilsgröße bezieht sich immer auf die gesamte Liegenschaft.';
export const schriftsatzAnteilsgrosseNennerTooltip = 'Nenner: Die Anteilsgröße bezieht sich immer auf die gesamte Liegenschaft.';
export const schriftsatzTagebuchzahlNummerTooltip = 'Tagebuchzahl: laufende Nummer (im entsprechenden Jahr).';
export const schriftsatzTagebuchzahlJahrTooltip = 'Tagebuchzahl: Jahreszahl des Jahres in dem der Geschäftsfall registriert wurde.';
export const schriftsatzBaseGeschaeftsabteilung = 'Geschäftsabteilung des angerufenen Gerichts, in den meisten Fällen „001“.';
export const schriftsatzBaseGruppebezeichnung = 'Wird als eindeutige Bezeichnung an das Gericht übermittelt. (20 Zeichen)';
export const schriftsatzBaseVeraltetBegehren = 'Veraltet - bitte löschen Sie dieses Begehren und erfassen Sie es neu!';
export const schriftsatzBaseGemeindenummerOrt = 'Ort';
export const schriftsatzBaseGemeindenummerNummer = 'Politischer Gemeindenummer';
export const schriftsatzBegehenBestehendeNeueintragung = 'eine bestehende Eintragung (B-LNR): Geben Sie die betroffene Eintragung(en) mittels B-LNR und (optional Literale) an.\x0ANeueintragung (anderes Begehren): Verweis auf ein anderes Begehren.';

export const schriftsatzBaseEUMGerichtTooltip = 'Für die Durchführung des Mahnverfahrens ist ab dem 1.7.2009 ausschließlich das Bezirksgericht für Handelssachen Wien zuständig.';
export const schriftsatzBaseEUMBeimEinspruchTooltip = 'Auswahl des weiteren Verfahrens im Falle eines Einspruchs.';
export const schriftsatzBaseEUMFallsSmallClaimsTooltip = 'Weiteres Verfahren im Falle der Nichtanwendbarkeit des europäischen Verfahrens für geringfügige Forderungen.';
export const schriftsatzEUMForderungAnspruchBegruendungForderung = 'Umstand, mit dem die Forderung begründet wird, zB. ausgebliebene Zahlung.';
export const schriftsatzEUMForderungAnspruchSonstigeAngaben = 'zB. Ort des Vertragsabschlusses oder der Leistung';
export const schriftsatzEUMForderungAnspruchErlaeuterungen = 'Nähere Erläuterung der Forderung, nur wenn bei "Sonstige Angaben" der letzte Eintrag "48" gewählt wurde (128 Zeichen).';
export const schriftsatzEUMForderungAnspruchBetrag = 'Höhe der Forderung';
export const schriftsatzEUMForderungAnspruchDatumVon = 'Zeitpunkt des Vetragsabschlusses oder des schädigenden Ereignisses oder Beginn des Zeitraumes der Miete/Pacht.';
export const schriftsatzEUMForderungAnspruchDatumBis = 'Ende des Zeitraumes der Miete oder Pacht (optional).';
export const schriftsatzEUMForderungZinsenZurForderung = 'Verweis auf die Forderung, die Grundlage für die Verzinsung ist.';
export const schriftsatzEUMForderungZinsen = 'Art des Zinssatzes oder der Zinsen.';
export const schriftsatzEUMForderungPeriode = 'Verzinsungsperiode: monatlich, vierteljährlich,...';
export const schriftsatzEUMForderungErlaeuterung = 'Nähere Erläuterung, wenn bei "Zinsen" die Option "06" gewählt wurde (128 Zeichen).';
export const schriftsatzEUMForderungBasiszinssatz = 'Angabe der Punkte (als Gleitkommazahl), die der Zinssatz über dem Basiszinssatz der EZB liegt (16 Zeichen).';
export const schriftsatzEUMForderungZinsenAb = 'Datum ab dem die geforderten Zinsen anfallen.';
export const schriftsatzEUMForderungZinsenBis = 'Datum bis zu dem Zinsen georfert werden; werden Zinsen bis zum Zeitpunkt der Entscheidung des Gerichtes gefordert, bleibt dieses Feld leer.';
export const schriftsatzEUMForderungBetrag = 'Geldbetrag auf den die Zinsen beansprucht werden (16 Zeichen).';
export const schriftsatzEUMForderungBeweiseDatum = 'Datum der Urkunde oder des Gutachtens.';
export const schriftsatzEUMForderunngBeweiseZurForderung = 'Bezug auf die Forderung, die sich auf diesen beweis stützt.';
export const schriftsatzEUMForderungBeweiseBeweiseArt = 'Art des Beweismittels, zB. Urkundenbeweis, Zeugenbeweis,...';
export const schriftsatzEUMForderungBeweiseBeschreibung = 'Titel, Aktenzeichen des Dokuments, Name des Zeugen, usw (128 Zeichen).';
export const schriftsatzEUMForderungSonstigeKostenBeschreibung = 'Erläuterung wenn bei Kosten "02 - sonstige Kosten" gewählt wurde (128 Zeichen).';
export const schriftsatzEUMForderungSonstigeKostenBetrag = 'Wird die Erstattung der Gerichtsgebühren beantragt und ist deren Höhe noch nicht bekannt, so bleibt dieses Feld frei, bei "Kosten" ist "01 - Antragsgebühren" auszuwählen (16 Zeichen).';

export const schriftsatzTHBVertragsdatum = 'Datum der Unterfertigung der Treuhandvereinbarung durch die Treugeber (letztes Datum zählt, wenn nicht gleichzeitig gefertigt wird).';
export const schriftsatzTHBStatutdatum = 'Datum der Statutfassung unter welchem die Treuhandschaft abgewickelt wird (z.B.: 01.01.2021).';
export const schriftsatzTHBAnwaltGeschaeftsfall = 'Zusatz zum Kontowortlaut (Bezeichnung des Anderkontos zB. „THK Verkäufer/Käufer“)';
export const schriftsatzTHBAnwaltKontowortlaut = '„Name des Treuhänders“ oder „Anwalt Geschäftsfall“ (z.B.: Verkäufer/Käufer)"';
export const schriftsatzTHBEinseitigeTreuhandschaftTooltip = 'Wenn nur EIN Treugeber/Geldbeisteller und ein (oder mehrere) "Sonstige Begünstigte" an der Treuhandschaft beteiligt sind, ist dieser Umstand hier anzuzeigen.\x0ADamit wird die Prüfung, dass mindestens 2 Treugeber erforderlich sind deaktiviert.';
export const schriftsatzTHBBautaegerSacheTooltip = 'Hier kann der Umstand Bauträgersache (Fristverlängerung) mitgeschickt werden.';

export const schriftsatzFBSachverhaltTooltip = 'Ergänzende Beschreibung zum Antrag';

export const schriftsatzBeteiligterFinanzamtBtn = 'Ab 1.1.2023 muss in allen Anträgen, in denen ein Finanzamt erfasst werden soll, das "Finanzamt Österreich, Postfach 260, 1000 Wien" mit dem Anschriftencode Z050071 erfasst werden.';
export const schriftsatzGrundbuchAuszugAlertTooltip = 'Ihre Eingabe kommt nicht in den Daten des Auszuges vor - bitte überprüfen Sie diese nochmals auf Richtigkeit. Der Antrag kann trotzdem eingebracht werden.';

// #######################################
// ####             Schriftsatz Suche              ####
// #######################################
export const schriftsaetzeSearchModelEinbringerTooltip = 'Alle Schriftsätze von einem Einbringer. Die Einbringer können Sie unter „Konfiguration - Profile“ definieren. Alle Einbringer müssen am WebERV angemeldet sein.';
export const schriftsaetzSearchModelVonTooltip = 'Alle Schriftsätze ab einem bestimmten Datum.';
export const schriftsaetzeSearchModelBisTooltip = 'Alle Schriftsätze bis zu einem bestimmten Datum.';
export const schriftsaetzeSearchModelAktenzeichenTooltip = 'Schriftsätze von zB. einem bestimmten Akt, bzw von allen Akten.';
export const schriftsaetzeSearchModelSchriftsatzartTooltip = 'Suche nach Schriftsatzart';
export const schriftsaetzeSearchModelPersonTooltip = 'Schriftsätze mit einer bestimmten Person/Firma.';
export const schriftsaetzSearchModelStatusAusgangTooltip = '(IA) nicht geprüft & nicht verschickt, (GP) geprüft & nicht verschickt, (GS) Gesendet & nicht bestätigt, (ZG) verschickt & Fehler (vom BRZ) wurden festgestellt,\x0A(OK) verschickt & bestätigt (vom BRZ), (ÜF) verschickt & noch nicht das „Haus“ verlassen, zB. Internetverbindung funktioniert nicht, (FH) nicht verschickt & Fehler bei der internen Prüfung.';
export const schriftsaetzeSearchModelStatusEingangTooltip = '(ER) empfangen & kontrolliert/gelesen, (OF) empfangen & nicht kontrolliert/gelesen.';
export const schriftsaetzeSearchModelMsgIdTooltip = 'Schriftsätze mit einer bestimmten Message ID.';
export const schriftsaetzeSearchModelGerichtTooltip = 'Wählen Sie ein Gericht aus, mit dem Sie Ihren Rückverkehr durchsuchen wollen!';
export const schriftsaetzeSearchModelInterneInfoTooltip = 'Schriftsätze mit einer internen Info.';

// #######################################
// ####             Beteiligte              ####
// #######################################
export const beteiligterNewRolleTooltip = 'Wählen Sie einen Eintrag aus der Liste. Eine freie Eingabe ist nicht erlaubt.';
export const beteiligterRolleOfFolgeeingabeTooltip = 'Die Rolle kann frei erfasst werden - Sie müssen keinen Eintrag aus der Liste auswählen';
export const beteiligterNewBeteiligterTooltip = 'Geben Sie das Kürzel oder den Nachnamen ein.';
export const beteiligterEinbringerTooltip = 'Der Einbringer wird in den Basisdaten festgelegt werden.';
export const beteiligterNewVertreterTooltip = 'Geben Sie das Kürzel oder den Nachnamen ein. Nach dem Kanzlei- oder Anwaltscode (zB. R123456) können Sie ebenso suchen.';
export const beteiligterSontstigeAngabenTooltip = 'Sonstige Angaben: z.B. "tritt lediglich als Antragsteller hinsichtlich der Ausnützung Treuhänder Rangordnung XY auf (max. 200 Zeichen)"';

// #######################################
// ####             Dokumente              ####
// #######################################
export const schriftsatzAttachmentsArchiveArchividToolip = 'Zahl der im Archivium abgelegten Urkunde (17 Zeichen).';
export const schriftsatzAttachmentsArchiveChecksumToolip = 'Prüfsumme (2 Zeichen): Sie finden diese auf der "Freigabebestätigung".\x0ASollte diese fehlen, dann haben Sie im Archivium die Urkunde nicht freigegeben.';
export const schriftsatzAttachmentsCyberdocAmtstellennrToolip = 'Amtstellennummer (7 Zeichen)';
export const schriftsatzAttachmentsCyberdocLfdnrTooltip = 'Laufende Nummer AS-Inhaber (3 Zeichen)';
export const schriftsatzAttachmentsCyberdocUrkundenTooltip = 'Archivierungscode (6 Zeichen)';
export const schriftsatzAttachmentsCyberdocUrkundentypTooltip = 'Urkundentyp (1 Zeichen)';
export const schriftsatzAttachmentsCyberdocPruefsummeTooltip = 'Prüfsumme (2 Zeichen)';
export const schriftsatzAttachmentsBaikId = 'Zahl des im Bundeskammer der Architekten und Ingenieurkonsulenten abgelegten Dokuments.';
export const schriftsatzAttachmentsBaikChecksumToolip = 'Prüfsumme, zur BAIK Dokumentzahl.';
export const schriftsatzAttachmentsPapierTooltip = 'Hinweis zur Urkunden, welche auf dem Postweg eingebracht wird.';
export const schriftsatzAttachmentsJustizarchivGerichtTooltip = 'Gericht: 3 Ziffern (mit 0 aufgefüllt)';
export const schriftsatzAttachmentsJustizarchivLaufnummertooltip = 'Laufnummer: 5 Ziffern (mit 0 aufgefüllt)';
export const schriftsatzAttachmentFBAuszugStichTagTooltip = 'Der allfällige Stichtag, der für die Urkunde vom Typ (Quelle) \'Firmenbuch Auszug\' verwendet werden soll.';
export const schriftsatzAttachmentPersonnummerTooltip = 'Die Nummer der Person, für die eine Urkunde des Typs (Quelle) \'Firmenbuch Auszug\' bzw. \'Meldezettel\' erstellt werden\n soll.';
export const schriftsatzAttachmentsJustizarchivJahrTooltip = 'Jahr: 4 Ziffern';
export const schriftsatzAttachmentsJustizarchivPositionsnummerTooltip = 'Positionsnummer: 3 Ziffern (mit 0 aufgefüllt)';
export const schriftsatzAttachmentsBeglaubigungDienststelleTooltip = 'Dienststelle: Format: 3 Ziffern (mit 0 aufgefüllt)';
export const schriftsatzAttachmentsBeglaubigungGeschaftsabteilungTooltip = 'Geschäftsabteilung: Format: 3 Ziffern (mit 0 aufgefüllt)';
export const schriftsatzAttachmentsBeglaubigungAktenzahlTooltip = 'Aktenzahl: Format 6 Ziffern (mit 0 aufgefüllt)';
export const schriftsatzAttachmentsBeglaubigungJahrTooltip = 'Jahr: Format JJJJ (4 Zeichen)';
export const schriftsatzAttachmentsJustizarchivUrkundenidTooltip = 'Urkunden-ID: Format 4 Ziffern';
export const schriftsatzAttachmentsAnlagenrTooltip = 'Beilagenummer des Dokumentes, zB. 1, 2,.. oder auch A1, B1,..';
export const schriftsatzAttachmentsGzTooltip = 'Gerichtliche oder behördliche Geschäftszahl.';
export const schriftsatzVjDokumentTypTooltip = 'Wählen Sie die Art des Dokumentes aus. Sollten sie keinen passenden Dokumententyp finden,\x0Awählen Sie „Sonstiges“ und erfassen im Feld „Bemerkung“ eine nähere Beschreibung.';
export const schriftsatzVjDokumentBemerkungTooltip = 'Erfassen Sie immer eine nähere Beschreibung zu dem Dokument.';
export const unterschriftDatumTooltip = 'Datum der Unterzeichnung (nicht aktuelles Datum).';
export const unterschriftNatPersonTooltip = 'Person, welche das Dokument unterzeichnet hat. Es muss auch das Geburtsdatum erfasst angegeben sein.\x0ADie ersten 50 gefundenen Einträge werden angezeigt.';
export const unterschriftPersonkennTooltip = 'Personenkennung des Firrmenbuches, zB. A, B,...';
export const unterschriftJurPersonTooltip = 'Name der Kommanditgesellschaft (KG), wenn die unterzeichnende Person ein Komplementär (persönlich haftende Gesellschafter einer KG) ist.\x0ADie ersten 50 gefundenen Einträge werden angezeigt.';
export const unterschriftRegisterTooltip = 'Möglichkeit der Eintragung, ob der Unterzeichnende in einem anderen Register als dem Firmenbuch eingetragen ist. (Vereinsregister,...)';
export const unterschriftRegisternummerTooltip = 'Registernummer zum genannten Register.';
export const attVgnrFinanzamtVgnrTooltip = 'Finanzamts-Vorgangsnummer (VGNR) - wird durch Finanz Online vergeben (Beispiel: 101472002015_001001).';

// #######################################
// ####             Erledigung              ####
// #######################################
export const erledigungAktenzeichenTooltip = 'Falls hier noch kein Aktenzeichen steht, können Sie dieses manuell eintragen.\x0ADas Aktenzeichen (wie Sie Ihren Akt bezeichnen) wird über 3 Methoden ermittelt.\x0AEntweder wird es über die ursprüngliche Eingabe anhand der Message-ID oder anhand der Geschäftszahl ermittelt oder in der Erledigung mitgeschickt.';
export const erledigungEmpfaengerTooltip = 'Empfänger';
export const erledigungSchriftsatzartTooltip = 'Schriftsatzart';
export const erledigungBetreffTooltip = 'Betreff';
export const erledigungGerichtTooltip = 'Gericht';
export const erledigungGeschaeftszahlTooltip = 'Geschäftszahl';
export const erledigungMsgIdTooltip = 'Message ID';
export const erledigungBereitgestelltAmTooltip = 'Bereitgestellt am';
export const erledigungAbgerufenAmTooltip = 'Abgerufen am';
export const erledigungStatusTooltip = 'Status: Gelesen oder Ungelesen';
export const erledigungInterneInfoTooltip = 'Interne Infomation';
export const erledigungZusatzInfoTooltip = 'Zusatzinfo.';

// #######################################
// ####       Direktzustellung        ####
// #######################################
export const tldzMitteilungTooltip = 'Beschreibung/Grund der Direktzustellung.';

// #######################################
// ####             Forderungen              ####
// #######################################
export const anspruchFallcodeTooltip = 'Wählen Sie den am treffensten Fallcode für Ihren Schriftsatz, zB. bei einem Verkehrsunfall „07 Schaden aus Verkehrsunfall.';
export const anspruchEVFallcodeTooltip = 'Wählen sie den Fallcode, der auf Ihren Schriftsatz am besten zutrifft, z.B. bei häuslicher Gewalt: "91F) einstweilige Vfg. außerhalb - Familienrechtssache.';
export const anspruchAnspruchtextTooltip = 'Zusätzliche Beschreibung zum Fallcode, zB. bei „01 Lieferung Kaufpreis“ erfassen Sie genau was geliefert wurde. (255 Zeichen)';
export const anspruchBelegnummerTooltip = 'zB. Rechnungsnummer (10 Zeichen)';
export const anspruchAnspruchBetragTooltip = 'Die Höhe der Forderung in Euro (16 Zeichen)';
export const anspruchAnspruchsdatumvonTooltip = 'zB. Datum der Rechnung';
export const anspruchAnspruchsdatumbisTooltip = 'Enddatum der Forderung (optional)';
export const zinsenstaffelBasiszinssatzTooltip = 'Zinsen für Unternehmen bei einem beiderseitigen Unternehmergeschäft.';
export const zinsenstaffelZinssatzTooltip = 'Erfassen Sie die Zinsen in %. Bei einem beiderseitigen Unternehmergeschäft, aktivieren Sie den Basiszinssatz. (6 Zeichen)';
export const zinsenstaffelAusTooltip = 'Betrag von dem die Zinsen berechnet werden sollen. (16 Zeichen)';
export const zinsenstaffelVonTooltip = 'Datum, ab wann die Zinsen berechnet werden sollen. Das Feld „Bis“ kann leer bleiben, wenn die Zinsen weiterlaufen.\x0AWenn Sie „ab Klagszustellung“ aktivieren, werden die Felder „Von - Bis“ deaktiviert';
export const zinsenstaffelAbklagszustellungTooltip = 'Die Zinsen werden ab der Klagszustellung berechnet, die Eingabe der Felder „Von-Bis“ ist deaktiviert.\x0ADie Zinseszinsen werden ab der Klagszustellung berechnet, die Eingabe des Feldes „Zinseszinsen seit“ ist deaktiviert.';
export const zinsenstaffelBisTooltip = 'Wenn die Zinsen weiterlaufen, lassen Sie das Feld leer.';
export const zinsenstaffelZeitraumTooltip = 'Periode für die Zinsen: monatlich, viertel-, halb- oder jährlich.';
export const zinsenstaffelKapitalisierungTooltip = 'Kapitalisierung der Zinsen laut Periode. Wenn Kapitalisierung aktivert ist, können Sie keine Zinseszinsen erfassen.';
export const zinsenstaffelZinseszinzenprozentTooltip = 'Zinseszinsen in %. (6 Zeichen)';
export const zinsenstaffelZinseszinzenseitTooltip = 'Ab wann sollen Zinseszinsen berechnet werden.\x0AWenn diese ab Klagszustellung berechnet werden sollen, aktivieren Sie (rechts) die Option „ab Klagszustellung“.';
export const zinsenZinsenbetragTooltip = 'Zinsenbetrag von der klagenden Partei errechnet und nicht in der Zinsstaffel enthalten.';
export const zinsenstaffelVertragsabschlussdatumTooltip = 'Datum des Vertragsabschlusses.';
export const beweisBeweisartTooltip = 'Die Beweismittel, deren sich die klagende Partei zum Nachweis ihrer Sachverhaltsbehauptungen zu bedienen beabsichtigt.';
export const beweisTextTooltip = 'Die Beweismittel, deren sich die klagende Partei zum Nachweis ihrer Sachverhaltsbehauptungen zu bedienen beabsichtigt,\x0AzB. Name und Adresse der Zeugen,... (255 Zeichen)';
export const zustaendigkeitPlzTooltip = 'PLZ der besondern Zuständigkeit. (4 Zeichen)';
export const zustaendigkeitOrtTooltip = 'Ort der besonderen Zuständigkeit. (30 Zeichen)';
export const sonstigekostenTextTooltip = 'Beschreibung von sonstigen bescheinigten Auslagen/Kosten, zB. Kosten einer Meldeanfrage, Kopier- und Portokosten, usw. (50 Zeichen)';
export const sonstigekostenBetragTooltip = 'Betrag in Euro. (16 Zeichen)';
export const glaeubigerInfoTooltip = '(Familienname, Titel, Vorname oder Firma), bei gerichtlicher (exekutiver) Überweisung der Forderung auch Gericht,\x0AGeschäftszahl und Datum der Exekutionsbewilligung) (255 Zeichen)';

// #######################################
// ####             Titel              ####
// #######################################
export const titelDatumTooltip = 'Datum des Titels (nicht aktuelles Datum).';
export const titelArtTooltip = 'Art des Titels, zB. Zahlungsbefehl.';
export const titelErlaeuterungTooltip = 'Erläuterung zum Titel';
export const titelGerichtTooltip = 'Gericht, welches den Titel ausgestellt hat.';
export const titelVollstreckbarkeitsdatumTooltip = 'Datum der Vollstreckbarkeit.';
export const titelUnterhaltTagTooltip = 'Zahlungstag im Monat.';
export const titelForderungTooltip = 'Forderung + Nebenforderung (ohne Zinsen und Kosten) (16 Zeichen).';
export const titelNebenforderungTooltip = 'Summe der Nebenforderungen (sind in der Forderung enthalten) (16 Zeichen).';
export const titelZinsenbetragTooltip = 'Zinsenbetrag von der klagenden Partei errechnet und nicht in der Zinsstaffel enthalten (16 Zeichen).';
export const titelKostenTooltip = 'Die Kosten des Titels (16 Zeichen).';
export const titelZinsenkostenTooltip = 'Zinsen aus Kosten, oft 4% (§54a) (16 Zeichen).';
export const titelZinsenkostenseitTooltip = 'Ab wann werden die Zinsen aus den Kosten berechnet?';
export const titelUnterhaltAb = 'Unterhalt ab Datum...';
export const titelBetragTooltip = 'Höhe des Betrages (16 Zeichen).';

// #######################################
// ####             Begehren              ####
// #######################################
export const bezugEinlagezahlTooltip = 'Einlagezahl (EZ) der betroffenen Einlage (5 Zeichen).';
export const bezugKatastralgemeindeTooltip = 'Nummer der Katastralgemeinde (KG), welcher die betroffene Einlage zugeordnet ist (5 Zeichen).';
export const bezugAnderesBegehrenTooltip = 'Hier kann auf ein anderes Begehren im (gleichen oder oben ausgewählten) Grundbuchsantrag verwiesen werden.';
export const bezugPfandrechtEintragungFuerTooltip = 'Person, die als (neuer) Eigentümer ins Grundbuch eingetragen werden soll.';
export const urkundenEditErfassteDokumenteTooltip = 'Auswahl aus der unter „Dokumente“ erfassten Urkunden.';
export const urkundenEditUrkundenkapitelTooltip = 'Beim „Urkundenkapitel“ können Sie auf einen bestimmten Teil der Urkunde, zB. Kapitel, Abschnitt, Punkt,... verweisen (100 Zeichen).';
export const gebuehrArtTooltip = 'Optionen für die Pauschalgebühr. Standard ist der Eintrag „(2) Gebührenvorschreibung (Entrichtung per Zahlschein)“.';
export const gebuehrenBefreiungsgrund = 'Bitte wählen Sie den zutreffenden Gebührenbefreiungsgrund aus.';
export const gebuehrMaxbetragTooltip = 'Legt den maximalen Betrag fest, der mittels Bankeinzug eingehoben werden darf. Wenn der zu zahlende Betrag größer ist, wird die Zahlung zur Gänze mittels Zahlschein abgewickelt.';
export const gebuehrFreitextTooltip = 'Freitext zur Eintragungsgebühr (500 Zeichen)';
export const gebuehrWaehrungTooltip = 'Währung, Standard sind EUR.';
export const gebuehrBegruendungTooltip = 'Begründung, zB. Gemäß § 15 Agrarverfahren oder Neubegründungsförderungsgesetz... (100 Zeichen)';
export const gebuehrBmsgrdlBetragTooltip = 'Betrag der Bemessungsgrundlage (200 Zeichen).';
export const gebuehrBmsgrdlErfassungsnummerTooltip = 'Eine oder mehrere Erfassungsnummern, zB. Aktenzahl einer für andere Aspekte des aktuellen GB-Geschäftsfalles zuständigen BMF- bzw. FA-Abteilung (50 Zeichen).';
export const gebuehrBmsgrdlReferenznummerTooltip = 'Steuer Nummer, mit der die Angabern zur Bemessungsgrundlage belegt werden. Dies ist zB. die Einheitswertaktenzahl (EW AZ = Aktenzahl der für den GB-Geschäftsfall zuständigen BMF- bzw, FA-Abteilung) (50 Zeichen).';
export const gebuehrBmsgrdlVorgangsnummerTooltip = 'Finanzamts-Vorgangsnummer (VGNR) - wird durch Finanz Online vergeben (zB. 101472002015_001001) (19 Zeichen).';
export const gebuehrBmsgrdlAnderesBegehrenTooltip = 'Verweis auf ein Begehren, in dem der summarische Betrag für die (Gesamt-) Bemessungsgrundlage angegeben ist.';
export const begehrenstextTooltip = 'Zusätzlicher Text, der in der Grundbucheintragung aufscheinen soll.';
export const bezugAnteileCLNRTooltip = 'Laufnummer im Lasten-Abschnitt (5 Zeichen).';
export const bezugAnteileGrundstuecksnummerTooltip = 'Grundstücksnummer (12 Zeichen)';
export const bezugAnteileHauptGrundstuecksnummerTooltip = 'Hauptgrundstücksnummer (12 Zeichen)';
export const bezugAnteileRangordnungscodeTooltip = 'Bezeichnet den Code, mit dem die eingetragene Rangordnung im Grundbuchsystem identifiziert wird. Dieser Code ersetzt ab 2010 den gedruckten Rangordnungsbeschluß. zB. „A46D-FGPQ-6FNY-Z7ML-4FLG-JE5“ (28 Zeichen)';
export const bezugAnteileSingleCLNRTooltip = 'Laufnummer im Lasten-Abschnitt (5 Zeichen).';
export const bezugAnteilePfandrechtVormerkung = 'Vormerkung';
export const bezugAnteilePfandrechtEintragungszusatz = 'Zusätzlicher Text, der in der Grundbucheintragung aufscheinen soll.';
export const bezugAnteileLoeschungenTeilLoeschungBegehrenstext = 'Freitexterklärung zur Teil-Löschung';
export const bezugAnteileWohnungseigentumObjectbezeichnung = 'Name des wohnungseigentumstauglichen Objekts, zB. „Wohnung 4“ (100 Zeichen).';
export const bezugAnteileBlnrTooltip = 'Bereits existierende B-LNR: z.B. 2, welche für den Antragsteller einverleibt werden soll.';
export const bezugAnteileLiteralTooltip = 'Zur Berichtigung einer bestimmten Literal Eintragung.';
export const bezugAnteileVormerkungshinweis = 'Hinweis, bzw. eine Erklärung für die Vormerkung, zB. „Unbedenklichkeitsbescheinigung fehlt“.';
export const bezugAnteileRangordnungsCode = 'Bezeichnet den Code, mit dem die eingetragene Rangordnung im Grundbuchsystem identifiziert wird. Dieser Code ersetzt ab 2010 den gedruckten Rangordnungsbeschluß. zB. „A46D-FGPQ-6FNY-Z7ML-4FLG-JE5“ (28 Zeichen)';
export const bezugAnteileGesetzesgrundlage = 'Angabe einer speziellen Gesetzesgrundlage.';
export const bezugAnteileZusatzTooltip = 'zB. „Leibrente monatlich“, „Kaufpreisrestforderung“,... (100 Zeichen)';
export const bezugAnteileBetragTooltip = 'Betrag (und Währung) oder in %';
export const bezugAnteileForderungBetrag = 'Forderungsbetrag';
export const bezugAnteileZinsenInPercent = 'Zinsen in % (20 Zeichen)';
export const bezugAnteileVerzugszinsen = 'Verzugszinsen in % (20 Zeichen)';
export const bezugAnteileZinseszinsen = 'Zinseszinsen in % (20 Zeichen)';
export const bezugAnteileNebengebuehrensicherst = 'Nebengebührensicherstellung (NGS) in %';
export const bezugAnteileNebensicherstBetrag = 'Höhe des Nebensicherstellungsbetrags';
export const bezugAnteileNebenforderungZusatz = 'Nähere Angaben, zB. „jährlich“, „dekursiv“, „höchstens“,... (100 Zeichen)';
export const begehrenBegruendungszeileAnteilsZaehler = 'Zähler der Anteilsbruchzahl, der für den neuen Eigentümer eingetragen wird. z.B. „1/8“ bedeutet, dass der neue Eigentümer „1/8“ der Anteile erhält und der eingetragene Eigentümer „3/8“ behält.';
export const begehrenBegruendungszeileAnteilsNenner = 'NENNER der Anteilsbruchzahl, der für den neuen Eigentümer eingetragen wird. zB. „1/8“ bedeutet, dass der neue Eigentümer „1/8“ der Anteile erhält und der eingetragene Eigentümer „3/8“ behält.';
export const begehrenAenderungszeileLNR = 'B-Laufnummer des betroffenen Anteils.';
export const begehrenAenderungszeileLiteral = 'Zur Berichtigung einer bestimmten Literal Eintragung.';
export const BLNRTooltip = 'Bereits existierende B-LNR: z.B. 2, welche für den Antragsteller einverleibt werden soll.';
export const BLNRInLoeschungenBegehrenTooltip = 'Eine oder mehrere B-LNR (z.B.: 2,6), die aus der Liste der Referenzen in der C-Eintragung (zu löschende Eintragung) gelöscht werden soll.';
export const schriftsatzNewBVGH = 'Das Bundesverwaltungsgericht ist österreichweit die zentrale Anlaufstelle für Beschwerden gegen Behördenentscheidungen in Angelegenheiten der unmittelbaren Bundesverwaltung - mit Ausnahme des Finanzrechts (zuständig ist das Bundesfinanzgericht).';
export const schriftsatzNewTLDZ = 'Sie können PDF Dokumente an einen ERV-Teilnehmer übermitteln. Die Daten gehen nicht an das Gericht, sondern direkt über die Übermittlungsstellen an einen ERV-Teilnehmer. Zum Beispiel vom Rechtsanwalt des Klägers an den Rechtsanwalt der Gegenpartei.';
export const schriftsatzNewEinstweiligeVerf = 'Eine einstweilige Verfügung ist eine gerichtliche Sofortmaßnahme, die auf Antrag unter bestimmten, in der Exekutionsordnung vorgesehenen, Voraussetzungen zur einstweiligen Sicherung getroffen werden. Eine einstweilige Verfügung kann beim Bezirksgericht, Landesgericht oder Arbeitsgericht eingebracht werden.';
export const schriftsatzNewEUM = 'Der Antrag auf europäischen Zahlungsbefehl (max. € 5.000) soll die Abwicklung von grenzüberschreitenden Schuldenbetreibungen anhand eines einheitlichen Verfahrens in allen Mitgliedstaaten erleichtern.';
export const schriftsatzNewExek = 'Fahrnisexekutionen, Forderungsexekutionen (§ 294a), Rechteexekutionen, Herausgabeexekutionen, Räumungsexekutionen, Erwirkung von anderen vertretbaren Handlungen, Erwirkung von anderen unvertretbaren Handlungen, Ansprüche auf Herausgabe oder Leistung von körp. Sachen Unterlassungsexekutionen, Sonstige Exekutionen ...sowie Kombinationen bis zu € 50.000. Liegenschaftsexekutionen können NICHT per ERV eingebracht werden.';
export const schriftsatzNewFB = 'Neueintragungen (Online Gründung), Anträge zu einer bestehenden Firma und Einbringung von Jahresabschlüssen.';
export const schriftsatzNewGH = 'Anträge und Folgeeingaben an den Verfassungs- und Verwaltungsgerichtshof.';
export const schriftsatzNewGB = 'Einbringung von Grundbuchsanträgen.';
export const schriftsatzNewKartellEA = 'Kartellerstantrag beim Oberlandesgericht.';
export const schriftsatzNewKlage = 'Bezirksgericht (Räumung, Besitzstörung, Exekutionsrechtliche Klagen, Scheidung, Unterhalt, Wechselmandatsverfahren, Aufhebung/Nichtigkeit der Ehe, Sonstiger Streitgegenstand), Landesgericht (Wechselmandatsverfahren, Sonstiger Streitgegenstand), Arbeitsgericht (sonstige betriebsverfassungsrechtliche Sachen, sonstiger Streitgegenstand), Sozialrechtssache (Leistung, Einstellung, Feststellung, Rückersatz).';
export const schriftsatzNewKuendigung = 'Kündigungen an das Bezirks- und Arbeitsgericht.';
export const schriftsatzNewMahnKlage = 'Bezirksgericht (Klagen erster Instanz im Zivilrechtsbereich für alle Rechtssachen bis € 15.000 sowie (unabhängig vom Streitwert) für bestimmte Arten von Rechtssachen gem. §49 JN), Landesgericht (Klagen in welchen das Bezirksgericht in erster Instanz nicht zuständig ist, sowie in zweiter Instanz für die Behandlung der Rechtsmittel gegen Entscheidung der Bezirksgerichte gem §50 JN), Arbeitsgericht (Klagen mit einem Streitwert bis € 75.000, einzubringen bei Arbeits- und Sozialgerichten).';
export const schriftsatzNewSE = 'All jene Schriftsätze, welche nicht in der Aufzählung vorkommen. Bei einer sonstigen Ersteingabe ist noch keine Geschäftszahl eines Gerichts vorhanden. Zum Beispiel: bei Exekutionen „Antrag auf Kostenbestimmung“, Forderungsanmeldungen bei Insolvenzen, etc.';
export const schriftsatzNewSFE = 'All jene Schriftsätze, welche nicht in der Aufzählung vorkommen. Bei einer sonstigen Folgeeingabe ist bereits eine Geschäftszahl eines Gerichts vorhanden.';
export const schriftsatzNewStaatsanw = 'Eingaben an die Staatsanwaltschaften (mit oder ohne Justiz-Aktenzeichen).';
export const scriftzsatzEinverleibungTooltip = 'Dieses Element ist dann anzugeben, wenn eine Einverleibung der Löschung begehrt wird.';
export const begehrenUrkundenhinterlegungsgesetzArrowDownTooltip = 'Urkundenhinterlegungsregister:\n'
  + 'Die im UH-Register geführten Elemente sind Bauwerke (Superädifikate). Jedes Bauwerk liegt in einer\n'
  + 'eigenen UH-Einlage (dzt. in einer eigenen Karteikarte bei Gericht). Ein Bauwerk hat eine Bezeichnung\n'
  + 'und liegt auf 1 bis n Grundstücken.\n'
  + 'Zum Bauwerk können Urkunden hinterlegt werden, die bestimmte Sachverhalte bestätigen bzw.\n'
  + 'nachweisen (z.B. Eigentumsrecht, Pfandrecht). Diese Hinterlegung kann in 2 Ausprägungen passieren:\n'
  + 'Einreihung und Hinterlegung.';

// #######################################
// ####             Strukturierter Firmenbuch              ####
// #######################################
export const strukFbFirmaFirmenname = 'Bezeichnung der Firma inkl. Rechtsform (38 Zeichen). Sie können bis zu 12 Zeilen je 38 Zeichen erfassen.';
export const strukFbFirmaZustellanweisung = 'c/o, p. Adr., z.Hd. (100 Zeichen)';
export const strukFbFirmaStrasseUnstrukturiert = 'Die Straße ist im Firmenbuch noch in einem alten Format (unstrukturiert) eingetragen. Wenn Sie eine Adressänderung vornehmen, erfassen Sie die Straße / HausNr / Tür / Stiege getrennt!';
export const strukFbFirmaAdresse = 'Adresse (76 Zeichen)';
export const strukFbFirmaHausnr = 'Hausnummer (10 Zeichen)';
export const strukFbFirmaStiege = 'Stiege (10 Zeichen)';
export const strukFbFirmaTuer = 'Türnummer (10 Zeichen)';
export const strukFbFirmaPlz = 'Postleitzahl (10 Zeichen)';
export const strukFbFirmaOrt = 'Ort (95 Zeichen)';
export const strukFbFirmaAusland = 'inländische oder ausländische Firma';
export const strukFbFirmaZustellbar = 'Ist die Anschrift zugleich Post-Zustelladresse?';
export const strukFbFirmaSitz = 'Name der politischen Gemeinde (100 Zeichen)';
export const strukFbFirmaGemeindennummer = 'politische Gemeindenummer (5 Zeichen)';
export const strukFbFirmaHomepage = 'Homepage/Webseite des Unternehmens (200 Zeichen)';
export const strukFbFirmaGeschaeftszweig = 'Geschäftszweig der Firma (65 Zeichen)';
export const strukFbFirmaBetrag = 'Stammkapital der Firma (100 Zeichen)';
export const strukFbFirmaKapitalPrivileg = 'Eine gründungsprivilegierte GmbH kann, sofern dies im Gesellschaftsvertrag verankert wurde, mit einer Stammeinlage von € 10.000,- gegründet werden. Davon ist zumindest die Hälfte, also € 5.000,- sofort in bar einzuzahlen und Sacheinlagen sind ausgeschlossen.';
export const strukFbFirmaKapitalGsmin = 'Kapital Gsmin (7 Zeichen)';
export const strukFbFirmaZahlbar = 'Zahlbar (60 Zeichen)';
export const strukFbFirmaBeschreibungen = 'Beschreibungen (60 Zeichen)';
export const strukFbFirmaUnternehmensdauerBis = 'Gültig bis zum z.B. 15.03.2025';
export const strukFbFirmaUnternehmensdauerText = 'Text zur Unternehmensdauer, darf keine Leerzeilen haben und Textzeilen dürfen nicht mit "blank" beginnen (13 Zeilen zu je 60 Stellen)';
export const strukFbFirmaJahresabschlussStichtag = 'Bilanzstichtag der Jahresabschlusseinreichung';
export const strukFbFirmaBilanzstichtag = 'Bilanzstichtag: Monat und Tag, z.B. "1231" (ist der 31.12.). Als letzter Tag im Februar bitte "0229" erfassen.';
export const strukFbFirmaKonzernabschluss = 'Bilanzstichtag der Konzernabschlusseinreichung';
export const strukFbFirmaKonzernstichtag = '"Konzernstichtag: Monat und Tag, z.B. "1231" (ist der 31.12.). Als letzter Tag im Februar bitte "0229" erfassen.';
export const strukFbFirmaVertretungsbefugnis = 'Die Generalversammlung bestimmt, wenn mehrere Geschäftsführer oder Gesamtprokuristen bestellt sind, deren Vertretungsbefugnis. Die Geschäftsführer können die Gesellschaft auch gemeinsam mit Gesamtprokuristen vertreten. (60 Zeichen)';
export const strukFbFirmaArtDerBekanntmachung = 'Wo finden die Bekanntmachungen der Firma statt? (60 Zeichen)';
export const strukFbFirmaSonstigeBestimmungen = 'Die Firma betreffende, sonstige Bestimmungen (60 Zeichen)';
export const strukFbFirmaErgaenzendeBeschreibung = 'Ergänzende Beschreibung zum Antrag (80 Zeichen)';

export const strukFbStatSBezugZu = 'Rechtstatsachen kann es zu den Bereichen Hauptniederlassung (zB Gesellschaftsvertrag), Zweigniederlassung (zB Verlegungsplan), Funktion (zB Haftungsausschluss) oder Person (zB Partnerschaftsurkunde) geben. Die Zuordnung zu den genannten Stellen erfolgt durch die Platzierung in der Struktur.';
export const strukFbStatSCodeBezeichnung = 'Rechtstatsachencode, muss in der Wertetabelle vorkommen';
export const strukFbStatSText = 'Nähere Details z.B. zur Urkunde (40 Stellen)';
export const strukFbStatSDatumErrichtungUrkunde = 'Datum der Errichtung der Urkunde.';
export const strukFbStatSBeschreibung = 'Rechtstatsachenbeschreibung (13 Zeilen zu je 60 Zeichen)';
export const strukFbStatSTexte = 'Zusatztext zu Rechtstatsachenbeschreibung.';
export const strukFbStatSZeichen = 'Geschäftszahlen, zusätzliche Datumsangaben (25 Stellen)';

export const strukFbPersonKontaktType = 'Der "Name" einer natürlichen bzw die "Bezeichnung" einer juristischen Person';
export const strukFbPersonTitelVor = 'Vorgestellter Titel der natürlichen Person, zB "Dr." (40 Zeichen)';
export const strukFbPersonVorname = 'Vorname der natürlichen Person, zB "Max" (80 Zeichen)';
export const strukFbPersonNachname = 'Nachname der natürlichen Person, zB "Mustermann" (40 Zeichen)';
export const strukFbPersonTitelNach = 'Nachgestellter Titel der natürlichen Person, zB "MSc" (40 Zeichen)';
export const strukFbPersonGeburtsdatum = 'Muss in der Vergangenheit liegen, zB "1990-01-01"';
export const strukFbPersonStaat = 'Staat';
export const strukFbBezeichnung = '"Bezeichnung" einer juristischen Person.';
export const strukFbAbsenderCodeNichtVerhanden = 'Ist die FN-Nummer "noch nicht vorhanden / nicht bekannt" klicken Sie hier auf die Checkbox und die FN-Nummer wird mit Nullen befüllt';
export const strukFbAbsenderCode = 'Firmenbuchnummer.:\n1. ohne FN, ohne Prüfzeichen eingeben:\n2. z.B.: "FN 1234k" --> Sie geben "1234" ein\n3.das System ergänzt danach noch automatisch auf 6 Stellen mit vorangestellten Nullen. --> "001234"\n4. In der Visualisierung wird Ihnen die FN ohne Nullen und mit Prüfzeichen angezeigt (z.B.: "FN 1234k")\nIst die FN-Nummer "noch nicht vorhanden / nicht bekannt" klicken Sie auf die nachstehende Checkbox und die FN-Nummer wird mit Nullen befüllt.';
export const strukFbPersonZustellbar = 'Ist die Anschrift zugleich Post-Zustelladresse?';
export const strukFbPersonNummer = 'Person Fnr.';
export const strukFbPersonAbsenderCode = 'Absender Code (7 Zeichen)';
export const strukFbPersonenPnr = 'Personen Ordnungsnummer (3 Zeichen)';
export const strukFbPersonenHandelsgericht = 'Registrierungsstelle (52 Zeichen)';
export const strukFbPersonenFnr = 'Kennzeichnung (Firmenbuchnummer) des Rechtsträgers (52 Zeichen)';
export const strukFbPersonenSitz = 'Sitz des ausländischen Rechtsträgers (52 Zeichen)';
export const strukFbPersonenRechtsform = 'Muss in Kombination mit dem Staat aus der Adresse der Person in der Wertetabelle vorkommen. (3 Zeichen)';

export const strukFbFunktionAbwicklerVertretungsart = 'Muss in der Wertetabelle FBFUNK (VARTALLE) vorkommen (1 Stelle)';
export const strukFbFunktionDatumVon = 'Datum, ab wann der Geschäftsführer seine Funktion ausüben darf.';
export const strukFbFunktionMiteintragung = '"ja", wenn das "Datum von" das Datum der Eintragung ins Firmenbuch sein soll';
export const strukFbFunktionDatumBis = 'Datum, bis wann der Geschäftsführer seine Funktion ausüben darf.';
export const strukFbFunktionTextVertretung = 'Darf keine Leerzeilen haben und Textzeilen dürfen nicht mit "blank" beginnen (13 Zeilen zu je 60 Stellen).';
export const strukFbFunktionZusatzVertretung = 'Muss in der Wertetabelle FBFUNK (VSBALLE) vorkommen.';
export const strukFbFunktionVon = 'Datum, ab wann der Geschäftsführer seine Funktion ausüben darf.';
export const strukFbFunktionBis = 'Datum, bis wann der Geschäftsführer seine Funktion ausüben darf.';
export const strukFbFunktionBezugPNR = 'Kennung einer bestehenden, bereits im Firmenbuch eingetragenen Person, zB " A".';
export const strukFbFunktionEinstweiligerVerwalterVertretungsart = 'muss in der Wertetabelle FBFUNK (VARTALLE) vorkommen (1 Stelle).';
export const strukFbFunktionGeschaeftsfuehrerVertretungsart = 'muss in der Wertetabelle FBFUNK (VARTALLE) vorkommen (1 Stelle).';
export const strukFbFunktionKapitalWaehrung = 'Währung: muss in der Wertetabelle FBGELD vorkommen (Stammkapital des Gesellschafters).';
export const strukFbFunktionKapitalBetrag = 'Betrag: muss in der Wertetabelle FBGELD vorkommen (Stammkapital des Gesellschafters).';
export const strukFbFunktionEinbezahltWaehrung = 'Währung: muss in der Wertetabelle FBGELD vorkommen (Bereits geleistetes Stammkapital des Gesellschafters).';
export const strukFbFunktionEinbezahltBetrag = 'Betrag: muss in der Wertetabelle FBGELD vorkommen (Bereits geleistetes Stammkapital des Gesellschafters).';
export const strukFbFunktionPrivilegWaehrung = 'Währung: muss in der Wertetabelle FBGELD vorkommen.';
export const strukFbFunktionPrivilegBetrag = 'Betrag: muss in der Wertetabelle FBGELD vorkommen.';
export const strukFbFunktionProkuristVertretungsart = 'muss in der Wertetabelle FBFUNK (VARTALLE) vorkommen (1 Stelle).';
export const strukFbFunktionVertretungsBefugtNurFuer = 'Kennzeichnung der neuen oder bereits bestehenden Zweigniederlassung; der Prokurist ist nur für die genannten Zweigniederlassungen vertretungsbefugt; "000" steht dabei für die Hauptniederlassung; ist dieser Tag nicht angegeben, dann besteht uneingeschränkte Vertretungsbefugnis.';
export const strukFbFunktionVorlaeufigerVerwalterVertretungsart = 'muss in der Wertetabelle FBFUNK (VARTALLE) vorkommen (1 Stelle).';

export const strukFbZweigBezeichnungen = 'Für juristische Personen; eine Bezeichnung darf keine Leerzeilen haben und nicht leere Zeilen dürfen nicht mit "blank" beginnen (11 Zeilen zu je 38 Stellen).';
export const strukFbZweigGeschaeftszweige = 'Darf keine Leerzeilen haben und Textzeilen dürfen nicht mit "blank" beginnen (40 Zeilen zu je 65 Stellen).';
