export const HELP_URL_OVERVIEW = 'home';

// #######################################
// ####           Schriftsatz        ####
// #######################################
export const HELP_URL_SCHRIFTSATZ_NEW = 'schriftsatz/new';
export const HELP_URL_SCHRIFTSATZ_INTERNE_INFO = 'schriftsatz/interneinfo';
export const HELP_URL_SCHRIFTSATZ_VWGH = 'schriftsatz/vf/vwgh';
export const HELP_URL_SCHRIFTSATZ_VFGH = 'schriftsatz/vf/vfgh';
export const HELP_URL_SCHRIFTSATZ_BVGH = 'schriftsatz/vf/asylgh';
export const HELP_URL_SCHRIFTSATZ_STV = 'schriftsatz/stv/stveingabe';
export const HELP_URL_SCHRIFTSATZ_GB = 'schriftsatz/gb/antrag';
export const HELP_URL_SCHRIFTSATZ_GB_GRUPPE = 'schriftsatz/gb/gruppenantrag';
export const HELP_URL_SCHRIFTSATZ_GB_URKUNDENSCHWAERZUNG = 'schriftsatz/gb/urkundenschwaerzung';
export const HELP_URL_SCHRIFTSATZ_VALIDATE = 'schriftsatz/validation';
export const HELP_URL_SCHRIFTSATZ_PROTOKOLL = 'schriftsatz/print';
export const HELP_URL_SCHRIFTSATZ_SEARCH = 'schriftsatz/search';
export const HELP_URL_SCHRIFTSATZ_SEARCH_RESULT = 'schriftsatz/result';
export const HELP_URL_SCHRIFTSATZ_MAHNKLAGE = 'schriftsatz/vj/mahnklage';
export const HELP_URL_SCHRIFTSATZ_KLAGE = 'schriftsatz/vj/sonstigeklage';
export const HELP_URL_SCHRIFTSATZ_EZUSTELLUNG = 'schriftsatz/vj/ezustellung';
export const HELP_URL_SCHRIFTSATZ_SONSTIGE_ERSTEINGABE = 'schriftsatz/vj/ersteingabe';
export const HELP_URL_SCHRIFTSATZ_STAATSANWALTSCHAFTEN = 'schriftsatz/sta/staeingabe';
export const HELP_URL_SCHRIFTSATZ_SONSTIGE_FOLGEEINGABE = 'schriftsatz/vj/folgeeingabe';
export const HELP_URL_SCHRIFTSATZ_EXEKUTIONSANTRAG = 'schriftsatz/vj/exekutionsantrag';
export const HELP_URL_SCHRIFTSATZ_KUENDIGUNG = 'schriftsatz/vj/kuendigung';
export const HELP_URL_SCHRIFTSATZ_VERFUEGUNG = 'schriftsatz/vj/verfuegung';
export const HELP_URL_SCHRIFTSATZ_KARTELLERSTANTRAG = 'schriftsatz/vj/kartellerstantrag';
export const HELP_URL_SCHRIFTSATZ_EUM = 'schriftsatz/eu/mahnverfahren';
export const HELP_URL_SCHRIFTSATZ_EUM_C = 'schriftsatz/eu/formblattc';
export const HELP_URL_SCHRIFTSATZ_EUM_VERZICHT = 'schriftsatz/eu/verzicht';
export const HELP_URL_SCHRIFTSATZ_EUM_F = 'schriftsatz/eu/formblattf';
export const HELP_URL_SCHRIFTSATZ_EUM_BRIEF = 'schriftsatz/eu/eubrief';
export const HELP_URL_SCHRIFTSATZ_DIREKTZUSTELLUNG = 'schriftsatz/vj/direktverkehr';
export const HELP_URL_SCHRIFTSATZ_FB_ANTRAGZURFIRMA = 'schriftsatz/fb/antragzurfirma';
export const HELP_URL_SCHRIFTSATZ_FB_JAHRESABSCHLUSS = 'schriftsatz/fb/jahresabschluss';
export const HELP_URL_SCHRIFTSATZ_FB_NEUEINTRAGUNG = 'schriftsatz/fb/neueintragung';
export const HELP_URL_SCHRIFTSATZ_FB_ONLINE_GRUENDUNG = 'schriftsatz/fb/neueonlinegrue';
export const HELP_URL_SCHRIFTSATZ_FB_NEUEINTRAGUNGSANTRAG = 'schriftsatz/fb/neueintragungsantrag';
export const HELP_URL_SCHRIFTSATZ_FB_AENDERUNGSANTRAG = 'schriftsatz/fb/aenderungsantrag';
export const HELP_URL_SCHRIFTSATZ_FB_LOESCHUNGSANTRAG = 'schriftsatz/fb/loeschungsantrag';
export const HELP_URL_SCHRIFTSATZ_THB_ERSTMELDUNG = 'schriftsatz/ethb/erst_me_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_AENDERUNGERSTMELDUNG = 'schriftsatz/ethb/aend_erst_me_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_KVA = 'schriftsatz/ethb/kva_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_AKVA = 'schriftsatz/ethb/aend_kva_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_BERICHTSMELDUNG = 'schriftsatz/ethb/beri_me_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_FRISTERSTRECKUNGSMELDUNG = 'schriftsatz/ethb/fri_erstr_me_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_ABSCHLUSSMELDUNG = 'schriftsatz/ethb/abschl_me_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_UEBERNAMEERKLAERUNG = 'schriftsatz/ethb/uebernahme_a_rak';
export const HELP_URL_SCHRIFTSATZ_THB_KONTOEROEFFNUNG = 'schriftsatz/ethb/kontoeroe_a_bank';
export const HELP_URL_SCHRIFTSATZ_THB_AENDERUNGSERSTMELDUNG = 'schriftsatz/ethb/aend_erst_me_a_bank';
export const HELP_URL_SCHRIFTSATZ_THB_VERZICHTSMELDUNG = 'schriftsatz/ethb/verzi_me_a_bank';
export const HELP_URL_SCHRIFTSATZ_THB_KVA_BANK = 'schriftsatz/ethb/kva_a_bank';
export const HELP_URL_SCHRIFTSATZ_THB_ABSCHLUSSMELDUNG_BANK = 'schriftsatz/ethb/abschlu_a_bank';
export const HELP_URL_SCHRIFTSATZ_THB_BERICHTMELDUNG = 'schriftsatz/ethb/bericht_a_bank';
export const HELP_URL_SCHRIFTSATZ_THB_KONTOAUSZUG = 'schriftsatz/ethb/kontoauszug_a_bank';
export const HELP_URL_SCHRIFTSATZ_THB_KONTOSCHLIESSUNGMELDUNG = 'schriftsatz/ethb/kontoschliess_a_bank';

export const HELP_URL_FORDERUNGEN_ANPSRUCH_LIST = 'schriftsatz/vj/daten/listanspruch';
export const HELP_URL_FORDERUNGEN_ANSPRUCH_ITEM = 'schriftsatz/vj/daten/editanspruch';
export const HELP_URL_FORDERUNGEN_ZINSEN_LIST = 'schriftsatz/vj/daten/listzinsen';
export const HELP_URL_FORDERUNGEN_ZINSEN_ITEM = 'schriftsatz/vj/daten/editzinsen';
export const HELP_URL_FORDERUNGEN_ERGAENZUGNEN = 'schriftsatz/vj/daten/ergaenzungen';
export const HELP_URL_FORDERUNGEN_BEWEISE_LIST = 'schriftsatz/vj/daten/listbeweise';
export const HELP_URL_FORDERUNGEN_BEWEISE_ITEM = 'schriftsatz/vj/daten/editbeweise';
export const HELP_URL_FORDERUNGEN_BESONDERE_ZUSTAENDIGKEIT = 'schriftsatz/vj/daten/editzustaendigkeit';
export const HELP_URL_FORDERUNGEN_SONSITGE_KOSTEN_LIST = 'schriftsatz/vj/daten/listkosten';
export const HELP_URL_FORDERUNGEN_SONSTIGE_KOSTEN_ITEM = 'schriftsatz/vj/daten/editkosten';
export const HELP_URL_VORBRINGEN = 'schriftsatz/vj/daten/editvorbringen';
export const HELP_URL_VORBRINGEN_SACHVERHALT = 'schriftsatz/fb/daten/editsachverhalt';
export const HELP_URL_VORBRINGEN_KUMULIERTER_ANTRAG = 'schriftsatz/fb/daten/editkumulierterantrag';
export const HELP_URL_AUSGANG = 'verkehr/ausgang';
export const HELP_URL_EINGANG = 'verkehr/eingang';
export const HELP_URL_TITEL = 'schriftsatz/vj/daten/edittitel';
export const HELP_URL_LIST_TITEL = 'schriftsatz/vj/daten/listtitel';
export const HELP_URL_TITEL_ZINSEN_ITEM = 'schriftsatz/vj/daten/edittitelzinsen';

export const HELP_URL_FORDERUNGEN_EUM_ANSPRUCH_LIST = 'schriftsatz/eu/daten/listanspruch';
export const HELP_URL_FORDERUNGEN_EUM_ANSPRUCH_ITEM = 'schriftsatz/eu/daten/editanspruch';
export const HELP_URL_FORDERUNGEN_EUM_ZINSEN_LIST = 'schriftsatz/eu/daten/listzinsen';
export const HELP_URL_FORDERUNGEN_EUM_ZINSEN_ITEM = 'schriftsatz/eu/daten/editzinsen';
export const HELP_URL_FORDERUNGEN_EUM_ERGAENZUNGEN = 'schriftsatz/eu/daten/ergaenzungen';
export const HELP_URL_FORDERUNGEN_EUM_BEWEISE_LIST = 'schriftsatz/eu/daten/listbeweise';
export const HELP_URL_FORDERUNGEN_EUM_BEWEISE_ITEM = 'schriftsatz/eu/daten/editbeweise';
export const HELP_URL_FORDERUNGEN_EUM_BESONDERE_ZUSTAENDIGKEIT = 'schriftsatz/eu/daten/editzustaendigkeit';
export const HELP_URL_FORDERUNGEN_EUM_KOSTEN_LIST = 'schriftsatz/eu/daten/listkosten';
export const HELP_URL_FORDERUNGEN_EUM_KOSTEN_ITEM = 'schriftsatz/eu/daten/editkosten';


// #######################################
// ####           Beteiligte        ####
// #######################################
export const HELP_URL_VWGH_BETEILIGTE_LIST = 'schriftsatz/vf/daten/listbeteiligte';
export const HELP_URL_VWGH_BETEILIGTE_ITEM = 'schriftsatz/gb/daten/editbeteiligte';
export const HELP_URL_VJ_BETEILIGTE_LIST = 'schriftsatz/vj/daten/listbeteiligte';
export const HELP_URL_VJ_BETEILIGTE_ITEM = 'schriftsatz/vj/daten/editbeteiligte';
export const HELP_URL_GB_BETEILIGTE_LIST = 'schriftsatz/gb/daten/listbeteiligte';
export const HELP_URL_GB_BETEILIGTE_ITEM = 'schriftsatz/gb/daten/editbeteiligte';
export const HELP_URL_EUM_BETEILIGTE_LIST = 'schriftsatz/eu/daten/listbeteiligte';
export const HELP_URL_EUM_BETEILIGTE_ITEM = 'schriftsatz/eu/daten/editbeteiligte';
export const HELP_URL_STV_BETEILIGTE_LIST = 'schriftsatz/stv/daten/listbeteiligte';
export const HELP_URL_STV_BETEILIGTE_ITEM = 'schriftsatz/stv/daten/editbeteiligte';

// #######################################
// ####           Dokumente        ####
// #######################################
export const HELP_URL_VWGH_DOKUMENT_LIST = 'schriftsatz/vf/daten/listanhang';
export const HELP_URL_VWGH_DOKUMENT = 'schriftsatz/vf/daten/editanhang';
export const HELP_URL_FB_DOKUMENT_LIST = 'schriftsatz/fb/daten/listanhang';
export const HELP_URL_FB_DOKUMENT = 'schriftsatz/fb/daten/editanhang';
export const HELP_URL_VJ_DOKUMENT_LIST = 'schriftsatz/vj/daten/listanhang';
export const HELP_URL_VJ_DOKUMENT = 'schriftsatz/vj/daten/editanhang';
export const HELP_URL_STA_DOKUMENT_LIST = 'schriftsatz/sta/daten/listanhang';
export const HELP_URL_STA_DOKUMENT = 'schriftsatz/sta/daten/editanhang';
export const HELP_URL_GB_DOKUMENT_LIST = 'schriftsatz/gb/daten/listanhang';
export const HELP_URL_GB_DOKUMENT = 'schriftsatz/gb/daten/editanhang';
export const HELP_URL_EUM_DOKUMENT_LIST = 'schriftsatz/eu/daten/listanhang';
export const HELP_URL_EUM_DOKUMENT_ITEM = 'schriftsatz/eu/daten/editanhang';
export const HELP_URL_STV_DOKUMENT_LIST = 'schriftsatz/eu/daten/listanhang';
export const HELP_URL_STV_DOKUMENT_ITEM = 'schriftsatz/eu/daten/editanhang';

// #######################################
// ####           Erledigung        ####
// #######################################
export const HELP_URL_ERLEDIGUNG = 'verkehr/rueckverkehr';

// #######################################
// ####           Kontakt        ####
// #######################################
export const HELP_EDIT_KOTNAKT_BASISDATEN = 'kontakte/editkontakt';
export const HELP_EDIT_KOTNAKT_LIST_DETAILS = 'kontakte/listkontaktdetails';
export const HELP_EDIT_KOTNAKT_DETAILS = 'kontakte/editkontaktdetails';
export const HELP_EDIT_KOTNAKT_LIST_KONTEN = 'kontakte/listkonten';
export const HELP_EDIT_KOTNAKT_KONTO = 'kontakte/editkonten';
export const HELP_EDIT_SEARCH_KONTAKT = 'kontakte/searchkontakt';
export const HELP_EDIT_SEARCH_KONTAKT_LIST_KONTAKT = 'kontakte/listkontakt';

// #######################################
// ####           Einbringer        ####
// #######################################
export const HELP_URL_EINBRINGER_LIST_EINBRINGER = 'konfiguration/listprofil';
export const HELP_URL_EINBRINGER_EDIT_EINBRINGER = 'konfiguration/editprofil';
export const HELP_URL_EINBRINGER_EDIT_KONTEN = 'konfiguration/editkonten';

// #######################################
// ####           Dokument        ####
// #######################################
export const HELP_URL_DOKUMENT_UNTERSCHRIFT = 'schriftsatz/fb/daten/editunterschrift';

// #######################################
// ####           Begehren        ####
// #######################################
export const HELP_URL_BEGEHREN_LIST = 'schriftsatz/gb/begehren/liste';
export const HELP_URL_BEGEHREN_NEW = 'schriftsatz/gb/begehren/new';
export const HELP_URL_BEGEHREN_SONSTIGE_NEUE_EINLAGE = 'schriftsatz/gb/begehren/so_ne/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_UH_NEUE_EINLAGE = 'schriftsatz/gb/begehren/uh_so_ne/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_NEUEINTRAGUNG_EINTRAGUNGSFPAD = 'schriftsatz/gb/begehren/eintragungspfad';
export const HELP_URL_BEGEHREN_SONSTIGE_UH_NEUEINTRAGUNG_EINTRAGUNGSFPAD = 'schriftsatz/gb/begehren/uheintragungspfad';
export const HELP_URL_BEGEHREN_SONSTIGE_NEUEINTRAGUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/so_net/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_UH_NEUEINTRAGUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/uh_so_net/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_AENDERUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/so_ae/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_UH_AENDERUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/uh_so_ae/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_VORRANG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/so_vor/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_ERSICHTLICHMACHUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/uh_so_em/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_MITUEBERTRAGUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/so_mue/bezuganteile';
export const HELP_URL_BEGEHREN_SONSTIGE_AUFFORDERUNGSVERFAHREN_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/so_av/bezuganteile';
export const HELP_URL_BEGEHREN_BERECHTIGTE_PERSONEN = 'schriftsatz/gb/begehren/default/editberechtigteperson';
export const HELP_URL_BEGEHREN_URKUNDEN = 'schriftsatz/gb/begehren/default/editurkunden';
export const HELP_URL_BEGEHREN_EINTRAGUNGSGEBUEHR = 'schriftsatz/gb/begehren/default/eintragungsgebuehr';
export const HELP_URL_BEGEHREN_BEGEHRENSTEXT = 'schriftsatz/gb/begehren/default/zusatz';
export const HELP_URL_BEGEHREN_EINTRAGUNGSZUSATZ = 'schriftsatz/gb/begehren/default/zusatz';
export const HELP_URL_BEGEHREN_GLAEUBIGER = 'schriftsatz/gb/begehren/default/glaeubiger';
export const HELP_URL_BEGEHREN_GRUNDSTUECK_ANFORDEN = 'schriftsatz/gb/begehren/gv_tsttab/bezuganteile';
export const HELP_URL_BEGEHREN_GRUNDSTUECK_TRENNSTUECK_TABELLE_LIST = 'schriftsatz/gb/begehren/gv_tstdur/bezuganteile';
export const HELP_URL_BEGEHREN_GRUNDSTUECK_TRENNSTUECK_TABELLE_ZEILE = 'schriftsatz/gb/begehren/default/tstzeile';
export const HELP_URL_BEGHEREN_AB_ZUSCHREIBUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/gv_abzu_gst/bezuganteile';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_ANMERKUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/er_anm/bezuganteile';
export const HELP_URL_BEGEHREN_ANDERES_BEGEHREN = 'schriftsatz/gb/begehren/default/anderebegehren';
export const HELP_URL_BEGEHREN_EIGENTUMSANTEILE = 'schriftsatz/gb/begehren/default/eigentumsanteil';
export const HELP_URL_BEGEHREN_LIST_BERECHTIGTE_PERSON = 'schriftsatz/gb/begehren/default/listberechtigteperson';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_AENDERUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/er_aen/bezuganteile';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_BERICHTIGUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/er_ber/bezuganteile';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_EINVERLEIBUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/er_ein/bezuganteile';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_UH_EINVERLEIBUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/uh_er_ein/bezuganteile';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_UH_EINTRAGUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/er_eintr/bezuganteile';
export const HELP_URL_BEGERHEN_EIGENTUMSRECHT_VORMERKUNG_BEZUG_ANTEILE = 'schriftsatz/gb/begehren/er_vor/bezuganteile';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_EIGENTUMSANTEILE = 'schriftsatz/gb/begehren/default/anteile';
export const HELP_URL_BEGEHREN_ADRESSAENDERUNG_BEZUG = 'schriftsatz/gb/begehren/personenaenderung/adressaenderung/bezuganteile';
export const HELP_URL_BEGEHREN_PERSONENAENDERUNG_BEZUG = 'schriftsatz/gb/begehren/personenaenderung/namensaenderung/bezuganteile';
export const HELP_URL_BEGEHREN_TITELAENDERUNG_BEZUG = 'schriftsatz/gb/begehren/personenaenderung/titelaenderung/bezuganteile';
export const HELP_URL_BEGEHREN_SINGULAR_PFANDRECHT_EINTRAGUNG = 'schriftsatz/gb/begehren/pr_sin_ein_v2/bezuganteile';
export const HELP_URL_BEGEHREN_SINGULAR_UH_PFANDRECHT_EINTRAGUNG = 'schriftsatz/gb/begehren/uh_pr_sin_ein_v2/bezuganteile';
export const HELP_URL_BEGEHREN_SINGULAR_PFANDRECHT_ANMERKUNG = 'schriftsatz/gb/begehren/pr_sin_anm_v2/bezuganteile';
export const HELP_URL_BEGEHREN_SIMULTAN_PFANDRECHT_EINTRAUNG = 'schriftsatz/gb/begehren/pr_sim_ein_v2/bezuganteile';
export const HELP_URL_BEGEHREN_SIMULTAN_PFANDRECHT_ANMERKUNG = 'schriftsatz/gb/begehren/pr_sim_anm_v2/bezuganteile';
export const HELP_URL_BEGEHREN_LOESCHUNGEN = 'schriftsatz/gb/begehren/lo_loe/bezuganteile';
export const HELP_URL_BEGEHREN_LOESCHUNGEN_GANZE_EINLAGE = 'schriftsatz/gb/begehren/lo_loe_einlage/bezuganteile';
export const HELP_URL_BEGEHREN_LOESCHUNGEN_GANZE_EINLAGE_UH = 'schriftsatz/gb/begehren/uh_lo_loe_einlage/bezuganteile';
export const HELP_URL_BEGEHREN_UH_LOESCHUNGEN = 'schriftsatz/gb/begehren/uh_lo_loe/bezuganteile';
export const HELP_URL_BEGEHREN_WOHNUNGSEIGENTUM_BEGRUENDUNGSZEILE_LIST = 'schriftsatz/gb/default/begruendungszeilen';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_EIGENTUMSANTEILSZEILEN_LIST = 'schriftsatz/gb/default/eigetumsanteilszeilen';
export const HELP_URL_BEGEHREN_WOHNUNGSEIGENTUM_BEGRUENDUNG = 'schriftsatz/gb/we_begruendung/bezuganteile';
export const HELP_URL_BEGEHREN_EIGENTUMSRECHT_EIGENTUMSANTEIL = 'schriftsatz/gb/er_eigentumsanteil/bezuganteile';
export const HELP_URL_BEGEHREN_WOHNUNGSEIGENTUM_AENDERUNG = 'schriftsatz/gb/we_aenderung/bezuganteile';
export const HELP_URL_BEGEHREN_WOHNUNGSEIGENTUM_AENDERUNGSZEILE_LIST = 'schriftsatz/gb/default/aenderungszeilen';
export const HELP_URL_BEGEHREN_WOHNUNGSEIGENTUM_UMWANDLUNG = 'schriftsatz/begehren/we_umwandlung/bezuganteile';
export const HELP_URL_BEGEHREN_WOHNUNGSEIGENTUM_ANMERKUNG = 'schriftsatz/gb/begehren/we_e_anm/bezuganteile';
export const HELP_URL_BEGEHREN_WOHNUNGSEIGENTUM_ERSICHTLICHMACHUNG = 'schriftsatz/gb/begehren/we_e_ers/bezuganteile';
export const HELP_URL_BEGEHREN_RANGORDNUNG_VERAEUSSERUNG = 'schriftsatz/gb/begehren/ro_verae/bezuganteile';
export const HELP_URL_BEGEHREN_RANGORDNUNG_VERPFAENDUNG = 'schriftsatz/gb/begehren/ro_verpf/bezuganteile';
export const HELP_URL_BEGEHREN_RANGORDNUNG_EINRAEMUNG = 'schriftsatz/gb/begehren/ro_ein/bezuganteile';
export const HELP_URL_BEGEHREN_VERAEU_VERBOT_EINTRAGUNG = 'schriftsatz/gb/begehren/veraeu_verbot_eintragung/bezuganteile';

// #######################################
// ####           Struk. FB        ####
// #######################################
export const HELP_URL_STRUK_FB_FIRMA = 'schriftsatz/fb/daten/firma';
export const HELP_URL_STRUK_FB_RECHTSTATSACHE_LIST = 'schriftsatz/fb/daten/listrechtstatsachen';
export const HELP_URL_STRUK_FB_RECHTSTATSACHE_ITEM = 'schriftsatz/fb/daten/editrechtstatsachen';
export const HELP_URL_STRUK_FB_RECHTSTATSACHE_LIST_RECHTSTATSACHE_ITEM = 'schriftsatz/fb/daten/rechtstatsachen/editrechtstatsache';
export const HELP_URL_STRUK_FB_PERSON_LIST = 'schriftsatz/fb/daten/listperson';
export const HELP_URL_STRUK_FB_PERSON_ITEM = 'schriftsatz/fb/daten/editperson';
export const HELP_URL_STRUK_FB_FUNKTION_LIST = 'schriftsatz/fb/daten/listfunktion';
export const HELP_URL_STRUK_FB_FUNKTION_ITEM = 'schriftsatz/fb/daten/editfunktion';
export const HELP_URL_STRUK_FB_ZWEIGNIEDERLASSUNG_LIST = 'schriftsatz/fb/daten/listzweigniederlassung';
export const HELP_URL_STRUK_FB_ZWEIGNIEDERLASSUNG_ITEM = 'schriftsatz/fb/daten/editzweigniederlassung';
export const HELP_URL_STRUK_FB_VISUALISIERUNG = 'schriftsatz/fb/daten/visualisierung';


export const HELP_URL_404 = 'https://hilfe.manz.at';
