import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withConnectedUserData } from '@auth/auth-oidc-integration';
import { arrayHasItem } from '../../../utils/general.utils';
import getUrl from '../../../common/Rest/rest.utils';
import Rest from '../../../common/Rest';
import makeRestCall from '../../../common/Rest/makeRestCall';
import PATH from '../../../constants/path';
import { OK } from '../../../common/Rest/http.status.codes';
import Select from '../../../common/Form/Select';

const RECALL_PATHS = [
  `${PATH.DASHBOARD.value}`,
  `${PATH.KONTAKT.value}`,
  `${PATH.KONTAKT.value}${PATH.SUCHE.value}`,
  `${PATH.SCHRIFTSATZ.value}`,
  `${PATH.SCHRIFTSATZ.value}${PATH.NEW.value}`,
  `${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}`,
  `${PATH.AUSGANG.value}`,
  `${PATH.EINGANG.value}`,
];

/**
 * Abteilung
 * @param {Object} props props
 * @return {JSX} Abteilung
 */
class Abteilung extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.handleChangeAbteilung = this.handleChangeAbteilung.bind(this);
  }

  /**
   * change abteilung
   * @param {Object} selected selected value
   * @return {undefined}
   */
  async handleChangeAbteilung(selected) {
    const { userActions, history, appUserObj } = this.props;

    if (appUserObj.currentAbteilungId === selected) {
      return;
    }

    const url = getUrl(Rest.urls.benutzerChangeAbteilung, { targetAbteilungId: selected });
    const result = await makeRestCall('POST', url);

    if (result.status === OK) {
      // appUserObj update
      if (userActions) {
        userActions.storeCustomUser(result.body);
      }

      history.push({
        pathname: PATH.ROOT.value,
      });
    }
  }

  /**
   * get abteilungen options
   * @return {Array|*} abteilungen options
   */
  _getOptions() {
    const { appUserObj } = this.props;
    if (appUserObj && arrayHasItem(appUserObj.abteilungen)) {
      return appUserObj.abteilungen.map((v) => { // eslint-disable-line
        return ({
          value: v?.id,
          label: v?.name,
        });
      })
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
  }

  /**
   * get current abteilung
   * @return {{label: string, value: null}|{label: *, value: *}} current abteilung
   */
  _getCurrentAbteilung() {
    const { appUserObj } = this.props;
    if (appUserObj && arrayHasItem(appUserObj.abteilungen) && appUserObj.currentAbteilungId) {
      const fand = appUserObj.abteilungen.find((v) => v?.id === appUserObj.currentAbteilungId);
      if (fand) {
        return {
          value: fand?.id,
          label: fand?.name,
        };
      }
    }
    return {
      value: null,
      label: 'Auswählen',
    };
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { appUserObj, appAuthorized, location } = this.props;

    if (!(appAuthorized && appUserObj && appUserObj.abteilungen)) {
      // no Abteilungen Select
      return null;
    }

    const disabled = !(location && location.pathname && RECALL_PATHS.find((v) => v === location.pathname));
    const options = this._getOptions();
    const currentAbteilung = this._getCurrentAbteilung();

    const abteilung = disabled
      ? (
        <label className="col-form-label">
          {this._getCurrentAbteilung().label}
        </label>
      ) : (
        <Select
          inputId="abteilung"
          options={options}
          isDisabled={disabled}
          ariaLabel="Abteilung"
          classNamePrefix="react-select"
          defaultSelection={currentAbteilung.value}
          className="abteilung-select reactSelect__control"
          meta={{
            error: null,
          }}
          input={{
            value: () => currentAbteilung,
            onChange: (selected) => this.handleChangeAbteilung(selected),
          }}
        />
      );

    return (
      <div className="form-row abteilung-wrapper w-100">
        <label className="col-form-label">
          Abteilung:
        </label>
        <div className="flex-grow-1">
          {abteilung}
        </div>
      </div>
    );
  }
}

Abteilung.defaultProps = {
  userActions: null,
  appUserObj: null,
  appAuthorized: false,
};

Abteilung.propTypes = {
  appUserObj: PropTypes.object,
  userActions: PropTypes.object,
  appAuthorized: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withConnectedUserData(withRouter(Abteilung));
