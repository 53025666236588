import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { getFBAuszugHtml } from '../helpers/fb.auszug.helper';

/**
 * function
 * @param {Object} props props
 * @return {JSX.Element} JSX
 * @constructor
 */
class FbAuszugHtml extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      html: null,
      isBlank: false,
    };
  }

  /**
   * componentDidMount
   * @return {Promise<void>} undefined
   */
  async componentDidMount() {
    const { html, location } = this.props;

    let isBlank = false;
    let htmlStr = html;

    if (!html && location?.search) {
      const params = qs.parse(location.search.slice(1));
      const schriftsatzId = params.fbAuszugSchriftsatzId;

      if (schriftsatzId) {
        const response = await getFBAuszugHtml(schriftsatzId);
        if (response) {
          htmlStr = response;
          isBlank = true;
        }
      }
    }

    this.setState({
      isBlank,
      html: htmlStr,
    });
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { html, isBlank } = this.state;
    return html && (
      <div>
        {/* eslint-disable-next-line react/no-danger */}
        <div id="strukFbAuszugHtml" dangerouslySetInnerHTML={{ __html: html || '' }} className={`${isBlank ? '' : 'struk-fb-auszug-anzeigen'}`} />
      </div>
    );
  }
}

FbAuszugHtml.defaultProps = {
  html: null,
};

FbAuszugHtml.propTypes = {
  html: PropTypes.string,
  location: PropTypes.object.isRequired,
};

export default FbAuszugHtml;
