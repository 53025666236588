const STORE_DRAG_DROP_TARGET_ID = 'store.drag.drop.target.id';
const CLEAR_DRAG_DROP_TARGET_ID = 'clear.drag.drop.target.id';

const initial = {
  dragDropTargetId: null,
};

/**
 * gbAntragsgruppe reducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{activeKeys: Array}} state
 */
export function dragDropReducer(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_DRAG_DROP_TARGET_ID:
      return {
        ...state,
        dragDropTargetId: payload.dragDropTargetId,
      };
    case CLEAR_DRAG_DROP_TARGET_ID:
      return {
        ...state,
        dragDropTargetId: null,
      };
    default:
      return state;
  }
}

/**
 * storeDragDropItemId
 * @param {Number} dragDropTargetId dragDropTargetId
 * @return {Object} redux store
 */
export function storeDragDropTargetId(dragDropTargetId) {
  return {
    type: STORE_DRAG_DROP_TARGET_ID,
    payload: {
      dragDropTargetId,
    },
  };
}

/**
 * clearDragDropItemId
 * @return {Object} redux store
 */
export function clearDragDropTargetId() {
  return {
    type: CLEAR_DRAG_DROP_TARGET_ID,
  };
}
