import React from 'react';
import PropTypes from 'prop-types';
import ModalHeader from './ModalHeader';

/**
 * confirmation modal. has an ok button that executes an action and a cancel button,
 * to close the modal
 */
class GotoDokumentePageModal extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * confirmation modal render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      match,
      header,
      history,
      onClose,
      schriftsatzId,
    } = this.props;

    return [
      <ModalHeader
        key="modal-header"
        onClose={onClose}
      >
        {header}
      </ModalHeader>,
      <div
        key="modal-body"
        className="manz-modal-body"
      >
        <div>
          <span>
            {'Da nicht alle ERV-Box Anhänge erfolgreich hochgeladen wurden, kann der Schriftsatz noch nicht versendet werden. Bitte folgen Sie den Anweisungen unter dem Reiter "'}
          </span>
          <span
            className="manz-link"
            onClick={() => {
              history.push({
                pathname: `${match.url.replace('pruefen', '')}dokumente`,
                search: `?schriftsatzId=${schriftsatzId}`,
              });
              onClose();
            }}
          >
            Dokumente
          </span>
          <span>
            {'".'}
          </span>
        </div>
      </div>,
    ];
  }
}

GotoDokumentePageModal.defaultProps = {
  header: null,
};

GotoDokumentePageModal.propTypes = {
  header: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  schriftsatzId: PropTypes.number.isRequired,
};

export default GotoDokumentePageModal;
