import React from 'react';
import { withConnectedUserData } from '@auth/auth-oidc-integration';
import PropTypes from 'prop-types';
import ModalHeader from './ModalHeader';
import { arrayHasItem, getNow } from '../../utils/general.utils';

/**
 * unexpected error modal
 */
class FetchErrorModal extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);
    this.hotlineMail = 'hotline@manz.at';
    this.handleSendErrorReport = this.handleSendErrorReport.bind(this);
  }

  /**
   * returns a mailTo string to be used for the href property of an <a> tag
   * @returns {string} compiled mailTo string
   */
  getMailToString() {
    const {
      logNr,
      uhrzeit,
      logText,
      logTitel,
    } = this.props;
    const { kundenNummer, abteilungName, userMopsId } = this._getUserInfo();

    const titel = logTitel || logNr;
    const msg = logText || logNr;
    const zeit = uhrzeit || getNow();

    const subject = `subject=WebERV-Software Error Report: ${titel}`;
    const body = `body=Sehr geehrte Damen und Herren,
  %0D%0A%0D%0Abitte beschreiben Sie den Fehler kurz: ……………
  %0D%0A%0D%0ABei welcher Aktion ist der Fehler aufgetreten? …………………
  %0D%0A%0D%0ABitte Screenshot einfügen!!!!!
  %0D%0A%0D%0ABei der Durchführung einer Aktion ist ein Fehler in der WebERV-Software aufgetreten.
  %0D%0A%0D%0ABitte um Behebung des Fehlers mit der ${logText ? 'Fehlermeldung' : 'Fehlernummer'}: ${msg}, Kundennummer: ${kundenNummer}, Abteilung: ${abteilungName}, Benutzer: ${userMopsId}
  %0D%0A%0D%0AUhrzeit: ${zeit}
  %0D%0A%0D%0AMit freundlichen Grüßen`;

    return `mailTo:${this.hotlineMail}?${subject}&${body}`;
  }

  /**
   * create a link, append it to the document, click it and remove it afterwards
   * @returns {undefined}
   */
  handleSendErrorReport() {
    const mailTo = this.getMailToString();
    const link = document.createElement('a');

    link.href = mailTo;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /**
   * get user info
   * @return {Object} user info
   * @private
   */
  _getUserInfo() {
    const { userProfile, appUserObj } = this.props;

    let kundenNummer = null;
    let abteilungName = null;
    let userMopsId = null;

    if (userProfile && userProfile.naid) {
      kundenNummer = userProfile.naid;
    }

    if (appUserObj) {
      const abteilungObj = arrayHasItem(appUserObj.abteilungen) ? appUserObj.abteilungen.find((v) => v?.id === appUserObj.currentAbteilungId) : null;
      abteilungName = abteilungObj ? abteilungObj.name : null;
    }

    if (userProfile) {
      userMopsId = userProfile.uid ? `m${userProfile.uid}` : '';
    }

    return {
      kundenNummer,
      abteilungName,
      userMopsId,
    };
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      logNr,
      logText,
      onClose,
    } = this.props;
    const mailTo = this.getMailToString();

    const { kundenNummer, abteilungName, userMopsId } = this._getUserInfo();

    return [
      <ModalHeader
        key="modal-header"
        onClose={onClose}
      >
        Ein Fehler ist aufgetreten
      </ModalHeader>,

      <div
        key="modal-body"
        className="manz-modal-body"
      >
        <p>
          Ein Fehler ist aufgetreten, Ihre Aktion konnte nicht durchgeführt werden. Bitte versuchen
          Sie es später noch einmal, oder kontaktieren Sie unsere Hotline:
        </p>
        <dl className="contact">
          {logNr && (
            <div>
              <dt><b>Fehlernummer</b></dt>
              <dd>{logNr}</dd>
            </div>
          )}
          {logText && (
            <div>
              <dt><b>Fehlertext</b></dt>
              <dd>{logText}</dd>
            </div>
          )}
          {kundenNummer && (
            <div>
              <dt>Kundennummer</dt>
              <dd>{kundenNummer}</dd>
            </div>
          )}
          {abteilungName && (
            <div>
              <dt>Abteilung</dt>
              <dd>{abteilungName}</dd>
            </div>
          )}
          {userMopsId && (
            <div>
              <dt>Benutzer</dt>
              <dd>{userMopsId}</dd>
            </div>
          )}
          <dt>Telefon</dt>
          <dd>+43 1 531 61-11</dd>
          <dt>E-Mail</dt>
          <dd>
            <a className="manz-link" href={mailTo} title="E-Mail" aria-label="E-Mail">{this.hotlineMail}</a>
          </dd>
        </dl>
      </div>,

      <div
        key="modal-footer"
        className="manz-modal-footer"
      >
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={this.handleSendErrorReport}
          aria-label="Error Report an Manz senden button"
        >
          Error Report an Manz senden
        </button>
        <button
          type="button"
          onClick={onClose}
          aria-label="OK Button"
          className="btn btn-sm btn-secondary"
        >
          OK
        </button>
      </div>,
    ];
  }
}

FetchErrorModal.defaultProps = {
  logNr: null,
  uhrzeit: null,
  logText: null,
  logTitel: null,
  userProfile: null,
  appUserObj: null,
};

FetchErrorModal.propTypes = {
  logNr: PropTypes.string,
  uhrzeit: PropTypes.string,
  logText: PropTypes.string,
  logTitel: PropTypes.string,
  userProfile: PropTypes.object,
  appUserObj: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default withConnectedUserData(FetchErrorModal);
