import modalFactory from '../Modal/modalFactory';
import ConfirmationModal from '../Modal/ConfirmationModal';
import ErvBoxUploadNotFinishModal from '../Modal/ErvBoxUploadNotFinishModal';
import { isJustizboxUploadAbortable } from '../../constants/js/application.config';

export const NOT_SHOW_PROMPT = 'not.show.prompt';
export const NOT_FINISH_ERVBOX_UPLOAD = 'not.finish.ervbox.upload';

/**
 * Returns a promise that opens a confirmation modal for a not finished ERV-Box upload to Manz.
 * If the "Dokumente" tab is left, the upload will be aborted.
 *
 * @returns {Promise} A promise that resolves to the opened modal instance.
 */
async function getNotFinishErvboxUploadModal() {
  return await modalFactory.openWithPromise(
    ErvBoxUploadNotFinishModal,
    {},
  );
}

/**
 * Retrieves the default modal with a confirmation message.
 *
 * @returns {Promise<any>} A Promise that resolves to the default modal.
 */
async function getNotShowPromptModal() {
  return await modalFactory.openWithPromise(
    ConfirmationModal,
    {
      header: 'Achtung',
      body: 'Sie haben ungespeicherte Änderungen. \n\n Wollen Sie diese Seite wirklich verlassen?',
      yesText: 'Verlassen',
    },
  );
}

/**
 * custom get user confirmation function for react router
 * @param {String} message react router prompt custom message
 * @param {Function} callback react router prompt callback function
 * @returns {undefined}
 */
async function getUserConfirmation(message, callback) {
  let shouldCallback = false;
  let res = null;

  if (message === NOT_FINISH_ERVBOX_UPLOAD) {
    if (isJustizboxUploadAbortable()) {
      shouldCallback = true;
      res = await getNotFinishErvboxUploadModal();
    }
  } else if (message !== NOT_SHOW_PROMPT) {
    shouldCallback = true;
    res = await getNotShowPromptModal();
  }

  if (shouldCallback) {
    if (res) {
      if (callback) {
        callback(true);
      } else {
        return true;
      }
    }

    return false;
  }

  return true;
}

export default getUserConfirmation;
