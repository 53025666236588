// eslint-disable-next-line import/no-extraneous-dependencies
import merge from 'lodash/merge';
import mainconfig from './default-config.json';

const defaultConfig = mainconfig;
const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`;
defaultConfig.react_app_base_url = baseUrl;

// eslint-disable-next-line no-underscore-dangle
const environmentConfig = window._msolenv_;

// final config is based on the environment - prepared with client-env.js (mounted as secret at container runtime)
export const config = merge(defaultConfig, environmentConfig);
