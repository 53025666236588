import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { authReducer } from '@auth/auth-oidc-integration';
import { collapseReducer } from './common/Reducers/collapse.reducer';
import { breadcrumbReducer } from './common/Reducers/breadcrumbs.reducer';
import { gbAntragsgruppeReducer } from './common/Reducers/gb.antragsgruppe.reducer';
import { tableReducer } from './common/Reducers/simpleTable.reducer';
import { dragDropReducer } from './common/Reducers/dragDrop.reducer';
import { exekutionsantragReduder } from './common/Reducers/exekutionsantrag.reducer';
import { grundbuchAuszugSelectOptionReducer } from './common/Reducers/gb.auszug.select.reducer';
import { grundbuchBegehrenReducer } from './common/Reducers/gb.begehren.reducer';
import { strukFBRechtstatsacheReducer } from './common/Reducers/struk.fb.rechtstatsache.reducer';

export default combineReducers({
  tableReducer,
  collapseReducer,
  dragDropReducer,
  breadcrumbReducer,
  exekutionsantragReduder,

  gbAntragsgruppeReducer,
  grundbuchBegehrenReducer,
  grundbuchAuszugSelectOptionReducer,

  strukFBRechtstatsacheReducer,

  form: formReducer,
  auth: authReducer,
});
