import React from 'react';
import PropTypes from 'prop-types';

/**
 * search icon component
 * @param {Object} props props
 * @return {JSX} component
 */
function MenuIcon(props) {
  const { onClick } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 70 70" onClick={onClick} aria-label="menu icon">
      <g id="burger-menu">
        <g>
          <line className="cls-1" x1="11" y1="17" x2="59" y2="17" />
          <line className="cls-1" x1="11" y1="35" x2="59" y2="35" />
          <line className="cls-1" x1="11" y1="53" x2="59" y2="53" />
        </g>
      </g>
    </svg>
  );
}

MenuIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MenuIcon;
