import React from 'react';
import PropTypes from 'prop-types';
import { weiterleitenTooltip } from '../../constants/js/tooltip.constaints';

/**
 * send button component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function ErledigungWeiterleitenButton(props) {
  const {
    id,
    onClick,
    disabled,
    className,
  } = props;

  const cName = ['btn', 'btn-secondary'];
  if (className) {
    cName.push(className);
  }

  const text = 'Weiterleiten';

  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cName.join(' ')}
      title={weiterleitenTooltip}
      aria-label="Erledigung weiterleiten"
    >
      {text}
    </button>
  );
}

ErledigungWeiterleitenButton.defaultProps = {
  id: null,
  disabled: false,
  className: null,
};

ErledigungWeiterleitenButton.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ErledigungWeiterleitenButton;
