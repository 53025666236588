import React from 'react';
import { ReactComponent as FacebookSVG } from '../assets/icons/logo-sm-facebook.svg';
import { ReactComponent as LinkedinSVG } from '../assets/icons/logo-sm-linkedin.svg';
import { ReactComponent as InstagramSVG } from '../assets/icons/logo-sm-instagram.svg';
import { ReactComponent as XingSVG } from '../assets/icons/logo-sm-xing.svg';
import { ReactComponent as YoutubeSVG } from '../assets/icons/logo-sm-youtube.svg';

/**
 * navigation component
 * @returns {JSX} component jsx
 */
function Footer() {
  return (
    <div className="footer-content row rolleStyle">

      <div className="d-flex align-items-center no-wrap col-12 col-lg-2 justify-content-center justify-content-lg-start">
        ©
        {' '}
        {(new Date().getFullYear())}
        {' '}
        MANZ
      </div>

      <div className="social-media-container col-12 col-lg-6 mt-lg-0 mt-3 justify-content-center justify-content-lg-start">
        <a
          target="_blank"
          rel="noopener noreferrer"
          title="MANZ auf Facebook"
          className="icon-wrapper"
          aria-label="MANZ auf Facebook"
          href="https://www.facebook.com/manzverlag/"
        >
          <FacebookSVG title="MANZ auf Facebook" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="icon-wrapper"
          title="MANZ auf Instagram"
          aria-label="MANZ auf Instagram"
          href="https://www.instagram.com/manzverlag/"
        >
          <InstagramSVG title="MANZ auf Instagram" />
        </a>
        <a
          target="_blank"
          title="MANZ auf XING"
          rel="noopener noreferrer"
          className="icon-wrapper"
          aria-label="MANZ auf XING"
          href="https://www.xing.com/companies/manz"
        >
          <XingSVG title="MANZ auf XING" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          title="MANZ auf Linkedin"
          className="icon-wrapper"
          aria-label="MANZ auf Linkedin"
          href="https://www.linkedin.com/company/manz%27sche-verlags--und-universit%C3%A4tsbuchhandlung-gmbh/"
        >
          <LinkedinSVG title="MANZ auf Linkedin" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          title="MANZ auf YouTube"
          className="icon-wrapper"
          aria-label="MANZ auf YouTube"
          href="https://www.youtube.com/user/VerlagManz"
        >
          <YoutubeSVG title="MANZ auf YouTube" />
        </a>
      </div>

      <div className="link-container col-12 col-lg-4  mt-lg-0 mt-3 justify-content-center justify-content-lg-end">
        <div>
          <a
            id="impressum"
            target="_blank"
            title="Impressum"
            aria-label="Impressum"
            rel="noopener noreferrer"
            href="http://www.manz.at/wir_ueber_uns/agb/impressum.html"
          >
            Impressum
          </a>
        </div>
        <div>
          <a onClick={() => cookieConfiguration.createCookieAdministrationPage(true)} title="Cookies" aria-label="Cookies"> {/* eslint-disable-line */}
            Cookies
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Allgemeine Geschäftsbedingungen"
            aria-label="Allgemeine Geschäftsbedingungen"
            href="http://www.manz.at/wir_ueber_uns/agb/allgemeine-geschaeftsbedingungen.html"
          >
            AGB
          </a>
        </div>
        <div>
          <a
            target="_blank"
            title="Datenschutz"
            aria-label="Datenschutz"
            rel="noopener noreferrer"
            href="http://www.manz.at/wir_ueber_uns/agb/datenschutzerklaerung.html"
          >
            Datenschutz
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
