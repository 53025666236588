import 'whatwg-fetch';
import { TIME_OUT } from './http.status.codes';
import { getDefalutTimeout } from '../../constants/js/application.config';

export const TIMEOUT_TYPE = {
  LOGIN: 'LOGIN',
  SENDEN: 'SENDEN',
  DEFAULT: 'DEFAULT',
  VALIDATE: 'VALIDATE',
  UPLOAD_FILE: 'UPLOAD_FILE',
  FB_READ_AUSZUG: 'FB_READ_AUSZUG',
  EINGANG_ABHOLEN: 'EINGANG_ABHOLEN',
  FB_VISUALISIERUNG: 'FB_VISUALISIERUNG',
};

const TIMEOUT_ERROR = {
  ok: false,
  status: TIME_OUT,
  message: 'timeout',
};

/**
 * fetch with timeout
 * @param {String} url url
 * @param {Object} options options
 * @param {Number} timeout timeout
 * @return {Promise} a promise with response
 */
export function fetchWithTimeout(url, options, timeout = getDefalutTimeout()) {
  return new Promise((resolve, reject) => {
    fetch(url, options).then(resolve, reject);
    setTimeout(reject.bind(null, TIMEOUT_ERROR), timeout);
  });
}
