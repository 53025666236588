import React from 'react'; // eslint-disable-line
import { lgBreakPoint } from './Site.utils.scss';

/**
 * is large page
 * @return {boolean} is lage page
 */
export function isLargePage() {
  const large = (lgBreakPoint && parseInt(lgBreakPoint.replace('px', ''), 10)) || 0;
  return window.innerWidth >= large;
}
