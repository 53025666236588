/**
 * cache object where the data and additional information is stored
 */
class CacheObject { // eslint-disable-line
  /**
   * initialize data and subscribers
   * @returns {undefined}
   */
  constructor() {
    this.data = null; // empty data on construction
    this.timestamp = null;
  }

  /**
   * Checks if the object is younger than the amount of milliseconds supplied as parameter
   * @param {number} maxAge max age in milliseconds
   * @returns {boolean} true if the object is younger than the max age
   */
  isYoungerThan(maxAge) {
    if (!this.timestamp) return false;
    if ((Date.now() - this.timestamp) <= maxAge) return true;
    return false;
  }

  /**
   * updates the cache object and sets the timestamp
   * @param {Object} data the object to store in the cache
   * @returns {undefined}
   */
  update(data) {
    this.timestamp = Date.now();
    this.data = data;
  }
}

/**
 * rest cache class
 */
class RestCache {
  /**
   * init cache and templateUrls
   */
  constructor() {
    this.cache = {}; // this is the cache contains CacheObject instances keys are the urlString
    this.urlTemplates = {}; // a list of urls which may be in the cache per template
  }

  /**
   * put data into the cache
   * @param {String} compiledUrl the compiledUrl of the rest request. used
   * to assoicate the cached data to a rest call
   * @param {String} urlTemplate the urlTemplate used to put this data here
   * @param {Object} data the data to be cached
   * @returns {undefined}
   */
  putData(compiledUrl, urlTemplate, data) {
    let obj;
    if (compiledUrl in this.cache) {
      obj = this.cache[compiledUrl];
    } else {
      obj = new CacheObject();
      this.cache[compiledUrl] = obj;
    }
    obj.update(data);
    if (urlTemplate in this.urlTemplates) {
      const tu = this.urlTemplates[urlTemplate];
      if (!(compiledUrl in tu)) {
        tu[compiledUrl] = true;
      }
    } else {
      this.urlTemplates[urlTemplate] = {
        [compiledUrl]: true,
      };
    }
  }

  /**
   * get data from the cache
   * @param {string} compiledUrl the urlString for the cached data
   * @param {number} maxAge the maximum age for the fresh indicator
   * @returns {Object} object with fresh indicator and the cached data
   */
  getData(compiledUrl, maxAge) {
    if (compiledUrl in this.cache) {
      const obj = this.cache[compiledUrl];
      let fresh = false;
      if (obj.isYoungerThan(maxAge)) fresh = true;

      return {
        data: obj.data,
        fresh,
      };
    }
    return {
      data: null,
      fresh: false,
    };
  }

  /**
   * clear data from cache
   * @param {string} compiledUrl the associated url to clear from the cache
   * @returns {undefined}
   */
  clearData(compiledUrl) {
    if (compiledUrl in this.cache) {
      delete this.cache[compiledUrl];
    }
  }

  /**
   * clear all cached data for a template
   * @param {string} urlTemplate the template string to clear the data for
   * @returns {undefined}
   */
  clearTemplate(urlTemplate) {
    if (urlTemplate in this.urlTemplates) {
      const urls = Object.keys(this.urlTemplates[urlTemplate]);
      for (let i = 0; i < urls.length; i += 1) {
        this.clearData(urls[i]);
      }
      delete this.urlTemplates[urlTemplate];
    }
  }

  /**
   * clear all data from cache
   * @returns {undefined}
   */
  clearAll() {
    this.cache = {};
    this.urlTemplates = {};
  }
}

export default RestCache;
