import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import BreadcrumbItem from './BreadcrumbItem';
import {
  canCreateNewBreadcrumbItemFormSearchResult,
  isCanCreateNewBreadcrumbItemFromReducer,
  isDisabledExtraPath,
  isNotSpecialPath,
  toBreadcrumbsText,
} from './breadcrumbs.helper';
import { clearExtraBreadcrumb } from '../Reducers/breadcrumbs.reducer';
import PATH from '../../constants/path';

/**
 * component
 */
class Breadcrumbs extends React.Component {
  /**
   * validate if it is home page path name
   * @param {String} pathName pathName
   * @return {boolean} yes or no
   */
  static isHomePage(pathName) {
    return pathName === PATH.DASHBOARD.value;
  }

  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};

    this.handleGoHome = this.handleGoHome.bind(this);
  }

  /**
   * componentDidUpdate
   * @return {undefined}
   */
  componentDidUpdate() {
    const { location, actions, breadcrumbReducer } = this.props;
    const { prefixPathName, extraPathName } = breadcrumbReducer;
    if (
      prefixPathName
      && extraPathName
      && !location.pathname.includes(`${PATH.BEGEHREN.value}/`)
    ) {
      actions.clearExtraBreadcrumb();
    }
  }

  /**
   * go to uebersicht site
   * @return {undefined}
   */
  handleGoHome() {
    const { history } = this.props;
    history.push('/');
  }

  /**
   * render BreadcrumbItem
   * @param {String} uiID uiID
   * @param {String} pathName pathName
   * @param {String} breadcrumbText breadcrumbText
   * @param {Boolean} notShowUnderline notShowUnderline
   * @return {*} JSX
   * @private
   */
  _renderBreadcrumbItem(uiID, pathName, breadcrumbText, notShowUnderline) { // eslint-disable-line
    return (
      <BreadcrumbItem
        key={uiID}
        uiID={uiID}
        pathName={pathName}
        breadcrumbText={breadcrumbText}
        notShowUnderline={notShowUnderline}
      />
    );
  }

  /**
   * render breadcrumbs because of first breadcrumb
   * @param {String} firstBreadcrumb firstBreadcrumb
   * @param {String} secondBreadcrumb secondBreadcrumb
   * @param {Array} breadcrumbs breadcrumbs
   * @param {String} locationPathname location -> pathname
   * @return {undefined}
   * @private
   */
  _renderBreadcrumbsOfFirstBreadcrumb(firstBreadcrumb, secondBreadcrumb, breadcrumbs, locationPathname) { // eslint-disable-line
    if (firstBreadcrumb && firstBreadcrumb !== '/') {
      // 1. add BreadcrumbItem for firstBreadcrumb
      breadcrumbs.push(this._renderBreadcrumbItem('first-element', firstBreadcrumb, toBreadcrumbsText(firstBreadcrumb), null));

      // 2. add BreadcrumbItem for search result
      if (canCreateNewBreadcrumbItemFormSearchResult(firstBreadcrumb, locationPathname)) {
        breadcrumbs.push(this._renderBreadcrumbItem('first-search-result-element', PATH.ERGEBNIS.value, toBreadcrumbsText(PATH.ERGEBNIS.name), null));
      }
    }

    // 3. add BreadcrumbItem for secondBreadcrumb
    if (secondBreadcrumb && secondBreadcrumb !== '/') {
      breadcrumbs.push(this._renderBreadcrumbItem('second-element', secondBreadcrumb, toBreadcrumbsText(secondBreadcrumb), null));
    }
  }

  /**
   * render breadcrumbs because of location -> pathname
   * @param {String} locationPathname location -> pathname
   * @param {Array} breadcrumbs breadcrumbs
   * @param {String} prefixPathName prefixPathName
   * @param {String} extraPathName extraPathName
   * @return {undefined}
   * @private
   */
  _renderBreadcrumbsOfPathname(locationPathname, breadcrumbs, prefixPathName, extraPathName) { // eslint-disable-line
    const pathNameArr = locationPathname.split('/');
    for (let idx = 1; idx < pathNameArr.length; idx += 1) {
      const pathName = pathNameArr[idx];

      if (isNotSpecialPath(pathName) && !isDisabledExtraPath(breadcrumbs, locationPathname, pathName)) {
        breadcrumbs.push(this._renderBreadcrumbItem(idx.toString(), pathName, toBreadcrumbsText(pathName) || pathName, idx === (pathNameArr.length - 1)));
      }

      if (isCanCreateNewBreadcrumbItemFromReducer(prefixPathName, extraPathName, pathName)) {
        breadcrumbs.push(this._renderBreadcrumbItem(`${idx}-extra`, extraPathName, toBreadcrumbsText(extraPathName) || extraPathName, idx === (pathNameArr.length - 1)));
      }
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { location, breadcrumbReducer } = this.props;
    const {
      extraPathName,
      firstBreadcrumb,
      prefixPathName,
      secondBreadcrumb,
    } = breadcrumbReducer;

    const breadcrumbs = [];

    const locationPathname = location.pathname;

    if (!Breadcrumbs.isHomePage(locationPathname)) {
      this._renderBreadcrumbsOfFirstBreadcrumb(firstBreadcrumb, secondBreadcrumb, breadcrumbs, locationPathname);
      this._renderBreadcrumbsOfPathname(locationPathname, breadcrumbs, prefixPathName, extraPathName);
    }

    return (
      <div className="breadcrumbs">
        <div
          onClick={this.handleGoHome}
          className="flex breadcrumbText cursor-pointer maus-over-underline"
        >
          Dashboard
        </div>
        {breadcrumbs}
      </div>
    );
  }
}

Breadcrumbs.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  breadcrumbReducer: PropTypes.object.isRequired,
};

/**
 * mapStateToProps function
 * @param {Object} state state
 * @returns {Object} mapped state to props
 */
function mapStateToProps(state) {
  return {
    ...state,
  };
}

/**
 * mapDispatchToProps
 * @param {Object} dispatch dispatch
 * @return {Object} redux object
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      clearExtraBreadcrumb,
    }, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs));
