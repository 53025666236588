import { createStore } from 'redux';
import reducers from './reducers';

/**
 * redux store
 * @param {Object} initalState initialState of the store
 * @returns {Object} store
 */
export default function configureReduxStore(initalState) {
  const store = createStore(reducers, initalState);
  return store;
}
