import qs from 'qs';
import PATH from '../../constants/path';
import breadcrumbText from '../../constants/breadcrumbs';
import { BEGEHREN_FIRST_TAB } from '../../constants/js/tabs.constants';
import { arrayHasItem } from '../../utils/general.utils';

/**
 * check if it is not special path name
 * @param {String} pathName path name
 * @return {boolean} yes or no
 */
export function isNotSpecialPath(pathName) {
  return pathName !== PATH.NEW.name
    && pathName !== PATH.SUCHE.name
    && pathName !== PATH.EINGANG.name
    && pathName !== PATH.ERGEBNIS.name
    && pathName !== PATH.SCHRIFTSATZ.name
    && pathName !== PATH.LANDING_PAGE.name
    && pathName !== PATH.DATEN_IMPORT.name
    && pathName !== PATH.NEW_BEGEHREN.name
    && pathName !== PATH.KONFIGURATION.name
    && pathName !== PATH.HILFE_SUPPORT.name;
}

/**
 * handle special path name
 * @param {String} pathName path name
 * @param {String} newPath new pathnanme
 * @param {String} locationSearch location search
 * @return {*|string} new pathname
 */
export function handleSpecialPath(pathName, newPath, locationSearch) {
  let newPathname = newPath;
  let newLocationSearch = locationSearch;

  if (pathName === `${PATH.SCHRIFTSATZ.value}${PATH.NEW.value}`) {
    newPathname = `${PATH.SCHRIFTSATZ.value}${PATH.NEW.value}`;
  }

  if (pathName === `${PATH.AUSGANG.value}`) {
    newPathname = `${PATH.AUSGANG.value}`;
  }

  if (pathName === `${PATH.EINGANG.value}`) {
    newPathname = `${PATH.EINGANG.value}`;
  }

  if (newPath.includes(PATH.FORDERUNGEN.value)) {
    newPathname = `${newPath}${PATH.ANSPRUCH.value}`;
  }

  if (newPath.includes(PATH.TITEL_KOSTEN.value)) {
    newPathname = `${newPath}${PATH.TITEL.value}`;
  }

  if (pathName.includes(`${PATH.SCHRIFTSATZ.name}`) && pathName.includes(`${PATH.SUCHE.name}`)) {
    newPathname = `${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}`;
    return {
      newPathname,
      newLocationSearch,
    };
  }

  if (pathName.includes(`${PATH.KONTAKT.name}`) && pathName.includes(`${PATH.SUCHE.name}`)) {
    newPathname = `${PATH.KONTAKT.value}${PATH.SUCHE.value}`;
    return {
      newPathname,
      newLocationSearch,
    };
  }

  // for new begehren
  if (newPath.includes(PATH.NEW_BEGEHREN.name)) {
    newPathname = `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${PATH.NEW_BEGEHREN.value}`;
    newLocationSearch = `?schriftsatzId=${qs.parse(locationSearch.slice(1)).schriftsatzId}`;
  } // eslint-disable-line

  // for first tab of begehren
  else if (newPath.includes(`${PATH.BEGEHREN.value}/`)) {
    newPathname = _handleSpecialPathOfExistBegehren(newPath, newPathname);
  }

  return {
    newPathname,
    newLocationSearch,
  };
}

/**
 * handle special path for begehren
 * @param {String} newPath new path
 * @param {String} newPathname newPathname
 * @return {*|string} updated newPathname
 * @private
 */
function _handleSpecialPathOfExistBegehren(newPath, newPathname) { // eslint-disable-line
  let retval = newPathname;

  // for wohnungseigentum begründungszeile
  if (newPathname.includes(PATH.BEGEHREN_BEGRUENDUNGSZEILE.name)) {
    retval = newPathname.replace(PATH.BEGEHREN_BEGRUENDUNGSZEILE.name, PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.name);
  } // eslint-disable-line

  // for wohnungseigentum änderungszeile
  else if (newPathname.includes(PATH.BEGEHREN_AENDERUNGSZEILE.name)) {
    retval = newPathname.replace(PATH.BEGEHREN_AENDERUNGSZEILE.name, PATH.BEGEHREN_AENDERUNGSZEILE_LIST.name);
  } // eslint-disable-line

  // for others
  else {
    const split = newPath.split(PATH.BEGEHREN.value);
    const begehrenArt = split[split.length - 1];
    if (begehrenArt && BEGEHREN_FIRST_TAB[begehrenArt]) {
      retval = `${newPath}${BEGEHREN_FIRST_TAB[begehrenArt]}`;
    }
  }

  return retval;
}

/**
 * can create a new breadcrumb item because of first breadcrumbs
 * @param {String} firstBreadcrumb firstBreadcrumb
 * @param {String} pathname pathname
 * @return {boolean} yes or no
 */
export function canCreateNewBreadcrumbItemFormSearchResult(firstBreadcrumb, pathname) {
  return (firstBreadcrumb === `${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}`
    && pathname !== `${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}`)
    || (
      firstBreadcrumb === `${PATH.KONTAKT.value}${PATH.SUCHE.value}`
      && pathname !== `${PATH.KONTAKT.value}${PATH.SUCHE.value}`
    );
}

/**
 * check if it need to create a new breadcrumb item from reducer
 * @param {String} prefixPathName prefixPathName
 * @param {String} extraPathName extraPathName
 * @param {String} pathName pathName
 * @return {*|boolean} yes or no
 */
export function isCanCreateNewBreadcrumbItemFromReducer(prefixPathName, extraPathName, pathName) {
  return prefixPathName && extraPathName && pathName === prefixPathName;
}

/**
 * add extra path if it is necessary
 * @param {String} breadcrumbs breadcrumbs
 * @param {String} locationPathname locationPathname
 * @param {String} pathName pathName
 * @return {Boolean} yes or no
 */
export function isDisabledExtraPath(breadcrumbs, locationPathname, pathName) {
  let retval = false;

  if (arrayHasItem(breadcrumbs) && pathName === PATH.KONTAKT.name) {
    const pathArr = breadcrumbs
      .filter((v) => v && !!v.props && !!v.props.pathName)
      .map((v) => v.props.pathName);

    if (pathArr.length === 1) {
      // 1. disable breadcrumb: /kontakt/suche
      retval = !!pathArr.find((v) => v === `${PATH.KONTAKT.value}${PATH.SUCHE.value}`);

      // 2. disable breadcrumb: /kontakt
      retval = retval || !!pathArr.find((v) => v === `${PATH.KONTAKT.value}`);
    }

    // 3. disable breadcrumb: /kontakt/suche/ergebnis
    if (pathArr.length === 2) {
      retval = !!pathArr.find((v) => v === `${PATH.KONTAKT.value}${PATH.SUCHE.value}`)
        && !!pathArr.find((v) => v === PATH.ERGEBNIS.value)
        && locationPathname === `${PATH.KONTAKT.value}${PATH.SUCHE.value}${PATH.ERGEBNIS.value}`;
    }
  }

  return retval;
}

/**
 * get breadcrumbs text
 * @param {String} pathname pathname
 * @return {*} breadcrumbs text
 */
export function toBreadcrumbsText(pathname) {
  if (pathname === PATH.KONTAKT.name) {
    return 'Kontakt - Basisdaten';
  }
  return breadcrumbText.FISRT_BREADCRUMB[pathname] || breadcrumbText.SUFFIX_BREADCRUMBS[pathname];
}
