import React from 'react';
import qs from 'qs';
import PropTyes from 'prop-types';
import { restCallHilfeXml } from '../../utils/help.utils';
import LoadingSpinner from '../Spinner/Loading/LoadingSpinner';

/**
 * HelpView component
 * @param {Object} props props
 * @return {JSX} component
 * @constructor
 */
class HelpView extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      body: false,
      header: false,
      loading: false,
    };
  }

  /**
   * componentDidMount
   * @return {Promise<void>} undefined
   */
  async componentDidMount() {
    const { location } = this.props;
    this.setState({ loading: true });
    const params = qs.parse(location.search.slice(1));
    const hilfe = await restCallHilfeXml(params.path);
    if (hilfe) {
      this.setState({
        body: hilfe.infoBody || '',
        header: hilfe.infoHeader || '',
      });
    }
    this.setState({ loading: false });
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { body, header, loading } = this.state;

    return (body && (
      <div>
        <div className="breadcrumbsLayout d-none d-lg-block" />
        <div className="layout">
          <div className="rolleStyle hilfeContentStyle">
            <div className="content padding-lg">
              <h4 className="manz-red">{header}</h4>

              {loading && <LoadingSpinner key="spinner" />}

              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: body || '' }} />

            </div>
          </div>
        </div>
      </div>
    ));
  }
}

HelpView.propTypes = {
  location: PropTyes.object.isRequired,
};

export default HelpView;
