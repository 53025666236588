export const EVENT_COUNT_GS_AUSGANG_UG_EINGANG = 'event.count.gesendete.ausgaenge.or.ungelesene.eingaenge';
export const EVENT_RELOAD_AUSGANG_LIST = 'event.reload.ausgang.list';
export const EVENT_RELOAD_EINGANG_LIST = 'event.reload.eingang.list';
export const EVENT_RELOAD_AUSGANG_EINGANG_LIST = 'event.reload.ausgang.eingang.list';
export const EVENT_RELOAD_GB_AUSZUG = 'event.reload.grundbuch.auszug';
export const EVENT_RELOAD_GB_AUSZUG_BY_BEGEHEN = 'event.reload.grundbuch.auszug.by.begehren';

export const EVENT_RELOAD_GRUNDBUCH_AUSZUG = 'event.reload.gb.begehren';

export const EVENT_ABORT_JUSTIZBOX_UPLOAD = 'event.abort.justizbox.upload';
