import React from 'react';
import PropTypes from 'prop-types';
import { createSimpleConfirmationModal, createSimpleConfirmationModalByString } from '../Modal/ConfirmationModal';
import DeleteIcon from '../Icons/DeleteIcon';

/**
 * Delete button component
 * @param {Object} props props
 * @returns {JSX} component JSX
 * @constructor
 */
class DeleteButton extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      isDeleteModalActive: false,
    };

    this.handleMultiDelete = this.handleMultiDelete.bind(this);
    this.handleStandardDelete = this.handleStandardDelete.bind(this);
    this.handleToggleConfirmModal = this.handleToggleConfirmModal.bind(this);
  }

  /**
   * click delete button
   * @param {Object} event event
   * @return {undefined}
   */
  async handleStandardDelete(event) {
    const {
      onClick,
      stopPropagation,
      isComfirmationRequired,
      isDoubleConfirmationRequired,

      firstConfirmationBodyMsg,
      firstConfirmationHeaderStr,

      firstConfirmationBody,
      firstConfirmationHeader,

      secondConfirmationBody,
      secondConfirmationHeader,
    } = this.props;

    if (stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (isComfirmationRequired) {
      const firstConfirm = firstConfirmationBodyMsg
        ? await createSimpleConfirmationModalByString(firstConfirmationHeaderStr, firstConfirmationBodyMsg)
        : await createSimpleConfirmationModal(firstConfirmationHeader, firstConfirmationBody);

      if (isDoubleConfirmationRequired && firstConfirm) {
        const secondConfirm = await createSimpleConfirmationModal(secondConfirmationHeader, secondConfirmationBody);

        if (secondConfirm) {
          onClick(event);
        }
      } else if (firstConfirm) {
        onClick(event);
      }
    } else {
      onClick(event);
    }
  }

  /**
   * click mutli delete button
   * @param {Object} event event
   * @return {undefined}
   */
  async handleMultiDelete(event) {
    const {
      onClick,
      onSecondClick,
      stopPropagation,
      isComfirmationRequired,
      isDoubleConfirmationRequired,

      firstConfirmationBody,
      firstConfirmationHeader,

      secondConfirmationBody,
      secondConfirmationHeader,
    } = this.props;

    if (stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (isComfirmationRequired) {
      const firstConfirm = await createSimpleConfirmationModal(firstConfirmationHeader, firstConfirmationBody);

      if (firstConfirm) {
        onClick(event);

        if (isDoubleConfirmationRequired) {
          const secondConfirm = await createSimpleConfirmationModal(secondConfirmationHeader, secondConfirmationBody);

          if (secondConfirm) {
            onSecondClick(event);
          }
        }
      }
    } else {
      onClick(event);
    }
  }

  /**
   * cancel confirm modal
   * @return {undefined}
   */
  handleToggleConfirmModal() {
    const { isDeleteModalActive } = this.state;
    this.setState({ isDeleteModalActive: !isDeleteModalActive });
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      id,
      btnText,
      btnTitle,
      disabled,
      className,
      withoutIcon,
      onSecondClick,
      classNameIcon,
      deletionInProgress,
    } = this.props;

    let txt = btnText;
    if (deletionInProgress) {
      txt = 'Löscht...';
    }

    const disabledBtn = disabled || deletionInProgress;

    return (
      <button
        id={id}
        type="button"
        title={btnTitle}
        aria-label={txt}
        disabled={disabledBtn}
        className={className || 'btn btn-danger icon-button'}
        onClick={onSecondClick ? (e) => this.handleMultiDelete(e) : (e) => this.handleStandardDelete(e)}
      >
        {!withoutIcon && (
          <DeleteIcon
            className={`${classNameIcon} ${disabledBtn ? 'collapse-btn-icon-disabled' : ''}`}
          />
        )}
        {txt && (
          <span className="label">
            {txt}
          </span>
        )}

      </button>
    );
  }
}

DeleteButton.defaultProps = {
  id: 'id',
  disabled: false,
  className: null,
  withoutIcon: false,
  btnText: 'Löschen',
  btnTitle: 'Löschen',
  classNameIcon: null,
  stopPropagation: false,
  deletionInProgress: false,
  onSecondClick: undefined,
  isComfirmationRequired: true,
  isDoubleConfirmationRequired: false,

  firstConfirmationBodyMsg: null,
  firstConfirmationHeaderStr: 'Löschen',

  firstConfirmationBody: 'delete.body',
  firstConfirmationHeader: 'delete.header',

  secondConfirmationBody: 'double.check.delete.body',
  secondConfirmationHeader: 'double.check.delete.header',
};

DeleteButton.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  btnText: PropTypes.string,
  btnTitle: PropTypes.string,
  withoutIcon: PropTypes.bool,
  className: PropTypes.string,
  onSecondClick: PropTypes.func,
  stopPropagation: PropTypes.bool,
  classNameIcon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  deletionInProgress: PropTypes.bool,
  isComfirmationRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isDoubleConfirmationRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

  firstConfirmationBodyMsg: PropTypes.string,
  firstConfirmationHeaderStr: PropTypes.string,

  firstConfirmationBody: PropTypes.string,
  firstConfirmationHeader: PropTypes.string,

  secondConfirmationBody: PropTypes.string,
  secondConfirmationHeader: PropTypes.string,
};

export default DeleteButton;
