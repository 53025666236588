import React from 'react';
import { connect } from 'react-redux';
import { ERROR_LEVEL, reportError } from './errorReporter.helpers';

/**
 * @param {component} WrappedComponent component to wrap with HOC for error messages
 * @returns {JSX} wrapped component with error HOC
 */
export function withErrorReporter(WrappedComponent) {
  const mapStateToProps = state => ({ // eslint-disable-line
    error: state.error,
  });

  return connect(mapStateToProps)(class extends React.Component {
    /**
     * constructor
     * @param {Object} props props
     */
    constructor(props) {
      super(props);

      this.state = {};
    }

    /**
     * @param {props} nextProps properties for component
     * @returns {null} no state used here
     */
    static getDerivedStateFromProps(nextProps) { // eslint-disable-line
      return null;
    }

    /**
     * componentDidCatch
     * @param {Object} error error
     * @param {Object} errorInfo errorInfo
     * @return {undefined}
     */
    componentDidCatch(error, errorInfo) { // eslint-disable-line
      const pathname = (window && window.location) ? window.location.href || window.location.pathname : '';
      reportError(
        error.toString(),
        errorInfo && errorInfo.componentStack.toString(),
        ERROR_LEVEL.ERROR,
        pathname,
      );
    }

    /**
     * render
     * @returns {JSX} comp jsx
     */
    render() {
      return <WrappedComponent {...this.props} />;
    }
  });
}
