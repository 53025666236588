import DOMPurify from 'dompurify';
import { replaceAll } from './general.utils';
import {
  getMrsHrefPrefix,
  getMrsRestRoot,
  getMrsUrlPrefix,
} from '../common/Rest/rest.utils';
import { makeExternalRestCall } from '../common/Rest/makeRestCall';
import { OK } from '../common/Rest/http.status.codes';

export const HILFE_PREFIX = 'hilfe?path=';

/**
 * modify img src
 * @param {String} sourceText sourceText
 * @return {*|string} modified sourceText
 */
function modifyImgSrc(sourceText) {
  return (sourceText && replaceAll(sourceText, '<img src="/', `<img src="${getMrsRestRoot()}${getMrsUrlPrefix()}`)) || '';
}

/**
 * modify relative href
 * @param {String} sourceText sourceText
 * @return {*|string} modified sourceText
 */
export function modifyRelativeHref(sourceText) {
  let modifiedHrefForAll = sourceText;
  if (sourceText) {
    const modifiedHrefForMultimedia = replaceAll(sourceText, 'href="/dms/', `href="${getMrsRestRoot()}${getMrsUrlPrefix()}dms/`);
    modifiedHrefForAll = replaceAll(modifiedHrefForMultimedia, 'href="/', `href="/${HILFE_PREFIX}`);
  }
  return modifiedHrefForAll;
}

/**
 * get mrs href
 * @param {String} href href
 * @return {string} href
 */
export function getMrsHref(href) {
  return `${getMrsUrlPrefix()}${getMrsHrefPrefix()}${href}.xml`;
}

/**
 * get mrs url
 * @param {string} path path
 * @return {String} url
 */
export function getMrsUrl(path) {
  return `/${HILFE_PREFIX}${path}`;
}

/**
 * rest call hilfe xml
 * @param {String} path path
 * @return {Promise<null|*|String|Node|string>} xml body
 */
export async function restCallHilfeXml(path) {
  try {
    const response = await makeExternalRestCall('GET', getMrsHref(path && `/${path}`));

    if (response && response.status === OK) {
      let responseText = await response.text();

      responseText = modifyImgSrc(responseText);
      responseText = modifyRelativeHref(responseText);

      const infoBody = new DOMParser()
        .parseFromString(responseText, 'text/xml')
        .querySelector('infoBody')
        .textContent;

      const infoHeader = new DOMParser()
        .parseFromString(responseText, 'text/xml')
        .querySelector('infoHeader')
        .textContent;

      if (infoBody) {
        return {
          infoHeader,
          infoBody: DOMPurify.sanitize(infoBody, { ADD_ATTR: ['target'] }),
        };
      }
    }
  } catch (exception) {
    console.log('problem reading help site', exception); // eslint-disable-line
  }
  return null;
}
