/* eslint-disable linebreak-style */
import React, { useEffect, useRef } from 'react';
import { WidgetInstance } from 'friendly-challenge';
import PropTypes from 'prop-types';
import { config } from '../../config';
import { FRIENDLY_CAPTCHA_ENDPOINT } from '../../constants/js/application.config';

const FriendlyCaptcha = ({ onSolutionSolved }) => {
  const container = useRef();
  const widget = useRef();

  const doneCallback = (sol) => {
    console.log('Captcha was solved. The form can be submitted.'); // eslint-disable-line
    onSolutionSolved(sol);
  };

  const errorCallback = (err) => {
    console.log('There was an error when trying to solve the Captcha.'); // eslint-disable-line
    console.log(err); // eslint-disable-line
  };

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        language: 'de',
        doneCallback,
        errorCallback,
      });
    }

    return () => {
      if (widget.current !== undefined) widget.current.destroy();
    };
  }, [container]);

  return (
    <div ref={container} className="frc-captcha" data-sitekey={config.friendlyCaptcha.sitekey} data-puzzle-endpoint={FRIENDLY_CAPTCHA_ENDPOINT} data-i18n="de" />
  );
};


FriendlyCaptcha.defaultProps = {
  onSolutionSolved: () => {},
};

FriendlyCaptcha.propTypes = {
  onSolutionSolved: PropTypes.func,
};

export default FriendlyCaptcha;
