import Rest from '../../common/Rest';
import { BET_ROLLE_DEFAULT_OPTIONS, BET_ROLLE_VJ_FOLGEEINGABE_OPTIONS } from './beteiligte.constants';
import gbDokBezeichnung from '../gb.dok.bezeichnungen.json';
import { LOESCHUNGEN_GESETZESGRUNDLAGE_OPTIONS, GB_VERTRETUNGSVOLLMACHT } from './gb.constants';
import { jsonToArray } from '../../utils/general.utils';

export const UPDATE_WHOLE_OBJECT = 'UPDATE_WHOLE_OBJECT';

export const VERALTET_TABLE_ITEM = '(Veraltet)';

const DEFAULT_PAGEABLE = {
  page: 0,
  size: 50,
  sort: 'kuerzel,ASC',
};

export const COUNTRY_ASYNC_SETTINGS = {
  valueKey: 'ort',
  updateAdditionalFields: {
    formNames: ['adresse.plz'],
    valueKeys: ['plz'],
  },
  url: Rest.urls.codesCountry,
};

export const PA_COUNTRY_ASYNC_SETTINGS = {
  valueKey: 'ort',
  updateAdditionalFields: {
    formNames: ['element.adresse.postleitzahl'],
    valueKeys: ['plz'],
  },
  url: Rest.urls.codesCountry,
};

export const FORDERUNGEN_COUNTRY_ASYNC_SETTINGS = {
  valueKey: 'ort',
  updateAdditionalFields: {
    formNames: ['element.zustaendigkeitsOrt.zustaendigkeitsPLZ'],
    valueKeys: ['plz'],
  },
  url: Rest.urls.codesCountry,
};


export const COUNTRY_ASYNC_SETTINGS_SEARCH = {
  ...COUNTRY_ASYNC_SETTINGS,
  updateAdditionalFields: {
    formNames: ['plz'],
    valueKeys: ['plz'],
  },
};

export const PLZ_ASYNC_SETTINGS = {
  valueKey: 'plz',
  updateAdditionalFields: {
    formNames: ['adresse.ort'],
    valueKeys: ['ort'],
  },
  url: Rest.urls.codesPlz,
};

export const PA_PLZ_ASYNC_SETTINGS = {
  valueKey: 'plz',
  updateAdditionalFields: {
    formNames: ['element.adresse.ort'],
    valueKeys: ['ort'],
  },
  url: Rest.urls.codesPlz,
};

export const PLZ_FB_ASYNC_SETTINGS = {
  valueKey: 'plz',
  updateAdditionalFields: {
    formNames: ['element.adresse.ort'],
    valueKeys: ['ort'],
  },
  url: Rest.urls.codesWtPlz,
};

export const COUNTRY_FB_ASYNC_SETTINGS = {
  valueKey: 'ort',
  updateAdditionalFields: {
    formNames: ['element.adresse.plz'],
    valueKeys: ['plz'],
  },
  url: Rest.urls.codesWtOrte,
};

export const FORDERUNGEN_PLZ_ASYNC_SETTINGS = {
  valueKey: 'plz',
  updateAdditionalFields: {
    formNames: ['element.zustaendigkeitsOrt.zustaendigkeitsOrt'],
    valueKeys: ['ort'],
  },
  url: Rest.urls.codesPlz,
};

export const PLZ_ASYNC_SETTINGS_SEARCH = {
  ...PLZ_ASYNC_SETTINGS,
  updateAdditionalFields: {
    formNames: ['ort'],
    valueKeys: ['ort'],
  },
};

export const POLITISCHE_GEMEINDE_NUMMERN_SETTINGS = {
  valueKey: 'gemeindeNummer',
  updateAdditionalFields: {
    formNames: ['element.sitz.sitz'],
    valueKeys: ['ort'],
  },
  url: Rest.urls.codesWtPolitischeGemeindeNummern,
};

export const POLITISCHE_GEMEINDE_NAMEN_SETTINGS = {
  valueKey: 'ort',
  updateAdditionalFields: {
    formNames: ['element.sitz.ortNr'],
    valueKeys: ['gemeindeNummer'],
  },
  url: Rest.urls.codesWtPolitischeGemeindeNamen,
};

export const AKTENZEICHEN_ASYNC_SETTINGS = {
  valueKey: 'label',
  resetAdditionalFields: true,
  updateAdditionalFields: {
    formNames: ['aktid'],
    valueKeys: ['value'],
  },
  url: Rest.urls.schriftsaetzeAkt,
};

export const AKTENZEICHEN_ASYNC_SETTINGS_SCHRIFTSATZ_SEARCH = {
  valueKey: 'label',
  url: Rest.urls.schriftsaetzeAkt,
};

export const SCHRIFSTATZ_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  resetAdditionalFields: true,
  resetInputField: true,
  updateAdditionalFields: {
    formNames: ['kontaktId'],
    valueKeys: ['id'],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const BETEILIGTER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['kontakt'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const VERTRETER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['vertreterKontakt'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const DRITTSCHULDNER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['details.drittschuldner'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const TLDZ_EMPFAENGER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['uestEmpfaenger'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const BMI_EMPFAENGER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['uestEmpfaenger'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const TLDZ_BANK_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['inhalt.referenzkonto.bankinstitut'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const TLDZ_TREUHANDSCHAFT_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['inhalt.treuhandschaft'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const TLDZ_ABTRETENDER_TREUHAENDER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['inhalt.abtretenderTreuhaender'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const TLDZ_TREUHAENDER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['inhalt.treuhaender'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const UNTERZEICHNER_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['unterzeichner'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const GESELLSCHAFT_SEARCH_KONTAKT_ASYNC_SETTINGS = {
  valueKey: 'kuerzel',
  updateAdditionalFields: {
    formNames: ['gesellschaft'],
    valueKeys: [UPDATE_WHOLE_OBJECT],
  },
  url: Rest.urls.kontakt,
  pageable: {
    ...DEFAULT_PAGEABLE,
  },
};

export const BETEILIGTE_ROLLE_VJ_FOLGEEINGABE_SYNC_SETTINGS = {
  valueKey: 'label',
  options: BET_ROLLE_VJ_FOLGEEINGABE_OPTIONS,
};

export const BETEILIGTE_ROLLE_EZUSTELLUNG_SYNC_SETTINGS = {
  valueKey: 'label',
  options: BET_ROLLE_DEFAULT_OPTIONS,
};

export const BETEILIGTE_ROLLE_STV_SYNC_SETTINGS = {
  valueKey: 'label',
  options: BET_ROLLE_DEFAULT_OPTIONS,
};

export const GB_EINGABEGEBUEHR_SETTINGS = {
  valueKey: 'label',
  options: GB_VERTRETUNGSVOLLMACHT,
};

export const GB_DOK_BEZEICHNUNG_SETTINGS = {
  valueKey: 'label',
  options: jsonToArray(gbDokBezeichnung),
};

export const BEGHEREN_PR_GESETZEGRUNDLAGE_SETTINGS = {
  valueKey: 'label',
  options: LOESCHUNGEN_GESETZESGRUNDLAGE_OPTIONS,
};
