import React from 'react';
import { ReactComponent as TelSVG } from '../../../assets/icons/mz-tel.svg';
import { ReactComponent as HelpSVG } from '../../../assets/icons/mz-help.svg';
import { getMrsUrl } from '../../../utils/help.utils';
import modalFactory from '../../../common/Modal/modalFactory';
import HelpModal from '../../../common/Form/HelpModal';
import { getMrsRestRoot } from '../../../common/Rest/rest.utils';

/**
 * on click
 * @param {Object} event event
 * @return {undefined}
 */
function onClick(event) {
  event.stopPropagation();
  event.preventDefault();

  modalFactory.open(
    HelpModal,
    {
      size: 'lg',
      hilfeTitel: 'Hilfe',
      hilfePathname: 'FAQ',
    },
  );
}

/**
 * Icons in header
 * @return {*} undefined
 * @constructor
 */
function IconNavigation() {
  return (
    <div className="icon-menu d-none d-lg-flex">
      <nav>
        <ul className="nav-icons">
          <li className="nav-icon">
            <TelSVG className="icon icon-hover" title="" />
            <div className="submenu">
              <ul>
                <li><b>Hotline</b></li>
                <li><a href="tel:+4315316111" className="full-width manz-link" title="tel:+4315316111" aria-label="tel:+4315316111">+43 1 531 61 11</a></li>
                <li><a href="mailto:hotline@manz.at" className="full-width manz-link" title="hotline@manz.at" aria-label="hotline@manz.at">hotline@manz.at</a></li>
              </ul>
            </div>
          </li>
          <li className="nav-icon">
            <HelpSVG className="icon icon-hover" title="" />
            <div className="submenu">
              <ul>
                <li>
                  {/* eslint-disable-next-line */}
                  <a
                    title="FAQ"
                    target="_blank"
                    aria-label="FAQ"
                    rel="noreferrer noopener"
                    href={getMrsUrl('FAQ')}
                    className="full-width manz-link"
                    onClick={(e) => onClick(e)}
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a
                    target="_blank"
                    className="manz-link"
                    rel="noreferrer noopener"
                    title="Benutzerhandbuch"
                    aria-label="Benutzerhandbuch"
                    href={`${getMrsRestRoot()}/resource_service/content/dms/weberv/Benutzerhandbuch/webERV_Software_Benutzerhandbuch_Version2023.pdf`}
                  >
                    Benutzerhandbuch
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default IconNavigation;
