import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from '../common/Buttons/CopyToClipboard';

/**
 * text redux form component
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function TextView(props) {
  const {
    input,
    tooltip,
    className,
    formatValue,
  } = props;

  const classNames = className ? className.split(' ') : [];

  if (formatValue) {
    input.value = formatValue(input.value);
  }

  return (
    <div
      className={`${classNames.join(' ')} d-flex align-items-center`}
    >
      <div title={tooltip} className="word-break-all">
        {input.value}
      </div>
      <CopyToClipboard
        text={input.value}
        className="border-0"
        tooltip="Message ID in Zwischenablage kopieren"
      />
    </div>
  );
}

TextView.defaultProps = {
  input: null,
  tooltip: null,
  className: null,
  formatValue: null,
};

TextView.propTypes = {
  input: PropTypes.object,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  formatValue: PropTypes.func,
};

export default TextView;
