export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const SORT_ASC_TEXT = ' (aufsteigend)';
export const SORT_DESC_TEXT = ' (absteigend)';

export const SORT_DIRECTION = {
  ASC: SORT_ASC,
  DESC: SORT_DESC,
};

export const SortDirectionText = {
  ASC: SORT_ASC_TEXT,
  DESC: SORT_DESC_TEXT,
};
