import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CopySVG } from '../../assets/icons/mz-kopieren.svg';
import modalFactory from '../Modal/modalFactory';
import ConfirmationModal from '../Modal/ConfirmationModal';
import getFormattedMessage from '../FormatMessage/formattedMessage';

/**
 * text redux form component
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function CopyToClipboard(props) {
  const {
    text,
    tooltip,
    className,
    buttonText,
    onCallbackClick,
  } = props;

  const classNames = className ? className.split(' ') : [];
  const showModalDialog = (textMsg) => {
    modalFactory.openWithPromise(ConfirmationModal, {
      header: getFormattedMessage({ id: 'hinweis.header' }),
      body: getFormattedMessage({
        id: textMsg,
      }),
      disableClose: true,
    });
  };
  const copy = () => {
    if (Object.hasOwnProperty.call(window, 'ActiveXObject') && !window.ActiveXObject) {
      // IE11
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      document.body.removeChild(textArea);
      showModalDialog('erledigung.messageId.copied');
    } else if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text);
      showModalDialog('erledigung.messageId.copied');
    } else {
      showModalDialog('error.browser.doesNotSupport');
    }

    if (onCallbackClick) {
      onCallbackClick();
    }
  };

  return (
    <button
      type="button"
      title={tooltip}
      onClick={copy}
      aria-label="Kopieren"
      className={`${classNames.join(' ')} btn btn-tertiary icon-button ml-1`}
    >
      <CopySVG title={tooltip} />
      { buttonText && (
        <span className="label">
          {buttonText}
        </span>
      )}
    </button>
  );
}

CopyToClipboard.defaultProps = {
  text: null,
  tooltip: null,
  className: null,
  buttonText: '',
  onCallbackClick: null,
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  onCallbackClick: PropTypes.func,
};

export default CopyToClipboard;
