import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Paging from '../Paging/Paging';
import sortIcon from '../../assets/icons/mz-sortierung.svg';
import sortAsc from '../../assets/icons/mz-sortierung-2.svg';
import sortDesc from '../../assets/icons/mz-sortierung-3.svg';
import { SORT_ASC, SORT_DESC } from '../../constants/js/sort.constants';
import SimpleTableColumns from './SimpleTableColumns';
import ResponsiveSimpleTableBodyMobil from './ResponsiveSimpleTableBodyMobil';
import { arrayHasItem } from '../../utils/general.utils';
import SCHRIFTSATZ_STATUS from '../../constants/schriftsatz.status.json';
import {
  clearTableExtraSearchParam,
  clearTableIndex, clearTableLocationSearch,
  storeTableExtraSearchParam,
  storeTableIndex, storeTableLocationSearch,
} from '../Reducers/simpleTable.reducer';

/**
 * simple table component with sicky table headers for browser which support position sticky
 */
class SimpleTable extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    const pageSizes = [10, 25, 50];
    const firstColumn = props.tableColumns[0];

    let sortId = (firstColumn && firstColumn.sortColumn) || ''; // eslint-disable-line
    let sortDirection = SORT_DESC;

    if (typeof props.sortedBy === 'string') {
      const sortedBy = props.sortedBy.split(',');
      if (sortedBy.length > 0 && sortedBy[0] !== '') {
        sortDirection = sortedBy[sortedBy.length - 1];
        sortedBy.splice(sortedBy.length - 1, 1);
        sortId = sortedBy.join(',');
      }
    } else if (typeof props.sortedBy === 'object') {
      const sortedBy = props.sortedBy;
      if (sortedBy?.id !== '') {
        sortDirection = sortedBy.direction;
        sortId = sortedBy?.id;
      }
    }

    const newPageSizes = props.pageSizes || pageSizes;

    this.state = {
      page: props.page ? parseInt(props.page, 10) : 0,

      pageSize: props.pageSize ? parseInt(props.pageSize, 10) : newPageSizes[0],
      pageSizes: newPageSizes,

      sortedBy: {
        id: sortId,
        direction: sortDirection,
      },

      dragTarget: {
        uiId: null,
        dbId: null,
        rowId: null,
      },
    };

    this.isClickRow = false;

    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeSorting = this.handleChangeSorting.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this);

    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  /**
   * componentDidUpdate
   * @param {Object} prevProps prevProps
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    const {
      page,
      tableType,
      tableReducer,
      resetPageBySearch,
    } = this.props;

    if (arrayHasItem(tableReducer?.tableIndexArray)) {
      const fand = tableReducer.tableIndexArray.find((v) => v.tableType === tableType);
      if (fand) {
        const element = document.getElementById(`table-id-${fand.tableIndex}`);
        const top = element?.offsetTop;
        if (top) {
          window.scrollTo(0, top);
        }
      }
    }

    if (resetPageBySearch && prevProps.page !== 0 && page === 0) {
      this.setState({ page: 0 }); // eslint-disable-line
    }
  }

  /**
   * componentWillUnmount
   * @return {undefined}
   */
  componentWillUnmount() {
    const { tableType, actions } = this.props;
    if (!this.isClickRow) {
      actions.clearTableIndex(tableType);
    }
  }

  /**
   * handler for click events on table rows
   * @param {Object} row data of the clicked row
   * @returns {undefined}
   */
  handleRowClick(row) {
    const {
      actions,
      onClick,
      tableType,
      extraLocationUrl,
      extraSearchParam,
    } = this.props;
    const { page, pageSize } = this.state;
    if (onClick) {
      let uiIndex;
      if (row.index) {
        const index = parseInt(row.index, 10);
        uiIndex = page > 0 ? (index - (page * pageSize)) : index;
      }
      const tableIndex = (uiIndex || row?.id) - 1;
      actions.storeTableIndex(tableType, tableIndex);
      if (extraSearchParam) {
        actions.clearTableLocationSearch(tableType);
        actions.storeTableExtraSearchParam(tableType, extraSearchParam);
      } else if (extraLocationUrl) {
        actions.clearTableExtraSearchParam(tableType);
        actions.storeTableLocationSearch(tableType, extraLocationUrl);
      } else {
        actions.clearTableLocationSearch(tableType);
        actions.clearTableExtraSearchParam(tableType);
      }
      onClick(row);
      this.isClickRow = true;
    }
  }

  /**
   * handler for changing pages
   * @param {number} page selected page
   * @returns {undefined}
   */
  handleChangePage(page) {
    const { onChange } = this.props;
    const { pageSize, sortedBy } = this.state;
    this.setState({
      page,
    });
    onChange(page, pageSize, sortedBy);
  }

  /**
   * handler for column sorting
   * @param {Object} col sorted col
   * @param {String} direc direction
   * @returns {undefined}
   */
  handleChangeSorting(col, direc) {
    const { onChange } = this.props;
    const { page, pageSize } = this.state;
    this.setState((prevState) => {
      let sortedBy = prevState.sortedBy;
      const sortId = col.sortColumn;
      if (sortId !== sortedBy?.id) {
        sortedBy = {
          id: sortId,
          direction: SORT_ASC,
        };
      } else {
        sortedBy.direction = direc || sortedBy.direction === SORT_ASC ? SORT_DESC : SORT_ASC;
      }
      return {
        sortedBy,
      };
    }, () => onChange(page, pageSize, this.state.sortedBy));
  }

  /**
   * handler for page size changes
   * @param {Object} event event
   * @returns {undefined}
   */
  handleChangePageSize(event) {
    const value = event.target.value;
    const newPageSize = parseInt(value, 10);
    const newPage = 0;
    this.setState({
      pageSize: newPageSize,
      page: newPage,
    });
    const { onChange } = this.props;
    const { sortedBy } = this.state;
    onChange(newPage, newPageSize, sortedBy);
  }

  /**
   * drag start
   * @param {Object} e event
   * @return {undefined}
   */
  handleDragStart(e) { // eslint-disable-line
    e.dataTransfer.dropEffect = 'move';
  }

  /**
   * drag over
   * @param {Object} e event
   * @return {undefined}
   */
  handleDragOver(e) { // eslint-disable-line
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  /**
   * drag enter
   * @param {Object} e event
   * @return {undefined}
   */
  handleDragEnter(e) {
    const { tableData } = this.props;
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    const uiId = e.currentTarget?.id;

    const rowIdStr = uiId.replace('table-id-', '');
    const rowId = parseInt(rowIdStr, 10);

    let dbId = null;
    const fand = (rowIdStr && arrayHasItem(tableData) && tableData[rowId]) || null;
    if (fand) {
      dbId = fand?.id;
    }

    this.setState({
      dragTarget: {
        uiId,
        dbId,
        rowId,
      },
    });
  }

  /**
   * drag end
   * @param {Object} e event
   * @return {undefined}
   */
  handleDragEnd(e) {
    const { onDragDropRow, tableData } = this.props;
    const { dragTarget } = this.state;
    const uiId = e.target?.id;

    const rowIdStr = uiId.replace('table-id-', '');
    const rowId = parseInt(rowIdStr, 10);

    let dbId = null;
    const fand = (rowIdStr && arrayHasItem(tableData) && tableData[rowId]) || null;
    if (fand) {
      dbId = fand?.id;
    }

    const dragSource = {
      uiId,
      dbId,
      rowId,
    };

    if (onDragDropRow) {
      onDragDropRow(dragSource, dragTarget);
    }
  }

  /**
   * _renderPagingCount
   * @param {Array} pageSizeSelectOptions pageSizeSelectOptions
   * @return {JSX.Element[]} JSX
   * @private
   */
  _renderPagingCount(pageSizeSelectOptions) {
    const { total } = this.props;
    const {
      page,
      pageSize,
    } = this.state;

    return [
      <div key="table-paging-placeholder" className="table-paging-placeholder d-none d-md-inline" />,
      <div key="table-paging-page">
        <Paging
          total={total}
          page={page}
          pageSize={pageSize}
          onPageChange={this.handleChangePage}
        />
      </div>,
      <div key="table-paging-select" className="select-paging">
        <span className="select-text">Anzeigen</span>
        <select
          value={pageSize}
          aria-label="Anzeigen"
          onChange={(e) => this.handleChangePageSize(e)}
        >
          {pageSizeSelectOptions}
        </select>
      </div>,
    ];
  }

  /**
   * render function
   * @returns {JSX} component JSX
   */
  render() {
    const {
      onClick,
      tableData,
      draggable,
      tableType,
      onClickItem,
      extraHeader,
      isIgnoreSort,
      onSelectItem,
      isWithBolder,
      itemColIndex,
      tableColumns,
      emptyListText,
      withoutPaging,
      canResponsive,
      noTableHeader,
      substringLength,
      notDisplayHeader,
      responsiveSortable,
      isSubstringContent,
      showResponseRow,
      responsiveTableColumns,
      isTopDisplayPagingCount,
    } = this.props;
    const {
      page,
      pageSize,
      sortedBy,
      pageSizes,
    } = this.state;

    // defines table header
    const tableHeader = !noTableHeader && tableColumns.map((col, idx) => {
      const className = col.className || '';

      if (
        isIgnoreSort
        || col.dataKey === 'id'
        || col.dataKey === 'btns'
        || col.dataKey === 'kopieren'
        || col.dataKey === 'rowindex'
        || col.dataKey === 'actions'
        || col.ignoreSort
      ) {
        return (
          <th
            key={idx.toString()}
            className={`cursor-default ${className}`}
          >
            <span>{col.label}</span>
          </th>
        );
      }

      let icon = sortIcon;
      if (col.sortColumn === sortedBy?.id) {
        const sortDirection = sortedBy.direction;
        if (sortDirection === SORT_ASC) {
          icon = sortAsc;
        } else if (sortDirection === SORT_DESC) {
          icon = sortDesc;
        }
      }

      return (
        <th
          key={idx.toString()}
          className={className}
          onClick={() => this.handleChangeSorting(col)}
        >
          <span>{col.label}</span>
          <img
            src={icon}
            alt="sort icon"
            aria-label="sort icon"
            className="icon-xs ml-1"
          />
          {col.component}
        </th>
      );
    });

    // defines table rows
    const tableRows = tableData.map((row, idx) => {
      const cols = (
        <SimpleTableColumns
          idx={idx}
          row={row}
          page={page}
          pageSize={pageSize}
          onClickItem={onClickItem}
          onSelectItem={onSelectItem}
          isWithBolder={isWithBolder}
          itemColIndex={itemColIndex}
          tableColumns={tableColumns}
          substringLength={row.substringLength || substringLength}
          isSubstringContent={row.isSubstringContent || isSubstringContent}
        />
      );

      return (
        <tr
          key={idx.toString()}
          draggable={draggable}
          id={`table-id-${idx}`}
          onDragEnd={this.handleDragEnd}
          onDragOver={this.handleDragOver}
          onDragStart={this.handleDragStart}
          onDragEnter={this.handleDragEnter}
          onClick={() => this.handleRowClick(row)}
          className={
            (row.statusValue === SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value || row.statusValue === SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value)
              ? 'error-status-tooltip-container'
              : ''
          }
        >
          {cols}
        </tr>
      );
    });

    const pageSizeSelectOptions = pageSizes.map((size) => {
      const selected = size === pageSize;
      return (
        <option
          key={size}
          value={size}
          defaultValue={selected}
        >
          {size}
        </option>
      );
    });

    return (
      <>
        {(
          <div className="table-container">

            {(isTopDisplayPagingCount && arrayHasItem(tableData) && (tableData.length > 5 || page > 0)) && (
              <div id="table-paging-number" key="table-paging" className="table-paging">
                {!withoutPaging && this._renderPagingCount(pageSizeSelectOptions)}
              </div>
            )}

            <div className={canResponsive ? 'd-none d-lg-block' : ''}>
              <table key="table">
                <thead>
                  {!notDisplayHeader && (
                    <tr>{tableHeader}</tr>
                  )}
                  {extraHeader && (
                    <tr>{extraHeader}</tr>
                  )}
                </thead>
                <tbody>{tableRows}</tbody>
              </table>
            </div>

            {canResponsive && (
              <div className={canResponsive ? 'd-block d-lg-none' : ''}>
                <ResponsiveSimpleTableBodyMobil
                  onClick={onClick}
                  tableData={tableData}
                  tableType={tableType}
                  sortable={responsiveSortable}
                  onSort={this.handleChangeSorting}
                  showResponseRow={showResponseRow}
                  tableColumns={responsiveTableColumns}
                />
              </div>
            )}

            {tableData.length === 0 && (
              <div
                className="text-center manz-red font-weight-bold"
              >
                {emptyListText || 'Keine Ergebnisse gefunden'}
              </div>
            )}

            <div id="table-paging-number" key="table-paging" className="table-paging">
              {!withoutPaging && this._renderPagingCount(pageSizeSelectOptions)}
            </div>

          </div>
        )}
      </>
    );
  }
}

SimpleTable.defaultProps = {
  total: 0,
  page: null,
  sortedBy: '',
  tableData: [],
  actions: null,
  onClick: null,
  pageSize: null,
  pageSizes: null,
  tableType: null,
  draggable: false,
  onClickItem: null,
  extraHeader: null,
  isIgnoreSort: false,
  onSelectItem: null,
  itemColIndex: null,
  isWithBolder: false,
  withoutPaging: false,
  canResponsive: false,
  noTableHeader: false,
  onDragDropRow: null,
  extraLocationUrl: null,
  extraSearchParam: null,
  notDisplayHeader: false,
  responsiveSortable: true,
  isSubstringContent: null,
  resetPageBySearch: false,
  showResponseRow: false,
  substringLength: 999999,
  responsiveTableColumns: [],
  isTopDisplayPagingCount: false,
  emptyListText: 'Keine Ergebnisse gefunden',
  onChange: () => {
  },
};

SimpleTable.propTypes = {
  onClick: PropTypes.func,
  total: PropTypes.number,
  actions: PropTypes.object,
  draggable: PropTypes.bool,
  onChange: PropTypes.func,
  tableData: PropTypes.array,
  pageSizes: PropTypes.array,
  tableType: PropTypes.string,
  isIgnoreSort: PropTypes.bool,
  onClickItem: PropTypes.func,
  onSelectItem: PropTypes.func,
  isWithBolder: PropTypes.bool,
  withoutPaging: PropTypes.bool,
  canResponsive: PropTypes.bool,
  noTableHeader: PropTypes.bool,
  emptyListText: PropTypes.string,
  extraHeader: PropTypes.element,
  onDragDropRow: PropTypes.func,
  itemColIndex: PropTypes.number,
  notDisplayHeader: PropTypes.bool,
  extraLocationUrl: PropTypes.string,
  resetPageBySearch: PropTypes.bool,
  responsiveSortable: PropTypes.bool,
  isSubstringContent: PropTypes.bool,
  showResponseRow: PropTypes.bool,
  extraSearchParam: PropTypes.string,
  substringLength: PropTypes.number,
  isTopDisplayPagingCount: PropTypes.bool,
  tableReducer: PropTypes.object.isRequired,
  responsiveTableColumns: PropTypes.array,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sortedBy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      sortingDisabled: PropTypes.bool,
    }),
  ).isRequired,
};

/**
 * mapStateToProps function
 * @param {Object} state state
 * @returns {{form: string, initialValues: {}}} object
 */
export function mapStateToProps(state) {
  return {
    ...state,
  };
}

/**
 * map disaptch function to component properties
 * @param {Function} dispatch redux dispatch function
 * @returns {Object} object of actions and
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      storeTableIndex,
      clearTableIndex,
      storeTableLocationSearch,
      clearTableLocationSearch,
      storeTableExtraSearchParam,
      clearTableExtraSearchParam,
    }, dispatch),
  };
}

export default (connect(mapStateToProps, mapDispatchToProps)(SimpleTable));
