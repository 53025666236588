import React from 'react';
import { Redirect } from 'react-router'; // eslint-disable-line
import PropTypes from 'prop-types';
import PATH from '../../constants/path';

/**
 * home site component
 * display the landing page if not authenticates otherwise redirects to upload
 * @param {Object} props Component Properties where user object ist part of it
 * @returns {JSX} component JSX
 */
function BaseRoute(props) {
  const { appAuthorized } = props;

  return appAuthorized
    ? (
      <Redirect
        from="*"
        to={PATH.DASHBOARD.value}
      />
    )
    : (
      <Redirect
        from="*"
        to={PATH.LANDING_PAGE.value}
      />
    );
}

BaseRoute.defaultProps = {
  appAuthorized: false,
};

BaseRoute.propTypes = {
  appAuthorized: PropTypes.bool,
};

export default BaseRoute;
