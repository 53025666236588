import React from 'react';
import PropTypes from 'prop-types';
import neueMitteilung from '../../assets/icons/mz-neue-mitteilung.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function NeueMitteilungIcon(props) {
  const { className, onClick } = props;
  return (
    <img // eslint-disable-line
      width={32}
      height={32}
      onClick={onClick}
      id="manz-plus-circle"
      src={neueMitteilung}
      className={className}
      alt="neue mitteilung icon"
      aria-label="neue mitteilung icon"
    />
  );
}

NeueMitteilungIcon.defaultProps = {
  onClick: null,
  className: null,
};

NeueMitteilungIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default NeueMitteilungIcon;
