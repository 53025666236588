const STORE_TABLE_INDEX = 'store.table.index';
const CLEAR_TABLE_INDEX = 'clear.table.index';

const STORE_TABLE_LOCATION_SEARCH = 'store.table.location.search';
const CLEAR_TABLE_LOCATION_SEARCH = 'clear.table.location.search';
const CLEAR_ALL_TABLE_LOCATION_SEARCH = 'clear.all.table.location.search';

const STORE_TABLE_EXTRA_SEARCH_PARAM = 'store.table.extra.search.param';
const CLEAR_TABLE_EXTRA_SEARCH_PARAM = 'clear.table.extra.search.param';
const CLEAR_ALL_TABLE_EXTRA_SEARCH_PARAM = 'clear.all.table.extra.search.param';

const initial = {
  /**
   * tableIndexArray: [
   *    {
   *      tableType: table type,
   *      tableIndex: table index,
   *    },
   *    ...
   * ]
   */
  tableIndexArray: [],

  /**
   * tableExtraSearchParams: [
   *    {
   *      tableType: table type,
   *      searchParam: search param,
   *    },
   *    ...
   * ]
   */
  tableLocationSearch: [],

  /**
   * tableExtraSearchParams: [
   *    {
   *      tableType: table type,
   *      searchParam: search param,
   *    },
   *    ...
   * ]
   */
  tableExtraSearchParam: [],
};

/**
 * table reducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{activeKeys: Array}} state
 */
export function tableReducer(state = initial, action) {
  const payload = action.payload;

  let existIndex = null;
  let byIndex = null;

  let tableLocationSearch = null;

  let existSearchParam = null;
  let bySearchParamIndex = null;

  switch (action.type) {
    case STORE_TABLE_INDEX:
      existIndex = state.tableIndexArray.find((v) => v.tableType === payload.tableType);
      if (existIndex) {
        existIndex.tableIndex = payload.tableIndex;
      } else {
        state.tableIndexArray.push(payload);
      }
      return {
        ...state,
      };

    case CLEAR_TABLE_INDEX:
      byIndex = state.tableIndexArray.findIndex((v) => v.tableType === payload.tableType);
      if (byIndex) {
        state.tableIndexArray.splice(byIndex, 1);
      }
      return {
        ...state,
      };

    case STORE_TABLE_LOCATION_SEARCH:
      tableLocationSearch = state.tableLocationSearch.find((v) => v.tableType === payload.tableType);
      if (tableLocationSearch) {
        tableLocationSearch.searchParam = payload.searchParam;
      } else {
        state.tableLocationSearch.push(payload);
      }
      return {
        ...state,
      };

    case CLEAR_TABLE_LOCATION_SEARCH:
      tableLocationSearch = state.tableLocationSearch.findIndex((v) => v.tableType === payload.tableType);
      if (tableLocationSearch) {
        state.tableLocationSearch.splice(tableLocationSearch, 1);
      }
      return {
        ...state,
      };

    case CLEAR_ALL_TABLE_LOCATION_SEARCH:
      state.tableLocationSearch = [];
      return {
        ...state,
      };

    case STORE_TABLE_EXTRA_SEARCH_PARAM:
      existSearchParam = state.tableExtraSearchParam.find((v) => v.tableType === payload.tableType);
      if (existSearchParam) {
        existSearchParam.searchParam = payload.searchParam;
      } else {
        state.tableExtraSearchParam.push(payload);
      }
      return {
        ...state,
      };

    case CLEAR_TABLE_EXTRA_SEARCH_PARAM:
      bySearchParamIndex = state.tableExtraSearchParam.findIndex((v) => v.tableType === payload.tableType);
      if (bySearchParamIndex) {
        state.tableExtraSearchParam.splice(bySearchParamIndex, 1);
      }
      return {
        ...state,
      };

    case CLEAR_ALL_TABLE_EXTRA_SEARCH_PARAM:
      state.tableExtraSearchParam = [];
      return {
        ...state,
      };

    default:
      return state;
  }
}

/**
 * storeTableIndex
 * @param {String} tableType active tableType
 * @param {String} tableIndex tableIndex
 * @return {Object} redux action
 */
export function storeTableIndex(tableType, tableIndex) {
  return {
    type: STORE_TABLE_INDEX,
    payload: {
      tableType,
      tableIndex,
    },
  };
}

/**
 * clearTableIndex
 * @param {String} tableType active tableType
 * @return {Object} redux action
 */
export function clearTableIndex(tableType) {
  return {
    type: CLEAR_TABLE_INDEX,
    payload: {
      tableType,
    },
  };
}

/**
 * storeTableLocationSearch
 * @param {String} tableType active tableType
 * @param {String} searchParam searchParam
 * @return {Object} redux action
 */
export function storeTableLocationSearch(tableType, searchParam) {
  return {
    type: STORE_TABLE_LOCATION_SEARCH,
    payload: {
      tableType,
      searchParam,
    },
  };
}

/**
 * clearTableLocationSearch
 * @param {String} tableType active tableType
 * @return {Object} redux action
 */
export function clearTableLocationSearch(tableType) {
  return {
    type: CLEAR_TABLE_LOCATION_SEARCH,
    payload: {
      tableType,
    },
  };
}

/**
 * clearTableLocationSearch
 * @return {Object} redux action
 */
export function clearAllTableLocationSearch() {
  return {
    type: CLEAR_ALL_TABLE_LOCATION_SEARCH,
  };
}

/**
 * storeTableExtraSearchParam
 * @param {String} tableType active tableType
 * @param {String} searchParam searchParam
 * @return {Object} redux action
 */
export function storeTableExtraSearchParam(tableType, searchParam) {
  return {
    type: STORE_TABLE_EXTRA_SEARCH_PARAM,
    payload: {
      tableType,
      searchParam,
    },
  };
}

/**
 * clearTableIndex
 * @param {String} tableType active tableType
 * @return {Object} redux action
 */
export function clearTableExtraSearchParam(tableType) {
  return {
    type: CLEAR_TABLE_EXTRA_SEARCH_PARAM,
    payload: {
      tableType,
    },
  };
}

/**
 * clearTableIndex
 * @return {Object} redux action
 */
export function clearAllTableExtraSearchParam() {
  return {
    type: CLEAR_ALL_TABLE_EXTRA_SEARCH_PARAM,
  };
}
