// eslint-disable-next-line import/no-cycle
import getUrl from '../Rest/rest.utils';
import Rest from '../Rest'; //eslint-disable-line
import makeRestCall from '../Rest/makeRestCall'; //eslint-disable-line
import { getNow } from '../../utils/general.utils';

export const ERROR_LEVEL = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
  DEBUG: 'DEBUG',
};

/**
 * report error
 * @param {String} message message
 * @param {String} stacktrace stacktrace
 * @param {String} level level
 * @param {String} pathname pathname
 * @return {undefined}
 */
export function reportError(message, stacktrace, level, pathname) {
  const userAgent = (window && window.navigator && window.navigator.userAgent) || 'No Content!';
  const msgWithUserAgent = `${message}; USER-AGENT: ${userAgent}`;
  makeRestCall('POST', getUrl(Rest.urls.errorLog), {
    level,
    message: msgWithUserAgent,
    pathname,
    stacktrace,
    timestamp: getNow(),
  }, null, true);
}

/**
 * add event listener for error and report this error to backend
 * @return {undefined}
 */
export function addErrorEventListenerAndReportToBackend() {
  if (window && window.addEventListener) {
    window.addEventListener('error', function (event) { // eslint-disable-line
      if (event && event.error) {
        if (event.error.hasBeenCaught !== undefined) {
          return false;
        }
        event.error.hasBeenCaught = true;

        const { message, error } = event;
        const pathname = (window && window.location) ? window.location.href || window.location.pathname : '';
        reportError(
          message,
          error.stack,
          ERROR_LEVEL.ERROR,
          pathname,
        );
      }
    });
  }
}
