export const OK = 200;
export const CREATED = 201;
export const ACCEPTED = 202;
export const HINT = 222;
export const NO_CONTENT = 204;
export const BAD_REQUEST = 400;
export const FORBIDDEN = 403;
export const TIME_OUT = 408;
export const PRECONDITION_FAILED = 412;
export const EXPECTATION_FAILED = 417;
export const UNPROCCESSABLE_ENTITY = 422;
export const INTERNAL_SERVER_ERROR = 500;
export const BAD_GATEWAY = 502;
export const SERVICE_UNAVIALABLE = 503;
export const GATEWAY_TIMEOUT = 504;
