import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import Routes from './Routes/Routes';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-collapse/assets/index.css';
import './styles/imports.global.scss';
import { addErrorEventListenerAndReportToBackend } from '../common/ErrorReporter/errorReporter.helpers';

/**
 * Main component
 * Component to contain all providers, routers etc.
 * @param {Object} props props
 * @returns {JSX} jsx component
 */
function Main(props) {
  const { store } = props;

  addErrorEventListenerAndReportToBackend();

  return (
    <CookiesProvider>
      <Provider store={store}>
        <Routes />
      </Provider>
    </CookiesProvider>
  );
}

Main.propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

export default Main;
