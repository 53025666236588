const STORE_CHANGED_GB_AUSZUG_SELECT_OPTION = 'store.changed.gb.auszug.select.option';
const CLEAR_CHANGED_GB_AUSZUG_SELECT_OPTION = 'clear.changed.gb.auszug.select.option';

const STORE_DELELTE_BETEILIGTER = 'store.delete.beteiligter';
const CLEAR_DELELTE_BETEILIGTER = 'clear.delete.beteiligter';

const STORE_INIT_GB_AUSZUG_SELECT_LIST = 'store.init.gb.auszug.select.list';
const CLEAR_INIT_GB_AUSZUG_SELECT_LIST = 'clear.init.gb.auszug.select.list';

const STORE_NOT_IN_CURRENT_TABLE_DATA = 'store.not.in.current.table.data';
const CLEAR_NOT_IN_CURRENT_TABLE_DATA = 'clear.not.in.current.table.data';

const initial = {
  /**
   * change grundbuch auszug list option
   */
  changed: false,


  /**
   * delete a beteiligter
   */
  deleteBeteiligter: false,


  /**
   * store selected elements of table data
   */
  // current table data type
  modalType: null,

  // existed elements in formValues
  initSelectList: [],

  // existed elements which are not in current table data
  notInCurrentTableData: [],
};

/**
 * grundbuchAuszugSelectOptionReducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{activeKeys: Array}} state
 */
export function grundbuchAuszugSelectOptionReducer(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_CHANGED_GB_AUSZUG_SELECT_OPTION:
      return {
        ...state,
        changed: payload.changed,
      };

    case CLEAR_CHANGED_GB_AUSZUG_SELECT_OPTION:
      return {
        ...state,
        changed: false,
      };

    case STORE_DELELTE_BETEILIGTER:
      return {
        ...state,
        deleteBeteiligter: payload.deleteBeteiligter,
      };

    case CLEAR_DELELTE_BETEILIGTER:
      return {
        ...state,
        deleteBeteiligter: false,
      };

    case STORE_INIT_GB_AUSZUG_SELECT_LIST:
      return {
        ...state,
        initSelectList: payload.initSelectList,
        modalType: payload.modalType,
      };

    case CLEAR_INIT_GB_AUSZUG_SELECT_LIST:
      return {
        ...state,
        modalType: null,
        initSelectList: [],
      };

    case STORE_NOT_IN_CURRENT_TABLE_DATA:
      return {
        ...state,
        notInCurrentTableData: payload.notInCurrentTableData,
      };

    case CLEAR_NOT_IN_CURRENT_TABLE_DATA:
      return {
        ...state,
        notInCurrentTableData: [],
      };

    default:
      return state;
  }
}

/**
 * store gb antragsgruppe id
 * @param {Number} changed changed
 * @return {Object} redux store
 */
export function storeChangedGbAuszugSelectOption(changed) {
  return {
    type: STORE_CHANGED_GB_AUSZUG_SELECT_OPTION,
    payload: {
      changed,
    },
  };
}

/**
 * clear gb antragsgruppe id
 * @return {Object} redux store
 */
export function clearChangedGbAuszugSelectOption() {
  return {
    type: CLEAR_CHANGED_GB_AUSZUG_SELECT_OPTION,
  };
}

/**
 * storeDeleteBeteiligter
 * @param {Number} deleteBeteiligter deleteBeteiligter
 * @return {Object} redux store
 */
export function storeDeleteBeteiligter(deleteBeteiligter) {
  return {
    type: STORE_DELELTE_BETEILIGTER,
    payload: {
      deleteBeteiligter,
    },
  };
}

/**
 * clearDeleteBeteiligter
 * @return {Object} redux store
 */
export function clearDeleteBeteiligter() {
  return {
    type: CLEAR_DELELTE_BETEILIGTER,
  };
}

/**
 * storeOldGbAuszugSelectList
 * @param {Array} initSelectList initSelectList
 * @param {String} modalType modalType
 * @return {Object} redux store
 */
export function storeInitGbAuszugSelectList(initSelectList, modalType) {
  return {
    type: STORE_INIT_GB_AUSZUG_SELECT_LIST,
    payload: {
      initSelectList,
      modalType,
    },
  };
}

/**
 * clear gb antragsgruppe id
 * @return {Object} redux store
 */
export function clearInitGbAuszugSelectList() {
  return {
    type: CLEAR_INIT_GB_AUSZUG_SELECT_LIST,
  };
}

/**
 * storeNotInCurrentTableData
 * @param {Array} notInCurrentTableData notInCurrentTableData
 * @return {Object} redux store
 */
export function storeNotInCurrentTableData(notInCurrentTableData) {
  return {
    type: STORE_NOT_IN_CURRENT_TABLE_DATA,
    payload: {
      notInCurrentTableData,
    },
  };
}

/**
 * clearNotInCurrentTableData
 * @return {Object} redux store
 */
export function clearNotInCurrentTableData() {
  return {
    type: CLEAR_NOT_IN_CURRENT_TABLE_DATA,
  };
}
