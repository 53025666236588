import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/**
 * save button component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
class SaveButton extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * click save button
   * @return {undefined}
   */
  handleClick() {
    const { history, onClick, isGoBack } = this.props;

    if (onClick) {
      onClick();
    }

    if (isGoBack) {
      history.goBack();
    }
  }

  /**
   * collapse wrapper render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      id,
      btnText,
      disabled,
      className,
      submitting,
    } = this.props;

    return (
      <button
        id={id}
        type="submit"
        aria-label="Speichern"
        onClick={this.handleClick}
        disabled={disabled || submitting}
        className={className || 'btn btn-primary'}
      >
        {btnText}
      </button>
    );
  }
}

SaveButton.defaultProps = {
  id: null,
  onClick: null,
  disabled: false,
  isGoBack: false,
  className: null,
  submitting: false,
  btnText: 'Speichern',
};

SaveButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isGoBack: PropTypes.bool,
  btnText: PropTypes.string,
  submitting: PropTypes.bool,
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default withRouter(SaveButton);
