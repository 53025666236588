import React from 'react';
import modalFactory from '../common/Modal/modalFactory';
import HelpModal from '../common/Form/HelpModal';
import { HILFE_PREFIX, restCallHilfeXml } from '../utils/help.utils';

/**
 * Overview component
 * @returns {JSX} component
 * @constructor
 */
class HilfeSupport extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = { msg: false };

    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * componentWillUnmount
   * @return {undefined}
   */
  async componentDidMount() {
    const msgObject = await restCallHilfeXml('hilfe');
    this.setState({ msg: msgObject && msgObject.infoBody });
  }

  /**
   * handleClick
   * @param {Object} event event
   * @return {undefined}
   */
  async handleClick(event) { // eslint-disable-line
    const value = event.target;

    event.stopPropagation();
    event.preventDefault();

    if (value) {
      const body = new DOMParser()
        .parseFromString(value, 'text/html')
        .querySelector('body')
        .textContent;

      if (body && typeof (body) === 'string' && !body.includes('[object')) {
        if (body.includes(HILFE_PREFIX)) {
          await modalFactory.open(
            HelpModal,
            {
              size: 'lg',
              hilfeTitel: value.innerHTML,
              hilfePathname: body.split(HILFE_PREFIX)[1],
            },
          );
        } else {
          window.open(body, 'target', '');
        }
      }
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { msg } = this.state;

    return (msg && (
      <div>
        {/* eslint-disable-next-line react/no-danger */}
        <div onClick={(event) => this.handleClick(event)} dangerouslySetInnerHTML={{ __html: this.state.msg }} />
      </div>
    ));
  }
}

export default HilfeSupport;
