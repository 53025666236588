import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import Upload from '../common/Form/Upload';
import { requiredField } from '../utils/validator.utils';
import FORM from '../constants/form.json';
import ImportButton from '../common/Buttons/ImportButton';
import { arrayHasItem } from '../utils/general.utils';
import Rest from '../common/Rest';

/**
 * Datenimport
 */
class Datenimport extends React.Component {
  /**
   * constuctor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const { formValues } = this.props;

    return (
      <div className="padding-content">
        <form>
          <div>
            Mit dem Datenimport können
            <span><strong> Kontakte </strong></span>
            importiert werden.
          </div>

          <div className="margin-top-sx">
            Erstellen Sie einen aktuellen Export aus Ihrer Software und öffnen
            danach die entsprechende Datei.
          </div>

          <div className="margin-top-sx">
            <strong>
              Bitte kontrollieren Sie Ihre Kontakte um
              fehlerhafte Datensätze zu vermeiden.
            </strong>
          </div>

          <div className="row margin-top-sx minHeight">
            <div className="col-12 col-lg-8 col-xl-6">
              <div key="csv" className="form-group form-row margin-top-sx">
                <label
                  className="col-form-label col-12 col-sm-4 col-md-1 label-required"
                >
                  Datei
                </label>
                <div className="col-12 col-sm-8 col-md-6">
                  <Field
                    name="csv"
                    component={Upload}
                    validate={requiredField}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-right">
            <ImportButton
              url={Rest.urls.kontaktImport}
              data={formValues && arrayHasItem(formValues.csv) && formValues.csv[0]}
            />
          </div>
        </form>
      </div>
    );
  }
}

Datenimport.defaultProps = {
  formValues: null,
};

Datenimport.propTypes = {
  formValues: PropTypes.object,
};

/**
 * mapStateToProps function
 * @param {Object} state state
 * @returns {object} redux form
 */
export function mapStateToProps(state) {
  return {
    formValues: getFormValues(FORM.DATEN_IMPORT)(state),
  };
}

let form = reduxForm({ // eslint-disable-line
  form: FORM.DATEN_IMPORT,
  enableReinitialize: true,
})(Datenimport);

form = connect(mapStateToProps)(form);

export default form;
