import { SORT_ASC } from './sort.constants';

export const ERLEDIGUNG_STATUS_OPTIONS = [
  {
    value: 'NEU',
    label: 'Ungelesen',
  },
  {
    value: 'KONTROLLIERT',
    label: 'Gelesen',
  },
];

export const ERLEDIGUNG_DOCUMENT_TABLE_COLS = [
  {
    dataKey: 'label',
    sortColumn: 'label',
    className: 'label-col',
  },
  {
    dataKey: 'download',
    className: 'download-col',
  },
  {
    dataKey: 'reihung',
    sortColumn: 'reihung',
    className: 'd-none',
  },
];

export const ERLEDIGUNG_DOCUMENT_RESP_TABLE_COLS = [
  {
    dataKey: 'bemerkung',
    sortColumn: 'bezeichnung',
  },
  {
    dataKey: 'download',
    btnText: 'Download',
    className: 'download-col',
  },
];

export const ERLEDIGUNG_DOCUMENT_DEFAULT_SORT = {
  id: 'reihung',
  direction: SORT_ASC,
};
