import PATH from '../../constants/path.json';

const STORE_FIRST_BREADCRUMB = 'store.first.breadcrumb';
const CLEAR_FIRST_BREADCRUMB = 'clear.first.breadcrumb';
const STORE_SECOND_BREADCRUMB = 'store.second.breadcrumb';
const CLEAR_SECOND_BREADCRUMB = 'clear.second.breadcrumb';
const STORE_EXTRA_BREADCRUMB = 'store.extra.breadcrumb';
const CLEAR_EXTRA_BREADCRUMB = 'clear.extra.breadcrumb';
const STORE_SCHRIFTSATZ_SEARCH_PARAMS = 'store.schriftsatz.search.params';
const STORE_KONTAKT_SEARCH_PARAMS = 'store.kontakt.search.params';

export const BEGHEREN_PREFIX_PATH = `${PATH.BEGEHREN.name}`;

const initial = {
  firstBreadcrumb: null,
  secondBreadcrumb: null,

  prefixPathName: null,
  extraPathName: null,

  kontaktSearchParams: null,

  schriftsatzSearchParams: null,
};

/**
 * breadcrumb reducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{activeKeys: Array}} state
 */
export function breadcrumbReducer(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_FIRST_BREADCRUMB:
      return {
        ...state,
        firstBreadcrumb: payload.firstBreadcrumb,
      };
    case CLEAR_FIRST_BREADCRUMB:
      return {
        ...state,
        firstBreadcrumb: null,
      };
    case STORE_SECOND_BREADCRUMB:
      return {
        ...state,
        secondBreadcrumb: payload.secondBreadcrumb,
      };
    case CLEAR_SECOND_BREADCRUMB:
      return {
        ...state,
        secondBreadcrumb: null,
      };
    case STORE_EXTRA_BREADCRUMB:
      return {
        ...state,
        prefixPathName: payload.prefixPathName,
        extraPathName: payload.extraPathName,
      };
    case CLEAR_EXTRA_BREADCRUMB:
      return {
        ...state,
        prefixPathName: null,
        extraPathName: null,
      };
    case STORE_SCHRIFTSATZ_SEARCH_PARAMS:
      return {
        ...state,
        schriftsatzSearchParams: payload.schriftsatzSearchParams,
      };
    case STORE_KONTAKT_SEARCH_PARAMS:
      return {
        ...state,
        kontaktSearchParams: payload.kontaktSearchParams,
      };
    default:
      return state;
  }
}

/**
 * store first breadcrumb
 * @param {String} firstBreadcrumb firstBreadcrumb
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function storeFirstBreadcrumb(firstBreadcrumb) {
  return {
    type: STORE_FIRST_BREADCRUMB,
    payload: {
      firstBreadcrumb,
    },
  };
}

/**
 * clear extra breadcrumb
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function clearFirstBreadcrumb() {
  return {
    type: CLEAR_FIRST_BREADCRUMB,
  };
}

/**
 * store first breadcrumb
 * @param {String} secondBreadcrumb secondBreadcrumb
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function storeSecondBreadcrumb(secondBreadcrumb) {
  return {
    type: STORE_SECOND_BREADCRUMB,
    payload: {
      secondBreadcrumb,
    },
  };
}

/**
 * clear extra breadcrumb
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function clearSecondBreadcrumb() {
  return {
    type: CLEAR_SECOND_BREADCRUMB,
  };
}

/**
 * store extra breadcrumb item path name with it's prefix path name
 * @param {String} prefixPathName prefixPathName
 * @param {String} extraPathName stored path name
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function storeExtraBreadcrumb(prefixPathName, extraPathName) {
  return {
    type: STORE_EXTRA_BREADCRUMB,
    payload: {
      prefixPathName,
      extraPathName,
    },
  };
}

/**
 * clear extra breadcrumb
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function clearExtraBreadcrumb() {
  return {
    type: CLEAR_EXTRA_BREADCRUMB,
  };
}

/**
 * store schriftsatz search params
 * @param {String} schriftsatzSearchParams schriftsatzSearchParams
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function storeSchriftsatzSearchParams(schriftsatzSearchParams) {
  return {
    type: STORE_SCHRIFTSATZ_SEARCH_PARAMS,
    payload: {
      schriftsatzSearchParams,
    },
  };
}

/**
 * store kontakt search params
 * @param {String} kontaktSearchParams kontaktSearchParams
 * @return {{payload: {firstBreadcrumb: *}, type: string}} redux store
 */
export function storeKontaktSearchParams(kontaktSearchParams) {
  return {
    type: STORE_KONTAKT_SEARCH_PARAMS,
    payload: {
      kontaktSearchParams,
    },
  };
}
