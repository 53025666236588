import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import webErvLogo from '../../../assets/logo-manz-webERV.svg';
import MenuIcon from '../../../common/Icons/MenuIcon';
import cross from '../../../assets/icons/mz-cross.svg';
import Abteilung from './Abteilung';
import { isLargePage } from '../../../common/Site/Site.uitls';
import PATH from '../../../constants/path';
import IconNavigation from '../Navigation/IconNavigation';

/**
 * Header
 * @returns {JSX} component JSX
 */
class Header extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      showBurgerMenu: false,
    };

    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
  }

  /**
   * This keeps your state in sync with the opening/closing of the menu
   * via the default means, e.g. clicking the X, pressing the ESC key etc.
   * see https://github.com/negomi/react-burger-menu/wiki/FAQ#i-want-to-control-the-open-state-programmatically-but-i-dont-understand-how-to-use-the-isopen-prop
   * @param {Object} state state
   * @returns {undefined}
   */
  handleStateChange(state) {
    this.setState(() => ({
      showBurgerMenu: state.showBurgerMenu,
    }));
  }

  /**
   * close the burger menu
   * @returns {undefined}
   */
  handleCloseMenu() {
    this.setState(() => ({
      showBurgerMenu: false,
    }));
  }

  /**
   * open the burger menu
   * @returns {undefined}
   */
  handleOpenMenu() {
    this.setState(() => ({
      showBurgerMenu: true,
    }));
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { showBurgerMenu } = this.state;

    return (
      <div className="headerWrapper">
        <div className="mr-4 d-block d-lg-none">
          <Menu
            customBurgerIcon={false}
            customCrossIcon={false}
            isOpen={showBurgerMenu}
            onStateChange={(state) => this.handleStateChange(state)}
            key="menu"
          >
            <div className="burgerMenuHeader">
              <img
                className="logo"
                alt="web-erv-logo"
                src={webErvLogo}
                id="burger-menu-logo"
                aria-label="burger menu logo"
              />
              <img // eslint-disable-line
                src={cross}
                height={25}
                alt="close icon"
                id="burger-menu-close-button"
                onClick={this.handleCloseMenu}
              />
            </div>
            {!isLargePage() && (
              <>
                <div className="navbar">
                  <Abteilung key="abteilung-mob" />
                </div>
                {
                  <this.props.navigationMenuComponent
                    {...this.props.navigationMenuParams}
                    isBurgerMenu
                    onClick={this.handleCloseMenu}
                  />
                }
              </>
            )}
          </Menu>
        </div>
        <div className="">
          <div className="header">
            <div className="rolleStyle">
              <div className="mr-4 d-flex d-lg-none align-items-center">
                <MenuIcon
                  onClick={this.handleOpenMenu}
                  key="menu-icon"
                />
              </div>
              <div className="navbar mr-5">
                <Link to={PATH.ROOT.value} className="logo">
                  <img
                    aria-label="logo"
                    className="logo"
                    alt="web-erv-logo"
                    src={webErvLogo}
                  />
                </Link>
              </div>
              {isLargePage() && (
                <div key="AbteilungSelect" className="flex-grow-1  d-flex align-items-center">
                  <Abteilung key="abteilung" />
                </div>
              )}
              <IconNavigation key="icon-nav" />
            </div>
          </div>
          <div
            className="breadcrumbs"
          />
        </div>
      </div>
    );
  }
}

Header.defaultProps = {
  navigationMenuParams: null,
  navigationMenuComponent: null,
};

Header.propTypes = {
  navigationMenuParams: PropTypes.object,
  navigationMenuComponent: PropTypes.elementType,
};

export default Header;
