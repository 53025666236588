import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import { handleSpecialPath } from './breadcrumbs.helper';
import PATH from '../../constants/path';
import TABLE_TYPE from '../../constants/table.type.json';

/**
 * component
 */
class BreadcrumbItem extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};

    this.handleClickBreadcrumb = this.handleClickBreadcrumb.bind(this);
  }

  /**
   * click breadcrumb and go to the corresponding page
   * @param {String} pathName pathName
   * @return {undefined}
   */
  handleClickBreadcrumb(pathName) {
    const {
      history,
      location,
      tableReducer,
      breadcrumbReducer,
      gbAntragsgruppeReducer,
    } = this.props;
    const { tableLocationSearch } = tableReducer;
    const { schriftsatzSearchParams, kontaktSearchParams } = breadcrumbReducer;
    const { pathname, search } = location;

    if (pathname) {
      const newPath = `${pathname.split(pathName)[0]}${pathName}`;
      let { newPathname } = handleSpecialPath(pathName, newPath, search);
      let newSearch = search;


      // 1. rewirte schriftsatz or erledigung pathname and search
      if (this.rewriteBySchriftsatz(pathName, pathname, schriftsatzSearchParams)) {
        newSearch = schriftsatzSearchParams;
        newPathname = `${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}${PATH.ERGEBNIS.value}`;
      }


      // 2. rewrite kontakt pathname and search
      if (this.rewriteByKontakt(pathName, pathname, kontaktSearchParams, tableLocationSearch)) {
        const fand = tableLocationSearch.find((v) => v.tableType === TABLE_TYPE.KONTAKT);
        newSearch = fand?.searchParam || kontaktSearchParams;
        newPathname = `${PATH.KONTAKT.value}${PATH.SUCHE.value}${PATH.ERGEBNIS.value}`;
      }


      // 3. rewrite location search to null
      if (this.rewriteSearch(pathName)) {
        newSearch = null;
      }


      // 4. rewrite for Grundbuch Antragsgruppe
      if (this.rewritedAntragsgruppe(pathName)) {
        if (gbAntragsgruppeReducer && gbAntragsgruppeReducer.antragsgruppeId) {
          newPathname = `${PATH.SCHRIFTSATZ.value}${PATH.GB_GRUPPE.value}`;
          newSearch = `?schriftsatzId=${gbAntragsgruppeReducer.antragsgruppeId}`;
        } else {
          return;
        }
      }


      history.push({
        pathname: newPathname,
        search: newSearch,
      });
    }
  }

  /**
   * Checks if the given path name indicates a rewrite for the Grundbuch Antragsgruppe.
   *
   * @param {string} pathName - The path name to be checked.
   * @return {boolean} - Returns true if the path name indicates a rewrite for the Grundbuch Antragsgruppe, otherwise false.
   */
  rewritedAntragsgruppe(pathName) { // eslint-disable-line
    return pathName.includes(PATH.GB_GRUPPE.name);
  }

  /**
   * Determines whether the location search should be rewritten to null based on the given pathname.
   *
   * @param {string} pathName - The pathname to check.
   * @return {boolean} - True if the location search should be rewritten to null, otherwise false.
   */
  rewriteSearch(pathName) { // eslint-disable-line
    return pathName.includes(`${PATH.SUCHE.name}`)
      || pathName.includes(`${PATH.AUSGANG.name}`)
      || pathName.includes(`${PATH.EINGANG.name}`);
  }

  /**
   * Determines whether the provided pathName should rewrite the Kontakt pathname and search.
   *
   * @param {string} pathName - The current pathname.
   * @param {string} pathname - The Kontakt pathname.
   * @param {object} kontaktSearchParams - The Kontakt search parameters.
   * @param {Array<object>} tableLocationSearch - The table location search array.
   *
   * @returns {boolean} - Returns true if the pathName should rewrite the Kontakt pathname and search, false otherwise.
   */
  rewriteByKontakt(pathName, pathname, kontaktSearchParams, tableLocationSearch) { // eslint-disable-line
    return pathName === PATH.ERGEBNIS.value
      && pathname.includes(`${PATH.KONTAKT.value}`)
      && (kontaktSearchParams || tableLocationSearch.find((v) => v.tableType === TABLE_TYPE.KONTAKT));
  }

  /**
   * Checks if the given path name should be rewritten for the Schriftsatz or Erledigung pathname and search.
   *
   * @param {string} pathName - The current path name.
   * @param {string} pathname - The current pathname.
   * @param {object} schriftsatzSearchParams - The search parameters for Schriftsatz.
   * @return {boolean} - True if the path name should be rewritten, false otherwise.
   */
  rewriteBySchriftsatz(pathName, pathname, schriftsatzSearchParams) { // eslint-disable-line
    return pathName === PATH.ERGEBNIS.value
      && (pathname.includes(`${PATH.SCHRIFTSATZ.value}/`) || pathname.includes(`${PATH.ERLEDIGUNG.value}`))
      && schriftsatzSearchParams;
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      uiID,
      showIcon,
      pathName,
      breadcrumbText,
      notShowUnderline,
    } = this.props;

    return (
      <div key={uiID.toString()} className="flex">
        {showIcon && (
          <ArrowRightIcon
            className="arrowIcon"
          />
        )}
        <div
          onClick={notShowUnderline ? null : () => this.handleClickBreadcrumb(pathName)}
          className={`breadcrumbText ${notShowUnderline ? '' : 'cursor-pointer maus-over-underline'}`}
        >
          {breadcrumbText}
        </div>
      </div>
    );
  }
}

BreadcrumbItem.defaultProps = {
  uiID: '',
  showIcon: true,
  tableReducer: null,
  notShowUnderline: false,
};

BreadcrumbItem.propTypes = {
  showIcon: PropTypes.bool,
  tableReducer: PropTypes.object,
  notShowUnderline: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pathName: PropTypes.string.isRequired,
  breadcrumbText: PropTypes.string.isRequired,
  breadcrumbReducer: PropTypes.object.isRequired,
  gbAntragsgruppeReducer: PropTypes.object.isRequired,
  uiID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

/**
 * mapStateToProps function
 * @param {Object} state state
 * @returns {Object} mapped state to props
 */
function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default withRouter(connect(mapStateToProps)(BreadcrumbItem));
