import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  AUSGANG,
  EINGANG,
  SCHRIFTVERKEHR_SEARCH_TEMPLATE,
  SUFFIX_APPLIKATION_PATH,
} from '../../constants/js/schriftsatz.constants';
import { isSendable } from '../../utils/schriftsatz.utils';
import { getPagingInfoFromSearchParams } from '../Table/utils/table.utils';
import SimpleTable from '../Table/SimpleTable';
import REST from '../Rest';
import Senden from '../SendenEmpfangen/Senden';
import Polling from '../Polling/Polling';
import getUrl from '../Rest/rest.utils';
import makeRestCall from '../Rest/makeRestCall';
import AusgangSelect from '../Form/AusgangSelect';
import LoadingSpinner from '../Spinner/Loading/LoadingSpinner';
import { arrayHasItem } from '../../utils/general.utils';
import SCHRIFTSATZ_STATUS from '../../constants/schriftsatz.status';
import { getPollingTimeEingangAbholen } from '../../constants/js/application.config';
import { TIMEOUT_TYPE } from '../Rest/fetchWithTimeout';
import { OK } from '../Rest/http.status.codes';
import TABLE_TYPE from '../../constants/table.type.json';
import { storeTableLocationSearch } from '../Reducers/simpleTable.reducer';
import { existErledigungEmpfangenJob, setErledigungEmpfangenJob } from '../Sessions/erledigung.empfangen.session';

const SCHRIFTSATZ = 'SCHRIFTSATZ';
const ERLEDIGUNG = 'ERLEDIGUNG';

/**
 * component
 */
class SchriftverkehrTables extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      isSelectAllAusgang: false,
      schriftsaetze: null,
      erledigungen: null,
      ausgangLoading: false,
      eingangLoading: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handlePollling = this.handlePollling.bind(this);
    this.handleSelectAllAusgang = this.handleSelectAllAusgang.bind(this);
    this.handleReloadAusgangTable = this.handleReloadAusgangTable.bind(this);
    this.handleChangeEingangTable = this.handleChangeEingangTable.bind(this);
    this.handleChangeAusgangTable = this.handleChangeAusgangTable.bind(this);
  }

  /**
   * componentDidMount
   * @return {undefined}
   */
  async componentDidMount() {
    await this._resetSchriftverkehr(SCHRIFTSATZ, false);
    await this._resetSchriftverkehr(ERLEDIGUNG, false);
  }

  /**
   * componentDidUpdate
   * @param {Object} prevProps prevProps
   * @return {undefined}
   */
  async componentDidUpdate(prevProps) {
    const { ausgangSearchParams, eingangSearchParams } = this.props;
    if (prevProps.ausgangSearchParams && ausgangSearchParams && prevProps.ausgangSearchParams !== ausgangSearchParams) {
      await this._resetSchriftverkehr(SCHRIFTSATZ, false);
    }

    if (prevProps.eingangSearchParams && eingangSearchParams && prevProps.eingangSearchParams !== eingangSearchParams) {
      await this._resetSchriftverkehr(ERLEDIGUNG, false);
    }
  }

  /**
   * reset schriftsaetze oder erledigungen
   * @param {String} schriftverkehrType schriftverkehrType
   * @param {Boolean} dontShowRestError dontShowRestError
   * @return {Promise<void>} undefined
   * @private
   */
  async _resetSchriftverkehr(schriftverkehrType, dontShowRestError) {
    const { tableType, eingangSearchParams, ausgangSearchParams } = this.props;

    let disableSchriftsatzSearch = true;
    if (schriftverkehrType === SCHRIFTSATZ && tableType !== TABLE_TYPE.EINGANG) {
      disableSchriftsatzSearch = false;
    }
    if (schriftverkehrType === ERLEDIGUNG && tableType !== TABLE_TYPE.AUSGANG) {
      disableSchriftsatzSearch = false;
    }

    if (!disableSchriftsatzSearch) {
      if (schriftverkehrType === SCHRIFTSATZ) {
        this.setState({ ausgangLoading: true });
      } else {
        this.setState({ eingangLoading: true });
      }

      if (schriftverkehrType === SCHRIFTSATZ && ausgangSearchParams.template) {
        ausgangSearchParams.template = SCHRIFTVERKEHR_SEARCH_TEMPLATE.WORK_IN_PROGRESS;
      }
      if (schriftverkehrType === ERLEDIGUNG && eingangSearchParams.template) {
        eingangSearchParams.template = SCHRIFTVERKEHR_SEARCH_TEMPLATE.TODO;
      }

      const params = ((schriftverkehrType === SCHRIFTSATZ) && ausgangSearchParams) || eingangSearchParams;

      const url = getUrl(REST.urls.schriftverkehr, { type: schriftverkehrType }, params);

      const restResult = await makeRestCall('GET', url, null, null, dontShowRestError);

      if (restResult.status === OK) {
        if (schriftverkehrType === SCHRIFTSATZ) {
          this.setState({
            schriftsaetze: restResult.body,
            ausgangLoading: false,
          });
        } else {
          this.setState({
            erledigungen: restResult.body,
            eingangLoading: false,
          });
        }
      }
    }
  }

  /**
   * handle ausgang table change evetns>
   * @param {number} page page number
   * @param {number} size page size
   * @param {Object} sort table sort obj
   * @returns {undefined}
   */
  async handleChangeAusgangTable(page, size, sort) {
    await this._handleChangeTable(page, size, sort, AUSGANG);
  }

  /**
   * handle eingang table change evetns
   * @param {number} page page number
   * @param {number} size page size
   * @param {Object} sort table sort obj
   * @returns {undefined}
   */
  async handleChangeEingangTable(page, size, sort) {
    await this._handleChangeTable(page, size, sort, EINGANG);
  }

  /**
   * handle table change events
   * @param {number} page page number
   * @param {number} size page size
   * @param {Object} sort table sort obj
   * @param {String} type eingang or ausgang
   * @returns {undefined}
   */
  async _handleChangeTable(page, size, sort, type) {
    const { onChangeTable } = this.props;
    onChangeTable(page, size, sort, type);
  }

  /**
   * select a schriftsatz and navigate to the schriftsatz for editing
   * @param {Object} row selected schriftsatz obj
   * @returns {undefined}
   */
  handleSelect(row) {
    const {
      history,
      actions,
      eingangSearchParams,
      ausgangSearchParams,
    } = this.props;
    let pathname = `/schriftsatz/${SUFFIX_APPLIKATION_PATH[row.inhaltArt]}`;
    let search = `?schriftsatzId=${row.schriftsatzId}`;
    if (row.isEingang) {
      pathname = '/erledigung';
      search = `?erledigungId=${row.schriftsatzId}`;
    }
    actions.storeTableLocationSearch(TABLE_TYPE.SEARCH_AUSGANG, ausgangSearchParams);
    actions.storeTableLocationSearch(TABLE_TYPE.SEARCH_EINGANG, eingangSearchParams);
    history.push({
      pathname,
      search,
    });
  }

  /**
   * select all sendable ausgaenge or not
   * @param {Object} event event
   * @return {undefined}
   */
  handleSelectAllAusgang(event) {
    event.stopPropagation();
    event.preventDefault();

    const { onCallbackSelectOrRemoveAllSendableAusgang } = this.props;
    const { schriftsaetze } = this.state;
    const { isSelectAllAusgang } = this.state;
    if (onCallbackSelectOrRemoveAllSendableAusgang) {
      const selectedAusgangIds = schriftsaetze.content
        .filter((schriftsatz) => isSendable(schriftsatz))
        .map((schriftsatz) => schriftsatz?.id);
      if (selectedAusgangIds.length > 0) {
        onCallbackSelectOrRemoveAllSendableAusgang(!isSelectAllAusgang, selectedAusgangIds);
      }
    }
    this.setState({ isSelectAllAusgang: !isSelectAllAusgang });
  }

  /**
   * reload ausgang table
   * @return {undefined}
   */
  async handleReloadAusgangTable() {
    const { onReloadTable } = this.props;
    if (onReloadTable) {
      onReloadTable();
    }
    await this._resetSchriftverkehr(SCHRIFTSATZ, false);
  }

  /**
   * start polling
   * @return {undefined}
   */
  async handlePollling() {
    const exist = await existErledigungEmpfangenJob();
    console.log(`handlePollling -> existErledigungEmpfangenJob: ${exist}`); // eslint-disable-line

    if (!exist || exist !== 'true') {
      await setErledigungEmpfangenJob(true);

      const url = getUrl(REST.urls.erledigungEmpfangen);
      const result = await makeRestCall('GET', url, null, null, true, null, TIMEOUT_TYPE.EINGANG_ABHOLEN);

      if (result.status === OK && result.body && result.body > 0) {
        this._resetSchriftverkehr(SCHRIFTSATZ, false);
        this._resetSchriftverkehr(ERLEDIGUNG, false);
      }
    }

    await setErledigungEmpfangenJob(false);
  }

  /**
   * add send column only for ausgang list
   * @param {Array} ausgangTableCols AUSGANG_TABLE_COLS
   * @param {boolean} isSelectAllAusgang isSelectAllAusgang
   * @return {undefined}
   * @private
   */
  _addSendColumn(ausgangTableCols, isSelectAllAusgang) {
    const { schriftsaetze } = this.state;

    const tableCols = ausgangTableCols;
    const find = tableCols.findIndex((o) => o.dataKey === 'senden');

    const hasSendableAusgaenge = (
      schriftsaetze
      && arrayHasItem(schriftsaetze.content)
      && schriftsaetze.content.find((v) => (
        v.status === SCHRIFTSATZ_STATUS.GEPRUEFT.value
        || v.status === SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value
      ))
    ) || false;

    const component = (
      <AusgangSelect
        isSelectAll
        checked={isSelectAllAusgang}
        disabled={!hasSendableAusgaenge}
        onClick={(e) => this.handleSelectAllAusgang(e)}
      />
    );

    if (find === -1) {
      tableCols.push({
        component,
        label: 'Senden',
        dataKey: 'senden',
        className: 'check-table-container',
      });
    } else {
      tableCols.splice(find, 1, {
        component,
        label: 'Senden',
        dataKey: 'senden',
        className: 'check-table-container',
      });
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      schriftsaetze,
      erledigungen,
      eingangLoading,
      ausgangLoading,
      isSelectAllAusgang,
    } = this.state;

    const {
      pageSizes,

      ausgangTableCols,
      eingangTableCols,

      responsiveAusgangTableCols,
      responsiveEingangTableCols,

      ausgangSearchParams,
      eingangSearchParams,

      onFormatAusgangTableData,
      onFormatEingangTableData,

      onGetCurrentSelectedAusgangIds,

      showAusgangList,
      showEingangList,

      showAusgangSendButton,

      startPolling,
      ausgangButtons,

      tableType,

      extraSearchParam,
    } = this.props;

    const ausgangTotal = (schriftsaetze && schriftsaetze.totalElements) || 0;
    const eingangTotal = (erledigungen && erledigungen.totalElements) || 0;

    const { ausgangPage, ausgangSize, ausgangPageInfo } = (ausgangSearchParams && getPagingInfoFromSearchParams(ausgangSearchParams, ausgangTotal, AUSGANG)) || {};
    const { eingangPage, eingangSize, eingangPageInfo } = (eingangSearchParams && getPagingInfoFromSearchParams(eingangSearchParams, eingangTotal, EINGANG)) || {};

    let ausgangTableData = null;
    if (onFormatAusgangTableData) {
      ausgangTableData = onFormatAusgangTableData(schriftsaetze);
    }

    let eingangTableData = null;
    if (onFormatEingangTableData) {
      eingangTableData = onFormatEingangTableData(erledigungen);
    }

    if (ausgangTableCols && showAusgangSendButton) {
      this._addSendColumn(ausgangTableCols, isSelectAllAusgang);
    }

    let selectedAusgangIds = [];
    if (onGetCurrentSelectedAusgangIds) {
      selectedAusgangIds = onGetCurrentSelectedAusgangIds();
    }

    return (
      <div>
        {startPolling && (
          <Polling
            action={this.handlePollling}
            duration={getPollingTimeEingangAbholen()}
          />
        )}

        {showAusgangList && (
          <div>
            <div className="d-none d-lg-block">
              <h2 className="d-inline-block">Ausgang</h2>
              {` (${ausgangPageInfo} Schriftsätze)`}
              {(!schriftsaetze || ausgangLoading) && <LoadingSpinner classNames="margin-table-top" />}
            </div>

            <div className="d-block d-lg-none">
              <h5 className="d-inline-block">Ausgang</h5>
              {` (${ausgangPageInfo} Schriftsätze)`}
              {(!schriftsaetze || ausgangLoading) && <LoadingSpinner classNames="margin-table-top" />}
            </div>

            <div className="py-2">
              <SimpleTable
                canResponsive
                itemColIndex={7}
                className="table"
                page={ausgangPage}
                total={ausgangTotal}
                pageSizes={pageSizes}
                tableType={tableType}
                onClick={this.handleSelect}
                tableData={ausgangTableData}
                tableColumns={ausgangTableCols}
                sortedBy={ausgangSearchParams.sort}
                extraSearchParam={extraSearchParam}
                onChange={this.handleChangeAusgangTable}
                responsiveTableColumns={responsiveAusgangTableCols}
                pageSize={ausgangSize || (pageSizes ? pageSizes[0] : null)}
              />
            </div>

            {showAusgangSendButton && (
              <div className="d-flex justify-content-end">
                {ausgangButtons}
                <Senden
                  className="float-right ml-2"
                  schriftsatzId={selectedAusgangIds}
                  selectedCount={selectedAusgangIds.length}
                  disabled={selectedAusgangIds.length === 0}
                  onReloadAusgaenge={this.handleReloadAusgangTable}
                />
              </div>
            )}
          </div>
        )}

        {showEingangList && (
          <div>
            <div className="d-none d-lg-block">
              <h2 className="d-inline-block">Eingang</h2>
              {` (${eingangPageInfo} Erledigungen)`}
              {(!erledigungen || eingangLoading) && <LoadingSpinner classNames="margin-table-top" />}
            </div>

            <div className="d-block d-lg-none">
              <h5 className="d-inline-block">Eingang</h5>
              {` (${eingangPageInfo} Erledigungen)`}
              {(!erledigungen || eingangLoading) && <LoadingSpinner classNames="margin-table-top" />}
            </div>

            {(
              <div className="py-2">
                <SimpleTable
                  canResponsive
                  itemColIndex={7}
                  className="table"
                  page={eingangPage}
                  total={eingangTotal}
                  pageSizes={pageSizes}
                  tableType={tableType}
                  onClick={this.handleSelect}
                  tableData={eingangTableData}
                  tableColumns={eingangTableCols}
                  sortedBy={eingangSearchParams.sort}
                  onChange={this.handleChangeEingangTable}
                  responsiveTableColumns={responsiveEingangTableCols}
                  pageSize={eingangSize || (pageSizes ? pageSizes[0] : null)}
                />
              </div>
            )}

          </div>
        )}
      </div>
    );
  }
}

SchriftverkehrTables.defaultProps = {
  pageSizes: null,
  tableType: null,
  startPolling: false,
  eingangTableCols: [],
  onReloadTable: null,
  ausgangTableCols: [],
  ausgangButtons: null,
  showEingangList: true,
  showAusgangList: true,
  extraSearchParam: null,
  eingangSearchParams: null,
  ausgangSearchParams: null,
  responsiveEingangTableCols: [],
  responsiveAusgangTableCols: [],
  showAusgangSendButton: false,
  onFormatEingangTableData: null,
  onFormatAusgangTableData: null,
  onGetCurrentSelectedAusgangIds: null,
  onCallbackSelectOrRemoveAllSendableAusgang: null,
};

SchriftverkehrTables.propTypes = {
  pageSizes: PropTypes.array,
  startPolling: PropTypes.bool,
  tableType: PropTypes.string,
  onReloadTable: PropTypes.func,
  ausgangButtons: PropTypes.node,
  showEingangList: PropTypes.bool,
  showAusgangList: PropTypes.bool,
  eingangTableCols: PropTypes.array,
  ausgangTableCols: PropTypes.array,
  extraSearchParam: PropTypes.string,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  eingangSearchParams: PropTypes.object,
  ausgangSearchParams: PropTypes.object,
  showAusgangSendButton: PropTypes.bool,
  onChangeTable: PropTypes.func.isRequired,
  onFormatEingangTableData: PropTypes.func,
  onFormatAusgangTableData: PropTypes.func,
  responsiveEingangTableCols: PropTypes.array,
  responsiveAusgangTableCols: PropTypes.array,
  onGetCurrentSelectedAusgangIds: PropTypes.func,
  onCallbackSelectOrRemoveAllSendableAusgang: PropTypes.func,
};

export { SchriftverkehrTables as SchriftverkehrTablesTest };

/**
 * mapStateToProps function
 * @param {Object} state state
 * @returns {{form: string, initialValues: {}}} object
 */
export function mapStateToProps(state) {
  return {
    ...state,
  };
}

/**
 * map disaptch function to component properties
 * @param {Function} dispatch redux dispatch function
 * @returns {Object} object of actions and
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      storeTableLocationSearch,
    }, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchriftverkehrTables));
