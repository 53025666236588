import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { withConnectedUserData } from '@auth/auth-oidc-integration';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import {
  SECTION_FUNKTIONEN,
  SECTION_KONTAKT,
  SECTION_PAKETE,
  SECTION_START,
  SECTION_USECASES,
} from './sections';
import s from './Home.module.scss';
import ContactForm from './ContactForm/ContactForm';
import RedCircle from './RedCircle/RedCircle';
import ScrollUp from './ScrollUp';
import { isMobileView } from '../utils/landing.page.utils';
import iconUsecase1 from '../assets/icons/1-webERV-erfassen-versenden-ohne-padding.svg';
import iconUsecase2 from '../assets/icons/2-webERV-posteingang-auf-allen-geraeten-ohne-padding.svg';
import iconUsecase3 from '../assets/icons/3-webERV-elektronische-zustellung-ohne-padding.svg';
import heroImage from '../assets/hero-image-webERV.png';
import { ReactComponent as TopOfferSVG } from '../assets/icons/mz-top-offer-full-color.svg';
import { ReactComponent as CheckRedSVG } from '../assets/icons/mz-check-thick-red.svg';
import { ReactComponent as CrossGreySVG } from '../assets/icons/mz-cross-thick-gray.svg';
import { ReactComponent as CleverShineSVG } from '../assets/icons/mz-clever-shine-white.svg';
import { ReactComponent as WebErvLogoSVG } from '../assets/icons/mz-web-erv-software.svg';
import { ReactComponent as ScrollDownSVG } from '../assets/icons/mz-arrow-down.svg';
import { ReactComponent as Icon1lock } from '../assets/icons/mz-verschluesselung-ohne-padding.svg';
import { ReactComponent as Icon2list } from '../assets/icons/mz-dateibereich-auswaehlen-ohne-padding.svg';
import { ReactComponent as Icon3autom } from '../assets/icons/mz-automatische-fehlerpruefung-ohne-padding.svg';
import { ReactComponent as Icon4newsletter } from '../assets/icons/mz-newsletter-ohne-padding.svg';
import { ReactComponent as Icon5drag } from '../assets/icons/mz-drag-drop-ohne-padding.svg';
import { ReactComponent as Icon6mitteilung } from '../assets/icons/mz-neue-mitteilung-ohne-padding.svg';
import { ReactComponent as Icon7verfassung } from '../assets/icons/mz-verfassungsrecht-ohne-padding.svg';
import { ReactComponent as Icon8zeit } from '../assets/icons/mz-versions-ohne-padding.svg';
import PATH from '../constants/path.json';

/**
 * home site component
 * display the landing page if not authenticates otherwise redirects to upload
 * @param {Object} props Component Properties where user object ist part of it
 * @returns {JSX} component JSX
 */
class Home extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const { appAuthorized } = this.props;

    if (appAuthorized) {
      return (
        <Redirect to={
          {
            pathname: PATH.DASHBOARD.value,
          }
        }
        />
      );
    }

    const offset = isMobileView() ? -40 : -60;
    configureAnchors({
      offset,
      scrollDuration: 0,
    });

    return (
      <div className={s.homeContent}>
        <ScrollableAnchor id={SECTION_START}>
          <section className={`${s.homeSection} ${s.sectionGradient}`}>
            <div className="container">
              <div className="row">
                <div className={`col-12 col-lg-4 ${s.center} ${s.logoContainer}`}>
                  <WebErvLogoSVG
                    title="webERV Logo"
                    className="icon icon-xxl"
                  />
                  <h1 className="heading">webERV</h1>
                  <p className={`${s.paragraph} ${s.paragraphLarge}`}>
                    Elektronischer Rechtsverkehr:
                    Schriftsätze schnell digital bei Gericht einbringen.
                    {' '}
                  </p>
                </div>
                <div className="col-12 col-lg-8">
                  <img
                    src={heroImage}
                    alt="webERV Banner"
                    aria-label="webERV Banner"
                    className={s.videoContainer}
                  />
                  <RedCircle />
                </div>
              </div>
              <div className={`${s.scrollDown} d-none d-md-block`}>
                <div className={s.center}>
                  <a className="manz-link" href={`#${SECTION_USECASES}`} title="Zu den Use Cases" aria-label="Zu den Use Cases">
                    <ScrollDownSVG
                      title="Zu den Use Cases"
                      className="icon icon-70px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <ScrollableAnchor id={SECTION_USECASES}>
          <section className={`${s.section} ${s.sectionWhite}`}>
            <Carousel interval={15000}>
              <Carousel.Item>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className={s.subheader}>Use Case 1</div>
                      <h2 className={s['heading-2']}>Erfassen und Versenden</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
                      <img src={iconUsecase1} alt="Web App" className="icon icon-200" aria-label="Web App" />
                    </div>
                    <div className="col-12 col-md-9">
                      <p className={s.paragraphLarge}>
                        Mit „Drag und Drop“ sowie intuitiven Online-Formularen erfassen Sie innerhalb kürzester Zeit
                        Anträge und Schriftsätze und übermitteln diese per ERV fristgerecht an die jeweiligen Gerichte.
                      </p>
                      <p className={s.paragraphLarge}>
                        Die elektronische Bestätigung der Einbringung eines Schriftsatzes per ERV ist der Übermittlung
                        per „eingeschriebenen Brief“ rechtlich gleichgestellt.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className={s.subheader}>Use Case 2</div>
                      <h2 className={s['heading-2']}>Posteingang auf allen Geräten</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
                      <img src={iconUsecase2} alt="Web App" className="icon icon-200" aria-label="Web App" />
                    </div>
                    <div className="col-12 col-md-9">
                      <p className={s.paragraphLarge}>
                        Erledigungen der Gerichte werden in Ihr ERV Postfach elektronisch zugestellt.
                        Auf Ihr ERV-Postfach können Sie jederzeit entweder auf Ihrem Desktop-PC oder Ihren mobilen
                        Geräten (Tablet, Handy) zugreifen und in zugestellte Schriftstücke der Gerichte einsehen.
                        Die Daten werden dabei immer verschlüsselt übertragen.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className={s.subheader}>Use Case 3</div>
                      <h2 className={s['heading-2']}>Elektronische Zustellung</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
                      <img src={iconUsecase3} alt="Web App" className="icon icon-200" aria-label="Web App" />
                    </div>
                    <div className="col-12 col-md-9">
                      <p className={s.paragraphLarge}>
                        Mit 1.1.2020 ist das Recht auf elektronischen Verkehr mit Behörden in Kraft getreten. Seither
                        müssen alle Bundesbehörden die elektronische Zustellung von Nachrichten ermöglichen.
                        Gleichzeitig wurden Unternehmen verpflichtet, für Behörden im Weg der elektronischen Zustellung
                        erreichbar zu sein.
                      </p>
                      <p className={s.paragraphLarge}>
                        In Ihr ERV-Postfach werden behördliche Nachrichten rund um die Uhr garantiert und sicher
                        zugestellt bzw. können auch Sie an diese rechtssicher Nachrichten versenden.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </section>
        </ScrollableAnchor>

        <section className={`${s.banner} ${s.sectionGrey} `}>
          <div className={`container ${s.container}`}>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-8 align-items-center text-align-md-center justify-content-center">
                Sie wollen mehr Informationen zu webERV?
                {' '}
                <br />
                {' '}
                Nehmen Sie jetzt Kontakt auf.
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center justify-content-center " title="Jetzt anfragen" aria-label="Jetzt anfragen">
                <a href={`#${SECTION_KONTAKT}`} type="button" className={`${s.btn}  btn btn-blue btn-lg btn-block manz-link`}>
                  Jetzt
                  anfragen
                </a>
              </div>
            </div>
          </div>
        </section>

        <ScrollableAnchor id={SECTION_FUNKTIONEN}>
          <section className={`${s.section} ${s.sectionWhite} ${s.sectionBorderTop}`}>
            <div className={`container ${s.container}`}>
              <div className={s.subheader}>Ihre Vorteile</div>
              <h2 className={s['heading-2']}>Schriftsätze einfach online einreichen</h2>
              <ul className={`row ${s.unstyled}`}>
                <li className={`'col-12 col-sm-6 col-md-3 mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon1lock title="Verschlüsselte Datenübertragung" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Verschlüsselte
                    <br />
                    Datenübertragung
                  </span>
                </li>
                <li className={`'col-12 col-sm-6 col-md-3 mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon2list title="Unterstützung bei der Eingabe" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Unterstützung
                    <br />
                    bei der Eingabe
                  </span>
                </li>
                <li className={`col-12 col-sm-6 col-md-3 mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon3autom title="Automatische Fehlerprüfung" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Automatische
                    <br />
                    Fehlerprüfung
                  </span>
                </li>
                <li className={`col-12 col-sm-6 col-md-3 mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon4newsletter title="Schnell und fristgerecht" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Schnell und
                    <br />
                    fristgerecht
                  </span>
                </li>
                <li className={`col-12 col-sm-6 col-md-3 mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon5drag title="Drag And Drop" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Anhänge per
                    <br />
                    Drag&nbsp;&&nbsp;Drop
                    <br />
                    hochladen
                  </span>
                </li>
                <li className={`col-12 col-sm-6 col-md-3  mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon6mitteilung title="Automatische Direktzustellung" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Automatische
                    <br />
                    Direktzustellung
                    <br />
                  §&nbsp;112&nbsp;ZPO
                  </span>
                </li>
                <li className={`col-12 col-sm-6 col-md-3 mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon7verfassung title="Elektronischer Verkehr" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Elektronischer Verkehr
                    <br />
                    mit Behörden (eZustellung)
                    {' '}
                    <br />
                    <span className="no-wrap">§ 1a E-GovG</span>
                  </span>
                </li>
                <li className={`col-12 col-sm-6 col-md-3 mt-4 ${s.centerMobile} ${s.paragraphBig}`}>
                  <Icon8zeit title="Verkürzte Verfahrenszeit" className="icon icon-70px mb-3" />
                  <br />
                  <span>
                    Verkürzte
                    <br />
                    Verfahrenszeit
                  </span>
                </li>
              </ul>
            </div>
          </section>
        </ScrollableAnchor>

        <ScrollableAnchor id={SECTION_PAKETE}>
          <section className={`${s.section} ${s.sectionWhite} ${s.sectionBorderTop}`}>
            <div className="container">
              <div className={s.subheader}>Alles, was Sie brauchen</div>
              <h2 className={s['heading-2']}>Ihre webERV-Pauschalpakete</h2>
            </div>
            <div className={`container ${s.tableWrapper}`}>
              <table>
                <tbody>
                  <tr>
                    <th>&nbsp;</th>
                    <th className={s.checkColumn}>Standard</th>
                    <th className={s.checkColumn}>
                      <div className={s.topOfferWrapper}>
                        Expert
                        <div className={s.topOffer}>
                          <TopOfferSVG title="Top-Angebot" />
                        </div>
                      </div>
                    </th>
                    <th className={`${s.checkColumn}`}>Pro</th>
                  </tr>
                  <tr>
                    <td>Firmenbuch</td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Verfahrensautomation der Justiz</td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert " />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>EU-Mahnverfahren</td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Gerichtshöfe &amp; Verwaltungsgericht</td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Grundbuch</td>
                    <td>
                      <div className={`${s.icon} ${s.cross}`}>
                        <CrossGreySVG title="Nicht inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Elektronisches Treuhandbuch</span>
                    </td>
                    <td>
                      <div className={`${s.icon} ${s.cross}`}>
                        <CrossGreySVG title="Nicht inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon} ${s.cross}`}>
                        <CrossGreySVG title="Nicht inkludiert" />
                      </div>
                    </td>
                    <td>
                      <div className={`${s.icon}`}>
                        <CheckRedSVG title="Inkludiert" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={`${s.bannerRed} container mt-4 `}>
              <div className="row justify-content-md-center">

                <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center justify-content-center ">
                  <div className="icon-70px">
                    <CleverShineSVG />
                  </div>
                </div>
                <div
                  className="col-12 col-md-9 margin-m-top-md d-flex align-items-center justify-content-center text-center"
                >
                  <span>Noch flexibler: Alle Pakete auch ohne monatliche Fixkosten</span>
                </div>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <ScrollableAnchor id={SECTION_KONTAKT}>
          <section className={`${s.section} ${s.sectionLightGrey} ${s.sectionContact}`}>
            <div className="container">
              <h2 className={`${s['heading-2']} ${s['heading-contact']}`}>webERV anfragen</h2>
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-5">
                  <p className={`${s.paragraph} ${s.paragraphCenter}`}>
                    Danke für Ihr Interesse an webERV.
                    Lassen Sie sich sofort Informationen oder ein attraktives Angebot zuschicken.
                  </p>
                  <p className={`${s.paragraph} ${s.paragraphCenter}`}>
                    Kontaktieren Sie uns:
                    {' '}
                    <a className="manz-link" href="tel:+43153161655 " title="tel:+43153161655 " aria-label="tel:+43153161655 ">+43&nbsp;1&nbsp;531&nbsp;61&nbsp;655</a>
                    .
                  </p>
                </div>
              </div>
              <ContactForm />
            </div>
          </section>
        </ScrollableAnchor>
        <ScrollUp startSection={SECTION_START} />
      </div>
    );
  }
}

Home.defaultProps = {
  appAuthorized: false,
};

Home.propTypes = {
  appAuthorized: PropTypes.bool,
};

export default withConnectedUserData(Home);
