import React from 'react';
import PropTypes from 'prop-types';
import homeIcon from '../../assets/icons/mz-arrow-right-small-dicker.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function ArrowRightIcon(props) {
  const { className } = props;
  return (
    <img // eslint-disable-line
      id="manz-home"
      src={homeIcon}
      alt="arrow right icon"
      className={className}
      aria-label="arrow right icon"
    />
  );
}

ArrowRightIcon.defaultProps = {
  className: null,
};

ArrowRightIcon.propTypes = {
  className: PropTypes.string,
};

export default ArrowRightIcon;
