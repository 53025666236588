import React from 'react';
import PropTypes from 'prop-types';

/**
 * text redux form component
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function TextView(props) {
  const {
    input,
    tooltip,
    className,
    formatValue,
    val,
  } = props;

  const classNames = className ? className.split(' ') : [];

  if (formatValue) {
    input.value = formatValue(input.value);
  }
  const value = val || input.value;
  return (
    <div
      className={`${classNames.join(' ')} `}
      title={tooltip}
    >
      {value}
    </div>
  );
}

TextView.defaultProps = {
  input: null,
  val: '',
  tooltip: null,
  className: null,
  formatValue: null,
};

TextView.propTypes = {
  input: PropTypes.object,
  val: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  formatValue: PropTypes.func,
};

export default TextView;
