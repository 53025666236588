export const STORE_ACTIVE_KEYS = 'store.active.keys';
export const REMOVE_ACTIVE_KEY = 'remove.active.key';
export const CLEAR_ACTIVE_KEYS = 'clear.active.keys';
export const RESET_ACTIVE_KEYS = 'reset.active.keys';

const initial = {
  /**
   * activeKeysArray: [
   *    {
   *      key (collapseKey): `${collapsetype}.${collapseownerid1}.${collapseownerid2}...`,
   *      value: [
   *        activeKey 1: `${collapsetype}-${collapseDataKey}`,
   *        activeKey 2: `${collapsetype}-${collapseDataKey}`,
   *        ...
   *      ],
   *    },
   *    ...
   * ]
   */
  activeKeysArray: [],
};

/**
 * store active keys
 * @param {Object} state state
 * @param {Object} payload payload
 * @return {Object} state
 * @private
 */
function _storeActiveKeys(state, payload) { // eslint-disable-line
  const existActiveKeys = state.activeKeysArray.find(activeKeys => activeKeys.key === payload.collapseKey); // eslint-disable-line
  if (existActiveKeys) {
    existActiveKeys.key = payload.collapseKey;
    existActiveKeys.value = payload.activeKeys;
  } else {
    const newActiveKeys = {
      key: payload.collapseKey,
      value: payload.activeKeys,
    };
    state.activeKeysArray.push(newActiveKeys);
  }
  return {
    ...state,
  };
}

/**
 * remove active key
 * @param {Object} state state
 * @param {Object} payload payload
 * @return {Object} state
 * @private
 */
function _removeActiveKey(state, payload) { // eslint-disable-line
  const existActiveKeys = state.activeKeysArray.find(activeKeys => activeKeys.key === payload.collapseKey); // eslint-disable-line
  if (existActiveKeys && existActiveKeys.value) {
    if (Array.isArray(existActiveKeys.value)) {
      const existActiveKeyIndex = existActiveKeys.value.findIndex((activeKey) => activeKey === payload.activeKeys);
      if (existActiveKeyIndex !== -1) {
        existActiveKeys.value.splice(existActiveKeyIndex, 1);
      }
    } else {
      existActiveKeys.value = [];
    }
  }
  return {
    ...state,
  };
}

/**
 * clear active keys
 * @param {Object} state state
 * @param {Object} payload payload
 * @return {Object} state
 * @private
 */
function _clearActvieKeys(state, payload) { // eslint-disable-line
  const existActiveKeysIndex = state.activeKeysArray.findIndex(activeKeys => activeKeys.key === payload.collapseKey); // eslint-disable-line
  if (existActiveKeysIndex !== -1) {
    state.activeKeysArray.splice(existActiveKeysIndex, 1);
  }
  return {
    ...state,
  };
}

/**
 * _resetActvieKeys
 * @param {Object} state state
 * @param {Object} payload payload
 * @return {Object} state
 * @private
 */
function _resetActvieKeys(state, payload) { // eslint-disable-line
  state.activeKeysArray = payload.activeKeysArray;
  return {
    ...state,
  };
}

/**
 * collapse reducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{activeKeys: Array}} state
 */
export function collapseReducer(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_ACTIVE_KEYS:
      return _storeActiveKeys(state, payload);
    case REMOVE_ACTIVE_KEY:
      return _removeActiveKey(state, payload);
    case CLEAR_ACTIVE_KEYS:
      return _clearActvieKeys(state, payload);
    case RESET_ACTIVE_KEYS:
      return _resetActvieKeys(state, payload);
    default:
      return state;
  }
}

/**
 * store active keys from collapse list
 * @param {Array} activekeys active keys
 * @param {String} collapseStoreKey collapseStoreKey
 * @return {Object} redux action
 */
export function storeActiveKeys(activekeys, collapseStoreKey) {
  return {
    type: STORE_ACTIVE_KEYS,
    payload: {
      collapseKey: collapseStoreKey,
      activeKeys: activekeys,
    },
  };
}

/**
 * remove active key by collapse type and collapse owner id
 * @param {String} activekey activekey
 * @param {String} collapseStoreKey collapseStoreKey
 * @return {Object} redux action
 */
export function removeActiveKey(activekey, collapseStoreKey) {
  return {
    type: REMOVE_ACTIVE_KEY,
    payload: {
      collapseKey: collapseStoreKey,
      activeKeys: activekey,
    },
  };
}

/**
 * clear active keys by collapse type and collapse owner id
 * @param {String} collapseStoreKey collapseStoreKey
 * @return {Object} redux action
 */
export function clearActiveKeys(collapseStoreKey) {
  return {
    type: CLEAR_ACTIVE_KEYS,
    payload: {
      collapseKey: collapseStoreKey,
    },
  };
}

/**
 * resetActiveKey
 * @param {Array} activeKeysArray activeKeysArray
 * @return {{payload: {activeKeysArray}, type: string}} resetActiveKey
 */
export function resetActiveKey(activeKeysArray) {
  return {
    type: RESET_ACTIVE_KEYS,
    payload: {
      activeKeysArray,
    },
  };
}

/**
 * find active keys in collapse reducer by collapse type
 * @param {Object} collapsereducer collapse reducer
 * @param {String} collapseStoreKey collapseStoreKey
 * @return {Object} activeKeys
 */
export function findActiveKeys(collapsereducer, collapseStoreKey) {
  const activeKeysWrapper = collapsereducer.activeKeysArray.find((activeKeys) => activeKeys.key === collapseStoreKey);
  return (activeKeysWrapper && activeKeysWrapper.value) || [];
}
