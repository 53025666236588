import React from 'react';
import PropTypes from 'prop-types';
import { Manager, Popper, Reference } from 'react-popper';
import { withRouter } from 'react-router-dom';
import { ReactComponent as ArrowBackSVG } from '../assets/icons/mz-arrow-left.svg';
import SaveButton from '../common/Buttons/SaveButton';
import { ReactComponent as SaveSVG } from '../assets/icons/mz-save.svg';
import { ReactComponent as MenuSVG } from '../assets/icons/mz-multilink-full.svg';
import SubMenu from './SubMenu';

/**
 * Erledigung (Rückvekehr) component
 */
class MobileHeader extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      showSubMenu: false,
    };
    this.hideSubmenu = this.hideSubmenu.bind(this);
    this.showSubmenu = this.showSubmenu.bind(this);
    this.handleBackToTable = this.handleBackToTable.bind(this);
  }

  /**
   * shows submenu
   * @returns {undefined}
   */
  showSubmenu() {
    this.setState({ showSubMenu: true });
  }

  /**
   * hides submenu
   * @returns {undefined}
   */
  hideSubmenu() {
    this.setState({ showSubMenu: false });
  }

  /**
   * toggles the status of the erledigung
   * @returns {undefined}
   */
  handleBackToTable() {
    const { history } = this.props;
    if (history) {
      history.goBack();
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      pristine,
      onSubmit,
      erledigung,
      globalDisabled,
      handleDeleteForm,
    } = this.props;
    const { showSubMenu } = this.state;

    return (
      <>
        <div
          className="form-row align-items-center red-heading cursor-pointer"
          onClick={this.handleBackToTable}
        >
          <div className="icon-container-md">
            <ArrowBackSVG />
          </div>
          <div className="d-inline-block text-uppercase">Eingang - Rückverkehr</div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <SaveButton
            isGoHome
            disabled={pristine}
            onClick={onSubmit}
            btnText={<SaveSVG />}
            id="erledigung-save-button"
            className="icon-button btn"
          />
          <>
            <Manager>
              <Reference>
                {({ ref }) => (
                  <button
                    ref={ref}
                    type="button"
                    aria-label="Menü Button"
                    onClick={this.showSubmenu}
                    className="icon-button btn btn-tertiary ml-1"
                  >
                    <MenuSVG ref={ref} className="icon icon-hover" title="Menü" />
                  </button>
                )}
              </Reference>
              <Popper placement="bottom-start">
                {({ ref, style, placement }) => (
                  showSubMenu && (
                  <div ref={ref} style={style} data-placement={placement} className="popper mobil-menu">
                    <SubMenu erledigung={erledigung} globalDisabled={globalDisabled} handleDeleteForm={handleDeleteForm} hideSubmenu={this.hideSubmenu} />
                  </div>
                  )
                )}
              </Popper>
            </Manager>
          </>
        </div>
      </>
    );
  }
}

MobileHeader.defaultProps = {
  pristine: false,
  onSubmit: null,
  erledigung: null,
  globalDisabled: false,
};

MobileHeader.propTypes = {
  pristine: PropTypes.bool,
  onSubmit: PropTypes.func,
  erledigung: PropTypes.object,
  globalDisabled: PropTypes.bool,
  history: PropTypes.object.isRequired,
  handleDeleteForm: PropTypes.func.isRequired,
};

export default withRouter(MobileHeader);
