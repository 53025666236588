import React from 'react';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import CopyToClipboard from '../common/Buttons/CopyToClipboard';
import DeleteButton from '../common/Buttons/DeleteButton';

/**
 * shows submenu in erledigung when mobile
 * @param {object} props props
 * @return {*} mobile submenu
 * @constructor
 */
function SubMenu(props) {
  const {
    erledigung,
    hideSubmenu,
    globalDisabled,
    handleDeleteForm,
  } = props;

  /**
   * hides submenu in parent
   * @param {Object} e event
   * @returns {undefined}
   */
  SubMenu.handleClickOutside = () => hideSubmenu();

  return (
    <div className="submenu">
      <CopyToClipboard
        className="icon-button"
        onCallbackClick={hideSubmenu}
        buttonText="MessageID kopieren"
        text={erledigung && erledigung.msgId}
        tooltip="Message ID in Zwischenablage kopieren"
      />
      <DeleteButton
        type="button"
        disabled={globalDisabled}
        onClick={handleDeleteForm}
        id="erledigung-delete-button"
        isDoubleConfirmationRequired
        btnText="Gesamte Erledigung löschen"
        className="ml-1 btn btn-danger icon-button"
      />
    </div>
  );
}

SubMenu.defaultProps = {
  erledigung: null,
  globalDisabled: false,
};

SubMenu.propTypes = {
  erledigung: PropTypes.object,
  globalDisabled: PropTypes.bool,
  hideSubmenu: PropTypes.func.isRequired,
  handleDeleteForm: PropTypes.func.isRequired,
};

const clickOutsideConfig = {
  handleClickOutside: () => SubMenu.handleClickOutside,
};

export default onClickOutside(SubMenu, clickOutsideConfig);
