import React from 'react';
import clone from 'clone';
import moment from 'moment';
import numeral from 'numeral';
import { VALID_REGEX } from '../constants/js/validator.constants';
import APP_TYPE from '../constants/schriftsatz.application.type.json';
import SELECT_OPTION from '../constants/gb.begehren.select.option.json';
import { arrayHasItem, replaceAll, trimStr } from './general.utils';
import getFormattedMessage from '../common/FormatMessage/formattedMessage';

/**
 * not valid by regex
 * @param {String} value value
 * @param {String} regex regex
 * @return {*|boolean} yes or no
 * @private
 */
function _notValid(value, regex) { // eslint-disable-line
  return (value && !new RegExp(regex).test(value)) || false;
}

/**
 * redux formatter for number input fields
 * @param {String} value a number
 * @return {string} formatted number
 * @private
 */
function _numFormatter(value) { // eslint-disable-line
  let v = value.toString()
    .replace(',00', '')
    .replace('.00', '')
    .replace('.', ',');
  const lastChar = value[value.length - 1];
  if (lastChar === ',') {
    v = value;
  }
  // v = isNaN(Number(v)) ? '' : v; // eslint-disable-line
  return v;
}

/**
 * redux formatter for number input fields
 * @param {String} value a number
 * @return {string} formatted number
 */
export function numberFormatter(value) {
  return value ? _numFormatter(value) : '';
}

/**
 * numberOnlyFormatter
 * @param {String} value value
 * @returns {string|*} value
 */
export function numberOnlyFormatter(value) {
  if (value) {
    let retval = '';

    for (let i = 0; i < value.length; i += 1) {
      const char = value[i];
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(Number(char))) {
        retval += char;
      }
    }

    return retval || null;
  }
  return value;
}

/**
 * numberOnlyFormatter
 * @param {String} value value
 * @returns {string|*} value
 */
export function numberOnlyNormalizer(value) {
  if (value) {
    let retval = '';

    for (let i = 0; i < value.length; i += 1) {
      const char = value[i];
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(Number(char))) {
        retval += char;
      }
    }

    return retval || null;
  }
  return value;
}

/**
 * redux formatter for number with zero input fields
 * @param {String} value a number
 * @return {string} formatted number
 */
export function numberWithZeroFormatter(value) {
  return (value || value === 0) ? _numFormatter(value) : '';
}

/**
 * redux normalizer for numbers. converts a string input in a number
 * @param {String} value a number as string
 * @return {number|string} returns a number or a string if the last character is a , or .
 */
export function numberNormalizer(value) {
  if (value) {
    let v = numeral(value)
      .value();
    const lastChars = value[value.length - 1];
    if (_isTwoZeroDecimal(value) || lastChars === '.') {
      v = value;
    }
    return v || value;
  }
  return value;
}

/**
 * check if it is two zero decimal
 * @param {String} value value
 * @returns {boolean} boolean
 * @private
 */
function _isTwoZeroDecimal(value) { // eslint-disable-line
  let retval = false;
  if (value) {
    let lastChars = value[value.length - 1];
    if (value.length > 1 && lastChars === '0' && value[value.length - 2] === ',') {
      lastChars = ',0';
    }
    if (value.length > 2 && lastChars === '0' && value[value.length - 2] === '0' && value[value.length - 3] === ',') {
      lastChars = ',00';
    }
    retval = lastChars === ',' || lastChars === ',0' || lastChars === ',00';
  }
  return retval;
}

/**
 * normalize null value
 * @param {String} value value
 * @return {*|null} new value
 */
export function nullNormalizer(value) {
  return value || null;
}

/**
 * redux Form validator for required fields
 * @param {String} value input field value
 * @returns {String} validation error msg or undefined
 */
export function requiredField(value) {
  return value ? undefined : 'Pflichtfeld';
}

/**
 * redux Form validator for required fields
 * @param {String} value input field value
 * @returns {String} validation error msg or undefined
 */
export function requiredWithZeroField(value) {
  return (value || value === 0) ? undefined : 'Pflichtfeld';
}

/**
 * redux Form validator for required fields
 * @param {String} value input field value
 * @returns {String} validation error msg or undefined
 */
export function requiredFieldWithArray(value) {
  if (value) {
    if (Array.isArray(value) && value.length === 0) {
      return 'Pflichtfeld';
    }
    return undefined;
  }
  return 'Pflichtfeld';
}

/**
 * redux Form validator for required fields
 * @param {String} value input field value
 * @returns {String} validation error msg or undefined
 */
export function requiredFieldWithArrayOrString(value) {
  if (value) {
    let rows = [];

    // Array
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return 'Pflichtfeld';
      }
      rows = value;
    } // eslint-disable-line

    // String
    else {
      rows = value.split('\n');
    }

    let existContent = false;

    for (let i = 0; i < rows.length; i += 1) {
      const current = rows[i];
      if (current && trimStr(current)) {
        existContent = true;
        break;
      }
    }

    if (!existContent) {
      return 'Pflichtfeld';
    }

    return undefined;
  }
  return 'Pflichtfeld';
}

/**
 * redux Form validator for required fields
 * @returns {String} validation error msg or undefined
 */
export function isRequiredField() {
  return 'Pflichtfeld';
}

/**
 * redux Form validator for required fields
 * @param {String} value input field value
 * @param {Object} formValues formValues
 * @returns {String} validation error msg or undefined
 */
export function gegenstandsartField(value, formValues) {
  if (!value) {
    return 'Pflichtfeld';
  }
  let retval;

  const { teilLoeschung } = formValues.element;

  if (teilLoeschung) {
    const { grundstuecke, personenReferenzen, eintragungsReferenzen } = teilLoeschung;

    switch (value) {
      case SELECT_OPTION.grundstueck:
        if (!arrayHasItem(grundstuecke)) {
          retval = getFormattedMessage({ id: 'validator.grundstueck' });
        }
        break;
      case SELECT_OPTION.personen:
        if (!arrayHasItem(personenReferenzen)) {
          retval = getFormattedMessage({ id: 'validator.person' });
        }
        break;
      case SELECT_OPTION.teilbetrag:
        break;
      case SELECT_OPTION.eintragungen:
        if (!arrayHasItem(eintragungsReferenzen)) {
          retval = getFormattedMessage({ id: 'validator.eintragungen' });
        }
        break;
      case SELECT_OPTION.sonstige:
        break;
      default:
        break;
    }
  }

  return retval;
}

export const numberField = value => (value && isNaN(Number(value)) ? getFormattedMessage({ id: 'validator.number' }) : undefined); // eslint-disable-line

export const absenderCodeField = value => (value && isNaN(Number(value)) ? getFormattedMessage({ id: 'validator.absender.code' }) : undefined); // eslint-disable-line

export const kgField = value => (value && (isNaN(Number(value)) || value.length !== 5) ? getFormattedMessage({ id: 'validator.number.5' }) : undefined); // eslint-disable-line

/**
 * Validates if the given value is a positive number.
 *
 * @param {any} value - The value to be validated.
 * @returns {undefined|string} - Returns undefined if the value is a positive number, otherwise returns a string message.
 */
export function positiveNumberField(value) {
  if (value && value.toString() && value.toString()[0] === '-') {
    return getFormattedMessage({ id: 'positive.number' });
  }
  // eslint-disable-next-line no-restricted-globals
  if (value && isNaN(Number(value))) {
    return getFormattedMessage({ id: 'validator.number' });
  }
  return undefined;
}

/**
 * minNumberLength
 * @param {String} value value
 * @param {Number} minLength minLength
 * @return {string|null} minNumberLength
 */
export function minNumberLength(value, minLength) {
  if (value && minLength && value.length < minLength) {
    return `mindestens ${minLength} Ziffern`;
  }
  return null;
}

/**
 * redux form validator for input field containing a percent
 * @param {String} value percent value as string
 * @return {string|undefined} validation error
 */
export function percentField(value) {
  return _notValid(value, VALID_REGEX.percent) ? getFormattedMessage({ id: 'validator.percent' }) : undefined;
}

/**
 * redux form validator for input field containing a currency
 * @param {String} value currency value as string
 * @return {string|undefined} validation error
 */
export function currencyField(value) {
  if (value && value.length > 1 && value[value.length - 1] === '0' && value[value.length - 2] === ',') {
    return getFormattedMessage({ id: 'validator.two.decimal' });
  }
  return _notValid(value, VALID_REGEX.currency) ? getFormattedMessage({ id: 'validator.currency' }) : undefined;
}

/**
 * Checks if the given value is a valid currency format with a negative field.
 *
 * @param {string} value - The value to be checked.
 * @returns {string|undefined} - The error message if the value is not a valid currency format with a negative field, otherwise undefined.
 */
export function currencyWithNegativField(value) {
  const validateStr = value ? clone(value).toString() : null;
  if (validateStr === '-') {
    return getFormattedMessage({ id: 'validator.currency.negative' });
  }
  if (validateStr && validateStr[0] === '-') {
    return currencyField(validateStr.substring(1));
  }
  if (validateStr && validateStr.length > 1 && validateStr[validateStr.length - 1] === '0' && validateStr[validateStr.length - 2] === ',') {
    return getFormattedMessage({ id: 'validator.two.decimal' });
  }
  return _notValid(validateStr, VALID_REGEX.currency) ? getFormattedMessage({ id: 'validator.currency' }) : undefined;
}

/**
 * Checks if the given value is a positive currency field.
 *
 * @param {string} value - The value to be checked.
 *
 * @return {string|undefined} - Returns a message if the value is not a positive currency field, otherwise undefined.
 *                             - If the value contains a negative number, the message 'positive.number' is returned.
 *                             - If the value contains a currency with more than 2 decimal places, the message 'validator.two.decimal' is returned.
 *                             - If the value does not match the expected currency format, the message 'validator.currency' is returned.
 *                             - If the value is a valid positive currency field, undefined is returned.
 */
export function currencyPositiveField(value) {
  if (value && value.toString() && value.toString()[0] === '-') {
    return getFormattedMessage({ id: 'positive.number' });
  }
  if (value && value.length > 1 && value[value.length - 1] === '0' && value[value.length - 2] === ',') {
    return getFormattedMessage({ id: 'validator.two.decimal' });
  }
  return _notValid(value, VALID_REGEX.currency) ? getFormattedMessage({ id: 'validator.currency' }) : undefined;
}

/**
 * redux form validator for input field containing a currency
 * @param {String} value currency value as string
 * @return {string|undefined} validation error
 */
export function currencyBigThanZero(value) {
  if (value && (value === 0 || value === '0')) {
    return getFormattedMessage({ id: 'sollte größer als 0 sein' });
  }
  return undefined;
}

/**
 * validate x digits field
 * @param {String | Number} value value
 * @param {Number} digits digits
 * @return {string|*} validated or not
 * @private
 */
function _currencyXdigitsField(value, digits) { // eslint-disable-line
  return currencyField(value) || ((value && (value.toString()
    .split('.')[0]).length > digits) ? `max. ${digits}stellig` : undefined);
}

/**
 * validate 11 digits field
 * @param {String | Number} value value
 * @return {string|*} validated or not
 * @private
 */
export function currency11DigitsField(value) {
  return _currencyXdigitsField(value, 11);
}

/**
 * redux Form validator for react datepicker
 * checks if the entered date is a valid date
 * @param {String} value date as string
 * @returns {String} validation error msg or undefined
 */
export function dateField(value) {
  return !moment(value)
    .isValid() ? getFormattedMessage({ id: 'validator.date' }) : undefined;
}

/**
 * validate min length
 * @param {number} length min length
 * @return {function(*): *} function
 */
const minLength = (length) => (value) => (value.length >= length ? undefined : `Minimal ${length} Zeichen`);
export const minLength5 = minLength(5);

/**
 * validate max length
 * @param {number} length max length
 * @return {function(*): *} function
 */
const maxLength = (length) => (value) => (value.length <= length ? undefined : `Maximal ${length} Zeichen`);
export const maxLength5 = maxLength(5);
export const maxLength12 = maxLength(12);

/**
 * validate advmcode
 * @param {object} value input
 * @return {*} error message
 */
export const advmCodeField = (value) => (_notValid(value, VALID_REGEX.advmCode) ? getFormattedMessage({ id: 'validator.advmCode' }) : undefined);

/**
 * redux form validator for email
 * @param {String} value email in string
 * @return {String} validation error msg or undefined
 */
export function emailField(value) {
  return _notValid(value, VALID_REGEX.email) ? getFormattedMessage({ id: 'validator.email' }) : undefined;
}

/**
 * get editor illegal characters
 * @param {string} applicationsTyp applicationsTyp
 * @return {string} illegal characters
 */
export function getFieldContentRegex(applicationsTyp) {
  if (applicationsTyp) {
    switch (applicationsTyp) {
      case 'VJ':
        return VALID_REGEX.vj;
      case 'GT':
        return VALID_REGEX.gb;
      case 'STA':
        return VALID_REGEX.sta;
      case 'THB':
        return VALID_REGEX.thb;
      case 'FB':
        return VALID_REGEX.fbStrukturierterText;
      default:
        return '';
    }
  }
  return '';
}

/**
 * filter illegal characters of editor content
 * @param {string} content content
 * @param {string} regex regex pattern
 * @return {string|*} filtered illegal characters
 */
export function filterIllegalCharacters(content, regex) {
  if (content && regex) {
    const illegalChars = [];
    const pattern = new RegExp(regex);
    for (let i = 0; i < content.length; i += 1) {
      const char = content[i];
      if (
        !pattern.test(char)
        && !illegalChars.includes(char)
        && char !== '\n'
        && char !== '\t'
        && char !== '\r'
        && char !== '\u00A0') {
        illegalChars.push(char);
      }
    }
    if (illegalChars.length > 0) {
      return _formatIllegalCharacters(illegalChars);
    }
  }
  return null;
}

/**
 * format illegal characters
 * @param {Array} illegalChars illegal characters array
 * @return {string} illegal characters
 */
function _formatIllegalCharacters(illegalChars) { // eslint-disable-line
  let retval = null;
  for (let i = 0; i < illegalChars.length; i += 1) {
    const char = illegalChars[i];
    if (i === 0) {
      retval = `"${char}"`;
    } else {
      retval += `, "${char}"`;
    }
  }
  return retval;
}

/**
 * validate required applikation text
 * @param {String} value value
 * @param {String} regex application regex
 * @param {Boolean} shortVersion shortVersion
 * @param {Boolean} isTextArea isTextArea
 * @return {String} validation error msg or undefined
 */
function _requiredApplikationText(value, regex, shortVersion, isTextArea) { // eslint-disable-line
  const illegalChars = filterIllegalCharacters(value, regex);
  let errorMsg = '';
  if (isTextArea) {
    replaceAll(illegalChars, '""', '');
  }
  if (illegalChars) {
    errorMsg += `${shortVersion ? getFormattedMessage({ id: 'validator.unerlaubtenZeichenShort' }) : getFormattedMessage({ id: 'validator.unerlaubtenZeichen' })}${illegalChars}`;
  }
  return errorMsg || undefined;
}

/**
 * validate required vj text
 * @param {String} value value
 * @return {String} validation error msg or undefined
 */
export function VJTextField(value) {
  return _requiredApplikationText(value, getFieldContentRegex(APP_TYPE.VJ), true);
}

/**
 * validate required gb text
 * @param {String} value value
 * @return {String} validation error msg or undefined
 */
export function GBTextField(value) {
  return _requiredApplikationText(value, getFieldContentRegex(APP_TYPE.GT), true);
}

/**
 * validate required sta text
 * @param {String} value value
 * @return {String} validation error msg or undefined
 */
export function STATextField(value) {
  return _requiredApplikationText(value, getFieldContentRegex(APP_TYPE.STA), true);
}

/**
 * validate required thb text
 * @param {String} value value
 * @return {String} validation error msg or undefined
 */
export function THBTextField(value) {
  return _requiredApplikationText(value, getFieldContentRegex(APP_TYPE.THB), true);
}

/**
 * validate vorgangsnummer
 * @param {String} value value
 * @return {*} yes or no
 */
export function vorgangsnummerField(value) {
  return _notValid(value, VALID_REGEX.vorgangsnummer) ? getFormattedMessage({ id: 'validator.vorgangsnummer' }) : undefined;
}

/**
 * validate eigentumsrecht anmerkung eigentumsanteile literal
 * @param {String} value value
 * @return {*} yes or no
 */
export function literalField(value) {
  return _notValid(value, VALID_REGEX.literal) ? getFormattedMessage({ id: 'validator.literal' }) : undefined;
}

/**
 * validate bicswift
 * @param {String} value value
 * @return {*} yes or no
 */
export function bicswiftField(value) {
  return _notValid(value, VALID_REGEX.bicswift) ? getFormattedMessage({ id: 'konto.bic.invalid' }) : undefined;
}

/**
 * validate iban
 * @param {String} value value
 * @return {*} yes or no
 */
export function ibanField(value) {
  return _notValid(value, VALID_REGEX.iban) ? getFormattedMessage({ id: 'konto.iban.invalid' }) : undefined;
}

/**
 * validate sonstiges begehren neueintragung eigentumsanteile VorgemerkterEigentuemer
 * @param {String} value value
 * @return {*} yes or no
 */
export function vorgemerktenEigentuemerField(value) {
  return _notValid(value, VALID_REGEX.vorgemerktenEigentuemer) ? getFormattedMessage({ id: 'validator.vorgemerktenEigentuemer' }) : undefined;
}

/**
 * warn begehren katastralgemeinde
 * @param {String} value value
 * @return {string|undefined} warn message
 */
export function katastralgemeideField(value) {
  return _validateXSteilligeNummer(value, 5);
}

/**
 * warn begehren blnr
 * @param {String} value value
 * @return {string|undefined} warn message
 */
export function blnrField(value) {
  if (value) {
    const split = value.toString()
      .split(',');
    for (let i = 0; i < split.length; i += 1) {
      if (isNaN(Number(split[i]))) { // eslint-disable-line
        return getFormattedMessage({ id: 'validator.number' });
      }
      if (split[i].trim().length > 5) {
        return getFormattedMessage({ id: 'validator.max5StelligeNummer' });
      }
    }
  }
  return undefined;
}

/**
 * warn begehren Einlagezahl
 * @param {String} value value
 * @return {string|undefined} warn message
 */
export function einlagezahlField(value) {
  return numberField(value);
}

/**
 * warn begehren CLNR
 * @param {String} value value
 * @return {string|undefined} warn message
 */
export function clnrField(value) {
  return numberField(value);
}

/**
 * warn begehren LNR
 * @param {String} value value
 * @return {string|undefined} warn message
 */
export function lnrField(value) {
  return _validateXSteilligeNummer(value, null);
}

/**
 * warn 5 steillige nummer
 * @param {String} value value
 * @param {Number} count count
 * @return {string|undefined} warn message
 * @private
 */
function _validateXSteilligeNummer(value, count = null) { // eslint-disable-line
  if (value) {
    if (isNaN(Number(value))) { // eslint-disable-line
      return getFormattedMessage({ id: 'validator.number' });
    } else if (count && value.length < count) { // eslint-disable-line
      return `Minimal ${count} Zeichen`;
    }
  }
  return undefined;
}

/**
 * validate Geburtsdatum
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function birthdayField(value) {
  return (value && Date.parse(value, 'YYYY-MM-DD') > Date.now()) ? getFormattedMessage({ id: 'validator.birthday' }) : undefined;
}

/**
 * validate Geburtsdatum
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function timeField(value) {
  return (value && Date.parse(value, 'YYYY-MM-DD') > Date.now()) ? getFormattedMessage({ id: 'validator.time' }) : undefined;
}

/**
 * validate Pruefsumme
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function pruefsummeField(value) {
  return _notValid(value, VALID_REGEX.pruefsummer) ? getFormattedMessage({ id: 'validator.pruefsummer' }) : undefined;
}

/**
 * validate RangordnungsCode
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function rangordnungsCodeField(value) {
  return _notValid(value, VALID_REGEX.rangordnungsCode) ? getFormattedMessage({ id: 'validator.rangordnungsCode' }) : undefined;
}

/**
 * validate Grundstueck
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function grundstueckField(value) {
  return _notValid(value, VALID_REGEX.grundstueck) ? getFormattedMessage({ id: 'validator.grundstueck.false' }) : undefined;
}

/**
 * validate FirmenbuchNummer
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function firmenbuchNummerField(value) {
  return _notValid(value, VALID_REGEX.firmenbuchNummer) ? getFormattedMessage({ id: 'validator.firmenbuchNummer' }) : undefined;
}

/**
 * validate GeschaeftsfallNummer
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function geschaeftsfallNummerField(value) {
  return _notValid(value, VALID_REGEX.geschaeftsfallNummer) ? getFormattedMessage({ id: 'validator.geschaeftsfallNummer' }) : undefined;
}

/**
 * validate ZustaendigkeitsPLZ
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function zustaendigkeitsPLZField(value) {
  return _notValid(value, VALID_REGEX.zustaendigkeitsPLZ) ? getFormattedMessage({ id: 'validator.zustaendigkeitsPLZ' }) : undefined;
}

/**
 * validate Personenkennung
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function personenkennungField(value) {
  return _notValid(value, VALID_REGEX.personenkennung) ? getFormattedMessage({ id: 'validator.personenkennung' }) : undefined;
}

/**
 * validate abteilungsnummer
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function abteilungsnummerField(value) {
  return value && value.length !== 3 ? getFormattedMessage({ id: 'validator.abteilungsnummer' }) : undefined;
}

/**
 * validate vormerkungsHinweis
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function vormerkungsHinweisField(value) {
  return _requiredApplikationText(value, VALID_REGEX.vormerkungsHinweis, true);
}

/**
 * validate Tagbuchzahl Jahr
 * @param {String} value value
 * @return {String} yes or no
 */
export function tagbuchzahlJahrField(value) {
  const jahr = parseInt(value, 10);
  return jahr < 1919 ? getFormattedMessage({ id: 'validator.tagbuchzahl.jahr' }) : undefined;
}

/**
 * validate grundbuchEintragungZusatz
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function grundbuchEintragungZusatz(value) {
  return _requiredApplikationText(value, VALID_REGEX.gbEintragungZusatz, true);
}

/**
 * validate grundbuchDokumentBezeichnung
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function grundbuchDokumentBezeichnung(value) {
  return _requiredApplikationText(value, VALID_REGEX.gbEintragungstext, true);
}

/**
 * validate grundbuchDiakritischText
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function grundbuchDiakritischText(value) {
  return _requiredApplikationText(value, VALID_REGEX.gbDiakritischText, true);
}

/**
 * validate grundbuchToken
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function grundbuchToken(value) {
  return _requiredApplikationText(value, VALID_REGEX.gbToken, true);
}

/**
 * validate grundbuchStrukturierterText
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function grundbuchStrukturierterText(value) {
  return _requiredApplikationText(value, VALID_REGEX.gbStrukturierterText, true);
}

/**
 * validate firmenbuchStrukturierterText
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function firmenbuchStrukturierterText(value) {
  return _requiredApplikationText(value, VALID_REGEX.fbStrukturierterText, true);
}

/**
 * validate firmenbuchStrukturierterText
 * @param {String} value value
 * @returns {undefined} yes or no
 */
export function firmenbuchStrukturierterTextArea(value) {
  return _requiredApplikationText(value, VALID_REGEX.fbStrukturierterText, true, true);
}

/**
 * validate eigenerlagField
 * @param {String} value value
 * @return {String} yes or no
 */
export function eigenerlagField(value) {
  if (value) {
    const valueInt = parseInt(value, 10);
    if (valueInt > 5000) {
      return getFormattedMessage({ id: 'validator.eigenerlag.jahr' });
    }
  }
  return undefined;
}


/**
 * fbGrossZifferField
 * @param {String} value value
 * @return {String|undefined} value
 */
export function fbGrossZifferField(value) {
  return _notValid(value, VALID_REGEX.fbGrossZiffer) ? getFormattedMessage({ id: 'validator.fbGrossZiffer' }) : undefined;
}


/**
 * fbPersonPNRField
 * @param {String} value value
 * @return {String|undefined} value
 */
export function fbPersonPNRField(value) {
  return _notValid(value, VALID_REGEX.fbPersonPNR) ? getFormattedMessage({ id: 'validator.fbPersonPNR' }) : undefined;
}


/**
 * fbPersonFNRField
 * @param {String} value value
 * @return {String|undefined} value
 */
export function fbPersonFNRField(value) {
  return _notValid(value, VALID_REGEX.fbPersonFNR) ? getFormattedMessage({ id: 'validator.fbPersonFNR' }) : undefined;
}


/**
 * fbVertretungsartField
 * @param {String} value value
 * @return {String|undefined} value
 */
export function fbVertretungsartField(value) {
  return _notValid(value, VALID_REGEX.vertretungsart) ? getFormattedMessage({ id: 'validator.fbVertretungsart' }) : undefined;
}

/**
 * fbUnternehmensdauerField
 * @param {String} value value
 * @return {undefined|string} fbUnternehmensdauerField
 */
export function fbUnternehmensdauerField(value) {
  return (value === 'KEINE_ANGABE' || value === 'KEINE_AUSWAHL') ? 'Pflichtfeld' : undefined;
}

/**
 * fbFnrField
 * @param {String} value value
 * @return {undefined|string} fbFnrField
 */
export function fbFnrField(value) {
  if (value === '000000') {
    return undefined;
  }
  return _notValid(value, VALID_REGEX.fbFnr) ? getFormattedMessage({ id: 'validator.fbPersonPNR' }) : undefined;
}

/**
 * ifaField
 * @param {String} value value
 * @return {String|undefined} ifaField
 */
export function ifaField(value) {
  if (value) {
    const count = value.toString().trim().length;
    if (count < 20 || count > 24) {
      return getFormattedMessage({ id: 'validator.ifa.false' });
    }
  }
  return undefined;
}

/**
 * justizboxUploadFilenameField
 * @param {String} value value
 * @return {String|undefined} error message
 */
export function justizboxUploadFilenameField(value) {
  const msg = (
    <div>
      <div>
        {`ERV-Box: Der Dateiname "${value}" enthält unerlaubte Zeichen! `}
      </div>
      <div>
        Folgende Zeichen sind erlaubt: A-Z, a-z, 0-9, - (Bindestrich), _ (Unterstrich), . (Punkt).
      </div>
    </div>
  );

  return _notValid(value, VALID_REGEX.justizboxUploadFilename) ? msg : undefined;
}
