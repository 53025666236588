import React from 'react';
import PropTypes from 'prop-types';
import mzCross from '../../assets/icons/mz-cross.svg';

/**
 * modal header
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function ModalHeader(props) {
  const { children, onClose } = props;
  return (
    <div>
      <div
        className="manz-modal-header"
      >
        <div>
          {children}
        </div>
        <button
          type="button"
          onClick={onClose}
          className="close-btn"
          aria-label="Abbrechen Button"
        >
          <img src={mzCross} alt="close" aria-label="close" />
        </button>
      </div>
    </div>
  );
}

ModalHeader.defaultProps = {
  children: null,
};

ModalHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
};

export default ModalHeader;
