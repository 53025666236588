import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-oPadding.svg';

/**
 * checkbox redux form component
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function Checkbox(props) {
  const {
    label,
    input,
    tooltip,
    className,
    labelClassName,
    onClickIntercept,
    meta: {
      error,
      invalid,
      submitFailed,
    },
    ...rest
  } = props;

  const classNames = className ? className.split(' ') : [];
  if (error && submitFailed) {
    classNames.push('form-validation-error');
  }

  const disabled = rest.disabled;

  return (
    <div className={classNames.join(' ')}>
      <label
        title={tooltip}
        className={`form-check-label checkbox-container  ${labelClassName || ''}`}
      >
        {label}
        <input
          {...rest}
          {...input}
          title={tooltip}
          type="checkbox"
          aria-label={label}
          value={input.value}
          checked={input.value}
          onClick={onClickIntercept}
          className="form-check-input"
        />
        <span
          className={`checkmark ${disabled ? 'disabled' : ''}`}
          title={tooltip}
        />
      </label>
      {
        invalid && submitFailed && error && (
          <span className="error">
            <InfoSVG title="" />
            {' '}
            {error}
          </span>
        )
      }
    </div>
  );
}

Checkbox.defaultProps = {
  label: null,
  meta: null,
  input: null,
  tooltip: null,
  className: null,
  labelClassName: null,
  onClickIntercept: null,
};

Checkbox.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  onClickIntercept: PropTypes.func,
  labelClassName: PropTypes.string,
};

export default Checkbox;
