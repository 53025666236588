import makeRestCall from '../common/Rest/makeRestCall';
import { OK } from '../common/Rest/http.status.codes';
import getUrl from '../common/Rest/rest.utils';
import REST from '../common/Rest';
import { TIMEOUT_TYPE } from '../common/Rest/fetchWithTimeout';
import SCHRIFTSATZ_STATUS from '../constants/schriftsatz.status.json';
import modalFactory from '../common/Modal/modalFactory';
import NewsModal from './NewsModal';
import PATH from '../constants/path.json';
import { restCallHilfeXml } from '../utils/help.utils';

/**
 * rest call news
 * @return {Promise<*|String|Node|string|null>} news info
 */
async function restCallNews() {
  const newsMsgObject = await restCallHilfeXml('blog');

  let countZurueckgewiesenAusgang = 0;
  let countUebertragungFehlerAusgang = 0;

  const statusResult = await makeRestCall('GET', getUrl(REST.urls.countSchriftverkehr), null, null, true, null, TIMEOUT_TYPE.LOGIN);

  if (statusResult.status === OK && statusResult.body) {
    const status = statusResult.body;

    countZurueckgewiesenAusgang = status.countSchriftverkehr && status.countSchriftverkehr[SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value];
    countUebertragungFehlerAusgang = status.countSchriftverkehr && status.countSchriftverkehr[SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value];
  }

  return {
    countZurueckgewiesenAusgang,
    countUebertragungFehlerAusgang,
    newsMsg: newsMsgObject && newsMsgObject.infoBody,
  };
}

/**
 * display news modal
 * @param {Boolean} newsRead newsRead
 * @return {Promise<void>} undefined
 */
export async function displayNewsModal(newsRead) {
  const isDashboard = window.location.pathname === PATH.DASHBOARD.value;

  if (isDashboard && !newsRead) {
    const { newsMsg, countZurueckgewiesenAusgang, countUebertragungFehlerAusgang } = await restCallNews();

    if (newsMsg || countZurueckgewiesenAusgang || countUebertragungFehlerAusgang) {
      modalFactory.open(
        NewsModal,
        {
          newsMsg,
          countZurueckgewiesenAusgang,
          countUebertragungFehlerAusgang,
        },
      );
    }
  }
}
