import BERECHTIGUNG from '../berechtigungen.json';

export const INHALT_ART_BERECHTIGUNG = {
  VF_VFGH: BERECHTIGUNG.VF_VFGH,
  VF_VWGH: BERECHTIGUNG.VF_VWGH,
  VF_ASYLGH: BERECHTIGUNG.VF_ASYLGH,

  FB_Neu: BERECHTIGUNG.FB_Neu,
  FB_Neu_OG: BERECHTIGUNG.FB_Neu_OG,
  FB_Antrag: BERECHTIGUNG.FB_Antrag,
  FB_Jahres: BERECHTIGUNG.FB_Jahres,
  FB_AendrAntr: BERECHTIGUNG.FB_Antrag,
  FB_LoeschAntr: BERECHTIGUNG.FB_Antrag,
  FB_NeueinAntr: BERECHTIGUNG.FB_Antrag,

  TLDZ: BERECHTIGUNG.TLDZ,
  THB_TLDZ: BERECHTIGUNG.THB_Anwalt,
  DIREKTVERKEHR: BERECHTIGUNG.TLDZ,

  VJ_EVc: BERECHTIGUNG.VJ_EVc,
  VJ_EVcg: BERECHTIGUNG.VJ_EVcg,
  VJ_EVcga: BERECHTIGUNG.VJ_EVcga,
  VJ_Exe: BERECHTIGUNG.VJ_Exe,
  VJ_ExeNStru: BERECHTIGUNG.VJ_Exe,
  VJ_Exesonst: BERECHTIGUNG.VJ_Exe,
  VJ_Folge: BERECHTIGUNG.VJ_Folge,
  VJ_Kuenc: BERECHTIGUNG.VJ_Kuenc,
  VJ_Kuencga: BERECHTIGUNG.VJ_Kuencga,
  VJ_Mkc: BERECHTIGUNG.VJ_Mkc,
  VJ_Mkcg: BERECHTIGUNG.VJ_Mkcg,
  VJ_Mkcga: BERECHTIGUNG.VJ_Mkcga,
  VJ_Serst: BERECHTIGUNG.VJ_Serst,
  VJ_Skc: BERECHTIGUNG.VJ_Skc,
  VJ_Skcg: BERECHTIGUNG.VJ_Skcg,
  VJ_Skcga: BERECHTIGUNG.VJ_Skcga,
  VJ_Srs: BERECHTIGUNG.VJ_Srs,
  VJ_Kerst: BERECHTIGUNG.VJ_Kerst,

  GB_MI: BERECHTIGUNG.GB_MI,
  GB_AN: BERECHTIGUNG.GB_AN,
  GB_GRUPPE: BERECHTIGUNG.GB_AN,

  STA_Eingabe: BERECHTIGUNG.STA_Eingabe,

  EU_M: BERECHTIGUNG.EU_M,
  EU_C: BERECHTIGUNG.EU_M,
  EU_V: BERECHTIGUNG.EU_V,
  EU_F: BERECHTIGUNG.EU_F,
  EU_Brief: BERECHTIGUNG.EU_M,

  THB_A2B_KAA: BERECHTIGUNG.THB_Anwalt,
  THB_A2B_BM: BERECHTIGUNG.THB_Anwalt,
  THB_A2B_KA: BERECHTIGUNG.THB_Anwalt,
  THB_A2B_KSM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_EM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_AM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_BM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_FM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_AKVAM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_ABM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_KVAM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_UEM: BERECHTIGUNG.THB_Anwalt,
  THB_RM_ANWALT: BERECHTIGUNG.THB_Anwalt,
  THB_A2B_AEM: BERECHTIGUNG.THB_Anwalt,
  THB_A2B_AM: BERECHTIGUNG.THB_Anwalt,
  THB_A2B_VM: BERECHTIGUNG.THB_Anwalt,
  THB_A2B_KVA: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_AZM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_VEM: BERECHTIGUNG.THB_Anwalt,
  THB_A2K_VFM: BERECHTIGUNG.THB_Anwalt,

  THB_B2A_AFM: BERECHTIGUNG.THB_Bank,
  THB_B2A_RKAM: BERECHTIGUNG.THB_Bank,
  THB_B2A_RKAA: BERECHTIGUNG.THB_Bank,
  THB_B2A_RKSM: BERECHTIGUNG.THB_Bank,
  THB_B2A_WAEM: BERECHTIGUNG.THB_Bank,
  THB_B2A_WKVA: BERECHTIGUNG.THB_Bank,
  THB_B2A_WSM: BERECHTIGUNG.THB_Bank,
  THB_B2K_RKVA: BERECHTIGUNG.THB_Bank,
  THB_B2K_RSM: BERECHTIGUNG.THB_Bank,
  THB_B2K_RAEM: BERECHTIGUNG.THB_Bank,
  THB_B2K_BM: BERECHTIGUNG.THB_Bank,
  THB_B2K_WKSM: BERECHTIGUNG.THB_Bank,
  THB_B2A_RAM: BERECHTIGUNG.THB_Bank,
  THB_B2A_RAEM: BERECHTIGUNG.THB_Bank,
  THB_B2A_RKVA: BERECHTIGUNG.THB_Bank,
  THB_B2A_RSM: BERECHTIGUNG.THB_Bank,
  THB_B2A_RVM: BERECHTIGUNG.THB_Bank,
  THB_B2K_RAM: BERECHTIGUNG.THB_Bank,
  THB_B2K_RVM: BERECHTIGUNG.THB_Bank,

  THB_K2A_AM: BERECHTIGUNG.THB_RAK,
  THB_K2A_EM: BERECHTIGUNG.THB_RAK,
  THB_K2B_SM: BERECHTIGUNG.THB_RAK,

  STV: BERECHTIGUNG.STV,

  EZUSTELLUNG: BERECHTIGUNG.EZUSTELLUNG,

  BMI: BERECHTIGUNG.BMI,
};
