import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchSVG } from '../../assets/icons/mz-search-ergebnis.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function SearchErgebnisIcon(props) {
  const { className, onClick, title } = props;
  return (
    <SearchSVG // eslint-disable-line
      title={title}
      width={30}
      height={30}
      onClick={onClick}
      id="manz-plus-circle"
      className={className}
      alt="search ergebnis icon"
      aria-label="Suchenergebnisse Icon"
    />
  );
}

SearchErgebnisIcon.defaultProps = {
  onClick: null,
  title: 'Suchen',
  className: null,
};

SearchErgebnisIcon.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default SearchErgebnisIcon;
