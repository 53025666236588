import React from 'react';
import PropTypes from 'prop-types';

/**
 * send button component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function SendButton(props) {
  const {
    id,
    onClick,
    disabled,
    className,
    selectedCount,
  } = props;

  const cName = ['btn', 'btn-primary'];
  if (className) {
    cName.push(className);
  }

  let text = 'Senden';
  if (selectedCount && selectedCount > 0) {
    text = `Senden (${selectedCount})`;
  }

  return (
    <button
      id={id}
      type="button"
      aria-label={text}
      onClick={onClick}
      disabled={disabled}
      className={cName.join(' ')}
    >
      {text}
    </button>
  );
}

SendButton.defaultProps = {
  id: null,
  onClick: null,
  disabled: false,
  className: null,
  selectedCount: null,
};

SendButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  selectedCount: PropTypes.number,
};

export default SendButton;
