import React from 'react';
import FetchErrorModal from './FetchErrorModal';

/**
 * BackendUnreachableErrorModal
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function BackendDisconnectErrorModal(props) {
  return (
    <FetchErrorModal
      {...props}
    />
  );
}

export default BackendDisconnectErrorModal;
