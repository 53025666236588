import React from 'react';
import PropTypes from 'prop-types';
import AlertRedIcon from '../common/Icons/AlertRedIcon';
import LoadingSpinner from '../common/Spinner/Loading/LoadingSpinner';

/**
 * function
 * @param {Object} props props
 * @return {JSX.Element} JSX
 * @constructor
 */
function ErrorInfo(props) {
  const {
    onSend,
    sending,
    isOverview,
    inaktivEinbringer,
    existUebertragungsfehler,
  } = props;

  return (
    <>
      {existUebertragungsfehler && (
        <div className="layout mz-alert-info">
          <div className="rolleStyle">

            <AlertRedIcon
              width={35}
              height={35}
            />

            <div className="error-info-text">
              <div>
                {`Es gab Verbindungsprobleme bei der Übermittlung - bitte senden Sie ${isOverview ? 'die Schriftsätze' : 'diesen Schriftsatz'} erneut!`}
              </div>
              <div>
                Erstellen oder kopieren Sie den Schriftsatz nicht - dies kann zu Doppelübertragungen führen.
              </div>
            </div>

            <button
              type="button"
              onClick={onSend}
              id="erneut senden"
              aria-label="Erneut senden"
              disabled={sending || inaktivEinbringer}
              className="btn btn-blue error-info-erneut-senden-btn"
            >
              Erneut senden
            </button>

          </div>

          <div className="center margin-top-md">
            {sending && <LoadingSpinner key="spinner" />}
          </div>
        </div>
      )}
    </>
  );
}

ErrorInfo.defaultProps = {
  isOverview: false,
  inaktivEinbringer: false,
  existUebertragungsfehler: false,
};

ErrorInfo.propTypes = {
  isOverview: PropTypes.bool,
  inaktivEinbringer: PropTypes.bool,
  onSend: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  existUebertragungsfehler: PropTypes.bool,
};

export default ErrorInfo;
