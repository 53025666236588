import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TrashSVG } from '../../assets/icons/mz-trash.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function DeleteIcon(props) {
  const { className } = props;
  return (
    <div className={`icon ${className}`} aria-label="Löschen">
      <TrashSVG
        title="Löschen"
      />
    </div>
  );
}

DeleteIcon.defaultProps = {
  className: '',
};

DeleteIcon.propTypes = {
  className: PropTypes.string,
};

export default DeleteIcon;
