export const STORE_BEZUG_ANTEILE_TYPE_1 = 'store.bezug.anteile.type.1';
export const STORE_BEZUG_ANTEILE_TYPE_2 = 'store.bezug.anteile.type.2';
export const STORE_BEZUG_ANTEILE_TYPE_3 = 'store.bezug.anteile.type.3';

export const CLEAR_BEZUG_ANTEILE_TYPE = 'clear.bezug.anteile.type';

const initial = {
  bezugType1: null,
  bezugType2: null,
  bezugType3: null,
};

/**
 * grundbuchBegehrenReducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{bezugType: null}|{bezugType: (null|*)}} grundbuchBegehrenReducer
 */
export function grundbuchBegehrenReducer(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_BEZUG_ANTEILE_TYPE_1:
      return {
        ...state,
        bezugType1: payload.bezugType1,
      };

    case STORE_BEZUG_ANTEILE_TYPE_2:
      return {
        ...state,
        bezugType2: payload.bezugType2,
      };

    case STORE_BEZUG_ANTEILE_TYPE_3:
      return {
        ...state,
        bezugType3: payload.bezugType3,
      };

    case CLEAR_BEZUG_ANTEILE_TYPE:
      return {
        ...state,
        bezugType1: null,
        bezugType2: null,
        bezugType3: null,
      };

    default:
      return state;
  }
}

/**
 * storeBezugAnteileType1
 * @param {String} bezugType1 bezugType1
 * @return {{paylad: {bezugType}, type: string}} storeBezugAnteileType1
 */
export function storeBezugAnteileType1(bezugType1) {
  return {
    type: STORE_BEZUG_ANTEILE_TYPE_1,
    payload: {
      bezugType1,
    },
  };
}

/**
 * storeBezugAnteileType2
 * @param {String} bezugType2 bezugType2
 * @return {{paylad: {bezugType}, type: string}} storeBezugAnteileType2
 */
export function storeBezugAnteileType2(bezugType2) {
  return {
    type: STORE_BEZUG_ANTEILE_TYPE_2,
    payload: {
      bezugType2,
    },
  };
}

/**
 * storeBezugAnteileType3
 * @param {String} bezugType3 bezugType3
 * @return {{paylad: {bezugType}, type: string}} storeBezugAnteileType3
 */
export function storeBezugAnteileType3(bezugType3) {
  return {
    type: STORE_BEZUG_ANTEILE_TYPE_3,
    payload: {
      bezugType3,
    },
  };
}

/**
 * clearBezugAnteileType
 * @return {{type: string}} clearBezugAnteileType
 */
export function clearBezugAnteileType() {
  return {
    type: CLEAR_BEZUG_ANTEILE_TYPE,
  };
}
