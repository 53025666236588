import React from 'react';
import PropTypes from 'prop-types';
import getUrl from '../Rest/rest.utils';
import makeRestCall from '../Rest/makeRestCall';
import { OK } from '../Rest/http.status.codes';
import modalFactory from '../Modal/modalFactory';
import ConfirmationModal from '../Modal/ConfirmationModal';
import getFormattedMessage from '../FormatMessage/formattedMessage';
import { TIMEOUT_TYPE } from '../Rest/fetchWithTimeout';
import LoadingSpinner from '../Spinner/Loading/LoadingSpinner';

/**
 * ImportButton
 */
class ImportButton extends React.Component {
  /**
   * constuctor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleImport = this.handleImport.bind(this);
  }

  /**
   * import data
   * @returns {Promise<void>} undefined
   */
  async handleImport() {
    const { data, url } = this.props;

    if (data && url) {
      this.setState({ loading: true });

      const result = await makeRestCall('POST', getUrl(url), null, data, false, null, TIMEOUT_TYPE.UPLOAD_FILE);

      if (result.status === OK) {
        await modalFactory.openWithPromise(
          ConfirmationModal,
          {
            header: getFormattedMessage({ id: 'hinweis.header' }),
            body: getFormattedMessage({
              id: 'kontakte.import.success',
              params: result.body,
            }),
            disableClose: true,
          },
        );
      }

      this.setState({ loading: false });
    }
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const { loading } = this.state;

    return (
      <div className="d-flex justify-content-center align-items-center">
        {loading && (
          <div className="position-absolute">
            <LoadingSpinner key="spinner" />
          </div>
        )}

        <button
          id="import"
          type="button"
          aria-label="Importieren"
          className="icon-button btn"
          onClick={this.handleImport}
        >
          Importieren
        </button>
      </div>
    );
  }
}

ImportButton.propTypes = {
  url: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default ImportButton;
