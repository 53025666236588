import React from 'react';
import PropTypes from 'prop-types';
import ModalHeader from '../Modal/ModalHeader';
import { HILFE_PREFIX, restCallHilfeXml } from '../../utils/help.utils';
import LoadingSpinner from '../Spinner/Loading/LoadingSpinner';
import modalFactory from '../Modal/modalFactory';

/**
 * HelpModal component
 */
class HelpModal extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      body: false,
      header: false,
      loading: false,
    };

    this.handleClickModalInternLink = this.handleClickModalInternLink.bind(this);
  }

  /**
   * componentDidMount
   * @return {Promise<void>} undefined
   */
  async componentDidMount() {
    const { hilfePathname } = this.props;
    await this._restCallHilfeXml(hilfePathname);
  }

  /**
   * componentDidUpdate
   * @param {Object} prevProps prevProps
   * @return {Promise<void>} undefined
   */
  async componentDidUpdate(prevProps) {
    const { hilfePathname } = this.props;
    if (this.props !== prevProps) {
      await this._restCallHilfeXml(hilfePathname);
    }
  }

  /**
   * _restCallHilfeXml
   * @param {String} hilfePathname hilfePathname
   * @return {Promise<void>} undefined
   * @private
   */
  async _restCallHilfeXml(hilfePathname) {
    this.setState({ loading: true });
    const hilfe = await restCallHilfeXml(hilfePathname);
    let header = 'Hilfe';
    let body = '';
    if (hilfe) {
      header = hilfe.infoHeader;
      body = hilfe.infoBody;
    }
    this.setState({
      body,
      header,
      loading: false,
    });
  }

  /**
   * handleClickModalInternLink
   * @param {Object} event event
   * @return {Promise<void>} undefined
   */
  async handleClickModalInternLink(event) {
    const { onClose } = this.props;
    const value = event.target;

    event.stopPropagation();
    event.preventDefault();

    if (value) {
      const body = new DOMParser()
        .parseFromString(value, 'text/html')
        .querySelector('body')
        .textContent;

      if (body && typeof (body) === 'string' && !body.includes('[object')) {
        if (body.includes(HILFE_PREFIX)) {
          onClose();

          this.setState({
            body: false,
            header: false,
            loading: false,
          });

          await modalFactory.open(
            HelpModal,
            {
              size: 'lg',
              hilfePathname: body.split(HILFE_PREFIX)[1],
            },
          );
        } else {
          window.open(body, 'target', '');
        }
      }
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { onClose, hilfeTitel } = this.props;
    const { body, header, loading } = this.state;

    return (
      <div>
        <ModalHeader
          onClose={onClose}
          key="modal-header"
        >
          {header || hilfeTitel}
        </ModalHeader>
        <div
          key="modal-body"
          className="hilfe-modal-body manz-modal-help-body"
        >

          {loading && <LoadingSpinner key="spinner" />}

          {/* eslint-disable-next-line react/no-danger */}
          <div onClick={(event) => this.handleClickModalInternLink(event)} dangerouslySetInnerHTML={{ __html: body || '' }} />

        </div>
      </div>
    );
  }
}

HelpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  hilfeTitel: PropTypes.string.isRequired,
  hilfePathname: PropTypes.string.isRequired,
};

export default HelpModal;
