import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';

/**
 * function
 * @param {Object} props props
 * @return {JSX.Element} JSX
 * @constructor
 */
function LoadingSpinnerCenter(props) {
  const { display } = props;
  return display && (
    <div className="center-spinner-wrapper">
      <div className="center-spinner">
        <LoadingSpinner key="spinner" />
      </div>
    </div>
  );
}

LoadingSpinnerCenter.defaultProps = {
  display: false,
};

LoadingSpinnerCenter.propTypes = {
  display: PropTypes.bool,
};

export default LoadingSpinnerCenter;
