import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withConnectedUserData } from '@auth/auth-oidc-integration';
import getUrl from '../Rest/rest.utils';
import Rest from '../Rest';
import makeRestCall from '../Rest/makeRestCall';
import modalFactory from '../Modal/modalFactory';
import ConfirmationModal from '../Modal/ConfirmationModal';
import getFormattedMessage from '../FormatMessage/formattedMessage';
import LoadingSpinner from '../Spinner/Loading/LoadingSpinner';
import SendButton from '../Buttons/SendButton';
import PATH from '../../constants/path';
import { HINT, OK } from '../Rest/http.status.codes';
import PollingEventEmitter from '../Events/PollingEventEmitter';
import { EVENT_COUNT_GS_AUSGANG_UG_EINGANG } from '../../constants/js/events.constants';
import { TIMEOUT_TYPE } from '../Rest/fetchWithTimeout';
import { handleRestError } from '../Rest/RestError.utils';
import {
  checkNotTransferedJustizboxDokumenteForTransfer,
  checkZPO112WithErvBox,
  getErvboxOutdatedDokumenteList,
  openCheckBerechtigungHinweis,
  openCheckErvboxOutdatedListHinweis,
  openZPO112WithErvBoxHinweis,
  validateBerechtigungByInhaltArt,
} from '../../utils/schriftsatz.utils';
import { arrayHasItem } from '../../utils/general.utils';

/**
 * component
 */
class Senden extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleSendSchriftsatz = this.handleSendSchriftsatz.bind(this);
  }

  /**
   * senden a schriftsatz
   * @return {undefined}
   */
  async handleSendSchriftsatz() {
    const { schriftsatzId } = this.props;
    if (schriftsatzId) {
      if (Array.isArray(schriftsatzId)) {
        await this._sendSchriftsaetze();
      } else {
        await this._sendSchriftsatz();
      }
    }
  }

  /**
   * send schriftsaetze
   * @return {undefined}
   * @private
   */
  async _sendSchriftsaetze() {
    const {
      schriftsatz,
      appUserObj,
      schriftsatzId,
      onReloadAusgaenge,
    } = this.props;

    this.setState({ loading: true });

    let existedNotTransferedJustizbox = false;
    for (let i = 0; i < schriftsatzId.length; i += 1) {
      const ssId = schriftsatzId[i];
      existedNotTransferedJustizbox = await checkNotTransferedJustizboxDokumenteForTransfer(ssId);
      if (existedNotTransferedJustizbox) {
        break;
      }
    }

    this.setState({ loading: false });

    if (existedNotTransferedJustizbox) {
      return;
    }

    this.setState({ loading: true });

    const resultList = [];
    for (let i = 0; i < schriftsatzId.length; i += 1) {
      const ssId = schriftsatzId[i];

      const checkedZpo112WithErvBox = await checkZPO112WithErvBox(ssId);

      // TODO fuer aktuelle Loesung brauchen wir diese Funktion nicht mehr, weil wir diese Validierung einfach bei BRZ lassen, siehe ERVSW-3632.
      const ervboxOutdatedList = await getErvboxOutdatedDokumenteList(ssId); // eslint-disable-line

      const isBerechtigt = validateBerechtigungByInhaltArt(appUserObj, schriftsatz?.inhaltArt);

      if (!checkedZpo112WithErvBox) {
        openZPO112WithErvBoxHinweis();
      } else if (arrayHasItem(ervboxOutdatedList)) {
        openCheckErvboxOutdatedListHinweis(ervboxOutdatedList, schriftsatzId);
      } else if (!isBerechtigt) {
        openCheckBerechtigungHinweis();
      } else {
        const url = getUrl(Rest.urls.schriftsaetzeTransfer, { schriftsatzId: ssId });
        const restResult = await makeRestCall('POST', url, null, null, true); // eslint-disable-line
        resultList.push(restResult);
      }
    }

    if (onReloadAusgaenge) {
      onReloadAusgaenge();
    }

    this.setState({ loading: false });

    PollingEventEmitter.emit(EVENT_COUNT_GS_AUSGANG_UG_EINGANG);

    await modalFactory.openWithPromise(ConfirmationModal, {
      header: getFormattedMessage({ id: 'schriftsatz.pruefen.success.header' }),
      body: getFormattedMessage({ id: this._getSuccessValidateText() }),
      disableClose: true,
    });
  }

  /**
   * send a schriftsatz
   * @return {undefined}
   * @private
   */
  async _sendSchriftsatz() {
    const {
      match,
      history,
      schriftsatz,
      appUserObj,
      schriftsatzId,
      onInterceptClick,
      onInterceptBeforeSend,
    } = this.props;

    if (onInterceptClick) {
      onInterceptClick();
    }

    if (onInterceptBeforeSend === null || (onInterceptBeforeSend && await onInterceptBeforeSend() === true)) {
      this.setState({ loading: true });

      const checkedZpo112WithErvBox = await checkZPO112WithErvBox(schriftsatzId);

      const existedNotTransferedJustizbox = await checkNotTransferedJustizboxDokumenteForTransfer(schriftsatzId);

      const isBerechtigt = validateBerechtigungByInhaltArt(appUserObj, schriftsatz?.inhaltArt);

      // TODO fuer aktuelle Loesung brauchen wir diese Funktion nicht mehr, weil wir diese Validierung einfach bei BRZ lassen, siehe ERVSW-3632.
      const ervboxOutdatedList = await getErvboxOutdatedDokumenteList(schriftsatzId);

      this.setState({ loading: false });

      if (checkedZpo112WithErvBox) {
        if (existedNotTransferedJustizbox) {
          history.push({
            pathname: `${match.url.replace('pruefen', '')}dokumente`,
            search: `?schriftsatzId=${schriftsatzId}`,
          });
        } else if (arrayHasItem(ervboxOutdatedList)) {
          openCheckErvboxOutdatedListHinweis(ervboxOutdatedList, schriftsatzId);
        } else if (!isBerechtigt) {
          openCheckBerechtigungHinweis();
        } else {
          await this._transferSchriftsatz();
        }
      }
    }
  }

  /**
   * _transferSchriftsatz
   * @return {Promise<void>} undefined
   * @private
   */
  async _transferSchriftsatz() {
    const {
      match,
      history,
      schriftsatzId,
      onReloadSchriftsatz,
    } = this.props;

    this.setState({ loading: true });

    const url = getUrl(Rest.urls.schriftsaetzeTransfer, { schriftsatzId });

    const restResult = await makeRestCall('POST', url, null, null, true, null, TIMEOUT_TYPE.SENDEN);

    this.setState({ loading: false });

    const status = restResult.status;
    if (status === OK || status === HINT) {
      onReloadSchriftsatz();

      PollingEventEmitter.emit(EVENT_COUNT_GS_AUSGANG_UG_EINGANG);

      const res = await modalFactory.openWithPromise(ConfirmationModal, {
        header: getFormattedMessage({ id: status === HINT ? 'schriftsatz.pruefen.error.header' : 'schriftsatz.pruefen.success.header' }),
        body: getFormattedMessage({ id: status === HINT ? 'schriftsatz.pruefen.error.body' : this._getSuccessValidateText() }),
        disableClose: true,
      });

      const newPathname = match.url.includes('pruefen', '')
        ? `${match.url.replace('pruefen', '')}${PATH.PROTOKOLL.name}`
        : `${match.url}${PATH.PROTOKOLL.value}`;

      if (res) {
        history.push({
          pathname: newPathname,
          search: `?schriftsatzId=${schriftsatzId}`,
        });
      }
    } else {
      handleRestError(url, restResult.status, restResult);
    }
  }

  /**
   * get validate text by success
   * @return {JSX} component
   */
  _getSuccessValidateText() { // eslint-disable-line
    return [
      <div>{getFormattedMessage({ id: 'schriftsatz.pruefen.success.body1' })}</div>,
      <div>{getFormattedMessage({ id: 'schriftsatz.pruefen.success.body2' })}</div>,
      <div>
        {getFormattedMessage({ id: 'schriftsatz.pruefen.success.body3' })} "{/*eslint-disable-line*/}
        <span className="manz-red">Eingebracht</span>
        ". {/*eslint-disable-line*/}
      </div>,
      <div>{getFormattedMessage({ id: 'schriftsatz.pruefen.success.body4' })}</div>,
    ];
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { disabled, className, selectedCount } = this.props;
    const { loading } = this.state;

    const ret = [];
    if (loading) {
      ret.push(
        <LoadingSpinner
          key="loading"
        />,
      );
    }

    ret.push(
      <SendButton
        id="send-button"
        key="send-button"
        className={className}
        disabled={disabled || loading}
        selectedCount={selectedCount}
        onClick={this.handleSendSchriftsatz}
      />,
    );

    return ret;
  }
}

Senden.defaultProps = {
  disabled: true,
  className: null,
  schriftsatzId: null,
  selectedCount: null,
  onInterceptClick: null,
  onReloadSchriftsatz: null,
  onReloadAusgaenge: null,
  onInterceptBeforeSend: null,
};

Senden.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onInterceptClick: PropTypes.func,
  selectedCount: PropTypes.number,
  match: PropTypes.object.isRequired,
  onReloadSchriftsatz: PropTypes.func,
  onReloadAusgaenge: PropTypes.func,
  history: PropTypes.object.isRequired,
  onInterceptBeforeSend: PropTypes.func,
  schriftsatz: PropTypes.object.isRequired,
  appUserObj: PropTypes.object.isRequired,
  schriftsatzId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};


export default withConnectedUserData(withRouter(Senden));
