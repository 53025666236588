export const STORE_BEZUG_ZU_NAME = 'store.bezug.zu.name';

const initial = {
  bezugZuName: null,
};

/**
 * strukFBRechtstatsacheReducer
 * @param {Object} state state
 * @param {Object} action action
 * @return {{bezugType: null}|{bezugType: (null|*)}} grundbuchBegehrenReducer
 */
export function strukFBRechtstatsacheReducer(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_BEZUG_ZU_NAME:
      return {
        ...state,
        bezugZuName: payload.bezugZuName,
      };

    default:
      return state;
  }
}

/**
 * storeBezugZuName
 * @param {String} bezugZuName bezugZuName
 * @return {{payload: {bezugZuName}, type: string}} storeBezugZuName
 */
export function storeBezugZuName(bezugZuName) {
  return {
    type: STORE_BEZUG_ZU_NAME,
    payload: {
      bezugZuName,
    },
  };
}
