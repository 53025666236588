import React from 'react';
import PropTypes from 'prop-types';
import withRestClient from '../common/Rest/withRestClient';
import Rest from '../common/Rest';
import { LOADING } from '../common/Rest/rest.constants';
import LoadingSpinner from '../common/Spinner/Loading/LoadingSpinner';

/**
 * InfoSite component
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function InfoSite(props) {
  const { frontendstate, backendstate, restState } = props;

  const validationList = [];

  if (backendstate && backendstate.validation) {
    const keys = Object.keys(backendstate.validation);

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const val = backendstate.validation[key];
      validationList.push(<li key={key}>{key}:{' '}{val}</li>); // eslint-disable-line
    }
  }

  if (
    restState.backendstate.request === LOADING
    || restState.frontendstate.request === LOADING
  ) {
    return <LoadingSpinner key="spinner" />;
  }
  return (
    <div>
      <div>
        <legend>Frontend</legend>
        {
          frontendstate && frontendstate.build
          && (
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <span>&nbsp;Version: </span>
                <span>{frontendstate.build.version}</span>
              </li>
              <li>
                <span>&nbsp;Zeitpunkt: </span>
                <span>{frontendstate.build.time}</span>
              </li>
              <li>
                <span>&nbsp;Nummer: </span>
                <span>{frontendstate.build.number}</span>
              </li>
            </ul>
          )
        }
      </div>
      <br />
      <div>
        <legend>Backend</legend>
        {
          backendstate && backendstate.build
          && (
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <span>&nbsp;Version: </span>
                <span>{backendstate.build.version}</span>
              </li>
              <li>
                <span>&nbsp;Zeitpunkt: </span>
                <span>{backendstate.build.time}</span>
              </li>
              <li>
                <span>&nbsp;Health: </span>
                {
                  backendstate && backendstate.health && backendstate.health.status
                  && (
                    <span>
                      {backendstate.health.status}
                    </span>
                  )
                }
              </li>
              <li>
                <span>&nbsp;Server: </span>
                {
                  backendstate && backendstate.hostname
                  && (
                    <span>
                      {backendstate.hostname}
                    </span>
                  )
                }
              </li>
            </ul>
          )
        }
      </div>
      <div>
        <legend>ERV</legend>
        <ul style={{ listStyleType: 'none' }}>{validationList}</ul>
      </div>
    </div>
  );
}

InfoSite.defaultProps = {
  backendstate: null,
  frontendstate: null,
};

InfoSite.propTypes = {
  backendstate: PropTypes.object,
  frontendstate: PropTypes.object,
  restState: PropTypes.object.isRequired,
};

export { InfoSite as InfoSiteTest };

export default withRestClient({
  backendstate: {
    urlTemplate: Rest.urls.infoAll,
  },
  frontendstate: {
    urlTemplate: Rest.urls.infoBuild,
  },
})(InfoSite);
