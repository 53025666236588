const STORE_BASISDATEN = 'store.basisdaten';
const CLEAR_BASISDATEN = 'clear.basisdaten';

const initial = {
  basisdaten: null,
};

/**
 * exekutionsantragReduder
 * @param {Object} state state
 * @param {Object} action action
 * @return {{basisdaten: null}} exekutionsantragReduder
 */
export function exekutionsantragReduder(state = initial, action) {
  const payload = action.payload;

  switch (action.type) {
    case STORE_BASISDATEN:
      state.basisdaten = payload.basisdaten;
      return {
        ...state,
      };
    case CLEAR_BASISDATEN:
      state.basisdaten = null;
      return {
        ...state,
      };
    default:
      return state;
  }
}

/**
 * storeExekutionsantragBasisdaten
 * @param {Object} basisdaten basisdaten
 * @return {Object} redux action
 */
export function storeExekutionsantragBasisdaten(basisdaten) {
  return {
    type: STORE_BASISDATEN,
    payload: {
      basisdaten,
    },
  };
}

/**
 * clearExekutionsantragBasisdaten
 * @return {Object} redux action
 */
export function clearExekutionsantragBasisdaten() {
  return {
    type: CLEAR_BASISDATEN,
  };
}
