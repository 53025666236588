import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * a simple progress bar including an countdown
 * the countdown is diplyed in MM:SS format using moment js
 */
class ProgressBarWithCountDown extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      secondsRemaining: 0,
    };

    this.interval = null;
    this.progressTick = this.progressTick.bind(this);
  }

  /**
   * start the interval on mount
   * @returns {undefined}
   */
  componentWillMount() {
    clearInterval(this.interval);
    this.startInterval();
  }

  /**
   * clear the interval on unmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * starts the progress interval
   * @returns {undefined}
   */
  startInterval() {
    const { duration, intervalTimeout } = this.props;

    clearInterval(this.interval);
    this.setState(() => ({
      progress: 0,
      secondsRemaining: duration,
    }), () => {
      this.interval = setInterval(this.progressTick, intervalTimeout);
    });
  }

  /**
   * progress tick function. executed by setInterval
   * @returns {undefined}
   */
  progressTick() {
    const { duration, onAttemptToFinishProgress } = this.props;
    const { secondsRemaining } = this.state;
    if (secondsRemaining > 0) {
      const nextSecondsRemaining = secondsRemaining - 1;
      const progress = (duration - nextSecondsRemaining) / duration;
      this.setState(() => ({
        progress,
        secondsRemaining: nextSecondsRemaining,
      }));
    } else if (secondsRemaining <= 0) {
      this.setState(() => ({
        progress: 100,
        secondsRemaining: 0,
      }), () => {
        this.startInterval();
        this.handleIntervalEnd();
      });
      if (onAttemptToFinishProgress) {
        onAttemptToFinishProgress();
      }
    }
  }

  /**
   * on interval end execute an callback if there is one
   * @returns {undefined}
   */
  handleIntervalEnd() {
    const { onProgressEnd } = this.props;
    if (onProgressEnd) {
      onProgressEnd();
    }
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const { titel, suffixTitel } = this.props;
    const { secondsRemaining, progress } = this.state;
    const countdown = moment('2019-01-01').startOf('day').seconds(secondsRemaining).format('mm:ss');
    return (
      <div className="py-5">
        <h5>
          {titel}
          {countdown}
          {suffixTitel}
        </h5>
        <div className="progressBar">
          <div
            className="progress"
            style={{ width: `${progress * 100}%` }}
          />
        </div>
      </div>
    );
  }
}

ProgressBarWithCountDown.defaultProps = {
  duration: 10,
  onProgressEnd: null,
  intervalTimeout: 1000,
  onAttemptToFinishProgress: null,
  suffixTitel: ' überprüft und findet alle 3 Minuten statt!',
  titel: 'Die Rückmeldung aus dem Bundesrechenzentrum zu Ihrem Schriftsatz wird in ',
};

ProgressBarWithCountDown.propTypes = {
  titel: PropTypes.string,
  suffixTitel: PropTypes.string,
  duration: PropTypes.number,
  onProgressEnd: PropTypes.func,
  intervalTimeout: PropTypes.number,
  onAttemptToFinishProgress: PropTypes.func,
};

export default ProgressBarWithCountDown;
