import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../Spinner/Loading/LoadingSpinner';
import EmpfangenButton from '../Buttons/EmpfangenButton';
import getUrl from '../Rest/rest.utils';
import Rest from '../Rest';
import makeRestCall from '../Rest/makeRestCall';
import { OK } from '../Rest/http.status.codes';
import { TIMEOUT_TYPE } from '../Rest/fetchWithTimeout';
import { setErledigungEmpfangenJob } from '../Sessions/erledigung.empfangen.session';

/**
 * component
 */
class Empfangen extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleEmpfangenSchriftsatz = this.handleEmpfangenSchriftsatz.bind(this);
  }

  /**
   * empfangen schriftsatz
   * @return {undefined}
   */
  async handleEmpfangenSchriftsatz() { // eslint-disable-line
    const { onReload, onClick } = this.props;
    this.setState({ loading: true });

    await setErledigungEmpfangenJob(true);

    const url = getUrl(Rest.urls.erledigungEmpfangen);
    const result = await makeRestCall('GET', url, null, null, false, null, TIMEOUT_TYPE.EINGANG_ABHOLEN);
    if (result.status === OK && result.body && result.body > 0 && onReload) {
      onReload();
    }
    if (onClick) {
      onClick();
    }

    await setErledigungEmpfangenJob(false);

    this.setState({ loading: false });
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { className, title, disabled } = this.props;
    const { loading } = this.state;

    return (
      <>
        <div>
          {
            loading === true
            && <LoadingSpinner key="loading-spinner-get-button" />
          }
        </div>
        <EmpfangenButton
          title={title}
          id="send-button"
          key="send-button"
          className={className}
          disabled={loading || disabled}
          onClick={this.handleEmpfangenSchriftsatz}
        />
      </>
    );
  }
}

Empfangen.defaultProps = {
  className: '',
  onClick: null,
  disabled: false,
  onReload: null,
  title: 'Empfangen',
};

Empfangen.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onReload: PropTypes.func,
  className: PropTypes.string,
};

export default Empfangen;
