import React from 'react';
import PropTypes from 'prop-types';
import {
  arrayHasItem,
  isNumber,
  isString,
  subString,
} from '../../utils/general.utils';
import { VERALTET_TABLE_ITEM } from '../../constants/js/form.constants';
import { GB_BEGEHREN_UH_PREFIX_KURZ, GB_BEGEHREN_UH_PREFIX_LANG } from '../../constants/js/gb.constants';
import { schriftsatzBaseVeraltetBegehren } from '../../constants/js/tooltip.constaints';

/**
 * component
 */
class SimpleTableColumns extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.handleOnClickItem = this.handleOnClickItem.bind(this);
  }

  /**
   * on lick single item in this row
   * @param {Object} event event
   * @param {Object} row row
   * @param {number} idx index
   * @return {undefined}
   */
  handleOnClickItem(event, row, idx) {
    const { onClickItem, itemColIndex, onSelectItem } = this.props;
    if (onClickItem && itemColIndex && idx === itemColIndex) {
      event.stopPropagation();
      event.preventDefault();
      onClickItem(row);
    }
    if (onSelectItem) {
      onSelectItem(row);
    }
  }

  /**
   * get columns for a row
   * @param {Array} tableColumns tableColumns
   * @param {Object} row row
   * @param {number} idx idx
   * @param {number} page page
   * @param {number} pageSize pageSize
   * @return {Array} columns
   * @private
   */
  _getColsForRow(tableColumns, row, idx, page, pageSize) { // eslint-disable-line
    let cols = [];
    for (let i = 0; i < tableColumns.length; i += 1) {
      const headerColKey = tableColumns[i].dataKey;

      if (arrayHasItem(headerColKey)) {
        cols = this._typ1(headerColKey, row, cols, i, tableColumns);
      } else if (headerColKey.split('.').length > 1) {
        cols = this._typ2(headerColKey, row, cols, i, tableColumns);
      } else if (headerColKey === 'rowindex') {
        cols = this._typ3(idx, page, pageSize, cols, i, row);
      } else {
        cols = this._typ4(cols, i, tableColumns, row, headerColKey);
      }
    }

    return cols;
  }

  /**
   * Typ 1
   * @param {String} headerColKey headerColKey
   * @param {Object} row row
   * @param {Object} cols cols
   * @param {Number} i index
   * @param {Object} tableColumns tableColumns
   * @return {Array} columns
   * @private
   */
  _typ1(headerColKey, row, cols, i, tableColumns) {
    const arrValue = [];
    for (let a = 0; a < headerColKey.length; a += 1) {
      arrValue.push(row[headerColKey[a]]);
    }
    const valueStr = arrValue.join(' ');
    const value = this._subStringContent(valueStr);
    cols.push(
      <td // eslint-disable-line
        key={i}
        onClick={(e) => this.handleOnClickItem(e, row, i)}
        title={(isString(valueStr) || isNumber(valueStr)) ? valueStr : ''}
        className={`${tableColumns[i].className} ${row.veraltet ? 'veraltet-row' : ''}`}
      >
        {value}
      </td>,
    );
    return cols;
  }

  /**
   * Typ 2
   * @param {String} headerColKey headerColKey
   * @param {Object} row row
   * @param {Object} cols cols
   * @param {Number} i index
   * @param {Object} tableColumns tableColumns
   * @return {Array} columns
   * @private
   */
  _typ2(headerColKey, row, cols, i, tableColumns) {
    const objPath = headerColKey.split('.');
    let objValue = row;
    for (let o = 0; o < objPath.length; o += 1) {
      objValue = objValue[objPath[o]] ? objValue[objPath[o]] : '-';
    }
    const value = this._subStringContent(objValue);
    cols.push(
      <td
        key={i}
        title={(isString(objValue) || isNumber(objValue)) ? objValue : ''}
        className={`${tableColumns[i].className} ${row.veraltet ? 'veraltet-row' : ''}`}
      >
        {value}
      </td>,
    );
    return cols;
  }

  /**
   * Typ 3
   * @param {Number} idx index
   * @param {Number} page page
   * @param {Number} pageSize pageSize
   * @param {Array} cols colums
   * @param {Number} i index
   * @param {Object} row row
   * @return {Array} columns
   * @private
   */
  _typ3(idx, page, pageSize, cols, i, row) {
    let rowIndex = idx + 1;
    if (page > 0) {
      rowIndex = (page * pageSize) + idx + 1;
    }
    const value = this._subStringContent(rowIndex);
    cols.push(
      <td // eslint-disable-line
        key={i}
        className="nr"
        onClick={(e) => this.handleOnClickItem(e, row, i)}
        title={(isString(rowIndex) || isNumber(rowIndex)) ? rowIndex : ''}
      >
        {value}
      </td>,
    );
    return cols;
  }

  /**
   * Typ 4
   * @param {Array} cols colums
   * @param {Number} i index
   * @param {Object} tableColumns tableColumns
   * @param {Object} row row
   * @param {String} headerColKey headerColKey
   * @return {Array} columns
   * @private
   */
  _typ4(cols, i, tableColumns, row, headerColKey) {
    const { isWithBolder } = this.props;
    const rowElement = row[headerColKey];

    let title = (isString(rowElement) || isNumber(rowElement)) ? rowElement : '';
    if (title && row.isUrkundenhinterlegungsregister && title.toString().includes(GB_BEGEHREN_UH_PREFIX_KURZ)) {
      title = title.replace(GB_BEGEHREN_UH_PREFIX_KURZ, GB_BEGEHREN_UH_PREFIX_LANG);
    }

    const value = this._subStringContent(rowElement);
    let classVeraltet = '';
    if (value?.toString() === VERALTET_TABLE_ITEM) {
      classVeraltet = 'veraltet-row-index';
    } else if (row.veraltet) {
      classVeraltet = 'veraltet-row';
    }

    cols.push(
      <td // eslint-disable-line
        key={i}
        onClick={(e) => this.handleOnClickItem(e, row, i)}
        title={row.veraltet ? schriftsatzBaseVeraltetBegehren : title}
        className={`${tableColumns[i].className} ${classVeraltet} ${isWithBolder ? 'table-with-border' : ''} ${row.isHeader ? 'table-with-header' : ''} ${row.className || ''}`}
      >
        {value}
      </td>,
    );

    return cols;
  }

  /**
   * used substring method for table value
   * @param {String} value value
   * @return {*|string|*} updated value
   * @private
   */
  _subStringContent(value) {
    const { isSubstringContent, substringLength } = this.props;
    return (isSubstringContent && subString(value, substringLength)) || value;
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      idx,
      row,
      page,
      pageSize,
      tableColumns,
    } = this.props;

    return this._getColsForRow(tableColumns, row, idx, page, pageSize);
  }
}

SimpleTableColumns.defaultProps = {
  onClickItem: null,
  onSelectItem: null,
  itemColIndex: null,
  isWithBolder: false,
  isSubstringContent: null,
  substringLength: 999999,
};

SimpleTableColumns.propTypes = {
  onClickItem: PropTypes.func,
  onSelectItem: PropTypes.func,
  isWithBolder: PropTypes.bool,
  row: PropTypes.object.isRequired,
  itemColIndex: PropTypes.number,
  idx: PropTypes.number.isRequired,
  isSubstringContent: PropTypes.bool,
  page: PropTypes.number.isRequired,
  substringLength: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  tableColumns: PropTypes.array.isRequired,
};

export default SimpleTableColumns;
