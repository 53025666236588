import React from 'react';
import PropTypes from 'prop-types';

/**
 * AusgangSelect
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function AusgangSelect(props) {
  const {
    id,
    onClick,
    checked,
    disabled,
    isSelectAll,
    className,
    isUebertragungsfehler,
  } = props;

  let inputClass = 'form-check-input';
  if (isSelectAll) {
    inputClass = `${inputClass} selectInput`;
  }

  return (
    <div
      className="form-check-label checkbox-container check-table"
    >
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => {}}
        className={inputClass}
        aria-label="Ausgang auswählen Button"
        key={`input-${(id && id.toString()) || ''}`}
      />
      <span
        onClick={onClick}
        key={`span-${(id && id.toString()) || ''}`}
        className={`checkmark ${className} ${isUebertragungsfehler ? 'uebertragungsfehler-send-btn-blink' : ''}`}
      />
    </div>
  );
}

AusgangSelect.defaultProps = {
  id: null,
  className: '',
  disabled: true,
  isSelectAll: false,
  isUebertragungsfehler: false,
};

AusgangSelect.propTypes = {
  disabled: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  isUebertragungsfehler: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AusgangSelect;
