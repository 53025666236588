import React from 'react';
import PropTypes from 'prop-types';
import info from '../../assets/icons/mz-information.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function InformationIcon(props) {
  const { className, onClick } = props;
  return (
    <img // eslint-disable-line
      src={info}
      width={26}
      height={26}
      onClick={onClick}
      id="manz-plus-circle"
      alt="information icon"
      className={className}
      aria-label="information icon"
    />
  );
}

InformationIcon.defaultProps = {
  className: null,
  onClick: null,
};

InformationIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default InformationIcon;
