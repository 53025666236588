import React from 'react';
import PropType from 'prop-types';
import Select from '../ReactSelectWrapper';
import {
  SORT_ASC,
  SORT_DESC,
  SORT_DESC_TEXT,
} from '../../constants/js/sort.constants';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
class ResponsiveSimpleTableBodyMobil extends React.Component {
  /**
   * constructor
   * init stuff and things
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    const { tableColumns } = this.props;

    this.state = {
      sortOptions: this._getSortOptions(tableColumns),
    };

    this.handleChangeSort = this.handleChangeSort.bind(this);
  }

  /**
   * change sort parameter
   * @param {Object} option sort option
   * @return {undefined}
   */
  handleChangeSort(option) {
    const { tableColumns, onSort } = this.props;
    const optionValue = option.value.replace(SORT_ASC, '').replace(SORT_DESC, '');
    const tableCol = tableColumns.find((col) => col.sortColumn === optionValue);
    if (onSort && tableCol) {
      const isDesc = option.value.includes(SORT_DESC);
      onSort(tableCol, isDesc ? SORT_DESC : SORT_ASC);
    }
  }

  /**
   * get block data
   * @param {String} tableType table type
   * @param {Array} tableColumns tableColumns
   * @param {Object} data data
   * @param {number} tableIdx table index
   * @param {Function} onClick onClick
   * @return {JSX} component
   */
  _getBlockData(tableType, tableColumns, data, tableIdx, onClick) { // eslint-disable-line
    const { showResponseRow } = this.props;
    const rows = [];

    for (let idxCol = 0; idxCol < tableColumns.length; idxCol += 1) {
      const {
        label,
        action,
        dataKey,
        className,
        disableLabel,
      } = tableColumns[idxCol];

      let classNames = className;
      let row = '';

      // 1) datakey is array
      if (Array.isArray(dataKey)) {
        const actionInput = {};
        for (let i = 0; i < dataKey.length; i += 1) {
          const key = dataKey[i];

          // 1.1) datakey contains '.'
          if (key.split('.').length > 1) {
            let objValue = data;
            const objPath = key.split('.');
            for (let m = 0; m < objPath.length; m += 1) {
              objValue = objValue[objPath[m]] ? objValue[objPath[m]] : '-';
            }
            row += `${objValue} `;
          } // eslint-disable-line

          // 1.2) datakey does not contains '.'
          else {
            const value = data[key];
            if (action) {
              actionInput[key] = value;
            } else {
              row += (value && `${value} `) || '';
            }
          }
        }
        if (action) {
          row = action(actionInput);
        }
      } // eslint-disable-line

      // 2) datakey is not array
      else {
        row = data[dataKey];
        if ((dataKey === 'erstellt' || dataKey === 'aktenzeichen') && data.statusValue === 'NEU') {
          classNames = `${classNames} font-weight-bold`;
        }
        if (label && !disableLabel) {
          row = `${label}: ${row}`;
        }
      }

      if (data[dataKey] || (showResponseRow && row)) {
        rows.push(
          <div
            title={row || ''}
            key={`${tableIdx}-${idxCol}`}
            className={`${classNames || ''} entry`}
            onClick={() => {
              if (onClick) {
                onClick(data);
              }
            }}
          >
            {showResponseRow ? `${row} ` : row}
          </div>,
        );
      }
    }

    return (
      <div key={tableIdx.toString()} className="block">
        {rows}
      </div>
    );
  }

  /**
   * get sort options
   * @param {Array} tableColumns tableColumns
   * @return {Array} sort options
   */
  _getSortOptions(tableColumns) { // eslint-disable-line
    const sortOptions = [];
    for (let i = 0; i < tableColumns.length; i += 1) {
      const { label, sortColumn } = tableColumns[i];
      let lab = label;
      if (lab === 'Bereitgestellt' || lab === 'Erstellt') {
        lab = 'Datum';
      }
      if (sortColumn) {
        // is not empty option
        if (label) {
          sortOptions.push(
            {
              label: `Sortierung nach ${lab}${SORT_DESC_TEXT}`,
              value: `${sortColumn}${SORT_DESC}`,
            },
            {
              label: `Sortierung nach ${lab}`,
              value: sortColumn,
            },
          );
        }
      }
    }
    return sortOptions;
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      onClick,
      sortable,
      tableData,
      tableType,
      tableColumns,
    } = this.props;
    const { sortOptions } = this.state;

    const blocks = [];
    for (let tableIdx = 0; tableIdx < tableData.length; tableIdx += 1) {
      blocks.push(this._getBlockData(tableType, tableColumns, tableData[tableIdx], tableIdx, onClick));
    }

    const defaultSelectVal = sortOptions.filter((el) => el.value === `createDate${SORT_DESC}`)[0] ? sortOptions.filter((el) => el.value === `createDate${SORT_DESC}`)[0].value : '';

    return (
      <div className="responsive-table">
        <div className="row sticky-top pt-1 pb-2 bg-white">
          {sortable && (
            <div className="form-row col-12">
              <div className="col">
                <Select
                  isSearchable={false}
                  options={sortOptions}
                  ariaLabel="Auswählen"
                  defaultValue={defaultSelectVal}
                  onChange={this.handleChangeSort}
                />
              </div>
            </div>
          )}
        </div>
        <div className="py-2">
          {blocks}
        </div>
      </div>
    );
  }
}

ResponsiveSimpleTableBodyMobil.defaultProps = {
  onSort: null,
  onClick: null,
  sortable: true,
  tableData: null,
  tableType: null,
  tableColumns: null,
  showResponseRow: false,
};

ResponsiveSimpleTableBodyMobil.propTypes = {
  onSort: PropType.func,
  onClick: PropType.func,
  sortable: PropType.bool,
  tableData: PropType.array,
  tableType: PropType.string,
  tableColumns: PropType.array,
  showResponseRow: PropType.bool,
};

export default ResponsiveSimpleTableBodyMobil;
