import React from 'react';
import PropTypes from 'prop-types';
import { antwortenTooltip } from '../../constants/js/tooltip.constaints';

/**
 * send button component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function AntwortenButton(props) {
  const {
    id,
    onClick,
    disabled,
    className,
  } = props;

  const cName = ['btn', 'btn-secondary'];
  if (className) {
    cName.push(className);
  }

  const text = 'Antworten';

  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      disabled={disabled}
      aria-label="Antworten"
      title={antwortenTooltip}
      className={cName.join(' ')}
    >
      {text}
    </button>
  );
}

AntwortenButton.defaultProps = {
  id: null,
  onClick: null,
  disabled: false,
  className: null,
};

AntwortenButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default AntwortenButton;
