// https://github.com/apollographql/react-apollo/blob/master/src/shallowEqual.ts
// https://github.com/reactjs/react-redux/blob/master/src/utils/shallowEqual.js


/**
 * Shallow equal compare of two objects
 * @param {Object} objA Object A to compare
 * @param {Object} objB Object B to compare
 * @returns {boolean} true if objects are shallowly equal.
 */
export default function shallowEqual(objA, objB) {
  if (!objA || !objB) return true;
  if (objA === objB) return true;

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  // Test for A's keys different from B.
  const hasOwn = Object.prototype.hasOwnProperty;
  for (let i = 0; i < keysA.length; i += 1) {
    if (!hasOwn.call(objB, keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}
