import React from 'react';
import PropTypes from 'prop-types';
import { Popper } from 'react-popper';
import onClickOutside from 'react-onclickoutside';
import getUrl from '../Rest/rest.utils';
import makeRestCall from '../Rest/makeRestCall';
import PortalWrapper from '../Portal/PortalWrapper';
import { UPDATE_WHOLE_OBJECT } from '../../constants/js/form.constants';
import LoadingSpinnerInputField from '../Spinner/Loading/LoadingSpinnerInputField';
import { OK } from '../Rest/http.status.codes';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-oPadding.svg';
import { arrayHasItem } from '../../utils/general.utils';

/**
 * async select component
 * loads selectOptions based on the asyncSettings obj url and renders the select options
 * inside a react portal with popperjs
 */
class AsyncSelect extends React.PureComponent {
  /**
   * constructor - nothing special here
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loading: false,
      prevValue: null,
      cachedValue: null,
      showPopper: false,
      totalElements: null,
    };

    this.optionsRef = [];
    this.popperRef = null;
    this.inputTag = 'INPUT';
    this.optionWrapperTag = 'BUTTON';

    this.internal = null;
    this.mounted = false;

    /**
     * IMPORTANT! Do not remove this class from the button in the option list!
     * It is used to identify the clicks on select options. If the clicked element does not contain
     * this class the async select option dropdown will be closed
     * @type {string}
     */
    const { id } = props;

    this.ASYNC_INPUT_CLASS_CLICK_ID = `${id}-async-input-field`;
    this.ASYNC_OPTION_BTN_CLASS_CLICK_ID = `${id}-async-list-option`;

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocusLost = this.handleFocusLost.bind(this);
    this.handleOpenPopper = this.handleOpenPopper.bind(this);
    this.handleClosePopper = this.handleClosePopper.bind(this);
    this.handleSelectOption = this.handleSelectOption.bind(this);
    this.updateAdditionalFields = this.updateAdditionalFields.bind(this);
    this.handleToggleShowPopper = this.handleToggleShowPopper.bind(this);
    this.handleInputFieldKeyDown = this.handleInputFieldKeyDown.bind(this);
    this.handleSelectOptionKeyEvent = this.handleSelectOptionKeyEvent.bind(this);
  }

  /**
   * comp will mount
   * adds a global click listener to be able to distinguish between the clicked elements
   * therefore no portal backdrop is needed anymore
   * @returns {undefined}
   */
  componentWillMount() {
    if (document.getElementById('root')) {
      document.getElementById('root').addEventListener('mousedown', this.handleClick);
    }
  }

  /**
   * componentDidMount
   * @return {undefined}
   */
  componentDidMount() {
    const { asyncSettings, isDelayedAsync } = this.props;

    if (asyncSettings && asyncSettings.options) {
      this.setState({
        loading: false,
        options: asyncSettings.options,
        totalElements: asyncSettings.options.maxLength,
      });
    }

    if (isDelayedAsync) {
      this.mounted = true;
      clearInterval(this.internal);
      this.startInterval();
    }
  }

  /**
   * componentDidUpdate
   * @param {Object} prevProps prevProps
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    const { asyncSettings } = this.props;
    const { options } = this.state;
    // initialize options
    if (
      asyncSettings
      && asyncSettings.options
      && options
      && Array.isArray(options)
      && options.length === 0
      && this.props !== prevProps
    ) {
      this.setState({ options: asyncSettings.options }); // eslint-disable-line
    }
  }

  /**
   * comp will unmount
   * removes the global click listener
   * @returns {undefined}
   */
  componentWillUnmount() {
    const { isDelayedAsync } = this.props;

    if (document.getElementById('root')) {
      document.getElementById('root').removeEventListener('mousedown', this.handleClick);
    }

    if (isDelayedAsync) {
      this.mounted = false;
      clearInterval(this.interval);
    }
  }

  /**
   * starts the progress interval
   * @returns {undefined}
   */
  startInterval() {
    const { isDelayedAsync } = this.props;
    if (isDelayedAsync) {
      clearInterval(this.internal);
      this.setState(() => ({
        secondsRemaining: 2,
      }), () => {
        this.internal = setInterval(() => this.triggerAction(isDelayedAsync), 1100);
      });
    }
  }

  /**
   * trigger action
   * @param {Boolean} isDelayedAsync isDelayedAsync
   * @return {undefined}
   */
  triggerAction(isDelayedAsync) {
    if (isDelayedAsync) {
      const { secondsRemaining, cachedValue } = this.state;

      if (secondsRemaining > 0) {
        this.setState(() => ({ secondsRemaining: secondsRemaining - 1 }));
      }

      if (secondsRemaining <= 0) {
        this.setState(() => ({
          secondsRemaining: 0,
        }), async () => {
          this.startInterval();

          if (cachedValue) {
            // trigger action
            await this.restCallData(cachedValue);
            // reset cachedValue as null
            this.setState({ cachedValue: null });
          }
        });
      }
    }
  }

  /**
   * click listener handler
   * @param {Object} event js click obj
   * @returns {undefined}
   */
  handleClick(event) {
    let element = event.target;

    if (element.tagName !== this.optionWrapperTag && element.tagName !== this.inputTag) {
      element = element.closest(this.optionWrapperTag.toLowerCase());
    }

    const classList = (element && element.classList) ? element.classList : [];

    if (arrayHasItem(classList)) {
      const classFound = classList.some(this.ASYNC_INPUT_CLASS_CLICK_ID) || classList.some(this.ASYNC_OPTION_BTN_CLASS_CLICK_ID);
      if (!classFound) {
        this.handleFocusLost();
        this.handleClosePopper();
      }
    } else {
      this.handleFocusLost();
    }
  }

  /**
   * this function will be called if either the input field or the popper lost user focus
   * (e.g. user clicked somewhere else). if the user input contains an exact match of the
   * selectOptions, this option will be selected
   * @returns {undefined}
   */
  handleFocusLost() {
    const {
      meta,
      input,
      asyncSettings,
      onceUpdateAdditionField,
    } = this.props;

    if (meta.pristine === false) {
      const { valueKey } = asyncSettings;
      const { value } = input;

      const { options } = this.state;
      const selectedOption = options.find((opt) => (opt && opt[valueKey] === value));

      if (selectedOption) {
        let count = 0;

        /**
         * sanity check - it's possible that there are multiple values with the same label
         * i.e. Plz - Ort: 1210 - Wien, 1020 - Wien
         * if the user is in the ort input field and the input field looses focus by pressing TAB, it would be
         * wrong to update the plz if there is no exact match
         */
        for (let i = 0; i < options.length; i += 1) {
          if (options[i][valueKey] === selectedOption[valueKey]) {
            count += 1;
          }
        }

        if (count === 1 && (!onceUpdateAdditionField)) {
          this.updateAdditionalFields(selectedOption);
        }
      } else if (!selectedOption && asyncSettings.resetAdditionalFields) {
        this.updateAdditionalFields(null);
      }

      /**
       * makes it possible to reset the own input field value if no selection was made
       * probably only makes sense if additional fields should be updated
       * i.e if the user enters a kuerzel in the search form but decides not to select one
       */
      if (!selectedOption && asyncSettings.resetInputField && asyncSettings.updateAdditionalFields.action) {
        const { action } = asyncSettings.updateAdditionalFields;
        action(valueKey, '');
      }
    }
  }

  /**
   * hides the contextMenu when the user clicks outside of the div.
   * https://github.com/Pomax/react-onclickoutside
   * @returns {undefined}
   */
  handleClickOutside() {
    this.handleClosePopper();
  }


  /**
   * update values of additional form fields
   * @param {Object} selectedOption selectedOption
   * @returns {undefined}
   */
  updateAdditionalFields(selectedOption) {
    const { asyncSettings } = this.props;

    if (asyncSettings.updateAdditionalFields) {
      const { updateAdditionalFields } = asyncSettings;
      const valueKeys = updateAdditionalFields.valueKeys;
      const formNames = updateAdditionalFields.formNames;

      if (valueKeys.length !== formNames.length) {
        console.error('formNames and valueKeys must have the same length!'); // eslint-disable-line
      }

      for (let i = 0; i < formNames.length; i += 1) {
        const formKey = formNames[i];
        const dataKey = valueKeys[i];
        let formValue = null;
        if (selectedOption) {
          if (dataKey === UPDATE_WHOLE_OBJECT) {
            formValue = selectedOption;
          } else {
            formValue = selectedOption[dataKey];
          }
          updateAdditionalFields.action(formKey, formValue);
          if (updateAdditionalFields.interceptOption) {
            updateAdditionalFields.interceptOption(formValue);
          }
        } else {
          updateAdditionalFields.action(formKey, null);
          if (updateAdditionalFields.interceptOption) {
            updateAdditionalFields.interceptOption(null);
          }
        }
      }
    }
  }

  /**
   * toggle show popper
   * @return {undefined}
   */
  handleToggleShowPopper() {
    const { showPopper } = this.state;
    this.setState({ showPopper: !showPopper });
  }

  /**
   * close popper handler
   * @returns {undefined}
   */
  handleClosePopper() {
    this.setState(() => ({
      showPopper: false,
    }));
  }

  /**
   * opens the popper if there was data
   * @param {Object} e js click event obj
   * @returns {undefined}
   */
  handleOpenPopper(e) {
    const { options } = this.state;
    if (options && options.length) {
      this.setState(() => ({
        showPopper: true,
      }));
    }

    const value = e.target.value;
    if (value) {
      this.handleChange(e);
    }
  }

  /**
   * input field change handler -> requests new data
   * @param {Object} event js event
   * @returns {undefined}
   */
  async handleChange(event) {
    const { input, isDelayedAsync } = this.props;
    const value = event.target.value;

    input.onChange(value);

    if (isDelayedAsync) {
      this.setState({ cachedValue: value });
    } else {
      await this.restCallData(value);
    }
  }

  /**
   * restCallData
   * @param {String} value value
   * @return {Promise<void>} undefined
   */
  async restCallData(value) {
    const { asyncSettings } = this.props;
    const { prevValue } = this.state;

    let options = [];
    let totalElements = null;
    let showPopper = false;

    if (value && value.length > 0 && prevValue !== value) {
      const spinnerTimeout = setTimeout(() => this.setState(() => ({
        loading: true,
      })), 20);

      const res = await this.requestData(value);

      clearTimeout(spinnerTimeout);

      if (res.status === OK && res.body) {
        options = Array.isArray(res.body) ? res.body : res.body.content;
        totalElements = Array.isArray(res.body) ? null : res.body.totalElements;
        showPopper = true;
      }

      if (asyncSettings.options) {
        options = asyncSettings.options;
        totalElements = asyncSettings.options.length;
        showPopper = true;
      }

      if (arrayHasItem(options) && options[0].label) {
        let hasThisOption = false;
        for (let i = 0; i < options.length; i += 1) {
          if (options[i].label.toLowerCase().includes(value.toLowerCase())) {
            hasThisOption = true;
            break;
          }
        }
        showPopper = hasThisOption;
      }

      this.setState(() => ({
        options,
        showPopper,
        totalElements,
        loading: false,
        prevValue: value,
      }));
    }
  }

  /**
   * request data -> rest call
   * @param {String} search search query param
   * @returns {Promise} promise for await use
   */
  async requestData(search) {
    const { asyncSettings } = this.props;
    const { updateAdditionalFields } = asyncSettings;

    let queryParams = null;

    if (asyncSettings.pageable) {
      queryParams = asyncSettings.pageable;
      queryParams.search = search;
    } else {
      queryParams = { search };
    }

    if (asyncSettings.options) {
      return asyncSettings.options;
    }

    if (updateAdditionalFields?.interceptQueryParams) {
      updateAdditionalFields.interceptQueryParams(queryParams);
    }

    const compiledUrl = getUrl(asyncSettings.url, null, queryParams);

    return makeRestCall('GET', compiledUrl);
  }

  /**
   * key down handler for the input field
   * @param {Object} event js key down event
   * @returns {undefined}
   */
  handleInputFieldKeyDown(event) {
    const keyCode = event.which;
    if (keyCode === 27) {
      this.handleClosePopper();
    } else if ((keyCode === 38 || keyCode === 40) && this.optionsRef && this.optionsRef.length) {
      event.stopPropagation();
      event.preventDefault();
      this.optionsRef[0].ref.focus();
    } else if (keyCode === 9) {
      this.handleFocusLost();
    }
  }

  /**
   * handle select option key event handler
   * @param {Object} event js event obj
   * @param {number} index index of the option button of the optionsRef array
   * @param {Object} option select options
   * @returns {undefined}
   */
  handleSelectOptionKeyEvent(event, index, option) {
    const keyCode = event.which;
    if (keyCode !== 9) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (keyCode === 38 && index - 1 >= 0) {
      this.optionsRef[index - 1].ref.focus();
    } else if (keyCode === 40 && this.optionsRef.length > index + 1) {
      this.optionsRef[index + 1].ref.focus();
    } else if (keyCode === 13 || keyCode === 9) {
      this.handleSelectOption(option);
    }
  }

  /**
   * handler for select an option
   * @param {Object} selectedOption selectedOption
   * @returns {undefined}
   */
  handleSelectOption(selectedOption) {
    const { asyncSettings, input } = this.props;
    this.updateAdditionalFields(selectedOption);
    input.onChange(selectedOption[asyncSettings.valueKey]);
    this.handleClosePopper();
  }


  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      id,
      label,
      input,
      tooltip,
      disabled,
      ariaLabel,
      className,
      maxLength,
      placeholder,
      renderInPortal,
      onFormatLabel,
      onFormatTooltip,
      onFormatHeader,
      isErrorTextRight,
      showDropdownIcon,
      meta: {
        error,
        invalid,
        submitFailed,
      },
    } = this.props;

    const {
      loading,
      options,
      showPopper,
      totalElements,
    } = this.state;

    let classNames = [];
    if (className) {
      classNames = className.split(' ');
    }
    classNames.push('context');

    if (error && submitFailed) {
      classNames.push('form-validation-error');
    }

    const positionFixed = false;

    this.optionsRef = [];
    const optionsListItems = options.filter((v) => !(v.aktiv === false)).map((option, index) => {
      let optionLabel = option.label;
      if (onFormatLabel) {
        optionLabel = onFormatLabel(option);
      }

      let popperTooltip = '';
      if (onFormatTooltip) {
        popperTooltip = onFormatTooltip(option);
      }

      const key = `${option.label}-${index.toString()}`;
      return (
        <button
          key={key}
          type="button"
          title={popperTooltip}
          aria-label={`${optionLabel} Button`}
          onClick={() => this.handleSelectOption(option)}
          className={`${this.ASYNC_OPTION_BTN_CLASS_CLICK_ID} options`}
          onKeyDown={(e) => this.handleSelectOptionKeyEvent(e, index, option)}
          ref={(ref) => {
            if (ref !== null) {
              this.optionsRef.push({ key, ref });
            }
          }}
        >
          {optionLabel}
        </button>
      );
    });

    if (optionsListItems && optionsListItems.length > 0 && onFormatHeader) {
      const header = onFormatHeader('');
      optionsListItems.unshift(
        <div
          key="options-list-item-header"
          className="optionsHeader"
        >
          {header}
        </div>,
      );

      if (totalElements && totalElements > 50) {
        optionsListItems.push(
          <div
            key="popper-info"
            className="optionsInfo"
          >
            {`Die ersten 50 von ${totalElements} gefundenen Einträge werden angezeigt!`}
          </div>,
        );
      }
    }

    let inputFieldWidth = 0;
    const inputElem = document.getElementById(id);
    if (inputElem) {
      inputFieldWidth = inputElem.clientWidth;
    }

    let popper = (
      <Popper
        id="popper"
        placement="bottom-start"
        key="async-select-popper"
        positionFixed={positionFixed}
        referenceElement={this.popperRef}
      >
        {({ ref, style, placement }) => (
          <div
            ref={ref}
            data-placement={placement}
            style={{ ...style, minWidth: inputFieldWidth }}
            className="portal-content shadow-sm select-wrapper async-select"
          >
            <div className="selectOptionContainer">
              {optionsListItems}
            </div>
          </div>
        )}
      </Popper>
    );

    if (renderInPortal) {
      popper = (
        <PortalWrapper
          key="portal-content"
        >
          {popper}
        </PortalWrapper>
      );
    }

    const dropDownIcon = showDropdownIcon ? <div className="react-select-icon-custom dropDownIcon" onClick={this.handleToggleShowPopper} /> : '';
    const spinnerClass = `${showDropdownIcon ? 'spacingRight' : ''} spinner`;

    const spinner = loading
      && (
        <div className={spinnerClass}>
          <LoadingSpinnerInputField />
        </div>
      );

    return (
      <div className={classNames.join(' ')}>
        <div className="loadingSpinnerWrapper" title={tooltip}>
          <input
            {...input}
            id={id}
            type="text"
            title={tooltip}
            autoComplete="off"
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder}
            aria-label={ariaLabel || label}
            onChange={this.handleChange}
            onClick={this.handleOpenPopper}
            onKeyDown={this.handleInputFieldKeyDown}
            className={`${this.ASYNC_OPTION_BTN_CLASS_CLICK_ID} input-text form-control form-control-sm ${disabled ? 'manz-disabled' : ''}`}
            ref={(ref) => {
              this.popperRef = ref;
            }}
          />
          {spinner}
          {dropDownIcon}
        </div>
        {
          showPopper
          && popper
        }
        {
          invalid && submitFailed && error && (
            <span className={`error ${isErrorTextRight ? 'form-error-msg-right' : ''}`}>
              <InfoSVG title="" />
              {' '}
              {error}
            </span>
          )
        }
      </div>
    );
  }
}

AsyncSelect.defaultProps = {
  id: null,
  label: null,
  meta: null,
  input: null,
  tooltip: null,
  className: '',
  disabled: false,
  ariaLabel: null,
  placeholder: null,
  asyncSettings: null,
  maxLength: 99999,
  onFormatLabel: null,
  renderInPortal: false,
  isDelayedAsync: false,
  onFormatTooltip: null,
  onFormatHeader: null,
  isErrorTextRight: false,
  showDropdownIcon: false,
  onceUpdateAdditionField: false,
};

AsyncSelect.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  renderInPortal: PropTypes.bool,
  onFormatLabel: PropTypes.func,
  maxLength: PropTypes.number,
  isDelayedAsync: PropTypes.bool,
  onFormatTooltip: PropTypes.func,
  isErrorTextRight: PropTypes.bool,
  onFormatHeader: PropTypes.func,
  showDropdownIcon: PropTypes.bool,
  onceUpdateAdditionField: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  asyncSettings: PropTypes.shape({
    valueKey: PropTypes.string, // obj key to use as value from the REST response
    resetAdditionalFields: PropTypes.bool, // true if the additional fields should be set to null if no option was selected
    updateAdditionalFields: PropTypes.shape({
      action: PropTypes.func, // function that will be executed to update additional form fields
      interceptOption: PropTypes.func, // function that will be executed to update additional form fields
      formNames: PropTypes.array, // form names (= input field name) that should be updated
      valueKeys: PropTypes.array, // obj key to use as value from the REST response for an additional input field to update
      interceptQueryParams: PropTypes.func, // add new query params
    }),
    url: PropTypes.string, // REST url to load data from
    options: PropTypes.array, // select options
    pageable: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    resetInputField: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  }),
};

export default onClickOutside(AsyncSelect);
