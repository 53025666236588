const ALL_LETTERS = 'a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſƒǺǻǼǽǾǿȘșȚțẀẁẂẃẄẅỲỳ';

export const VALID_REGEX = {
  literal: '^[a-z]{1,3}$',
  vertretungsart: '^[A-Z]*$',
  iban: '^[0-9A-Z]{5,34}$',
  fbFnr: '^[0-9]{1,6} [a-z]$',
  bicswift: '^[0-9A-Z]{8,11}$',
  fbGrossZiffer: '^[0-9A-Z]{1,7}$',
  personenkennung: '^[A-Z]{1,3}$',
  pruefsummer: '^[0-9A-Fa-f]{2}$',
  fbPersonFNR: '^[0-9]{1,6} [a-z]$',
  currency: '^[0-9]+(.[0-9]{1,2})?$',
  advmCode: '^([a-zA-Z]|JB)[0-9]+$',
  percent: '^[0-9]{1,2}(.[0-9]{1,2})?$',
  zustaendigkeitsPLZ: '^[1-9][0-9]{3}$',
  firmenbuchNummer: '^[a-z0-9]{1,7}$',
  vorgangsnummer: '^[0-9]{12}[_][0-9]{6}$',
  justizboxUploadFilename: '^[a-zA-Z0-9-_.]*$',
  vorgemerktenEigentuemer: '^[a-z]{1,3}(,[a-z]{1,3})*$',
  vj: '[a-zA-Z0-9äöüßÄÖÜ .(+!;*)-/%&_?:§="°@,#\'$^]',
  geschaeftsfallNummer: '^[0-9]{1,6}/[0-9]{4}/[0-9]{2}$',
  grundstueck: '^\\.?[1-9][0-9]{0,4}(/[1-9][0-9]{0,4})?$',
  fbPersonPNR: '^([ ]{0,2}[A-Z]{1,3})|([A-Z]{2}[ ]{0,1})$',
  rangordnungsCode: '^([2-9A-HJ-NP-Z]{4}-){5}[2-9A-HJ-NP-Z]{3}$',
  urkundenKapitel: `[0-9 ${ALL_LETTERS}'-/,;:§€$'.()\\[\\]{}?~&"^@\\\\]`,
  thb: `[0-9${ALL_LETTERS} .'-/%!,;:=§€$_()\\[\\]{}+*-?<>#~&"|^@\\\\]`,
  gb: '[0-9a-zA-ZäöüßÄÖÜ /%!,;:=§€$_()\\[\\]{}+-?<>#~&"|^@\\n\\r\\t\\\\]',
  gbEintragungZusatz: `[0-9 ${ALL_LETTERS}.\\'-/,;:§€$()\\[\\]{}?~&"|^@\\n\\r\\t\\\\]`,
  gbEintragungstext: `[0-9 ${ALL_LETTERS}.'\\- /,;:§€$'\\.\\(\\)\\[\\]\\{\\}\\?~&\\"\\^@\\\\]`,
  vormerkungsHinweis: '^[0-9a-zA-ZäöüßÄÖÜ /%!,;:=§€$_\'.()\\[\\]{}+*-?<>#~&"|^@\\\\]+$',
  email: `^[0-9${ALL_LETTERS}._%+-]+@[0-9${ALL_LETTERS}.-]+\\.[0-9${ALL_LETTERS}]{2,4}$`,
  gbToken: '[0-9a-zA-ZäöüßÄÖÜ /%!,;:=§€$_\'\\.\\(\\)\\[\\]\\{\\}\\+\\*\\-\\?<>#~&\\"\\|\\^@\\\\]',
  gbDiakritischText: `[0-9 ${ALL_LETTERS} .'\\- /%!,;:=§€$_'\\.\\(\\)\\[\\]\\{\\}\\+\\*\\-\\?<>#~&\\"\\|\\^@\\\\]`,
  sta: `[0-9 ${ALL_LETTERS}!$&"(),;\\[%\\]{}*+-.:=?@^~¡¢£¤¥¦§¨©ª«¬­®¯°±²³µ¶·¸¹º»¼½¾¿×÷€™<>\\'_/#|]`,
  gbStrukturierterText: `[0-9 ${ALL_LETTERS}.'\\- /%!,;:=§€$_'\\.\\(\\)\\[\\]\\{\\}\\+\\*\\-\\?<>#~&\\"\\|\\^@\\n\\r\\t\\\\]`,
  fbStrukturierterText: `[0-9 ${ALL_LETTERS}!$&"(),;\\[%\\]{}*+-.:=?@^\`~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷€™<>\\'/#|]`,
};
