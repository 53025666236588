export const GESETZ_25a_GGG = 'https://rdb.manz.at/document/ris.n.NOR40260995';
export const GESETZ_25_4_GGG = 'https://rdb.manz.at/document/ris.n.NOR40243697';
export const GESETZ_1Z1_GGG = 'https://rdb.manz.at/document/ris.n.NOR40243698';
export const GESETZ_1Z2_GGG = 'https://rdb.manz.at/document/ris.n.NOR40243698';
export const GESETZ_53_GBG = 'https://rdb.manz.at/document/ris.n.NOR40138274';
export const GESETZ_53_WFG = 'https://rdb.manz.at/document/ris.n.NOR40023402';
export const GESETZ_42_WSG = 'https://rdb.manz.at/document/ris.n.NOR12149477';
export const GESETZ_15_3_AGRVG = 'https://rdb.manz.at/document/ris.n.NOR40007761';
export const GESETZ_34_BBG = 'https://rdb.manz.at/document/ris.n.NOR30002140?source=726462233230323330333033237269732e632e4247424c5f4f535f32303030313232395f315f3134322352534c2334313937323637383032';
export const GESETZ_NEU_FOEG = 'https://rdb.manz.at/document/ris.n.NOR30002093';
export const GESETZ_6B_3_GUG = 'https://rdb.manz.at/document/ris.c.BGBl__I_Nr__91_2024';
export const GESETZ_12_ERV_2021_2 = 'https://rdb.manz.at/document/ris.n.NOR40240405';
