import React from 'react';
import qs from 'qs';
import getUrl from '../common/Rest/rest.utils';
import makeRestCall from '../common/Rest/makeRestCall';
import SCHRIFTSATZ_STATUS from '../constants/schriftsatz.status';
import {
  arrayHasItem,
  emptyArray,
  equalsArrays,
  jsonToArray,
} from './general.utils';
import {
  CREATED,
  EXPECTATION_FAILED,
  NO_CONTENT,
  OK,
} from '../common/Rest/http.status.codes';
import WORKFLOW_STATUS from '../constants/schriftsatz.workflowStatus.json';
import { SELECT_ALL_OPTION } from '../common/ReactSelectWrapper/ReactSelect.constants';
import BEGEHREN_ART from '../constants/gb.begehren.art.json';
import ConfirmationModal, { createSimpleConfirmationModal } from '../common/Modal/ConfirmationModal';
import REST from '../common/Rest';
import { TIMEOUT_TYPE } from '../common/Rest/fetchWithTimeout';
import PATH from '../constants/path.json';
import { handleRestError } from '../common/Rest/RestError.utils';
import getFormattedMessage from '../common/FormatMessage/formattedMessage';
import modalFactory from '../common/Modal/modalFactory';
import { INHALT_ART_BERECHTIGUNG } from '../constants/js/inhalt.art.berechtigung';
import GotoDokumentePageModal from '../common/Modal/GotoDokumentePageModal';

/**
 * convert Aktenzeichen of Schriftsatz and show it in Schriftsatz search result list
 * @param {Object} schriftsatz schriftsatz
 * @returns {string} aktenzeichen
 */
export function buildAktenzeichenToDisplay(schriftsatz) {
  return schriftsatz.aktenzeichen;
}

/**
 * format schrifsatz search form values
 * @param {Object} formValues formValues
 * @return {Object} updated form values
 */
export function formatSchriftsatzSearchFormValues(formValues) {
  const retval = {
    ...formValues,
  };

  if (formValues.geschaeftszahl) {
    delete retval.geschaeftszahl;
    retval.geschaeftszahl = formValues.geschaeftszahl.abteilungsNummer + ' ' // eslint-disable-line
      + formValues.geschaeftszahl.gattung + ' '
      + formValues.geschaeftszahl.laufendeNummer + '/'
      + formValues.geschaeftszahl.jahr + ' '
      + formValues.geschaeftszahl.pruefKennzeichen;
  }

  if (retval.kontaktId === null) {
    delete retval.kontaktId;
  }

  if (retval.kuerzel) {
    delete retval.kuerzel;
  }

  if (retval.gerichtId) {
    retval.gericht = retval.gerichtId;
    delete retval.gerichtId;
  }

  if (arrayHasItem(formValues?.schriftsatzInterneInfos)) {
    retval.schriftsatzInterneInfos = formValues.schriftsatzInterneInfos.map((v) => v.value);
  } else {
    delete retval.schriftsatzInterneInfos;
  }

  return retval;
}

/**
 * convert schriftsatz status to string
 * @param {String} status status
 * @return {string} schriftsatz status
 */
export function convertSchriftsatzStatus(status) {
  return ` - [${status}]`;
}

/**
 * returns a rest client object for fetching schriftsatz templates
 * @returns {Object} rest client config obj
 */
export function getSchriftsatzTemplateRestClientObj() {
  return {
    urlTemplate: REST.urls.schriftsaetzeTemplate,
    disabled: (params) => params.schriftsatzId !== undefined,
    initialParameters: (props) => {
      const q = qs.parse(props.location.search.slice(1));
      return {
        inhaltArt: props.inhaltArt,
        schriftsatzId: q.schriftsatzId,
      };
    },
  };
}

/**
 * returns a rest client object for fetching a schriftsatz by id
 * @returns {Object} rest client config obj
 */
export function getSchriftsatzByIdRestClientObj() {
  return {
    urlTemplate: REST.urls.schriftsaetzeById,
    disabled: (params) => params.schriftsatzId === undefined,
    initialParameters: (props) => {
      const q = qs.parse(props.location.search.slice(1));
      return {
        schriftsatzId: q.schriftsatzId,
      };
    },
  };
}

/**
 * shorthand to avoid getDerivedStateFromProps implementation for every
 * schriftsatz -> reduce duplicate code
 * @param {Object} props component props
 * @param {Object} state component state
 * @returns {Object} updated state
 */
export function getDerivedStateSchriftsatzHelper(props, state) {
  const { schriftsatzById, template } = props;
  const { isNew, schriftsatz, status } = state;

  // -> new schriftsatz not saved
  if (isNew && template !== schriftsatz) {
    return {
      schriftsatz: template,
      status: template && convertSchriftsatzStatus(SCHRIFTSATZ_STATUS[template.status].label),
      globalDisabled: template && !template.editierbar,
    };
  }

  // -> existing schriftsatz
  if (!isNew && schriftsatz) {
    return {
      schriftsatz,
      status: schriftsatz && status,
      globalDisabled: schriftsatz && !schriftsatz.editierbar,
    };
  }

  if (!isNew && !schriftsatz) {
    return {
      schriftsatz: schriftsatzById,
      status: schriftsatzById && convertSchriftsatzStatus(SCHRIFTSATZ_STATUS[schriftsatzById.status].label),
      globalDisabled: schriftsatzById && !schriftsatzById.editierbar,
    };
  }

  return null;
}

/**
 * save or update schriftsatz helper function
 * @param {Object} schriftsatz schriftsatz to save or update
 * @param {Object} history react router history obj
 * @param {String} pathname schriftsatz type
 * @returns {Object} null or saved/updated schriftsatz
 */
export async function saveOrUpdateSchriftsatz(schriftsatz, history, pathname) {
  let url = getUrl(REST.urls.schriftsaetze, null);
  let method = 'POST';
  let successStatus = CREATED;

  if (schriftsatz.aktid) {
    if (schriftsatz.aktid === null) {
      delete schriftsatz.aktid; // eslint-disable-line
    } else {
      schriftsatz.aktid = parseInt(schriftsatz.aktid, 10); // eslint-disable-line
    }
  }

  if (schriftsatz?.id) {
    url = getUrl(REST.urls.schriftsaetzeById, { schriftsatzId: schriftsatz?.id });
    method = 'PUT';
    successStatus = OK;
  }

  const result = await makeRestCall(method, url, schriftsatz);

  if (result.status === successStatus) {
    if (successStatus === CREATED) {
      history.push({
        pathname: `/schriftsatz/${pathname.toLowerCase()}`,
        search: `?schriftsatzId=${result.body?.id}`,
      });
    }
    return {
      isNew: false,
      schriftsatz: result.body,
    };
  }

  if (method === 'POST') {
    return await _handleUebertragungsfehler(schriftsatz, history, pathname, result);
  }

  return null;
}

/**
 * _handleUebertragungsfehler
 * @param {Object} schriftsatz schriftsatz
 * @param {Object} history react router history obj
 * @param {String} pathname schriftsatz type
 * @param {Object} result result
 * @return {Promise<void>} undefined
 */
async function _handleUebertragungsfehler(schriftsatz, history, pathname, result) { // eslint-disable-line
  if (result.status === EXPECTATION_FAILED || result.body?.errorDetailCode?.id === 'schriftsatz.uebertragungsfehler.exist') {
    const schriftsatzIdByUebertragungsfehler = result.body?.errorDetailCode?.params?.schriftsatzId;
    const aktNameByUebertragungsfehler = result.body?.errorDetailCode?.params?.aktName;

    if (schriftsatzIdByUebertragungsfehler) {
      const body = (
        <div className="uebertragungsfehler-model">

          <div>
            {`Es gibt einen Schriftsatz mit dem Aktenzeichen "${aktNameByUebertragungsfehler}", `}
            <span className="font-weight-bold">
              der noch nicht übermittelt wurde.
            </span>
          </div>

          <div>
            Um Doppelübertragungen zu vermeiden, versenden Sie den vorhandenen Schriftsatz.
          </div>

          <div>
            Möchten Sie den vorhandenen Schriftsatz übermitteln oder einen neuen erfassen?
          </div>

        </div>
      );

      const confirm = await createSimpleConfirmationModal(
        'achtung.doppelte',
        body,
        null,
        true,
        'Vorhandenen Schriftsatz senden',
        'Neuen Schriftdatz erfassen',
      );


      // Option 1: send Uebertragungsfehler Schriftsatz
      if (confirm) {
        const checkedZpo112WithErvBox = await checkZPO112WithErvBox(schriftsatzIdByUebertragungsfehler);

        // TODO fuer aktuelle Loesung brauchen wir diese Funktion nicht mehr, weil wir diese Validierung einfach bei BRZ lassen, siehe ERVSW-3632.
        const ervboxOutdatedList = await getErvboxOutdatedDokumenteList(schriftsatzIdByUebertragungsfehler);

        if (!checkedZpo112WithErvBox) {
          openZPO112WithErvBoxHinweis();
        } else if (arrayHasItem(ervboxOutdatedList)) {
          openCheckErvboxOutdatedListHinweis(ervboxOutdatedList, schriftsatzIdByUebertragungsfehler);
        } else {
          await _sendUebertragungsfehlerSchriftsatz(schriftsatzIdByUebertragungsfehler);
        }
      } // eslint-disable-line


      // Option 2: create a new Schriftsatz with the same name of Uebertragungsfehler Schriftsatz
      else {
        return await _createNewSchriftsatzUsedSameNameOfUebertragungsfehlerSchriftsatz(schriftsatz, history, pathname);
      }
    }
  }

  return null;
}

/**
 * _sendUebertragungsfehlerSchriftsatz
 * @param {String} schriftsatzIdByUebertragungsfehler schriftsatzIdByUebertragungsfehler
 * @returns {Promise<void>} undefined
 * @private
 */
async function _sendUebertragungsfehlerSchriftsatz(schriftsatzIdByUebertragungsfehler) { // eslint-disable-line
  const url = getUrl(REST.urls.schriftsaetzeTransfer, { schriftsatzId: schriftsatzIdByUebertragungsfehler });

  const result = await makeRestCall('POST', url, null, null, true, null, TIMEOUT_TYPE.SENDEN);

  if (result.status === EXPECTATION_FAILED) {
    handleRestError(url, result.status, result);
  }

  await createSimpleConfirmationModal('hinweis.header', 'schriftsatz.transfer.uebertragungsfehler.schriftsatz.confirm');
}

/**
 * _createNewSchriftsatzUsedSameNameOfUebertragungsfehlerSchriftsatz
 * @param {Object} schriftsatz schriftsatz
 * @param {Object} history react router history obj
 * @param {String} pathname schriftsatz type
 * @returns {Promise<{schriftsatz, isNew: boolean}>} schriftsatz object
 * @private
 */
async function _createNewSchriftsatzUsedSameNameOfUebertragungsfehlerSchriftsatz(schriftsatz, history, pathname) { // eslint-disable-line
  const url = getUrl(REST.urls.schriftsaetzeById, { schriftsatzId: schriftsatz?.id }, { ignoreUebertragungsfehlerConfirmed: true });

  const createdResult = await makeRestCall('POST', url, schriftsatz);

  if (createdResult.status === CREATED) {
    history.push({
      pathname: `/schriftsatz/${pathname.toLowerCase()}`,
      search: `?schriftsatzId=${createdResult.body?.id}`,
    });

    return {
      isNew: false,
      schriftsatz: createdResult.body,
    };
  }

  return null;
}

/**
 * delete a schriftsatz
 * @param {Object} history react router history obj
 * @param {number} id schriftsatzId to delete
 * @param {Object} schriftsatzSearchParams schriftsatzSearchParams
 * @returns {undefined}
 */
export async function deleteSchriftsatz(history, id, schriftsatzSearchParams) {
  const url = getUrl(REST.urls.schriftsaetzeById, { schriftsatzId: id });
  const result = await makeRestCall('DELETE', url);
  if (result.status === NO_CONTENT) {
    if (schriftsatzSearchParams) {
      history.push({
        pathname: `${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}${PATH.ERGEBNIS.value}`,
        search: schriftsatzSearchParams,
      });
    } else {
      history.push('/');
    }
    return true;
  }
  return false;
}

/**
 * directly get schriftsatz and without withRestClient
 * @param {Object} schriftsatz schriftsatz
 * @return {Promise<*>} schriftsatz
 */
export async function directlyGetSchriftsatz(schriftsatz) {
  if (schriftsatz) {
    const url = getUrl(REST.urls.schriftsaetzeById, { schriftsatzId: schriftsatz?.id });
    const result = await makeRestCall('GET', url);
    if (result.status === OK) {
      return result.body;
    }
  }
  return null;
}

/**
 * double delete check
 * @param {String} schriftsatzStatus schriftsatzStatus
 * @return {*|boolean} boolean
 */
export function isDoubleConfirmationRequiredBySchriftsatz(schriftsatzStatus) {
  return schriftsatzStatus && (
    schriftsatzStatus === ' - [Eingebracht]'
    || schriftsatzStatus === ' - [Gesendet]'
    || schriftsatzStatus === SCHRIFTSATZ_STATUS.EINGEBRACHT.value
    || schriftsatzStatus === SCHRIFTSATZ_STATUS.GESENDET.value
  );
}

/**
 * clear schriftsatz by submit
 * @param {Object} schriftsatz schriftsatz
 * @param {Object} basisdaten basisdaten
 * @returns {Object} schrifsatz updated schriftsatz
 */
export function clearSchriftsatzBySubmit(schriftsatz, basisdaten) {
  const retval = {
    ...schriftsatz,
  };

  retval.einbringerId = basisdaten.einbringerId;
  retval.aktname = basisdaten.aktname;

  if (basisdaten.aktid) {
    retval.aktid = basisdaten.aktid;
  } else {
    delete retval.aktid;
  }

  retval.inhalt = {
    '@type': retval.inhalt['@type'],
    ...basisdaten.inhalt,
  };

  delete retval.inhalt.name;

  if (basisdaten.uestEmpfaenger) {
    retval.uestEmpfaenger = basisdaten.uestEmpfaenger;
  }

  if (schriftsatz?.schriftsatzDetail && emptyArray(schriftsatz?.schriftsatzDetail?.schriftsatzInterneInfos)) {
    delete retval.schriftsatzDetail;
  }

  return retval;
}

/**
 * get the heading for the schriftsatz page
 * @param {String} prefix some prefix for the heading text
 * @param {Object} schriftsatz the schriftsatz object
 * @param {String} status the schriftsatz status
 * @returns {string} complete heading
 */
export function getSchriftsatzHeading(prefix, schriftsatz, status) {
  return `${prefix}: ${(schriftsatz?.aktname) || 'Neuer Schriftsatz'} ${status}`;
}

/**
 * set einbringer for form
 * @param {Object} form form object
 * @param {Array} einbringerOptions einbringer select options
 * @returns {Object} updated form
 */
export function setEinbringer(form, einbringerOptions) {
  if (arrayHasItem(einbringerOptions)) {
    form.einbringerId = einbringerOptions.find((einbringer) => einbringer.standardEinbringer === true)?.value || einbringerOptions[0].value;
  }
  return form;
}

/**
 * disable Prüfen tab
 * @param {Object} schriftsatz schriftsatz
 * @return {boolean} disable ?
 */
export function disableValidate(schriftsatz) {
  return schriftsatz && (!schriftsatz.editierbar || schriftsatz.status === SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value);
}

/**
 * parse schriftsatz search initial parameters by type (schriftsatz oder erledigung)
 * @param {Object} props props
 * @param {String} type schriftvekehr oder erledigung
 * @return {Object} initial parameters
 */
export function parseSchriftsatzSearchInitialParametersByType(props, type) {
  return formatPagingParameters(qs.parse(props.location.search.slice(1)), type, null);
}

/**
 * validate if schriftsatz search parameters are not initialed
 * @param {Object} schriftsatzSearchParams schriftsatzSearchParams
 * @return {boolean} is initialed ?
 */
export function isNotInitialedSchriftsatzSearchParameters(schriftsatzSearchParams) {
  return schriftsatzSearchParams ? (!schriftsatzSearchParams.page || !schriftsatzSearchParams.size || !schriftsatzSearchParams.sort) : true;
}

/**
 * format paging parameters by type (aus- oder eingang)
 * @param {Object} payload payload
 * @param {String} type type (aus- oder eingang)
 * @param {String} template paging template
 * @return {String} formated pathUrl
 */
export function formatPagingParameters(payload, type, template) {
  const retval = {
    ...payload,
  };
  const page = retval[`${type}page`];
  const size = retval[`${type}size`];
  const sort = retval[`${type}sort`];

  delete retval.ausgangpage;
  delete retval.ausgangsize;
  delete retval.ausgangsort;
  delete retval.eingangpage;
  delete retval.eingangsize;
  delete retval.eingangsort;

  retval.page = page;
  retval.size = size;
  retval.sort = sort;
  if (template) {
    retval.template = template;
  }
  return retval;
}

/**
 * show or not show schriftsatz search result by schriftsatz type (ausgang or eingang)
 * @param {Object} location location
 * @param {String} type ausgang or eingang
 * @return {boolean} show or not show
 */
export function showSchriftsatzSearchResults(location, type) {
  return location.search.includes(`${type}=true`);
}

/**
 * validate if a schriftsatz is sendable
 * @param {Object} schriftsatz schriftsatz
 * @return {*|boolean} is sendable ?
 */
export function isSendable(schriftsatz) {
  return (schriftsatz?.status === SCHRIFTSATZ_STATUS.GEPRUEFT.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value)
    && schriftsatz?.hinverkehrAktiv !== false;
}

/**
 * create tooltip by schriftsatz status
 * @param {String} status status
 * @return {String} tooltip
 * @private
 */
export function createTooltipBySchriftsatzStatus(status) {
  let retval = '';
  if (status === SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value) {
    retval = 'Bei der Übermittlung sind Verbindungsprobleme aufgetreten. Kontrollieren Sie Ihre Internetverbindung und versenden Sie den Schiftsatz mit "Ausgang - Senden" erneut. Erstellen oder kopieren Sie den Schriftsatz nicht - dies kann zu Doppelübertragungen führen!';
  }
  if (status === SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value) {
    retval = 'Bei der Übermittlung sind Fehler aufgetreten – öffnen Sie den Schriftsatz und gehen Sie auf „Protokoll & Drucken“. Im Protokoll können Sie nachlesen welche Fehler aufgetreten sind. Verbessern Sie diese – wählen Sie danach „Prüfen“ und übermitteln  Sie den Schriftsatz erneut mit „Senden“.';
  }
  return retval;
}

/**
 * is verbessert schriftsatz ?
 * @param {Object} schriftsatz schriftsatz
 * @return {*|boolean} yes or no
 */
export function isVerbessertSchriftsatz(schriftsatz) {
  return schriftsatz?.workflowStatus === WORKFLOW_STATUS.VERBESSERUNG;
}

/**
 * is verbessert schriftsatz ?
 * @param {Object} schriftsatz schriftsatz
 * @return {*|boolean} yes or no
 */
export function isEingebrachtSchriftsatz(schriftsatz) {
  return schriftsatz?.status === SCHRIFTSATZ_STATUS.EINGEBRACHT.value;
}

/**
 * isCopyable
 * @param {Object} schriftsatz schriftsatz
 * @return {*|boolean} yes or no
 */
export function isCopyableInGrundbuchAntragsgruppe(schriftsatz) {
  return schriftsatz?.status === SCHRIFTSATZ_STATUS.IN_ARBEIT.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.FEHLER.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.GEPRUEFT.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value;
}

/**
 * isAskableGrundbuchAbfragen
 * @param {Object} schriftsatz schriftsatz
 * @return {*|boolean} yes or no
 */
export function isAskableGrundbuchAuszug(schriftsatz) {
  return schriftsatz?.status === SCHRIFTSATZ_STATUS.IN_ARBEIT.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.FEHLER.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.GEPRUEFT.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value;
}

/**
 * isGetableJustizboxUploadPercent
 * @param {Object} schriftsatz schriftsatz
 * @return {boolean} yes or no
 */
export function isGetableJustizboxUploadPercent(schriftsatz) {
  return schriftsatz?.status === SCHRIFTSATZ_STATUS.IN_ARBEIT.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.FEHLER.value
    || schriftsatz?.status === SCHRIFTSATZ_STATUS.GEPRUEFT.value;
}

/**
 * getBegehrenstypList
 * @return {[{label: string, value: string}]} begehren typ list
 */
export function getBegehrenstypList() {
  const retval = [
    {
      value: SELECT_ALL_OPTION,
      label: 'Alle',
    },
  ];
  jsonToArray(BEGEHREN_ART).filter((v) => (
    v.label.name !== BEGEHREN_ART.BEGEHREN.name
    && v.label.name !== BEGEHREN_ART.AENDERUNGSZEILE.name
    && v.label.name !== BEGEHREN_ART.BEGRUENDUNGSZEILE.name
    && v.label.name !== BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2_DETAIL.name
    && v.label.name !== BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2_DETAIL.name
  )).filter((v) => !!v.label.searchLabel).forEach((v) => {
    retval.push({
      value: v.label.name,
      label: v.label.searchLabel,
    });
  });
  return retval;
}

/**
 * getKundeAbteilungDetail
 * @returns {Promise<*>} undefined
 */
export async function getKundeAbteilungDetail() {
  const url = getUrl(REST.urls.benutzerKundeabteilungdetailSelect);
  return await makeRestCall('GET', url);
}

/**
 * persisKundeAbteilungDetail
 * @param {Number} schriftsatzId schriftsatzId
 * @param {Array} newSchriftsatzInterneInfos newSchriftsatzInterneInfos
 * @param {Array} originalSchriftsatzInterneInfos originalSchriftsatzInterneInfos
 * @returns {Promise<*>} KundeabteilungDetail
 */
export async function persistSchriftsatzInterneInfos(schriftsatzId, newSchriftsatzInterneInfos, originalSchriftsatzInterneInfos) {
  let post = false;

  console.log(newSchriftsatzInterneInfos, originalSchriftsatzInterneInfos); // eslint-disable-line

  const newSchriftsatzInterneInfosValues = arrayHasItem(newSchriftsatzInterneInfos) ? newSchriftsatzInterneInfos.map((v) => v.value) : null;
  const originalSchriftsatzInterneInfosValues = arrayHasItem(originalSchriftsatzInterneInfos) ? originalSchriftsatzInterneInfos.map((v) => v.value) : null;

  if (!equalsArrays(newSchriftsatzInterneInfosValues, originalSchriftsatzInterneInfosValues)) {
    post = true;
  }

  if (post) {
    const url = getUrl(REST.urls.schriftsaetzeUpdateInterneInfos, { schriftsatzId });
    const result = await makeRestCall('POST', url, newSchriftsatzInterneInfos.map((v) => v.value));
    if (result.status === OK) {
      return true;
    }
  }
  return false;
}

/**
 * canOnlyUpdateSchriftsatzInterneInfos
 * @param {Object} schriftsatz schriftsatz
 * @returns {boolean} yes or no
 */
export function isOnlyUpdateSchriftsatzInterneInfos(schriftsatz) {
  return schriftsatz?.status === SCHRIFTSATZ_STATUS.GESENDET.value
  || schriftsatz?.status === SCHRIFTSATZ_STATUS.IN_UEBERTRAGUNG.value
  || schriftsatz?.status === SCHRIFTSATZ_STATUS.EINGEBRACHT.value;
}

/**
 * buildHinweisAfterCopy
 * @param {Object} location location
 * @param {Number} countErvbox countErvbox
 * @param {Array} ervboxOutdatedList ervboxOutdatedList
 * @return {String} buildHinweisAfterCopy
 */
export function buildHinweisAfterCopy(location, countErvbox, ervboxOutdatedList) {
  let body = getFormattedMessage({ id: 'schriftsatz.copy.hinweis', params: location.state.aktenzeichen });

  if (countErvbox > 0) {
    const text = countErvbox === 1 ? 'Ein ERV-Box Anhang' : `Die ${countErvbox} ERV-Box Anhänge`;
    body = (
      <div>
        <div>
          {body}
        </div>
        <br />
        <div>
          <span className="font-weight-bold">
            {`${text} können nicht kopiert werden`}
          </span>
          <span>
            , diese müssen Sie ggf. erneut hochladen.
          </span>
        </div>
      </div>
    );
  }

  if (arrayHasItem(ervboxOutdatedList)) {
    const filenames = ervboxOutdatedList.map((v) => v.fileName).join(', ');
    body = (
      <div>
        <div>
          {body}
        </div>
        <br />
        <div>
          <span className="font-weight-bold">
            {`Ihre ERV-Box Anhänge (${filenames}) sind bereits veraltet und können daher nicht kopiert werden`}
          </span>
          <span>
            , diese müssen Sie ggf. erneut hochladen.
          </span>
        </div>
      </div>
    );
  }

  return body;
}

/**
 * validateBerechtigungByInhaltArt
 * @param {Object} appUserObj appUserObj
 * @param {String} inhaltArt inhaltArt
 * @return {*|boolean} validateBerechtigungByInhaltArt
 */
export function validateBerechtigungByInhaltArt(appUserObj, inhaltArt) {
  if (!appUserObj || !arrayHasItem(appUserObj.berechtigungen) || !inhaltArt) {
    return true;
  }
  const inhaltArtBerechtigung = INHALT_ART_BERECHTIGUNG[inhaltArt];
  if (!inhaltArtBerechtigung) {
    return true;
  }
  return appUserObj.berechtigungen.includes(inhaltArtBerechtigung);
}

/**
 * getErvboxDokumenteValidityList
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<void>} getErvboxDokumenteValidityList
 */
export async function getErvboxOutdatedDokumenteList(schriftsatzId) {
  return [];

  // TODO fuer aktuelle Loesung brauchen wir diese Funktion nicht mehr, weil wir diese Validierung einfach bei BRZ lassen, siehe ERVSW-3632.

  const url = getUrl(REST.urls.schriftsaetzeDokumentErvBoxValidity, { schriftsatzId }); // eslint-disable-line

  const result = await makeRestCall('GET', url);

  if (result.status === OK) {
    return result.body;
  }
  return [];
}

/**
 * openErvboxOutdatedListHinweis
 * @param {Array} ervboxOutdatedList ervboxOutdatedList
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<void>} openErvboxOutdatedListHinweis
 */
export async function openCheckErvboxOutdatedListHinweis(ervboxOutdatedList, schriftsatzId) {
  return;

  // TODO fuer aktuelle Loesung brauchen wir diese Funktion nicht mehr, weil wir diese Validierung einfach bei BRZ lassen, siehe ERVSW-3632.

  const filenames = ervboxOutdatedList.map((v) => v.fileName).join(', '); // eslint-disable-line
  const confirm = await modalFactory.openWithPromise(ConfirmationModal, {
    disableClose: false,
    header: getFormattedMessage({ id: 'hinweis.header' }),
    body: `Ihre folgenden ERV-Box Anhänge "(${filenames})" sind bereits veraltet und können dieser Schriftsatz daher nicht gesendet werden, diese müssen Sie ggf. löschen und dann erneut hochladen.\n\nWollten Sie die veralteten Dokumente löschen?`,
  });

  if (confirm) {
    const confirm2 = await modalFactory.openWithPromise(ConfirmationModal, {
      disableClose: false,
      header: getFormattedMessage({ id: 'hinweis.header' }),
      body: 'Sind Sie die veralteten Dokumente löschen möchten?',
    });

    if (confirm2) {
      const url = getUrl(REST.urls.schriftsaetzeDokumentErvBoxValidity, { schriftsatzId });

      await makeRestCall('DELETE', url);
    }
  }
}

/**
 * Opens the Dokumente page Hinweis modal.
 *
 * @param {number} schriftsatzId - The ID of the schriftsatz.
 * @param {object} history - The history object.
 * @param {object} match - The match object.
 *
 * @return {Promise<void>} - A promise that resolves when the modal is closed.
 */
export async function openGotoDokumentePageHinweis(schriftsatzId, history, match) {
  await modalFactory.openWithPromise(GotoDokumentePageModal, {
    disableClose: false,
    history,
    match,
    schriftsatzId,
    header: getFormattedMessage({ id: 'hinweis.header' }),
  });
}

/**
 * openCheckBerechtigungHinweis
 * @return {undefined}
 */
export function openCheckBerechtigungHinweis() {
  modalFactory.openWithPromise(ConfirmationModal, {
    disableClose: false,
    header: getFormattedMessage({ id: 'hinweis.header' }),
    body: 'Dieser Schriftsatz darf nicht gesendet werden, weil der keine Einbringungsberechtigung hat!',
  });
}

/**
 * checkZPO112WithErvBox
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<*|boolean>} checkZPO112WithErvBox
 */
export async function checkZPO112WithErvBox(schriftsatzId) {
  const url = getUrl(REST.urls.schriftsaetzeCheckZPO112WithErvBox, { schriftsatzId });

  const result = await makeRestCall('GET', url);

  if (result.status === OK) {
    return result.body;
  }
  return false;
}

/**
 * openZPO112WithErvBoxHinweis
 * @return {Promise<void>} undefined
 */
export async function openZPO112WithErvBoxHinweis() {
  modalFactory.openWithPromise(ConfirmationModal, {
    disableClose: false,
    header: getFormattedMessage({ id: 'hinweis.header' }),
    body: getFormattedMessage({ id: 'zpo112.with.ervbox' }),
  });
}

/**
 * getSchriftsatzDokumentJustizboxUploadInfoList
 * @param {Number} schriftsatzId schriftsatzId
 * @param {String} hinweisId hinweisId
 * @return {Promise<*[]>} getSchriftsatzDokumentJustizboxUploadInfoList
 */
export async function getSchriftsatzDokumentJustizboxUploadInfoList(schriftsatzId, hinweisId) {
  const url = getUrl(REST.urls.schriftsaetzeDokumentErvBoxUploadInfo, { schriftsatzId });

  const result = await makeRestCall('GET', url);

  let notTransferedJustizboxDokumente = [];
  if (result.status === OK) {
    notTransferedJustizboxDokumente = arrayHasItem(result.body) ? result.body : [];
  }

  if (arrayHasItem(notTransferedJustizboxDokumente)) {
    if (hinweisId) {
      modalFactory.openWithPromise(ConfirmationModal, {
        header: getFormattedMessage({ id: 'hinweis.header' }),
        body: getFormattedMessage({ id: hinweisId }),
      });
    }
    return notTransferedJustizboxDokumente;
  }

  return [];
}

/**
 * checkNotTransferedJustizboxDokumenteForCopy
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<boolean>} checkNotTransferedJustizboxDokumente
 */
export async function existedNotTransferedJustizboxDokumenteForCopy(schriftsatzId) {
  const schriftsatzDokumentJustizboxUploadInfoList = await getSchriftsatzDokumentJustizboxUploadInfoList(schriftsatzId, 'schriftsatz.copy.check.not.transfered.ervbox');
  return arrayHasItem(schriftsatzDokumentJustizboxUploadInfoList);
}

/**
 * checkNotTransferedJustizboxDokumenteForPruefen
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<boolean>} checkNotTransferedJustizboxDokumente
 */
export async function existedNotTransferedJustizboxDokumenteForPruefen(schriftsatzId) {
  const schriftsatzDokumentJustizboxUploadInfoList = await getSchriftsatzDokumentJustizboxUploadInfoList(schriftsatzId, 'schriftsatz.check.pruefen.not.transfered.ervbox');
  return arrayHasItem(schriftsatzDokumentJustizboxUploadInfoList);
}

/**
 * checkNotTransferedJustizboxDokumenteForTransfer
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Promise<boolean>} checkNotTransferedJustizboxDokumente
 */
export async function checkNotTransferedJustizboxDokumenteForTransfer(schriftsatzId) {
  const schriftsatzDokumentJustizboxUploadInfoList = await getSchriftsatzDokumentJustizboxUploadInfoList(schriftsatzId, 'schriftsatz.check.transfer.not.transfered.ervbox');
  return arrayHasItem(schriftsatzDokumentJustizboxUploadInfoList);
}

/**
 * restCallDeprecatedInhaltElemente
 * @param {Number} schriftsatzId schriftsatzId
 * @return {Object} restCallDeprecatedInhaltElemente
 */
export async function restCallDeprecatedInhaltElemente(schriftsatzId) {
  const url = getUrl(REST.urls.schriftsaetzeInhaltElementeDeprecated, { schriftsatzId });

  const result = await makeRestCall('GET', url);

  let rechtstatsache = false;

  if (result.status === OK && arrayHasItem(result.body)) {
    rechtstatsache = !!result.body.find((v) => v.inhaltElementTyp === 'RECHTSTATSACHE');
  }

  return {
    rechtstatsache,
  };
}
