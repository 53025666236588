import PATH from '../path';
import INHALT_ART from '../schriftsatz.inhalt.art';
import ERLEDIGUNG_INHALT_ART from '../erledigung.inhalt.art.json';
import SCHRIFTSATZ_STATUS from '../schriftsatz.status.json';
import { SELECT_ALL_OPTION } from '../../common/ReactSelectWrapper/ReactSelect.constants';

export const SCHRIFTSATZ_SEARCH_TYPE_OPTIONS = [
  {
    value: SELECT_ALL_OPTION,
    label: 'Alle',
    deprecated: false,
  },
  {
    value: INHALT_ART.UNKNOWN.value,
    label: INHALT_ART.UNKNOWN.label,
    deprecated: INHALT_ART.UNKNOWN.deprecated || false,
  },
  {
    value: INHALT_ART.FEHLER.value,
    label: INHALT_ART.FEHLER.label,
    deprecated: INHALT_ART.FEHLER.deprecated || false,
  },

  {
    label: 'Grundbuch (GB)',
    options: [
      {
        value: INHALT_ART.GB_AN.value,
        label: INHALT_ART.GB_AN.label,
        deprecated: INHALT_ART.GB_AN.deprecated || false,
      },
      {
        value: INHALT_ART.GB_MI.value,
        label: INHALT_ART.GB_MI.label,
        deprecated: INHALT_ART.GB_MI.deprecated || false,
      },
      {
        value: INHALT_ART.GB_ER.value,
        label: INHALT_ART.GB_ER.label,
        deprecated: INHALT_ART.GB_ER.deprecated || false,
      },
      {
        value: INHALT_ART.GB_URK_SCH.value,
        label: INHALT_ART.GB_URK_SCH.label,
        deprecated: INHALT_ART.GB_URK_SCH.deprecated || false,
      },
      {
        value: INHALT_ART.GB_FO.value,
        label: INHALT_ART.GB_FO.label,
        deprecated: INHALT_ART.GB_FO.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.GB_BE.value,
        label: ERLEDIGUNG_INHALT_ART.GB_BE.label,
        deprecated: ERLEDIGUNG_INHALT_ART.GB_BE.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.GB_AZ.value,
        label: ERLEDIGUNG_INHALT_ART.GB_AZ.label,
        deprecated: ERLEDIGUNG_INHALT_ART.GB_AZ.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.GB_VA.value,
        label: ERLEDIGUNG_INHALT_ART.GB_VA.label,
        deprecated: ERLEDIGUNG_INHALT_ART.GB_VA.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.GB_NOTE.value,
        label: ERLEDIGUNG_INHALT_ART.GB_NOTE.label,
        deprecated: ERLEDIGUNG_INHALT_ART.GB_NOTE.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.GB_JT.value,
        label: ERLEDIGUNG_INHALT_ART.GB_JT.label,
        deprecated: ERLEDIGUNG_INHALT_ART.GB_JT.deprecated || false,
      },
      {
        value: INHALT_ART.GB_GRUPPE.value,
        label: INHALT_ART.GB_GRUPPE.label,
        deprecated: INHALT_ART.GB_GRUPPE.deprecated || false,
      },
    ],
  },

  {
    label: 'Firmenbuch (FB)',
    options: [
      {
        value: INHALT_ART.FB_Neu.value,
        label: INHALT_ART.FB_Neu.label,
        deprecated: INHALT_ART.FB_Neu.deprecated || false,
      },
      {
        value: INHALT_ART.FB_Neu_OG.value,
        label: INHALT_ART.FB_Neu_OG.label,
        deprecated: INHALT_ART.FB_Neu_OG.deprecated || false,
      },
      {
        value: INHALT_ART.FB_Antrag.value,
        label: INHALT_ART.FB_Antrag.label,
        deprecated: INHALT_ART.FB_Antrag.deprecated || false,
      },
      {
        value: INHALT_ART.FB_Jahres.value,
        label: INHALT_ART.FB_Jahres.label,
        deprecated: INHALT_ART.FB_Jahres.deprecated || false,
      },
      {
        value: INHALT_ART.FB_NeueinAntr.value,
        label: INHALT_ART.FB_NeueinAntr.label,
        deprecated: INHALT_ART.FB_NeueinAntr.deprecated || false,
      },
      {
        value: INHALT_ART.FB_AendrAntr.value,
        label: INHALT_ART.FB_AendrAntr.label,
        deprecated: INHALT_ART.FB_AendrAntr.deprecated || false,
      },
      {
        value: INHALT_ART.FB_LoeschAntr.value,
        label: INHALT_ART.FB_LoeschAntr.label,
        deprecated: INHALT_ART.FB_LoeschAntr.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.FB_AZ.value,
        label: ERLEDIGUNG_INHALT_ART.FB_AZ.label,
        deprecated: ERLEDIGUNG_INHALT_ART.FB_AZ.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.FB_BESCHLUSS.value,
        label: ERLEDIGUNG_INHALT_ART.FB_BESCHLUSS.label,
        deprecated: ERLEDIGUNG_INHALT_ART.FB_BESCHLUSS.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.FB_ZAHLUNG.value,
        label: ERLEDIGUNG_INHALT_ART.FB_ZAHLUNG.label,
        deprecated: ERLEDIGUNG_INHALT_ART.FB_ZAHLUNG.deprecated || false,
      },
    ],
  },

  {
    label: 'Justizverfahren (VJ)',
    options: [
      {
        value: INHALT_ART.VJ_AZ.value,
        label: INHALT_ART.VJ_AZ.label,
        deprecated: INHALT_ART.VJ_AZ.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_SONST_ERL.value,
        label: INHALT_ART.VJ_SONST_ERL.label,
        deprecated: INHALT_ART.VJ_SONST_ERL.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_LADUNG.value,
        label: INHALT_ART.VJ_LADUNG.label,
        deprecated: INHALT_ART.VJ_LADUNG.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_ZAHLBEF.value,
        label: INHALT_ART.VJ_ZAHLBEF.label,
        deprecated: INHALT_ART.VJ_ZAHLBEF.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_EB.value,
        label: INHALT_ART.VJ_EB.label,
        deprecated: INHALT_ART.VJ_EB.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Kuenc.value,
        label: INHALT_ART.VJ_Kuenc.label,
        deprecated: INHALT_ART.VJ_Kuenc.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_EVcga.value,
        label: INHALT_ART.VJ_EVcga.label,
        deprecated: INHALT_ART.VJ_EVcga.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_EVcg.value,
        label: INHALT_ART.VJ_EVcg.label,
        deprecated: INHALT_ART.VJ_EVcg.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_EVc.value,
        label: INHALT_ART.VJ_EVc.label,
        deprecated: INHALT_ART.VJ_EVc.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Direkt.value,
        label: INHALT_ART.VJ_Direkt.label,
        deprecated: INHALT_ART.VJ_Direkt.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Folge.value,
        label: INHALT_ART.VJ_Folge.label,
        deprecated: INHALT_ART.VJ_Folge.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Serst.value,
        label: INHALT_ART.VJ_Serst.label,
        deprecated: INHALT_ART.VJ_Serst.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Exesonst.value,
        label: INHALT_ART.VJ_Exesonst.label,
        deprecated: INHALT_ART.VJ_Exesonst.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Mkc.value,
        label: INHALT_ART.VJ_Mkc.label,
        deprecated: INHALT_ART.VJ_Mkc.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Mkcg.value,
        label: INHALT_ART.VJ_Mkcg.label,
        deprecated: INHALT_ART.VJ_Mkcg.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Mkcga.value,
        label: INHALT_ART.VJ_Mkcga.label,
        deprecated: INHALT_ART.VJ_Mkcga.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Skc.value,
        label: INHALT_ART.VJ_Skc.label,
        deprecated: INHALT_ART.VJ_Skc.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Skcg.value,
        label: INHALT_ART.VJ_Skcg.label,
        deprecated: INHALT_ART.VJ_Skcg.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Skcga.value,
        label: INHALT_ART.VJ_Skcga.label,
        deprecated: INHALT_ART.VJ_Skcga.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Srs.value,
        label: INHALT_ART.VJ_Srs.label,
        deprecated: INHALT_ART.VJ_Srs.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Exe.value,
        label: INHALT_ART.VJ_Exe.label,
        deprecated: INHALT_ART.VJ_Exe.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_ExeNStru.value,
        label: INHALT_ART.VJ_ExeNStru.label,
        deprecated: INHALT_ART.VJ_ExeNStru.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Kuencga.value,
        label: INHALT_ART.VJ_Kuencga.label,
        deprecated: INHALT_ART.VJ_Kuencga.deprecated || false,
      },
      {
        value: INHALT_ART.VJ_Kerst.value,
        label: INHALT_ART.VJ_Kerst.label,
        deprecated: INHALT_ART.VJ_Kerst.deprecated || false,
      },
    ],
  },

  {
    label: 'DIREKTZUSTELLUNG (TLDZ)',
    options: [
      {
        value: INHALT_ART.TLDZ.value,
        label: INHALT_ART.TLDZ.label,
        deprecated: INHALT_ART.TLDZ.deprecated || false,
      },
      {
        value: INHALT_ART.DIREKTVERKEHR.value,
        label: INHALT_ART.DIREKTVERKEHR.label,
        deprecated: INHALT_ART.DIREKTVERKEHR.deprecated || false,
      },
    ],
  },

  {
    label: 'Gerichtshöfe (VF/VW/BW)',
    options: [
      {
        value: INHALT_ART.VF_VFGH.value,
        label: INHALT_ART.VF_VFGH.label,
        deprecated: INHALT_ART.VF_VFGH.deprecated || false,
      },
      {
        value: INHALT_ART.VF_VWGH.value,
        label: INHALT_ART.VF_VWGH.label,
        deprecated: INHALT_ART.VF_VWGH.deprecated || false,
      },
      {
        value: INHALT_ART.VF_ASYLGH.value,
        label: INHALT_ART.VF_ASYLGH.label,
        deprecated: INHALT_ART.VF_ASYLGH.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.VW_AZ.value,
        label: ERLEDIGUNG_INHALT_ART.VW_AZ.label,
        deprecated: ERLEDIGUNG_INHALT_ART.VW_AZ.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.BW_AZ.value,
        label: ERLEDIGUNG_INHALT_ART.BW_AZ.label,
        deprecated: ERLEDIGUNG_INHALT_ART.BW_AZ.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.VF_AZ.value,
        label: ERLEDIGUNG_INHALT_ART.VF_AZ.label,
        deprecated: ERLEDIGUNG_INHALT_ART.VF_AZ.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.VF_ZD.value,
        label: ERLEDIGUNG_INHALT_ART.VF_ZD.label,
        deprecated: ERLEDIGUNG_INHALT_ART.VF_ZD.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.VW_ZD.value,
        label: ERLEDIGUNG_INHALT_ART.VW_ZD.label,
        deprecated: ERLEDIGUNG_INHALT_ART.VW_ZD.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.BW_ZD.value,
        label: ERLEDIGUNG_INHALT_ART.BW_ZD.label,
        deprecated: ERLEDIGUNG_INHALT_ART.BW_ZD.deprecated || false,
      },
    ],
  },

  {
    label: 'EU Mahnverfahren (EUM)',
    options: [
      {
        value: INHALT_ART.EU_M.value,
        label: INHALT_ART.EU_M.label,
        deprecated: INHALT_ART.EU_M.deprecated || false,
      },
      {
        value: INHALT_ART.EU_C.value,
        label: INHALT_ART.EU_C.label,
        deprecated: INHALT_ART.EU_C.deprecated || false,
      },
      {
        value: INHALT_ART.EU_V.value,
        label: INHALT_ART.EU_V.label,
        deprecated: INHALT_ART.EU_V.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.EU_Resp_D.value,
        label: ERLEDIGUNG_INHALT_ART.EU_Resp_D.label,
        deprecated: ERLEDIGUNG_INHALT_ART.EU_Resp_D.deprecated || false,
      },
      {
        value: INHALT_ART.EU_F.value,
        label: INHALT_ART.EU_F.label,
        deprecated: INHALT_ART.EU_F.deprecated || false,
      },
      {
        value: INHALT_ART.EU_Brief.value,
        label: INHALT_ART.EU_Brief.label,
        deprecated: INHALT_ART.EU_Brief.deprecated || false,
      },
    ],
  },

  {
    label: 'Staatsanwaltschaften (STA)',
    options: [
      {
        value: INHALT_ART.STA_Eingabe.value,
        label: INHALT_ART.STA_Eingabe.label,
        deprecated: INHALT_ART.STA_Eingabe.deprecated || false,
      },
    ],
  },

  {
    label: 'Treuhandbuch (eTHB)',
    options: [
      {
        value: INHALT_ART.THB_A2B_AEM.value,
        label: INHALT_ART.THB_A2B_AEM.label,
        deprecated: INHALT_ART.THB_A2B_AEM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_AM.value,
        label: INHALT_ART.THB_A2B_AM.label,
        deprecated: INHALT_ART.THB_A2B_AM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_KAA.value,
        label: INHALT_ART.THB_A2B_KAA.label,
        deprecated: INHALT_ART.THB_A2B_KAA.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_KVA.value,
        label: INHALT_ART.THB_A2B_KVA.label,
        deprecated: INHALT_ART.THB_A2B_KVA.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_VM.value,
        label: INHALT_ART.THB_A2B_VM.label,
        deprecated: INHALT_ART.THB_A2B_VM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_BM.value,
        label: INHALT_ART.THB_A2B_BM.label,
        deprecated: INHALT_ART.THB_A2B_BM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_KA.value,
        label: INHALT_ART.THB_A2B_KA.label,
        deprecated: INHALT_ART.THB_A2B_KA.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_KA.value,
        label: INHALT_ART.THB_A2B_KA.label,
        deprecated: INHALT_ART.THB_A2B_KA.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2B_KSM.value,
        label: INHALT_ART.THB_A2B_KSM.label,
        deprecated: INHALT_ART.THB_A2B_KSM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_B2A_AFM.value,
        label: INHALT_ART.THB_B2A_AFM.label,
        deprecated: INHALT_ART.THB_B2A_AFM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_B2A_RKAM.value,
        label: INHALT_ART.THB_B2A_RKAM.label,
        deprecated: INHALT_ART.THB_B2A_RKAM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_B2A_RKSM.value,
        label: INHALT_ART.THB_B2A_RKSM.label,
        deprecated: INHALT_ART.THB_B2A_RKSM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_B2A_WAEM.value,
        label: INHALT_ART.THB_B2A_WAEM.label,
        deprecated: INHALT_ART.THB_B2A_WAEM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_B2A_WKVA.value,
        label: INHALT_ART.THB_B2A_WKVA.label,
        deprecated: INHALT_ART.THB_B2A_WKVA.deprecated || false,
      },
      {
        value: INHALT_ART.THB_B2A_WSM.value,
        label: INHALT_ART.THB_B2A_WSM.label,
        deprecated: INHALT_ART.THB_B2A_WSM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_B2A_RVM.value,
        label: INHALT_ART.THB_B2A_RVM.label,
        deprecated: INHALT_ART.THB_B2A_RVM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_AKVAM.value,
        label: INHALT_ART.THB_A2K_AKVAM.label,
        deprecated: INHALT_ART.THB_A2K_AKVAM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_AM.value,
        label: INHALT_ART.THB_A2K_AM.label,
        deprecated: INHALT_ART.THB_A2K_AM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_AZM.value,
        label: INHALT_ART.THB_A2K_AZM.label,
        deprecated: INHALT_ART.THB_A2K_AZM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_BM.value,
        label: INHALT_ART.THB_A2K_BM.label,
        deprecated: INHALT_ART.THB_A2K_BM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_EM.value,
        label: INHALT_ART.THB_A2K_EM.label,
        deprecated: INHALT_ART.THB_A2K_EM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_FM.value,
        label: INHALT_ART.THB_A2K_FM.label,
        deprecated: INHALT_ART.THB_A2K_FM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_KVAM.value,
        label: INHALT_ART.THB_A2K_KVAM.label,
        deprecated: INHALT_ART.THB_A2K_KVAM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_VEM.value,
        label: INHALT_ART.THB_A2K_VEM.label,
        deprecated: INHALT_ART.THB_A2K_VEM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_VFM.value,
        label: INHALT_ART.THB_A2K_VFM.label,
        deprecated: INHALT_ART.THB_A2K_VFM.deprecated || false,
      },
      {
        value: INHALT_ART.THB_A2K_UEM.value,
        label: INHALT_ART.THB_A2K_UEM.label,
        deprecated: INHALT_ART.THB_A2K_UEM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_K2A_AM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_K2A_AM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_K2A_AM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_K2A_EM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_K2A_EM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_K2A_EM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_K2B_SM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_K2B_SM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_K2B_SM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2A_RKAA.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2A_RKAA.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2A_RKAA.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2A_RKVA.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2A_RKVA.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2A_RKVA.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2K_RKVA.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2K_RKVA.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2K_RKVA.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2K_RSM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2K_RSM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2K_RSM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2K_RVM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2K_RVM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2K_RVM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2A_RAM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2A_RAM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2A_RAM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2A_RAEM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2A_RAEM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2A_RAEM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2K_RAM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2K_RAM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2K_RAM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2K_RAEM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2K_RAEM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2K_RAEM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2K_BM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2K_BM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2K_BM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2K_WKSM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2K_WKSM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2K_WKSM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_B2A_RSM.value,
        label: ERLEDIGUNG_INHALT_ART.THB_B2A_RSM.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_B2A_RSM.deprecated || false,
      },
      {
        value: ERLEDIGUNG_INHALT_ART.THB_RM_ANWALT.value,
        label: ERLEDIGUNG_INHALT_ART.THB_RM_ANWALT.label,
        deprecated: ERLEDIGUNG_INHALT_ART.THB_RM_ANWALT.deprecated || false,
      },
    ],
  },

  {
    label: 'E-Zustellung',
    options: [
      {
        value: INHALT_ART.EZUSTELLUNG.value,
        label: INHALT_ART.EZUSTELLUNG.label,
        deprecated: INHALT_ART.EZUSTELLUNG.deprecated || false,
      },
    ],
  },

  {
    label: 'STV',
    options: [
      {
        value: INHALT_ART.STV.value,
        label: INHALT_ART.STV.label,
        deprecated: INHALT_ART.STV.deprecated || false,
      },
    ],
  },

  {
    label: 'BMI',
    options: [
      {
        value: INHALT_ART.BMI.value,
        label: INHALT_ART.BMI.label,
        deprecated: INHALT_ART.BMI.deprecated || false,
      },
    ],
  },
];

export const AUSGANG_STATUS_OPTIONS = [
  {
    value: SELECT_ALL_OPTION,
    label: 'Alle',
  },
  {
    value: SCHRIFTSATZ_STATUS.IN_ARBEIT.value,
    label: SCHRIFTSATZ_STATUS.IN_ARBEIT.label,
  },
  {
    value: SCHRIFTSATZ_STATUS.FEHLER.value,
    label: SCHRIFTSATZ_STATUS.FEHLER.label,
  },
  {
    value: SCHRIFTSATZ_STATUS.GEPRUEFT.value,
    label: SCHRIFTSATZ_STATUS.GEPRUEFT.label,
  },
  {
    value: SCHRIFTSATZ_STATUS.IN_UEBERTRAGUNG.value,
    label: SCHRIFTSATZ_STATUS.IN_UEBERTRAGUNG.label,
  },
  {
    value: SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value,
    label: SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.label,
  },
  {
    value: SCHRIFTSATZ_STATUS.GESENDET.value,
    label: SCHRIFTSATZ_STATUS.GESENDET.label,
  },
  {
    value: SCHRIFTSATZ_STATUS.EINGEBRACHT.value,
    label: SCHRIFTSATZ_STATUS.EINGEBRACHT.label,
  },
  {
    value: SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value,
    label: SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.label,
  },
];

export const eingangStatus = [
  {
    value: SELECT_ALL_OPTION,
    label: 'Alle',
  },
  {
    value: 'NEU',
    label: 'Ungelesen',
  },
  {
    value: 'KONTROLLIERT',
    label: 'Gelesen',
  },
];

export const SCHRIFTVERKEHR_SEARCH_TEMPLATE = {
  TODO: 'TODO',
  WORK_IN_PROGRESS: 'WORK_IN_PROGRESS',
  GBAN_VALID_FOR_GRUPPE: 'GBAN_VALID_FOR_GRUPPE',
};

export const AUSGANG_TABLE_COLS = [
  {
    dataKey: 'rowindex',
    label: 'Nr.',
    class: 'nr',
  },
  {
    dataKey: 'typ',
    label: 'Typ',
    sortColumn: 'type',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    sortColumn: 'schriftverkehr.applikationsTyp',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    sortColumn: 'schriftverkehr.akt.name',
  },
  {
    dataKey: 'erstellt',
    label: 'Erstellt',
    sortColumn: 'createDate',
    className: 'date',
  },
  {
    dataKey: 'eingebracht',
    label: 'Eingebracht',
    sortColumn: 'einbringungsZeitpunkt',
    className: 'date',
  },
  {
    dataKey: 'status',
    label: 'Status',
    sortColumn: 'status',
    className: 'status',
  },
  {
    dataKey: 'kopieren',
    label: 'Kopieren',
    className: 'copy-icon',
  },
];

export const RESPONSIVE_AUSGANG_TABLE_COLS = [
  {
    dataKey: 'erstellt',
    label: 'Erstellt',
    disableLabel: true,
    sortColumn: 'createDate',
    className: 'date font-weight-bold',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'font-weight-bold aktenzeichen',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    disableLabel: true,
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'd-block',
  },
  {
    dataKey: 'einbringerLabel',
    label: 'Einbringer',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'einbringer d-block',
  },
  {
    dataKey: 'status',
    label: 'Status',
    disableLabel: true,
    sortColumn: 'status',
    className: 'w-50',
  },
  {
    dataKey: 'kopieren',
    className: 'copy w-50 d-inline-flex align-items-center justify-content-end',
  },
];

export const EINGANG_TABLE_COLS = [
  {
    dataKey: 'rowindex',
    label: 'Nr.',
    className: 'nr',
  },
  {
    dataKey: 'typ',
    label: 'Typ',
    sortColumn: 'type',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    sortColumn: 'schriftverkehr.applikationsTyp',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    sortColumn: 'schriftverkehr.akt.name',
  },
  {
    dataKey: 'erstellt',
    label: 'Bereitgestellt am',
    sortColumn: 'erstellZeitpunkt',
    className: 'date',
  },
  {
    dataKey: 'status',
    label: 'Status',
    sortColumn: 'status',
    className: 'status',
  },
];

export const RESPONSIVE_EINGANG_TABLE_COLS = [
  {
    dataKey: 'erstellt',
    label: 'Bereitgestellt',
    disableLabel: true,
    sortColumn: 'createDate',
    className: 'date',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'aktenzeichen',
  },
  {
    dataKey: 'gerichtLabel',
    className: 'gericht d-block',
    sortColumn: 'gericht',
    label: 'Gericht',
    disableLabel: true,
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    disableLabel: true,
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'art w-auto',
  },
];

export const OVERVIEW_AUSGANG_TABLE_COLS = [
  {
    dataKey: 'rowindex',
    label: 'Nr.',
    className: 'nr',
  },
  {
    dataKey: 'erstellt',
    label: 'Erstellt',
    sortColumn: 'createDate',
    className: 'date',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'art ',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    sortColumn: 'schriftverkehr.akt.name',
    className: 'aktenzeichen ',
  },
  {
    dataKey: 'einbringerLabel',
    label: 'Einbringer',
    sortColumn: 'schriftverkehr.akt.name',
    className: 'einbringer ',
  },
  {
    dataKey: 'status',
    label: 'Status',
    sortColumn: 'status',
    className: 'status ',
  },
];

export const OVERVIEW_RESPONSIVE_AUSGANG_TABLE_COLS = [
  {
    dataKey: 'erstellt',
    label: 'Erstellt',
    disableLabel: true,
    sortColumn: 'createDate',
    className: 'date font-weight-bold',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'font-weight-bold',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    disableLabel: true,
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'd-block',
  },
  {
    dataKey: 'einbringerLabel',
    label: 'Einbringer',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'einbringer d-block',
  },
  {
    dataKey: 'status',
    label: 'Status',
    disableLabel: true,
    sortColumn: 'status',
    className: 'w-50',
  },
];

export const OVERVIEW_EINGANG_TABLE_COLS = [
  {
    dataKey: 'rowindex',
    label: 'Nr.',
    className: 'nr',
  },
  {
    dataKey: 'erstellt',
    label: 'Bereitgestellt',
    sortColumn: 'createDate',
    className: 'date',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'art',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    sortColumn: 'schriftverkehr.akt.name',
    className: 'aktenzeichen',
  },
  {
    dataKey: 'sender',
    label: 'Absender',
    sortColumn: 'sender',
    className: 'einbringer',
  },
  {
    dataKey: 'status',
    label: 'Status',
    sortColumn: 'status',
    className: 'status',
  },
];

export const OVERVIEW_RESPONSIVE_EINGANG_TABLE_COLS = [
  {
    dataKey: 'erstellt',
    label: 'Bereitgestellt',
    disableLabel: true,
    sortColumn: 'createDate',
    className: 'date',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'aktenzeichen',
  },
  {
    dataKey: 'gerichtLabel',
    className: 'gericht d-block',
    label: 'Gericht',
    disableLabel: true,
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    disableLabel: true,
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'art w-auto',
  },
];

export const OVERVIEW_DEFAULT_SORT = {
  id: 'status',
  direction: 'ASC',
};

export const EINGANG_DEFAULT_SORT = {
  id: 'createDate',
  direction: 'DESC',
};

export const OVERVIEW_PAGE_SIZES = [5, 10, 25, 50];

export const FORDERUNGSUEBERGANG_OPTIONS = [
  {
    value: 'KEINE_AUSWAHL',
    label: 'Keine Auswahl',
  },
  {
    value: 'ABTRETUNG',
    label: 'A) Abtretung',
  },
  {
    value: 'ZAHLUNG_EINLOESUNG',
    label: 'Z) Zahlung/Einlösung',
  },
  {
    value: 'GERICHTLICHE_UEBERWEISUNG',
    label: 'E) Gerichtliche (exekutive) Überweisung',
  },
];

export const FORDERUNGEN_ZINSEN_PERIODE = [
  {
    value: null,
    label: 'auswählen...',
  },
  {
    value: 'MONATLICH',
    label: 'M) monatlich',
  },
  {
    value: 'VIERTELJAEHRLICH',
    label: 'V) vierteljährlich',
  },
  {
    value: 'HALBJAEHRLICH',
    label: 'H) halbjährlich',
  },
  {
    value: 'JAEHRLICH',
    label: 'J) jährlich',
  },
];

export const FORDERUNGEN_BEWEISE_ART_OPTIONS = [
  {
    value: 'LOKALAUGENSCHEIN',
    label: 'LA) Lokalaugenschein',
  },
  {
    value: 'PARTEIENVERNEHMUNG',
    label: 'PV) Parteienvernehmung',
  },
  {
    value: 'SONSTIGE',
    label: 'SO) Sonstige',
  },
  {
    value: 'SACHVERSTAENDIGE',
    label: 'SV) Sachverständige',
  },
  {
    value: 'URKUNDEN',
    label: 'UR) Urkunden',
  },
  {
    value: 'ZEUGEN',
    label: 'ZG) Zeugen',
  },
];

export const FORDERUNGEN_ZINSEN_BEGEHEREN_OPTIONS = [
  {
    value: 'ANLAGE',
    label: 'A) Zinssatz für Anlage',
  },
  {
    value: 'BEIDERSEITIG',
    label: 'B) Es liegt ein beiderseitiges Unternehmergeschäft vor',
  },
  {
    value: 'FAHRLAESSIGKEIT',
    label: 'F) Leichte Fahrlässigkeit',
  },
  {
    value: 'KREDIT',
    label: 'K) Zinssatz für Kredit',
  },
  {
    value: 'UNTERLASSUNG',
    label: 'U) Trotz Hinweis auf Notwendigkeit keine Zahlung',
  },
  {
    value: 'VEREINBART',
    label: 'V) Zinsen vereinbart',
  },
];

export const ARBEITSGERICHT_FORDERUNGEN_ZINSEN_BEGEHEREN_OPTIONS = [
  {
    value: 'ANLAGE',
    label: 'A) Zinssatz für Anlage',
  },
  {
    value: 'FAHRLAESSIGKEIT',
    label: 'F) Leichte Fahrlässigkeit',
  },
  {
    value: 'KREDIT',
    label: 'K) Zinssatz für Kredit',
  },
  {
    value: 'UNTERLASSUNG',
    label: 'U) Trotz Hinweis auf Notwendigkeit keine Zahlung',
  },
  {
    value: 'VEREINBART',
    label: 'V) Zinsen vereinbart',
  },
];

export const FORDERUNGEN_ZUNSAENDIGKEIT_ART_OPTIONS = [
  {
    value: 'ERFUELLUNGSORT',
    label: 'E) wurde als Erfüllungsort vereinbart',
  },
  {
    value: 'FAKTURA',
    label: 'F) vor bzw. gleichzeitig mit Übersendung der Ware wurde die Faktura mit dem Vermerk unbeanstandet angenommen, zahlbar und klagbar an dem oben angegebenen Ort',
  },
  {
    value: 'GERICHTSSTAND',
    label: 'G) wurde als Gerichtsstand vereinbart',
  },
  {
    value: 'MIETGEGENSTAND',
    label: 'M) ist Ort des Miet-/Pachtgegenstandes',
  },
  {
    value: 'UNFALLORT',
    label: 'U) war Unfallsort bzw. Ort der Schadenszufügung',
  },
];

export const ARBEITSGERICHT_FORDERUNGEN_ZUNSAENDIGKEIT_ART_OPTIONS = [
  {
    value: 'ARBEITSORT',
    label: 'A) Arbeitsort',
  },
  {
    value: 'GERICHTSSTAND',
    label: 'G) wurde als Gerichtsstand vereinbart',
  },
  {
    value: 'ZAHLORT',
    label: 'O) Ort der Entgeltzahlung',
  },
  {
    value: 'UNFALLORT',
    label: 'U) war Unfallsort bzw. Ort der Schadenszufügung',
  },
  {
    value: 'WOHNSITZ',
    label: 'W) Wohnsitz oder gewöhnlicher Aufenthalt des Arbeitnehmers',
  },
];

export const EINGANG_EINGANG_TABLE_COLS = [
  {
    dataKey: 'rowindex',
    label: 'Nr.',
  },
  {
    dataKey: 'erstellt',
    label: 'Bereitgestellt',
    sortColumn: 'createDate',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    sortColumn: 'schriftverkehr.applikationsTyp',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    sortColumn: 'schriftverkehr.akt.name',
  },
  {
    dataKey: 'sender',
    label: 'Absender',
    sortColumn: 'sender',
  },
  {
    dataKey: 'status',
    label: 'Status',
    sortColumn: 'status',
  },
];

export const EINGANG_RESPONSIVE_EINGANG_TABLE_COLS = [
  {
    dataKey: 'erstellt',
    label: 'Bereitgestellt',
    disableLabel: true,
    sortColumn: 'createDate',
    className: 'date',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'aktenzeichen',
  },
  {
    dataKey: 'gerichtLabel',
    className: 'gericht',
    label: 'Gericht',
    disableLabel: true,
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    disableLabel: true,
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'art w-auto',
  },
];

export const AUSGANG_AUSGANG_TABLE_COLS = [
  {
    dataKey: 'rowindex',
    label: 'Nr.',
    class: 'nr',
  },
  {
    dataKey: 'erstellt',
    label: 'Erstellt',
    sortColumn: 'createDate',
    className: 'date ',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'art ',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    sortColumn: 'schriftverkehr.akt.name',
    className: 'aktenzeichen ',
  },
  {
    dataKey: 'einbringerLabel',
    label: 'Einbringer',
    sortColumn: 'schriftverkehr.akt.name',
    className: 'einbringer ',
  },
  {
    dataKey: 'status',
    label: 'Status',
    sortColumn: 'status',
    className: 'status ',
  },
];

export const AUSGANG_RESPONSIVE_AUSGANG_TABLE_COLS = [
  {
    dataKey: 'erstellt',
    label: 'Erstellt',
    disableLabel: true,
    sortColumn: 'createDate',
    className: 'date font-weight-bold',
  },
  {
    dataKey: 'aktenzeichen',
    label: 'Aktenzeichen',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'font-weight-bold aktenzeichen',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    disableLabel: true,
    sortColumn: 'schriftverkehr.applikationsTyp',
    className: 'd-block',
  },
  {
    dataKey: 'einbringerLabel',
    label: 'Einbringer',
    disableLabel: true,
    sortColumn: 'schriftverkehr.akt.name',
    className: 'einbringer d-block',
  },
  {
    dataKey: 'status',
    label: 'Status',
    disableLabel: true,
    sortColumn: 'status',
    className: 'w-50',
  },
  {
    dataKey: 'senden',
    className: 'send w-50',
  },
];


export const THB_UEBERSICHT_TABLE_COLS = [
  {
    dataKey: 'rowindex',
    label: 'Nr.',
    class: 'nr',
  },
  {
    dataKey: 'typ',
    label: 'Typ',
    sortColumn: 'type',
  },
  {
    dataKey: 'art',
    label: 'Schriftsatzart',
    sortColumn: 'schriftverkehr.applikationsTyp',
  },
  {
    dataKey: 'erstellt',
    label: 'Erstellt',
    sortColumn: 'createDate',
    className: 'date',
  },
  {
    dataKey: 'eingebracht',
    label: 'Eingebracht',
    sortColumn: 'bereitstellZeitpunkt',
    className: 'date',
  },
  {
    dataKey: 'status',
    label: 'Status',
    sortColumn: 'status',
    className: 'status',
    disableLabel: true,
  },
];

export const AUSGANG_DEFAULT_SORT = {
  id: 'status',
  direction: 'ASC',
};

export const VALIDATION_INFO_TEXT_1 = 'Der Schriftsatz wird auf Geschäftsregeln überprüft (validiert).';
export const VALIDATION_INFO_TEXT_2 = 'Es kann nur ein geprüfter Schriftsatz, Status „Geprüft“ übermittelt werden.';
export const VALIDATION_INFO_TEXT_3 = 'Sollte eine Verletzung einer Geschäftsregel vorliegen, werden die Fehlermeldungen hier aufgelistet:';
export const VALIDATION_SUCESS_TEXT_1 = 'Der Schriftsatz wurde erfolgreich geprüft!';
export const VALIDATION_SUCESS_TEXT_2 = 'Zum Versenden, wählen Sie bitte das Menü „Senden/Empfangen“.';

export const AUSGANG = 'ausgang';
export const EINGANG = 'eingang';
export const REST_RESULT = 'restResultObject';
export const DEFAULT_PLACEHOLDER = 'Eingeben oder Auswählen...';

export const SUFFIX_APPLIKATION_PATH = {
  [INHALT_ART.VF_VFGH.value]: PATH.VF.name,
  [INHALT_ART.VF_VWGH.value]: PATH.VW.name,
  [INHALT_ART.VF_ASYLGH.value]: PATH.BW.name,

  [INHALT_ART.FB_Neu.value]: PATH.FB_NEU.name,
  [INHALT_ART.FB_Antrag.value]: PATH.FB_FIRMA.name,
  [INHALT_ART.FB_Neu_OG.value]: PATH.FB_NEU_ONLINE.name,
  [INHALT_ART.FB_Jahres.value]: PATH.FB_JAHRESABSCHLUSS.name,
  [INHALT_ART.FB_AendrAntr.value]: PATH.FB_AENDERUNGSANTRAG.name,
  [INHALT_ART.FB_LoeschAntr.value]: PATH.FB_LOESCHUNGSANTRAG.name,
  [INHALT_ART.FB_NeueinAntr.value]: PATH.FB_NEUEINTRAGUNGSANTRAG.name,

  [INHALT_ART.VJ_Mkc.value]: PATH.VJ_MAHN_C.name,
  [INHALT_ART.VJ_Mkcg.value]: PATH.VJ_MAHN_CG.name,
  [INHALT_ART.VJ_Mkcga.value]: PATH.VJ_MAHN_CGA.name,
  [INHALT_ART.VJ_Skc.value]: PATH.VJ_KLAGE_C.name,
  [INHALT_ART.VJ_Skcg.value]: PATH.VJ_KLAGE_CG.name,
  [INHALT_ART.VJ_Skcga.value]: PATH.VJ_KLAGE_CGA.name,
  [INHALT_ART.VJ_Srs.value]: PATH.VJ_KLAGE_CGS.name,
  [INHALT_ART.VJ_Serst.value]: PATH.VJ_ERSTEEINGABE.name,
  [INHALT_ART.VJ_Folge.value]: PATH.VJ_FOLGEEINGABE.name,
  [INHALT_ART.VJ_Exe.value]: PATH.VJ_STRU_EXE.name,
  [INHALT_ART.VJ_ExeNStru.value]: PATH.VJ_NICHT_STRU_EXE.name,
  [INHALT_ART.VJ_Exesonst.value]: PATH.VJ_SONST_EXE.name,
  [INHALT_ART.VJ_Kuenc.value]: PATH.VJ_Kuenc.name,
  [INHALT_ART.VJ_Kuencga.value]: PATH.VJ_Kuencga.name,
  [INHALT_ART.VJ_EVc.value]: PATH.VJ_EVc.name,
  [INHALT_ART.VJ_EVcg.value]: PATH.VJ_EVcg.name,
  [INHALT_ART.VJ_EVcga.value]: PATH.VJ_EVcga.name,
  [INHALT_ART.VJ_Kerst.value]: PATH.VJ_Kerst.name,

  [INHALT_ART.TLDZ.value]: PATH.TLDZ.name,
  [INHALT_ART.EZUSTELLUNG.value]: PATH.EZUSTELLUNG.name,

  [INHALT_ART.GB_AN.value]: PATH.GB_AN.name,
  [INHALT_ART.GB_MI.value]: PATH.GB_MI.name,
  [INHALT_ART.GB_ER.value]: PATH.GB_ER.name,
  [INHALT_ART.GB_URK_SCH.value]: PATH.GB_URK_SCH.name,
  [INHALT_ART.GB_GRUPPE.value]: PATH.GB_GRUPPE.name,

  [INHALT_ART.EU_M.value]: PATH.EU_M.name,
  [INHALT_ART.EU_C.value]: PATH.EU_C.name,
  [INHALT_ART.EU_V.value]: PATH.EU_V.name,
  [INHALT_ART.EU_F.value]: PATH.EU_F.name,
  [INHALT_ART.EU_Brief.value]: PATH.EU_Brief.name,

  [INHALT_ART.THB_A2K_ABM.value]: PATH.THB_A2K_ABM.name,
  [INHALT_ART.THB_A2K_UEM.value]: PATH.THB_A2K_UEM.name,
  [INHALT_ART.THB_A2K_AKVAM.value]: PATH.THB_A2K_AKVAM.name,
  [INHALT_ART.THB_A2K_AM.value]: PATH.THB_A2K_AM.name,
  [INHALT_ART.THB_A2K_AZM.value]: PATH.THB_A2K_AZM.name,
  [INHALT_ART.THB_A2B_KVA.value]: PATH.THB_A2B_KVA.name,
  [INHALT_ART.THB_A2K_BM.value]: PATH.THB_A2K_BM.name,
  [INHALT_ART.THB_A2B_VM.value]: PATH.THB_A2B_VM.name,
  [INHALT_ART.THB_A2K_EM.value]: PATH.THB_A2K_EM.name,
  [INHALT_ART.THB_A2K_FM.value]: PATH.THB_A2K_FM.name,
  [INHALT_ART.THB_A2B_KAA.value]: PATH.THB_A2B_KAA.name,
  [INHALT_ART.THB_A2K_VEM.value]: PATH.THB_A2K_VEM.name,
  [INHALT_ART.THB_A2K_VFM.value]: PATH.THB_A2K_VFM.name,
  [INHALT_ART.THB_A2B_AM.value]: PATH.THB_A2B_AM.name,
  [INHALT_ART.THB_A2B_AEM.value]: PATH.THB_A2B_AEM.name,
  [INHALT_ART.THB_A2B_BM.value]: PATH.THB_A2B_BM.name,
  [INHALT_ART.THB_A2B_KSM.value]: PATH.THB_A2B_KSM.name,
  [INHALT_ART.THB_A2K_KVAM.value]: PATH.THB_A2K_KVAM.name,
  [INHALT_ART.THB_B2A_RKAA.value]: PATH.THB_B2A_RKAA.name,
  [INHALT_ART.THB_B2A_AFM.value]: PATH.THB_B2A_AFM.name,
  [INHALT_ART.THB_B2A_RKAM.value]: PATH.THB_B2A_RKAM.name,
  [INHALT_ART.THB_B2A_RKSM.value]: PATH.THB_B2A_RKSM.name,
  [INHALT_ART.THB_B2A_WAEM.value]: PATH.THB_B2A_WAEM.name,
  [INHALT_ART.THB_B2A_WKVA.value]: PATH.THB_B2A_WKVA.name,
  [INHALT_ART.THB_B2A_WSM.value]: PATH.THB_B2A_WSM.name,
  [INHALT_ART.THB_B2A_RKVA.value]: PATH.THB_B2A_RKVA.name,
  [INHALT_ART.THB_B2K_RKVA.value]: PATH.THB_B2K_RKVA.name,
  [INHALT_ART.THB_B2K_RSM.value]: PATH.THB_B2K_RSM.name,
  [INHALT_ART.THB_B2K_RVM.value]: PATH.THB_B2K_RVM.name,
  [INHALT_ART.THB_B2K_BM.value]: PATH.THB_B2K_BM.name,
  [INHALT_ART.THB_B2A_RAM.value]: PATH.THB_B2A_RAM.name,
  [INHALT_ART.THB_B2A_RAEM.value]: PATH.THB_B2A_RAEM.name,
  [INHALT_ART.THB_B2K_RAM.value]: PATH.THB_B2K_RAM.name,
  [INHALT_ART.THB_B2K_RAEM.value]: PATH.THB_B2K_RAEM.name,
  [INHALT_ART.THB_B2K_WKSM.value]: PATH.THB_B2K_WKSM.name,
  [INHALT_ART.THB_B2A_RSM.value]: PATH.THB_B2A_RSM.name,
  [INHALT_ART.THB_B2A_RVM.value]: PATH.THB_B2A_RVM.name,
  [INHALT_ART.THB_K2A_AM.value]: PATH.THB_K2A_AM.name,
  [INHALT_ART.THB_K2A_EM.value]: PATH.THB_K2A_EM.name,
  [INHALT_ART.THB_K2B_SM.value]: PATH.THB_K2B_SM.name,

  [INHALT_ART.BMI.value]: PATH.BMI.name,

  [INHALT_ART.STA_Eingabe.value]: PATH.STA_Eingabe.name,

  [INHALT_ART.STV.value]: PATH.STV.name,
};

export const SELECT_PLACEHOLDER = {
  value: null,
  label: 'Auswählen...',
};

export const BOOLEAN_OPTIONS = [
  {
    value: 'KEINE_AUSWAHL',
    label: 'Keine Auswahl',
  },
  {
    value: true,
    label: 'Ja',
  },
  {
    value: false,
    label: 'Nein',
  },
];

export const NOT_TRIM_STR = 'not-trim';
