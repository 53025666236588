// eslint-disable-next-line import/no-cycle
import { reportError } from '../ErrorReporter/errorReporter.helpers';
import 'whatwg-fetch';
import { getTimeout, handleRestError } from './RestError.utils';
import restReloadBridge from './RestReloadBridge';
import modalFactory from '../Modal/modalFactory';
import { fetchWithTimeout, TIMEOUT_TYPE } from './fetchWithTimeout';
import ConfirmationModal from '../Modal/ConfirmationModal';
import getFormattedMessage from '../FormatMessage/formattedMessage';
import {
  ACCEPTED,
  BAD_GATEWAY,
  CREATED,
  GATEWAY_TIMEOUT,
  NO_CONTENT,
  OK,
  PRECONDITION_FAILED,
  SERVICE_UNAVIALABLE,
  TIME_OUT,
  HINT,
} from './http.status.codes';
import TimeoutErrorModal from '../Modal/TimeoutErrorModal';
import ServerErrorModal from '../Modal/ServerErrorModal';
import InternetDisconnectErrorModal from '../Modal/InternetDisconnectErrorModal';
import { getNow } from '../../utils/general.utils';
import BackendDisconnectErrorModal from '../Modal/BackendDisconnectErrorModal';
import { RESPONSE_TYPE } from './response.constants';
// eslint-disable-next-line import/no-cycle
import { getMrsRestRoot, getRestRoot } from './rest.utils';

export const KEIN_PDF = 'kein.pdf';
export const FETCH_FAILED = 'Failed to fetch';

/**
 * disable IE11 GET caching
 * -> 'Cache-Control': 'no-cache'
 * -> 'Pragma': 'no-cache'
 */
export const withoutFileHeaders = {
  'Cache-Control': 'no-cache',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  'Pragma': 'no-cache', // eslint-disable-line quote-props
  'Accept': 'application/json', // eslint-disable-line quote-props
};

export const withFileHeaders = {
  'Access-Control-Allow-Origin': '*',
};

export const withoutFileHeadersXml = {
  'Cache-Control': 'no-cache',
  'Access-Control-Allow-Origin': '*',
  'Pragma': 'no-cache', // eslint-disable-line quote-props
  'Accept': 'text/xml', // eslint-disable-line quote-props
};

export const withoutFileHeadersHtml = {
  'Cache-Control': 'no-cache',
  'Access-Control-Allow-Origin': '*',
  'Pragma': 'no-cache', // eslint-disable-line quote-props
  'Accept': 'text/html', // eslint-disable-line quote-props
};

/**
 * rest fetch which returns true or false if the REST call was successfull or not
 * @param {string} method method of the REST request
 * @param {string} query the query string for the REST request
 * @param {Object} payload payload of the REST requestp
 * @param {Object} file pdf file of the REST request
 * @param {boolean} dontShowRestError do not show rest error
 * @param {function} doInterceptError intercept error and do something
 * @param {String} timeoutType timeoutType
 * @returns {Promise} Promise with result as json.
 */
async function makeRestCall(method, query, payload, file, dontShowRestError = false, doInterceptError, timeoutType = TIMEOUT_TYPE.DEFAULT) {
  const url = `${getRestRoot()}${query}`;

  // 1. make rest request config
  const requestConfig = {
    credentials: 'same-origin',
    method: method.toUpperCase(),
    body: _prepareRequestBody(file, payload),
    headers: file ? withFileHeaders : withoutFileHeaders,
  };

  return _handleRestCall(url, requestConfig, query, method, dontShowRestError, doInterceptError, !!file, getTimeout(timeoutType, !!file));
}

/**
 * rest fetch which returns true or false if the REST call was successfull or not for MRS ressources
 * @param {string} method method of the REST request
 * @param {string} query the query string for the REST request
 * @param {Object} payload payload of the REST request
 * @param {String} responseType response type
 * @param {String} timeoutType timeoutType
 * @returns {Promise} Promise with result as json.
 */
export async function makeExternalRestCall(method, query, payload, responseType = RESPONSE_TYPE.XML, timeoutType = TIMEOUT_TYPE.DEFAULT) {
  const url = `${getMrsRestRoot()}${query}`;

  // 1. make rest request config
  const requestConfig = {
    headers: withoutFileHeaders,
    method: method.toUpperCase(),
    body: _prepareRequestBody(null, payload),
  };

  const timeout = getTimeout(timeoutType);

  if (method.toUpperCase() === 'GET') {
    requestConfig.headers = responseType === RESPONSE_TYPE.XML ? withoutFileHeadersXml : withoutFileHeadersHtml;
    return _doRestCall(url, requestConfig, query, method, true, false, timeout);
  }

  return _handleRestCall(url, requestConfig, query, method, true, null, false, timeout);
}

/**
 * rest call handling for all use cases
 * @param {string} url absolut REST endpoint url
 * @param {Object} requestConfig request object
 * @param {string} query the query string for the REST request
 * @param {string} method method of the REST request
 * @param {boolean} dontShowRestError do not show rest error
 * @param {function} doInterceptError intercept error and do something
 * @param {Boolean} hasFile hasFile
 * @param {Number} timeout timeout
 * @returns {Promise} Promise with result as json.
 */
async function _handleRestCall(url, requestConfig, query, method, dontShowRestError = false, doInterceptError, hasFile, timeout) { // eslint-disable-line
  // 2. make rest call
  const response = await _doRestCall(url, requestConfig, query, method, dontShowRestError, hasFile, timeout);

  const { status } = response;

  // 3. handle disconnect error, server error, time out
  if (handleDisconnectOrServerOrTimeoutError(status, query, timeout, dontShowRestError)) {
    return response;
  }

  // 4. handle results
  let json;
  try {
    json = await response.json();
  } catch (e) {
    json = null;
  }

  // 5. handle success
  if (status && (status === OK || status === CREATED || status === ACCEPTED || status === NO_CONTENT || status === HINT)) {
    return _handleSuccess(status, dontShowRestError, json, url);
  }

  // 6. handle error
  return _handleError(dontShowRestError, doInterceptError, json, url, status, query, method);
}

/**
 * hande disconnect error, server error, timeout error
 * @param {String} status status
 * @param {String} query query
 * @param {Number} timeout time in milliseconds
 * @param {Boolean} dontShowRestError dontShowRestError
 * @return {Boolean} has error ?
 */
export function handleDisconnectOrServerOrTimeoutError(status, query, timeout, dontShowRestError = false) {
  let hasError = false;
  let message = '';

  // 1. disconnect error
  if (!dontShowRestError && status === FETCH_FAILED) {
    // 1.1 backend disconnect error
    if (navigator && navigator.onLine) {
      modalFactory.open(
        BackendDisconnectErrorModal,
        {
          uhrzeit: getNow(),
          logText: getFormattedMessage({ id: 'disconnect.backend.text' }),
          logTitel: getFormattedMessage({ id: 'disconnect.backend.titel' }),
        },
      );
      message = `Error: Backend disconnect; Endpoint: ${query}`;
    } // eslint-disable-line

    // 1.2 internet disconnect error
    else {
      modalFactory.open(
        InternetDisconnectErrorModal,
        {
          uhrzeit: getNow(),
          logText: getFormattedMessage({ id: 'disconnect.internet.text' }),
          logTitel: getFormattedMessage({ id: 'disconnect.internet.titel' }),
        },
      );
      message = `Error: Internet disconnect; Endpoint: ${query}`;
    }
    hasError = true;
  }

  // 2. server errors
  if (!dontShowRestError && (status === BAD_GATEWAY || status === SERVICE_UNAVIALABLE || status === GATEWAY_TIMEOUT)) {
    modalFactory.open(
      ServerErrorModal,
      {
        uhrzeit: getNow(),
        logText: getFormattedMessage({ id: `${status.toString()}.text` }),
        logTitel: getFormattedMessage({ id: `${status.toString()}.titel` }),
      },
    );
    message = `Error: Server error with Http Code ${status}; Endpoint: ${query}`;
    hasError = true;
  }

  // 3. timeout error
  if (!dontShowRestError && status === TIME_OUT) {
    modalFactory.open(
      TimeoutErrorModal,
      {
        uhrzeit: getNow(),
        logText: getFormattedMessage({ id: 'timeout' }),
        logTitel: getFormattedMessage({ id: 'timeout' }),
      },
    );
    message = `Error: Timeout, in ${timeout} milliseconds; Endpoint: ${query}`;
    hasError = true;
  }

  if (hasError) {
    reportError(
      message,
      null,
      'ERROR',
      (window && window.location) ? window.location.href || window.location.pathname : '',
    );
  }

  return hasError;
}

/**
 * prepare request body
 * @param {Object} file file
 * @param {Object} payload payload
 * @return {Object} request body
 * @private
 */
function _prepareRequestBody(file, payload) { // eslint-disable-line
  let reqBody = null;
  if (file) {
    reqBody = new FormData();
    if (payload) {
      reqBody.append('metadata', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
    }
    if (file !== KEIN_PDF) {
      reqBody.append('file', file);
    }
  } else if (payload) {
    reqBody = JSON.stringify(payload);
  }
  return reqBody;
}

/**
 * handle rest call
 * @param {String} url url
 * @param {Object} requestConfig requestConfig
 * @param {string} query the query string for the REST request
 * @param {string} method method of the REST request
 * @param {boolean} dontShowRestError do not show rest error
 * @param {Boolean} hasFile hasFile
 * @param {Boolean} timeout timeout
 * @return {Promise<*>} response
 * @private
 */
async function _doRestCall(url, requestConfig, query, method, dontShowRestError = false, hasFile, timeout) { // eslint-disable-line
  let response;

  await fetchWithTimeout(url, requestConfig, timeout)
    .then((data) => {
      response = data;
    })
    .catch((e) => {
      console.log(`${query} ${method} Fetch throwed: `, e); // eslint-disable-line no-console

      if (!dontShowRestError) {
        handleRestError(url, e.status, e);
      }

      response = {
        body: e,
        error: true,
        errorInfo: 'FETCH_THROWED',
        status: e.status || FETCH_FAILED,
      };
    });
  return response;
}

/**
 * handle if rest call is success
 * @param {String} status status
 * @param {boolean} dontShowRestError do not show rest error
 * @param {Object} json result json
 * @param {String} url url
 * @return {{error: boolean, body: *, status: _handleSuccess.props}} rest call result
 * @private
 */
function _handleSuccess(status, dontShowRestError = false, json, url) { // eslint-disable-line
  if (status === HINT && !dontShowRestError) {
    modalFactory.open(
      ConfirmationModal,
      {
        disableClose: true,
        header: 'Hinweis',
        body: getFormattedMessage(json.errorDetailCode),
      },
    );
    restReloadBridge.reload(url);
  }

  return ({
    status,
    body: json,
    error: false,
  });
}

/**
 * handle if rest call is fails
 * @param {boolean} dontShowRestError do not show rest error
 * @param {function} doInterceptError intercept error and do something
 * @param {Object} json result json
 * @param {String} url url
 * @param {String} status status
 * @param {string} query the query string for the REST request
 * @param {string} method method of the REST request
 * @return {{errorInfo: string, error: boolean, body: ({errorDetailCode}|*), status: *}} error result
 * @private
 */
function _handleError(dontShowRestError, doInterceptError = false, json, url, status, query, method) { // eslint-disable-line
  if (!dontShowRestError && !(status && status === PRECONDITION_FAILED) && !(doInterceptError && doInterceptError(json))) {
    handleRestError(url, status, json);
  }

  console.log(`${query} ${method} Response received status code: ${status}`); // eslint-disable-line no-console, max-len

  return ({
    status,
    body: json,
    error: true,
    errorInfo: 'WRONG_STATUS',
  });
}

export default makeRestCall;
