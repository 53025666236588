import React from 'react';
import invariant from 'invariant';
import deepEqual from 'deep-equal';
import shallowEqual from './shallowEqual';
// eslint-disable-next-line import/no-cycle
import requestScheduler from './RequestScheduler';
// eslint-disable-next-line import/no-cycle
import RestQuery from './RestQuery';
import {
  ERROR,
  FINISHED,
  FRESHDATA,
  LOADING,
  NODATA,
  OTHERDATA,
  STALEDATA,
} from './rest.constants';

/**
 * gets a display name for the rest hoc
 * @param {Object} WrappedComponent the wrapped
 * @returns {string} display name
 */
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * HoC for REST calls
 * @param {Object} connections REST connections/calls to the BE
 * @returns {JSX} wrapped component with REST client
 */
function withRestClient(connections) {
  const alias = 'RestHoc';

  const wrapWithRestClientComponent = (WrappedComponent) => {
    const restClientDisplayName = `${alias}(${getDisplayName(WrappedComponent)})`;

    /**
     * rest container class
     */
    class RestContainer extends React.Component {
      static displayName = restClientDisplayName; // eslint-disable-line

      static WrappedComponent = WrappedComponent;

      /**
       * init rest queries -> create new queries
       * init rest states of queries -> state of request (e.g. LOADING), data type (e.g. STALEDATA)
       * @param {Object} props component properties
       */
      constructor(props) {
        super(props);
        this.hasMounted = null;

        this.componentId = null;
        this.connections = connections;

        this.childData = {}; // data for each property key (REST responses)
        this.queries = {}; // queries for each property key (the query the data should be set to)
        this.queryStates = {};
        this.propertyKeys = Object.keys(this.connections); // keys of the connection object -> "REST call keys"

        // functions available for the "client" (e.g. the wrapped component)
        this.clientFunctions = {
          setParameters: this.setParameters.bind(this),
          invalidate: this.invalidate.bind(this),
          forceFetch: this.forceFetch.bind(this),
        };

        this.initQueries(props);
        this.initQueryStates();
      }

      /**
       * comp will mount
       * -> register the component to the rest scheduler
       * -> subscribe to queries
       * @returns {undefined}
       */
      componentWillMount() {
        this.hasMounted = true;
        this.componentId = requestScheduler.registerComponent(this);
        this.subscribeToQueries();
      }

      /**
       * comp will unmount
       * -> unregister component from scheduler
       * @returns {undefined}
       */
      componentWillUnmount() {
        this.hasMounted = false;
        requestScheduler.unregisterComponent(this.componentId);
      }

      /**
       * initialize queries
       * creates new rest query that will be executed from the client
       * @param {Object} props properties used to init the initial properties
       * @returns {undefined}
       */
      initQueries(props) { // eslint-disable-line
        this.queries = {};
        for (let i = 0; i < this.propertyKeys.length; i += 1) {
          const propKey = this.propertyKeys[i];
          const connection = this.connections[propKey];
          let params = {};
          if (connection.initialParameters && typeof (connection.initialParameters === 'function')) { // eslint-disable-line
            params = connection.initialParameters(props);
          }
          this.queries[propKey] = new RestQuery(
            connection.urlTemplate,
            params,
          );
        }
      }

      /**
       * initialize the states of the rest queries
       * at initialization all request are in the loading state and have no data
       * @returns {undefined}
       */
      initQueryStates() {
        this.queryStates = {};
        for (let i = 0; i < this.propertyKeys.length; i += 1) {
          const propKey = this.propertyKeys[i];
          this.queryStates[propKey] = {
            request: LOADING,
            data: NODATA,
            urlTemplate: null,
            parameters: null,
          };
        }
      }

      /**
       * subscribe to all queries
       * @returns {undefined}
       */
      subscribeToQueries() {
        for (let i = 0; i < this.propertyKeys.length; i += 1) {
          // Fire and forget subscriptions - pew pew
          const key = this.propertyKeys[i];
          this.subscribeToQuery(key);
        }
      }

      /**
       * actual subscription of a rest call. the request will be executed by the scheduler.
       * after the request finishes the component will be notified
       * @param {String} key rest query connection key
       * @param {number} maxAge maxAge of the query
       * @returns {undefined}
       */
      subscribeToQuery(key, maxAge = null) {
        const connection = this.connections[key];

        const compiledUrl = this.queries[key].getQuery();
        const urlTemplate = this.queries[key].urlTemplate;
        const parameters = this.queries[key].getParameters();

        if (typeof (connection.disabled) === 'function') {
          if (connection.disabled(parameters)) {
            this.queryStates[key] = {
              request: FINISHED,
              data: NODATA,
              delayedLoading: false,
              urlTemplate,
              parameters,
            };
            delete this.childData[key];
            this.forceRenderChildComponents();
            return;
          }
        }

        const oldQueryState = this.queryStates[key];

        let dataState = NODATA;
        if (key in this.childData) {
          dataState = STALEDATA;
          if (
            (!shallowEqual(oldQueryState.parameters, parameters))
            && (!!parameters) === (!!oldQueryState.parameters)
          ) {
            dataState = OTHERDATA;
          }
        }

        let ma = null;
        if (maxAge !== null) {
          ma = maxAge;
        } else if (connection.maxAge) {
          ma = connection.maxAge;
        }

        const result = requestScheduler.requestData(
          this.componentId, key, compiledUrl, urlTemplate, parameters, ma,
        );

        if (result.fresh) {
          this.queryStates[key] = {
            request: FINISHED,
            data: FRESHDATA,
            urlTemplate,
            parameters,
          };
          this.childData[key] = result.data;
        } else {
          if (result.data) { // eslint-disable-line
            this.queryStates[key] = {
              ...oldQueryState,
              request: FINISHED,
              data: FRESHDATA,
              urlTemplate,
              parameters,
            };
          } else {
            this.queryStates[key] = {
              ...oldQueryState,
              request: LOADING,
              data: dataState,
            };
          }
        }

        this.forceRenderChildComponents();
      }

      /**
       * called after successfully finishing a query. it sets the childData and forces a render
       * of the component.
       * @param {string} key the property key for which the query was received
       * @param {string} query the query string (= compiled url incl params) used for this request
       * @param {string} params the parameters used for this request
       * @param {Object} json the json object returned by the query
       * @returns {undefined}
       */
      receiveQuery(key, query, params, json) {
        const targetQuery = this.queries[key].getQuery();
        if (query !== targetQuery) {
          console.log( // eslint-disable-line no-console
            'recevied data from an outdated query. ignoring', key, query, params,
          );
          return;
        }

        // for iterated queries to know if to update the childdata if necessary
        if (this.queryStates[key].data === STALEDATA) {
          // only replace the object if it is not deep equal the same to avoid rerender
          if (!deepEqual(this.childData[key], json, { strict: true })) {
            requestScheduler.clearTemplate(this.queries[key].template);
            this.childData[key] = json;
          }
        } else {
          this.childData[key] = json;
        }
        this.queryStates[key] = {
          request: FINISHED,
          data: FRESHDATA,
          delayedLoading: false,
          query,
          parameters: params,
        };

        this.forceRenderChildComponents();
      }

      /**
       * query execution throwed an error. update the corresponding queryState and set the
       * request to ERROR
       * @param {string} key the property key for which the query was an error
       * @param {string} query the query string of the request which finished with an error
       * @param {string} params the parameters of the request which finished with an error
       * @param {string|Object} e The exception or error message
       * @returns {undefined}
       */
      receiveError(key, query, params, e) {
        const targetQuery = this.queries[key].getQuery();
        if (query !== targetQuery) {
          console.log( // eslint-disable-line no-console
            'received error from an outdated query. ignoring.', key, query,
          );
          return;
        }
        // this.stopDelayedLoadingState(key);
        this.queryStates[key] = {
          request: ERROR,
          data: NODATA,
          delayedLoading: false,
          query,
          parameters: params,
        };

        delete this.childData[key]; // delete data if error
        console.log(e); // eslint-disable-line no-console

        this.forceRenderChildComponents();
      }

      /**
       * refetch a connection with maxAge default 0
       * @param {string} propKey the property key for witch to trigger a forced fetch
       * @param {number} maxAge optional maxAge for forcefetch to allow also deduplication
       * @returns {undefined}
       */
      forceFetch(propKey, maxAge = 0) {
        this.subscribeToQuery(propKey, maxAge);
      }

      invalidate() { // eslint-disable-line
        // TODO: implement
      }

      /**
       * set new parameters for an existing rest query
       * @param {String} propKey connection prop key
       * @param {Object} params new params for the next rest call for a connection
       * @returns {undefined}
       */
      setParameters(propKey, params) {
        invariant(propKey in this.queries, `setParameters: property ${propKey} is not handled by RestClient`);
        this.queries[propKey].setParameters(params);
        this.subscribeToQuery(propKey);
      }

      /**
       * rerender the child component
       * @returns {undefined}
       */
      forceRenderChildComponents() {
        this.shouldRerender = true;
        if (this.hasMounted) {
          this.setState(() => ({}));
        }
      }

      /**
       * get data for child component
       * compiles the properties to inject into the child component
       * @returns {Object} injected properties
       */
      getPropsForChildComponents() {
        const result = {
          restClient: this.clientFunctions,
          restState: {},
        };
        for (let i = 0; i < this.propertyKeys.length; i += 1) {
          const key = this.propertyKeys[i];
          if (key in this.childData) {
            result[key] = this.childData[key];
          }
          result.restState[key] = {
            ...this.queryStates[key],
          };
        }
        return result;
      }

      /**
       * render the wrapped component and pass props to it
       * @returns {JSX} component JSX
       */
      render() {
        const { shouldRerender, renderedElement, props } = this;
        this.shouldRerender = false;
        const clientProps = this.getPropsForChildComponents();
        const mergedPropsAndData = {
          ...props,
          ...clientProps,
        };

        if (!shouldRerender && renderedElement) {
          return renderedElement;
        }

        this.renderedElement = React.createElement(WrappedComponent, mergedPropsAndData);
        return this.renderedElement;
      }
    }

    return RestContainer;
  };

  return wrapWithRestClientComponent;
}

export default withRestClient;
