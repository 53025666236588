import PATH from '../path.json';
import INHALT_ART from '../schriftsatz.inhalt.art.json';
import BEGEHREN_ART from '../gb.begehren.art.json';
import { INHATL_ELEMENT_TYPS as INHALT_ELEMENT_TYPS } from './inhalt.element.constants';

// #############################################################
// ### VJ ###
// #############################################################
/**
 * get vj base url
 * @param {string} inhaltArt inhaltArt
 * @return {string} base url
 */
function _getVJMahnklageBaseTabUrl(inhaltArt) { // eslint-disable-line
  let baseUrl = null;
  switch (inhaltArt) {
    case INHALT_ART.VJ_Mkc.value:
      baseUrl = `${PATH.SCHRIFTSATZ.value}${PATH.VJ_MAHN_C.value}`;
      break;
    case INHALT_ART.VJ_Mkcg.value:
      baseUrl = `${PATH.SCHRIFTSATZ.value}${PATH.VJ_MAHN_CG.value}`;
      break;
    case INHALT_ART.VJ_Mkcga.value:
      baseUrl = `${PATH.SCHRIFTSATZ.value}${PATH.VJ_MAHN_CGA.value}`;
      break;
    default:
      return null;
  }
  return baseUrl;
}

/**
 * get vj mahnklage tabs
 * @param {string} inhaltArt inhaltArt
 * @return {array} mahnklage tabs
 */
export function getVJMahnklageTabs(inhaltArt) {
  const baseUrl = _getVJMahnklageBaseTabUrl(inhaltArt);

  return [
    {
      value: PATH.BASISDATEN.name,
      id: 'basisdaten-tab',
      label: PATH.BASISDATEN.label,
      url: baseUrl,
    },
    {
      value: PATH.BETEILIGTE.name,
      id: 'beteiligte-tab',
      label: PATH.BETEILIGTE.label,
      url: `${baseUrl}${PATH.BETEILIGTE.value}`,
    },
    {
      value: PATH.FORDERUNGEN.name,
      id: 'forderungen-tab',
      label: PATH.FORDERUNGEN.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ANSPRUCH.value}`,
    },
    {
      value: PATH.VORBRINGEN.name,
      id: 'vorbringen-tab',
      label: PATH.VORBRINGEN.label,
      url: `${baseUrl}${PATH.VORBRINGEN.value}`,
    },
    {
      value: PATH.DOKUMENTE.name,
      id: 'dokumente-tab',
      label: PATH.DOKUMENTE.label,
      url: `${baseUrl}${PATH.DOKUMENTE.value}`,
    },
    {
      value: PATH.PRUEFEN.name,
      id: 'pruefen-tab',
      label: PATH.PRUEFEN.label,
      url: `${baseUrl}${PATH.PRUEFEN.value}`,
    },
    {
      value: PATH.PROTOKOLL.name,
      id: 'protokoll-tab',
      label: PATH.PROTOKOLL.label,
      url: `${baseUrl}${PATH.PROTOKOLL.value}`,
    },
  ];
}

/**
 * get vj klage base url
 * @param {string} inhaltArt inhaltArt
 * @return {string} base url
 */
function _getVJDefaultBaseTabUrl(inhaltArt) { // eslint-disable-line
  switch (inhaltArt) {
    case INHALT_ART.VJ_Skc.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_KLAGE_C.value}`;
    case INHALT_ART.VJ_Skcg.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_KLAGE_CG.value}`;
    case INHALT_ART.VJ_Skcga.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_KLAGE_CGA.value}`;
    case INHALT_ART.VJ_Srs.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_KLAGE_CGS.value}`;
    case INHALT_ART.VJ_Serst.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_ERSTEEINGABE.value}`;
    case INHALT_ART.VJ_Folge.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_FOLGEEINGABE.value}`;
    case INHALT_ART.VJ_Exe.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_STRU_EXE.value}`;
    case INHALT_ART.VJ_ExeNStru.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_NICHT_STRU_EXE.value}`;
    case INHALT_ART.VJ_Exesonst.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_SONST_EXE.value}`;
    case INHALT_ART.VJ_Kuenc.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_Kuenc.value}`;
    case INHALT_ART.VJ_Kuencga.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_Kuencga.value}`;
    case INHALT_ART.VJ_EVc.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_EVc.value}`;
    case INHALT_ART.VJ_EVcg.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_EVcg.value}`;
    case INHALT_ART.VJ_EVcga.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_EVcga.value}`;
    case INHALT_ART.VJ_Kerst.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.VJ_Kerst.value}`;
    default:
      return null;
  }
}

/**
 * get vj klage tabs
 * @param {string} inhaltArt inhaltArt
 * @return {array} klage tabs
 */
export function getVJDefaultTabs(inhaltArt) {
  const baseUrl = _getVJDefaultBaseTabUrl(inhaltArt);

  return [
    {
      value: PATH.BASISDATEN.name,
      id: 'basisdaten-tab',
      label: PATH.BASISDATEN.label,
      url: baseUrl,
    },
    {
      value: PATH.BETEILIGTE.name,
      id: 'beteiligte-tab',
      label: PATH.BETEILIGTE.label,
      url: `${baseUrl}${PATH.BETEILIGTE.value}`,
    },
    {
      value: PATH.VORBRINGEN.name,
      id: 'vorbringen-tab',
      label: PATH.VORBRINGEN.label,
      url: `${baseUrl}${PATH.VORBRINGEN.value}`,
    },
    {
      value: PATH.DOKUMENTE.name,
      id: 'dokumente-tab',
      label: PATH.DOKUMENTE.label,
      url: `${baseUrl}${PATH.DOKUMENTE.value}`,
    },
    {
      value: PATH.PRUEFEN.name,
      id: 'pruefen-tab',
      label: PATH.PRUEFEN.label,
      url: `${baseUrl}${PATH.PRUEFEN.value}`,
    },
    {
      value: PATH.PROTOKOLL.name,
      id: 'protokoll-tab',
      label: PATH.PROTOKOLL.label,
      url: `${baseUrl}${PATH.PROTOKOLL.value}`,
    },
  ];
}

/**
 * get vj exekutionsantrag tabs
 * @param {String} inhaltArt inhaltArt
 * @return {*[]} tabs
 */
export function getVJExekutionsantragTabs(inhaltArt) {
  const baseUrl = _getVJDefaultBaseTabUrl(inhaltArt);

  return [
    {
      value: PATH.BASISDATEN.name,
      id: 'basisdaten-tab',
      label: PATH.BASISDATEN.label,
      url: baseUrl,
    },
    {
      value: PATH.BETEILIGTE.name,
      id: 'beteiligte-tab',
      label: PATH.BETEILIGTE.label,
      url: `${baseUrl}${PATH.BETEILIGTE.value}`,
    },
    {
      value: PATH.TITEL_KOSTEN.name,
      id: 'titel-kosten-tab',
      label: PATH.TITEL_KOSTEN.label,
      url: `${baseUrl}${PATH.TITEL_KOSTEN.value}${PATH.TITEL.value}`,
    },
    {
      value: PATH.VORBRINGEN.name,
      id: 'vorbringen-tab',
      label: PATH.VORBRINGEN.label,
      url: `${baseUrl}${PATH.VORBRINGEN.value}`,
    },
    {
      value: PATH.DOKUMENTE.name,
      id: 'dokumente-tab',
      label: PATH.DOKUMENTE.label,
      url: `${baseUrl}${PATH.DOKUMENTE.value}`,
    },
    {
      value: PATH.PRUEFEN.name,
      id: 'pruefen-tab',
      label: PATH.PRUEFEN.label,
      url: `${baseUrl}${PATH.PRUEFEN.value}`,
    },
    {
      value: PATH.PROTOKOLL.name,
      id: 'protokoll-tab',
      label: PATH.PROTOKOLL.label,
      url: `${baseUrl}${PATH.PROTOKOLL.value}`,
    },
  ];
}

/**
 * get vj exekutionsantrag tabs
 * @param {String} inhaltArt inhaltArt
 * @return {*[]} tabs
 */
export function getVJkartellerstantragTabs(inhaltArt) {
  const baseUrl = _getVJDefaultBaseTabUrl(inhaltArt);

  return [
    {
      value: PATH.BASISDATEN.name,
      id: 'basisdaten-tab',
      label: PATH.BASISDATEN.label,
      url: baseUrl,
    },
    {
      value: PATH.BETEILIGTE.name,
      id: 'beteiligte-tab',
      label: PATH.BETEILIGTE.label,
      url: `${baseUrl}${PATH.BETEILIGTE.value}`,
    },
    {
      value: PATH.DOKUMENTE.name,
      id: 'dokumente-tab',
      label: PATH.DOKUMENTE.label,
      url: `${baseUrl}${PATH.DOKUMENTE.value}`,
    },
    {
      value: PATH.PRUEFEN.name,
      id: 'pruefen-tab',
      label: PATH.PRUEFEN.label,
      url: `${baseUrl}${PATH.PRUEFEN.value}`,
    },
    {
      value: PATH.PROTOKOLL.name,
      id: 'protokoll-tab',
      label: PATH.PROTOKOLL.label,
      url: `${baseUrl}${PATH.PROTOKOLL.value}`,
    },
  ];
}

/**
 * get vj forderungen tabs
 * @param {string} inhaltArt inhaltArt
 * @return {array} mahnklage tabs
 */
export function getVjForderungenTabs(inhaltArt) {
  const baseUrl = _getVJMahnklageBaseTabUrl(inhaltArt);

  return [
    {
      value: PATH.ANSPRUCH.name,
      id: 'anspruch-tab',
      label: PATH.ANSPRUCH.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ANSPRUCH.value}`,
    },
    {
      value: PATH.ZINSEN.name,
      id: 'zinsen-tab',
      label: PATH.ZINSEN.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ZINSEN.value}`,
    },
    {
      value: PATH.ERGAENZUNGEN.name,
      id: 'ergaenzungen-tab',
      label: PATH.ERGAENZUNGEN.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ERGAENZUNGEN.value}`,
    },
    {
      value: PATH.BEWEISE.name,
      id: 'beweise-tab',
      label: PATH.BEWEISE.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.BEWEISE.value}`,
    },
    {
      value: PATH.BESONDERE_ZUSTAENDIGKEIT.name,
      id: 'besondere-zustaendigkeit-tab',
      label: PATH.BESONDERE_ZUSTAENDIGKEIT.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.BESONDERE_ZUSTAENDIGKEIT.value}`,
    },
    {
      value: PATH.SONSTIGE_KOSTEN.name,
      id: 'sonstige-kosten-tab',
      label: PATH.SONSTIGE_KOSTEN.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.SONSTIGE_KOSTEN.value}`,
    },
  ];
}

/**
 * get vj titel/kosten tabs
 * @return {*[]} options
 */
export function getVJTitelKostenTabs() {
  return [
    {
      value: PATH.TITEL.name,
      id: 'titel-tab',
      label: PATH.TITEL.label,
      url: `${PATH.SCHRIFTSATZ.value}${PATH.VJ_STRU_EXE.value}${PATH.TITEL_KOSTEN.value}${PATH.TITEL.value}`,
    },
    {
      value: PATH.KOSTEN.name,
      id: 'kosten-tab',
      label: PATH.KOSTEN.label,
      url: `${PATH.SCHRIFTSATZ.value}${PATH.VJ_STRU_EXE.value}${PATH.TITEL_KOSTEN.value}${PATH.KOSTEN.value}`,
    },
  ];
}


// #############################################################
// ### FB ###
// #############################################################
export const FB_NEUEINTRAGUNG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU.value}${PATH.PROTOKOLL.value}`,
  },
];
export const FB_NEUEINTRAGUNG_ONLINE_GRUENDUNG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU_ONLINE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU_ONLINE.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU_ONLINE.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEU_ONLINE.value}${PATH.PROTOKOLL.value}`,
  },
];
export const FB_ANTRAG_ZUR_EINER_BESTEHTENDEN_FIRMA_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_FIRMA.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_FIRMA.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_FIRMA.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_FIRMA.value}${PATH.PROTOKOLL.value}`,
  },
];
export const FB_JAHRESABSCHLUSS_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_JAHRESABSCHLUSS.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_JAHRESABSCHLUSS.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_JAHRESABSCHLUSS.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_JAHRESABSCHLUSS.value}${PATH.PROTOKOLL.value}`,
  },
];
export const FB_AENDERUNGSANTRAG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}`,
  },
  {
    value: PATH.SACHVERHALT.name,
    id: 'sachverhalt-tab',
    label: PATH.SACHVERHALT.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.SACHVERHALT.value}`,
  },
  {
    value: PATH.FIRMA.name,
    id: 'firma-tab',
    label: PATH.FIRMA.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.FIRMA.value}`,
  },
  {
    value: PATH.PERSONEN.name,
    id: 'personen-tab',
    label: PATH.PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.PERSONEN.value}`,
  },
  {
    value: PATH.FUNKTIONEN.name,
    id: 'funktionen-tab',
    label: PATH.FUNKTIONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.FUNKTIONEN.value}`,
  },
  {
    value: PATH.ZWEIGNIEDERLASSUNGEN.name,
    id: 'zweigniederlassungen-tab',
    label: PATH.ZWEIGNIEDERLASSUNGEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.ZWEIGNIEDERLASSUNGEN.value}`,
  },
  {
    value: PATH.RECHTSTATSACHEN.name,
    id: 'rechtstatsachen-tab',
    label: PATH.RECHTSTATSACHEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.RECHTSTATSACHEN.value}`,
  },
  {
    value: PATH.KUMULIERTER_ANTRAG.name,
    id: 'kumulierter-antrag-tab',
    label: PATH.KUMULIERTER_ANTRAG.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.KUMULIERTER_ANTRAG.value}`,
  },
  {
    value: PATH.VISUALISIERUNG.name,
    id: 'visualisierung-tab',
    label: PATH.VISUALISIERUNG.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.VISUALISIERUNG.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.PRUEFEN.value}`,
    tooltip: 'Bitte wählen Sie den Menüpunkt "Visualisierung" - erst wenn diese erfolgreich ist, können Sie den Antrag "Prüfen" und versenden.',
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_AENDERUNGSANTRAG.value}${PATH.PROTOKOLL.value}`,
  },
];
export const FB_LOESCHUNGSANTRAG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}`,
  },
  {
    value: PATH.SACHVERHALT.name,
    id: 'sachverhalt-tab',
    label: PATH.SACHVERHALT.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.SACHVERHALT.value}`,
  },
  {
    value: PATH.FIRMA.name,
    id: 'firma-tab',
    label: PATH.FIRMA.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.FIRMA.value}`,
  },
  {
    value: PATH.PERSONEN.name,
    id: 'personen-tab',
    label: PATH.PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.PERSONEN.value}`,
  },
  {
    value: PATH.FUNKTIONEN.name,
    id: 'funktionen-tab',
    label: PATH.FUNKTIONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.FUNKTIONEN.value}`,
  },
  {
    value: PATH.ZWEIGNIEDERLASSUNGEN.name,
    id: 'zweigniederlassungen-tab',
    label: PATH.ZWEIGNIEDERLASSUNGEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.ZWEIGNIEDERLASSUNGEN.value}`,
  },
  {
    value: PATH.RECHTSTATSACHEN.name,
    id: 'rechtstatsachen-tab',
    label: PATH.RECHTSTATSACHEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.RECHTSTATSACHEN.value}`,
  },
  {
    value: PATH.KUMULIERTER_ANTRAG.name,
    id: 'kumulierter-antrag-tab',
    label: PATH.KUMULIERTER_ANTRAG.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.KUMULIERTER_ANTRAG.value}`,
  },
  {
    value: PATH.VISUALISIERUNG.name,
    id: 'visualisierung-tab',
    label: PATH.VISUALISIERUNG.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.VISUALISIERUNG.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.PRUEFEN.value}`,
    tooltip: 'Bitte wählen Sie den Menüpunkt "Visualisierung" - erst wenn diese erfolgreich ist, können Sie den Antrag "Prüfen" und versenden.',
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_LOESCHUNGSANTRAG.value}${PATH.PROTOKOLL.value}`,
  },
];
export const FB_NEUEINTRAGUNGSANTRAG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}`,
  },
  {
    value: PATH.SACHVERHALT.name,
    id: 'sachverhalt-tab',
    label: PATH.SACHVERHALT.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.SACHVERHALT.value}`,
  },
  {
    value: PATH.FIRMA.name,
    id: 'firma-tab',
    label: PATH.FIRMA.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.FIRMA.value}`,
  },
  {
    value: PATH.PERSONEN.name,
    id: 'personen-tab',
    label: PATH.PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.PERSONEN.value}`,
  },
  {
    value: PATH.FUNKTIONEN.name,
    id: 'funktionen-tab',
    label: PATH.FUNKTIONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.FUNKTIONEN.value}`,
  },
  {
    value: PATH.ZWEIGNIEDERLASSUNGEN.name,
    id: 'zweigniederlassungen-tab',
    label: PATH.ZWEIGNIEDERLASSUNGEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.ZWEIGNIEDERLASSUNGEN.value}`,
  },
  {
    value: PATH.RECHTSTATSACHEN.name,
    id: 'rechtstatsachen-tab',
    label: PATH.RECHTSTATSACHEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.RECHTSTATSACHEN.value}`,
  },
  {
    value: PATH.KUMULIERTER_ANTRAG.name,
    id: 'kumulierter-antrag-tab',
    label: PATH.KUMULIERTER_ANTRAG.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.KUMULIERTER_ANTRAG.value}`,
  },
  {
    value: PATH.VISUALISIERUNG.name,
    id: 'visualisierung-tab',
    label: PATH.VISUALISIERUNG.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.VISUALISIERUNG.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.PRUEFEN.value}`,
    tooltip: 'Bitte wählen Sie den Menüpunkt "Visualisierung" - erst wenn diese erfolgreich ist, können Sie den Antrag "Prüfen" und versenden.',
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.FB_NEUEINTRAGUNGSANTRAG.value}${PATH.PROTOKOLL.value}`,
  },
];


// #############################################################
// ### GB ###
// #############################################################
/**
 * get grundbuch tabs
 * @param {String} currentBegehrenPath currentBegehrenPath
 * @return {*[]} grundbuch tabs
 */
export function getGrundbuchsantragTabs(currentBegehrenPath) {
  let begehrenUrl = `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}`;
  if (currentBegehrenPath) {
    begehrenUrl = `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${currentBegehrenPath}`;
  }

  return [
    {
      value: PATH.BASISDATEN.name,
      id: 'basisdaten-tab',
      label: PATH.BASISDATEN.label,
      url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}`,
    },
    {
      value: PATH.BETEILIGTE.name,
      id: 'beteiligte-tab',
      label: PATH.BETEILIGTE.label,
      url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BETEILIGTE.value}`,
    },
    {
      value: PATH.DOKUMENTE.name,
      id: 'dokumente-tab',
      label: PATH.DOKUMENTE.label,
      url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.DOKUMENTE.value}`,
    },
    {
      value: PATH.BEGEHREN.name,
      id: 'begehren-tab',
      label: PATH.BEGEHREN.label,
      url: begehrenUrl,
    },
    {
      value: PATH.PRUEFEN.name,
      id: 'pruefen-tab',
      label: PATH.PRUEFEN.label,
      url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.PRUEFEN.value}`,
    },
    {
      value: PATH.PROTOKOLL.name,
      id: 'protokoll-tab',
      label: PATH.PROTOKOLL.label,
      url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.PROTOKOLL.value}`,
    },
  ];
}

export const GB_MITTEILUNG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_MI.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_MI.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_MI.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_MI.value}${PATH.PROTOKOLL.value}`,
  },
];

export const GB_URKUNDEN_SCHWAERZUNG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_URK_SCH.value}`,
  },
  {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_URK_SCH.value}${PATH.BETEILIGTE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_URK_SCH.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.BEGEHREN_FREITEXT.name,
    id: 'begehrensfreitext-tab',
    label: PATH.BEGEHREN_FREITEXT.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_URK_SCH.value}${PATH.BEGEHREN_FREITEXT.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_URK_SCH.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_URK_SCH.value}${PATH.PROTOKOLL.value}`,
  },
];

export const GB_ERGAENZUNG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_ER.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_ER.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_ER.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_ER.value}${PATH.PROTOKOLL.value}`,
  },
];

export const GB_ANTRAGSGRUPPE_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.GB_GRUPPE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_GRUPPE.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_GRUPPE.value}${PATH.PROTOKOLL.value}`,
  },
];

// Sonstige Begehren
export const SONSITGE_NEUE_EINLAGE_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const SONSITGE_NEUE_EINLAGE_UH_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NE_UH.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.labelBauwerk,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NE_UH.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const SONSITGE_ERSICHTLICHMACHUNG_UH_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NE_UH.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const SONSTIGE_NEUEINTRAGUNG_TABS = [
  {
    value: PATH.BEGEHREN_EINTRAGUNGSPFAD.name,
    id: 'eintragungspfad-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSPFAD.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_EINTRAGUNGSPFAD.value}`,
  },
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name,
    id: 'berechtigte-personen-tab',
    label: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_BERECHTIGTE_PERSONEN.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_BEGEHRENSTEXT.name,
    id: 'begehrenstext-tab',
    label: PATH.BEGEHREN_BEGEHRENSTEXT.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_BEGEHRENSTEXT.value}`,
  },
];
export const SONSTIGE_AENDERUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name,
    id: 'berechtigte-personen-tab',
    label: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AE.value}${PATH.BEGEHREN_BERECHTIGTE_PERSONEN.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AE.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AE.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_BEGEHRENSTEXT.name,
    id: 'begehrenstext-tab',
    label: PATH.BEGEHREN_BEGEHRENSTEXT.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AE.value}${PATH.BEGEHREN_BEGEHRENSTEXT.value}`,
  },
];
export const SONSTIGE_VORRANG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_VOR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_VOR.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_VOR.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const SONSTIGE_MITUEBERTRAGUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_MUE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_MUE.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const SONSTIGE_AUFFORDERUNGSVERFAHREN_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AV.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_GLAEUBIGER.name,
    id: 'glaeubiger-tab',
    label: PATH.BEGEHREN_GLAEUBIGER.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AV.value}${PATH.BEGEHREN_GLAEUBIGER.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AV.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
// Grundstücksveränderungen
export const GRUNDSTUECKSVERAENDERUNGEN_AB_ZU_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const GRUNDSTUECKSVERAENDERUNGEN_TRENN_TABS = [
  {
    value: PATH.BEGEHREN_TRENNSTUECKTABELLE.name,
    id: 'trennstueck-tab',
    label: PATH.BEGEHREN_TRENNSTUECKTABELLE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_TSTTAB.value}${PATH.BEGEHREN_TRENNSTUECKTABELLE.value}`,
  },
];
export const GRUNDSTUECKSVERAENDERUNGEN_TRENN_DURCHFUEHRUNG_TABS = [
  {
    value: PATH.BEGEHREN_TRENNSTUECKTABELLE.name,
    id: 'trennstueck-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_TSTDUR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_TSTDUR.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
// Eigentumsrecht
export const EIGENTUMSRECHT_ANMERKUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name,
    id: 'berechtigte-personen-tab',
    label: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_BERECHTIGTE_PERSONEN.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const EIGENTUMSRECHT_ANMERKUNG_WITHOUT_EINTRAGUNGSGEBUEHR_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name,
    id: 'berechtigte-personen-tab',
    label: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_BERECHTIGTE_PERSONEN.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const EIGENTUMSRECHT_AENDERUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_AEN.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_AEN.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_AEN.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const EIGENTUMSRECHT_BERICHTIGUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_BER.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_BER.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_BER.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_BER.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const EIGENTUMSRECHT_EINVERLEIBUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EIN.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EIN.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EIN.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EIN.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const EIGENTUMSRECHT_EINTRAGUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.name,
    id: 'eigentumsanteil-zeilen-tab',
    label: PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const EIGENTUMSRECHT_EINTRAGUNG_EIGENTUMSANTEIL_ZEILE_TABS = [
  {
    value: PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_DETAILS.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_DETAILS.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.value}${PATH.BEGEHREN_EIGENTUMSANTEILSZEILE.value}${PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_DETAILS.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.value}${PATH.BEGEHREN_EIGENTUMSANTEILSZEILE.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.value}${PATH.BEGEHREN_EIGENTUMSANTEILSZEILE.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const EIGENTUMSRECHT_VORMERKUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_VOR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_VOR.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_VOR.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_VOR.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
// Pfandrecht
export const PFANDRECHT_SIN_EINTRAGUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name,
    id: 'berechtigte-personen-tab',
    label: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_BERECHTIGTE_PERSONEN.value}`,
  },
  {
    value: PATH.BEGEHREN_FORDERUNGEN.name,
    id: 'forderungen-tab',
    label: PATH.BEGEHREN_FORDERUNGEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_FORDERUNGEN.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const PFANDRECHT_SIN_NACH_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_ANM_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_ANM_V2.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_ANM_V2.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const PFANDRECHT_SIM_EINTRAGUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name,
    id: 'berechtigte-personen-tab',
    label: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.value}${PATH.BEGEHREN_BERECHTIGTE_PERSONEN.value}`,
  },
  {
    value: PATH.BEGEHREN_FORDERUNGEN.name,
    id: 'forderungen-tab',
    label: PATH.BEGEHREN_FORDERUNGEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.value}${PATH.BEGEHREN_FORDERUNGEN.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
];
export const PFANDRECHT_SIM_ANMERKUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_ANM_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_ANM_V2.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const PFANDRECHT_VERAEUSSERUNGSVERBOT_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_VERAE_EIN.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_VERAE_EIN.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_VERAE_EIN.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
// Löschungen
export const LOESCHUNGEN_LOESCHUNGEN_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
// Löschungen V2
export const LOESCHUNGEN_LOESCHUNGEN_DURCHFUEHRUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE_V2.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE_V2.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
// Wohnungseigentum
export const WOHNUNGSEIGENTUM_BEGRUENDUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.name,
    id: 'begruendung-zeilen-tab',
    label: PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const WOHNUNGSEIGENTUM_BEGRUENDUNG_ZEILE_TABS = [
  {
    value: PATH.BEGEHREN_BEGRUENDUNG_ZEILE_DETAILS.name,
    id: 'zeile-details-tab',
    label: PATH.BEGEHREN_BEGRUENDUNG_ZEILE_DETAILS.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE.value}${PATH.BEGEHREN_BEGRUENDUNG_ZEILE_DETAILS.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.value}${PATH.BEGEHREN_BEGRUENDUNGSZEILE.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const WOHNUNGSEIGENTUM_AENDERUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_AENDERUNGSZEILE_LIST.name,
    id: 'aenderung-zeilen-tab',
    label: PATH.BEGEHREN_AENDERUNGSZEILE_LIST.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value}${PATH.BEGEHREN_AENDERUNGSZEILE_LIST.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const WOHNUNGSEIGENTUM_AENDERUNG_ZEILE_TABS = [
  {
    value: PATH.BEGEHREN_AENDERUNG_ZEILE_DETAILS.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_AENDERUNG_ZEILE_DETAILS.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value}${PATH.BEGEHREN_AENDERUNGSZEILE_LIST.value}${PATH.BEGEHREN_AENDERUNGSZEILE.value}${PATH.BEGEHREN_AENDERUNG_ZEILE_DETAILS.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value}${PATH.BEGEHREN_AENDERUNGSZEILE_LIST.value}${PATH.BEGEHREN_AENDERUNGSZEILE.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value}${PATH.BEGEHREN_AENDERUNGSZEILE_LIST.value}${PATH.BEGEHREN_AENDERUNGSZEILE.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const WOHNUNGSEIGENTUM_UMWANDLUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_UMW_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_UMW_EIN_V2.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const WOHNUNGSEIGENTUM_END_ANMERKUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ANM.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ANM.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ANM.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const WOHNUNGSEIGENTUM_END_ERSICHT_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ERS.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ERS.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ERS.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
// Rangordnung
export const RANDORDNUNG_VERAUESSERUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERAE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERAE.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERAE.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const RANDORDNUNG_VERPFAENDUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERPF.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_FORDERUNGEN.name,
    id: 'forderungen-tab',
    label: PATH.BEGEHREN_FORDERUNGEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERPF.value}${PATH.BEGEHREN_FORDERUNGEN.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERPF.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    id: 'eintragung-gebuehr-tab',
    label: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERPF.value}${PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERPF.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
export const RANDORDNUNG_EINRAEUMUNG_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_EIN.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_EIN.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
  {
    value: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    id: 'eintragungszusatz-tab',
    label: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_EIN.value}${PATH.BEGEHREN_EINTRAGUNGSZUSATZ.value}`,
  },
];
// Personenaenderung
export const PERSONENAENDERUNG_ADRESSE_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_AAE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_AAE.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const PERSONENAENDERUNG_NAME_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_NAE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_NAE.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const PERSONENAENDERUNG_TITEL_TABS = [
  {
    value: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    id: 'bezug-anteile-tab',
    label: PATH.BEGEHREN_BEZUG_ANTEILE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_TTL.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    value: PATH.BEGEHREN_URKUNDEN.name,
    id: 'urkunden-tab',
    label: PATH.BEGEHREN_URKUNDEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_TTL.value}${PATH.BEGEHREN_URKUNDEN.value}`,
  },
];
export const BEGEHREN_FIRST_TAB = {
  [BEGEHREN_ART.BEGEHREN_SO_NE.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_SO_NE.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_SO_NE_UH.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_SO_NE_UH.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_SO_NET.name]: PATH.BEGEHREN_EINTRAGUNGSPFAD.value,
  [BEGEHREN_ART.BEGEHREN_SO_NET.value]: PATH.BEGEHREN_EINTRAGUNGSPFAD.value,

  [BEGEHREN_ART.BEGEHREN_SO_AE.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_SO_AE.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_SO_VOR.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_SO_VOR.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_SO_MUE.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_SO_MUE.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_SO_AV.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_SO_AV.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_SO_EM_UH.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_SO_EM_UH.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_GV_TSTTAB.name]: PATH.BEGEHREN_TRENNSTUECKTABELLE.value,
  [BEGEHREN_ART.BEGEHREN_GV_TSTTAB.value]: PATH.BEGEHREN_TRENNSTUECKTABELLE.value,

  [BEGEHREN_ART.BEGEHREN_GV_TSTDUR.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_GV_TSTDUR.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_ER_ANM.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_ER_ANM.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_ER_AEN.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_ER_AEN.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_ER_BER.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_ER_BER.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_ER_EIN.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_ER_EIN.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_ER_EINTR.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_ER_EINTR.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_ER_VOR.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_ER_VOR.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PR_SIN_ANM_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PR_SIN_ANM_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PR_SIM_ANM_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PR_SIM_ANM_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PR_VERAE_EIN.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PR_VERAE_EIN.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_LO_LOE.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_LO_LOE.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_LO_LOE_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_LO_LOE_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_WE_UMW_EIN_V2.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_WE_UMW_EIN_V2.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_WE_E_ANM.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_WE_E_ANM.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_WE_E_ERS.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_WE_E_ERS.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_RO_VERAE.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_RO_VERAE.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_RO_VERPF.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_RO_VERPF.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_RO_EIN.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_RO_EIN.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PA_AAE.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PA_AAE.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PA_NAE.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PA_NAE.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,

  [BEGEHREN_ART.BEGEHREN_PA_TTL.name]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
  [BEGEHREN_ART.BEGEHREN_PA_TTL.value]: PATH.BEGEHREN_BEZUG_ANTEILE.value,
};


// #############################################################
// ### Gerichtshoefe ###
// #############################################################
export const VWGH_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VW.value}`,
  },
  {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VW.value}${PATH.BETEILIGTE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VW.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VW.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VW.value}${PATH.PROTOKOLL.value}`,
  },
];
export const VFGH_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VF.value}`,
  },
  {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VF.value}${PATH.BETEILIGTE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VF.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.RECHTSNORMEN.name,
    id: 'rechtsnormen-tab',
    label: PATH.RECHTSNORMEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VF.value}${PATH.RECHTSNORMEN.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VF.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.VF.value}${PATH.PROTOKOLL.value}`,
  },
];
export const BVGH_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.BW.value}`,
  },
  {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.BW.value}${PATH.BETEILIGTE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.BW.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.BW.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.BW.value}${PATH.PROTOKOLL.value}`,
  },
];


// #############################################################
// ### STA ###
// #############################################################
/**
 * get staatsanwaltschaften tabs
 * @return {*[]} tabs
 */
export function getSTATabs() {
  const baseUrl = `${PATH.SCHRIFTSATZ.value}${PATH.STA_Eingabe.value}`;

  return [
    {
      value: PATH.BASISDATEN.name,
      id: 'basisdaten-tab',
      label: PATH.BASISDATEN.label,
      url: baseUrl,
    },
    {
      value: PATH.VORBRINGEN.name,
      id: 'vorbringen-tab',
      label: PATH.VORBRINGEN.label,
      url: `${baseUrl}${PATH.VORBRINGEN.value}`,
    },
    {
      value: PATH.DOKUMENTE.name,
      id: 'dokumente-tab',
      label: PATH.DOKUMENTE.label,
      url: `${baseUrl}${PATH.DOKUMENTE.value}`,
    },
    {
      value: PATH.PRUEFEN.name,
      id: 'pruefen-tab',
      label: PATH.PRUEFEN.label,
      url: `${baseUrl}${PATH.PRUEFEN.value}`,
    },
    {
      value: PATH.PROTOKOLL.name,
      id: 'protokoll-tab',
      label: PATH.PROTOKOLL.label,
      url: `${baseUrl}${PATH.PROTOKOLL.value}`,
    },
  ];
}

// #############################################################
// ### THB ###
// #############################################################
/**
 * get treuhandbuch tabs
 * @param {string} inhaltArt inhaltArt
 * @return {*[]} tabs
 */
export function getTHBTabs(inhaltArt) {
  const baseUrl = `${PATH.SCHRIFTSATZ.value}${PATH[inhaltArt].value}`;
  const basisdaten = {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: baseUrl,
  };
  const beteiligte = {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${baseUrl}${PATH.BETEILIGTE.value}`,
  };
  const dokumente = {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${baseUrl}${PATH.DOKUMENTE.value}`,
  };
  const details = {
    value: PATH.DETAILS.name,
    id: 'details-tab',
    label: PATH.DETAILS.label,
    url: `${baseUrl}${PATH.DETAILS.value}`,
  };
  const protokoll = {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${baseUrl}${PATH.PROTOKOLL.value}`,
  };
  const pruefen = {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${baseUrl}${PATH.PRUEFEN.value}`,
  };

  switch (inhaltArt) {
    // ANWALT
    case INHALT_ART.THB_A2B_KAA.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_VEM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_EM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_AM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_BM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_FM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_VFM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2B_VM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2B_KVA.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_AKVAM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_AZM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_ABM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2B_AM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2B_BM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2B_KA.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2B_KSM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2B_AEM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_KVAM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_A2K_UEM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];

    // RAK
    case INHALT_ART.THB_K2A_AM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_K2A_EM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_K2B_SM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];

    // BANK
    case INHALT_ART.THB_B2A_AFM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RKAA.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RKVA.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RKAM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RKSM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_WAEM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_WKVA.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_WSM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2K_RKVA.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2K_BM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_B2K_RSM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2K_WKSM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2K_RVM.value:
      return [basisdaten, beteiligte, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RSM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RAM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RAEM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2K_RAM.value:
      return [basisdaten, dokumente, details, pruefen, protokoll];
    case INHALT_ART.THB_B2K_RAEM.value:
      return [basisdaten, dokumente, pruefen, protokoll];
    case INHALT_ART.THB_B2A_RVM.value:
      return [basisdaten, dokumente, pruefen, protokoll];

    default:
      return null;
  }
}


// #############################################################
// ### TLDZ ###
// #############################################################
export const TLDZ_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.TLDZ.value}`,
  },
  {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.TLDZ.value}${PATH.BETEILIGTE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.TLDZ.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.TLDZ.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.TLDZ.value}${PATH.PROTOKOLL.value}`,
  },
];


// #############################################################
// ### eZustellung ###
// #############################################################
export const EZUSTELLUNG_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.EZUSTELLUNG.value}`,
  },
  {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.EZUSTELLUNG.value}${PATH.BETEILIGTE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.EZUSTELLUNG.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.EZUSTELLUNG.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.EZUSTELLUNG.value}${PATH.PROTOKOLL.value}`,
  },
];


// #############################################################
// ### STV ###
// #############################################################
export const STV_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.STV.value}`,
  },
  {
    value: PATH.BETEILIGTE.name,
    id: 'beteiligte-tab',
    label: PATH.BETEILIGTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.STV.value}${PATH.BETEILIGTE.value}`,
  },
  {
    value: PATH.DOKUMENTE.name,
    id: 'dokumente-tab',
    label: PATH.DOKUMENTE.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.STV.value}${PATH.DOKUMENTE.value}`,
  },
  {
    value: PATH.PRUEFEN.name,
    id: 'pruefen-tab',
    label: PATH.PRUEFEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.STV.value}${PATH.PRUEFEN.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.STV.value}${PATH.PROTOKOLL.value}`,
  },
];


// #############################################################
// ### EUM ###
// #############################################################
/**
 * get eum tabs
 * @param {String} inhaltArt inhaltArt
 * @return {string} url
 */
export function getEUMTabs(inhaltArt) {
  const baseUrl = _getBaseUrl(inhaltArt);

  switch (inhaltArt) {
    case INHALT_ART.EU_M.value:
      return [
        {
          value: PATH.BASISDATEN.name,
          id: 'basisdaten-tab',
          label: PATH.BASISDATEN.label,
          url: baseUrl,
        },
        {
          value: PATH.BETEILIGTE.name,
          id: 'beteiligte-tab',
          label: PATH.BETEILIGTE.label,
          url: `${baseUrl}${PATH.BETEILIGTE.value}`,
        },
        {
          value: PATH.FORDERUNGEN.name,
          id: 'forderungen-tab',
          label: PATH.FORDERUNGEN.label,
          url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ANSPRUCH.value}`,
        },
        {
          value: PATH.DOKUMENTE.name,
          id: 'dokumente-tab',
          label: PATH.DOKUMENTE.label,
          url: `${baseUrl}${PATH.DOKUMENTE.value}`,
        },
        {
          value: PATH.PRUEFEN.name,
          id: 'pruefen-tab',
          label: PATH.PRUEFEN.label,
          url: `${baseUrl}${PATH.PRUEFEN.value}`,
        },
        {
          value: PATH.PROTOKOLL.name,
          id: 'protokoll-tab',
          label: PATH.PROTOKOLL.label,
          url: `${baseUrl}${PATH.PROTOKOLL.value}`,
        },
      ];

    case INHALT_ART.EU_C.value:
      return [
        {
          value: PATH.BASISDATEN.name,
          id: 'basisdaten-tab',
          label: PATH.BASISDATEN.label,
          url: baseUrl,
        },
        {
          value: PATH.BETEILIGTE.name,
          id: 'beteiligte-tab',
          label: PATH.BETEILIGTE.label,
          url: `${baseUrl}${PATH.BETEILIGTE.value}`,
        },
        {
          value: PATH.DOKUMENTE.name,
          id: 'dokumente-tab',
          label: PATH.DOKUMENTE.label,
          url: `${baseUrl}${PATH.DOKUMENTE.value}`,
        },
        {
          value: PATH.PRUEFEN.name,
          id: 'pruefen-tab',
          label: PATH.PRUEFEN.label,
          url: `${baseUrl}${PATH.PRUEFEN.value}`,
        },
        {
          value: PATH.PROTOKOLL.name,
          id: 'protokoll-tab',
          label: PATH.PROTOKOLL.label,
          url: `${baseUrl}${PATH.PROTOKOLL.value}`,
        },
      ];

    case INHALT_ART.EU_F.value:
      return [
        {
          value: PATH.BASISDATEN.name,
          id: 'basisdaten-tab',
          label: PATH.BASISDATEN.label,
          url: baseUrl,
        },
        {
          value: PATH.BETEILIGTE.name,
          id: 'beteiligte-tab',
          label: PATH.BETEILIGTE.label,
          url: `${baseUrl}${PATH.BETEILIGTE.value}`,
        },
        {
          value: PATH.DOKUMENTE.name,
          id: 'dokumente-tab',
          label: PATH.DOKUMENTE.label,
          url: `${baseUrl}${PATH.DOKUMENTE.value}`,
        },
        {
          value: PATH.PRUEFEN.name,
          id: 'pruefen-tab',
          label: PATH.PRUEFEN.label,
          url: `${baseUrl}${PATH.PRUEFEN.value}`,
        },
        {
          value: PATH.PROTOKOLL.name,
          id: 'protokoll-tab',
          label: PATH.PROTOKOLL.label,
          url: `${baseUrl}${PATH.PROTOKOLL.value}`,
        },
      ];

    case INHALT_ART.EU_V.value:
      return [
        {
          value: PATH.BASISDATEN.name,
          id: 'basisdaten-tab',
          label: PATH.BASISDATEN.label,
          url: baseUrl,
        },
        {
          value: PATH.DOKUMENTE.name,
          id: 'dokumente-tab',
          label: PATH.DOKUMENTE.label,
          url: `${baseUrl}${PATH.DOKUMENTE.value}`,
        },
        {
          value: PATH.PRUEFEN.name,
          id: 'pruefen-tab',
          label: PATH.PRUEFEN.label,
          url: `${baseUrl}${PATH.PRUEFEN.value}`,
        },
        {
          value: PATH.PROTOKOLL.name,
          id: 'protokoll-tab',
          label: PATH.PROTOKOLL.label,
          url: `${baseUrl}${PATH.PROTOKOLL.value}`,
        },
      ];

    case INHALT_ART.EU_Brief.value:
      return [
        {
          value: PATH.BASISDATEN.name,
          id: 'basisdaten-tab',
          label: PATH.BASISDATEN.label,
          url: baseUrl,
        },
        {
          value: PATH.BETEILIGTE.name,
          id: 'beteiligte-tab',
          label: PATH.BETEILIGTE.label,
          url: `${baseUrl}${PATH.BETEILIGTE.value}`,
        },
        {
          value: PATH.DOKUMENTE.name,
          id: 'dokumente-tab',
          label: PATH.DOKUMENTE.label,
          url: `${baseUrl}${PATH.DOKUMENTE.value}`,
        },
        {
          value: PATH.PRUEFEN.name,
          id: 'pruefen-tab',
          label: PATH.PRUEFEN.label,
          url: `${baseUrl}${PATH.PRUEFEN.value}`,
        },
        {
          value: PATH.PROTOKOLL.name,
          id: 'protokoll-tab',
          label: PATH.PROTOKOLL.label,
          url: `${baseUrl}${PATH.PROTOKOLL.value}`,
        },
      ];

    default:
      return null;
  }
}

// #############################################################
// ### BMI ###
// #############################################################
export const BMI_TABS = [
  {
    value: PATH.BASISDATEN.name,
    id: 'basisdaten-tab',
    label: PATH.BASISDATEN.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.BMI.value}`,
  },
  {
    value: PATH.PROTOKOLL.name,
    id: 'protokoll-tab',
    label: PATH.PROTOKOLL.label,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.BMI.value}${PATH.PROTOKOLL.value}`,
  },
];

/**
 * get base url by inhalt art
 * @param {String} inhaltArt inhalt art
 * @return {string|null} url
 * @private
 */
function _getBaseUrl(inhaltArt) { // eslint-disable-line
  switch (inhaltArt) {
    case INHALT_ART.EU_M.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.EU_M.value}`;
    case INHALT_ART.EU_C.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.EU_C.value}`;
    case INHALT_ART.EU_F.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.EU_F.value}`;
    case INHALT_ART.EU_V.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.EU_V.value}`;
    case INHALT_ART.EU_Brief.value:
      return `${PATH.SCHRIFTSATZ.value}${PATH.EU_Brief.value}`;
    default:
      return null;
  }
}

/**
 * get eum forderungen tabs
 * @return {Array} tabs
 */
export function getEumForderungenTabs() {
  const baseUrl = `${PATH.SCHRIFTSATZ.value}${PATH.EU_M.value}`;

  return [
    {
      value: PATH.ANSPRUCH.name,
      id: 'anspruch-tab',
      label: PATH.ANSPRUCH.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ANSPRUCH.value}`,
    },
    {
      value: PATH.ZINSEN.name,
      id: 'zinsen-tab',
      label: PATH.ZINSEN.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ZINSEN.value}`,
    },
    {
      value: PATH.ERGAENZUNGEN.name,
      id: 'ergaenzungen-tab',
      label: PATH.ERGAENZUNGEN.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.ERGAENZUNGEN.value}`,
    },
    {
      value: PATH.BEWEISE.name,
      id: 'beweise-tab',
      label: PATH.BEWEISE.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.BEWEISE.value}`,
    },
    {
      value: PATH.BESONDERE_ZUSTAENDIGKEIT.name,
      id: 'besondere-zustaendigkeit-tab',
      label: PATH.BESONDERE_ZUSTAENDIGKEIT.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.BESONDERE_ZUSTAENDIGKEIT.value}`,
    },
    {
      value: PATH.SONSTIGE_KOSTEN.name,
      id: 'sonstige-kosten-tab',
      label: PATH.SONSTIGE_KOSTEN.label,
      url: `${baseUrl}${PATH.FORDERUNGEN.value}${PATH.SONSTIGE_KOSTEN.value}`,
    },
  ];
}

export const TAB_FLAGS = {
  [PATH.BASISDATEN.name]: {
    name: PATH.BASISDATEN.name,
    value: null,
    isList: false,
    flagTyp: 'BASISDATEN',
  },
  [PATH.BETEILIGTE.name]: {
    name: PATH.BETEILIGTE.name,
    value: null,
    isList: true,
    flagTyp: 'BETEILIGTE',
  },
  [PATH.RECHTSNORMEN.name]: {
    name: PATH.RECHTSNORMEN.name,
    value: null,
    isList: true,
    flagTyp: 'RECHTSNORMEN',
  },
  [PATH.VORBRINGEN.name]: {
    name: PATH.VORBRINGEN.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.VORBRINGEN,
  },
  [PATH.SACHVERHALT.name]: {
    name: PATH.SACHVERHALT.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.VORBRINGEN_SACHVERHALT,
  },
  [PATH.KUMULIERTER_ANTRAG.name]: {
    name: PATH.KUMULIERTER_ANTRAG.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.VORBRINGEN_KUMULIER_ANTRAG,
  },
  [PATH.BEGEHREN.name]: {
    name: PATH.BEGEHREN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.DOKUMENTE.name]: {
    name: PATH.DOKUMENTE.name,
    value: null,
    isList: true,
    flagTyp: 'DOKUMENTE',
  },
  [PATH.BEGEHREN.name]: {
    name: PATH.BEGEHREN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.PRUEFEN.name]: {
    name: PATH.PRUEFEN.name,
    value: null,
    isList: false,
    flagTyp: 'PRUEFEN',
  },
  [PATH.PROTOKOLL.name]: {
    name: PATH.PROTOKOLL.name,
    value: null,
    isList: true,
    flagTyp: 'PROTOKOLL',
  },

  [PATH.BEGEHREN_BEZUG_ANTEILE.name]: {
    name: PATH.BEGEHREN_BEZUG_ANTEILE.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name]: {
    name: PATH.BEGEHREN_BERECHTIGTE_PERSONEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.BEGEHREN_URKUNDEN.name]: {
    name: PATH.BEGEHREN_URKUNDEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name]: {
    name: PATH.BEGEHREN_EINTRAGUNG_GEBUEHR.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.BEGEHREN_BEGEHRENSTEXT.name]: {
    name: PATH.BEGEHREN_BEGEHRENSTEXT.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name]: {
    name: PATH.BEGEHREN_EINTRAGUNGSZUSATZ.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.BEGEHREN_GLAEUBIGER.name]: {
    name: PATH.BEGEHREN_GLAEUBIGER.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },
  [PATH.BEGEHREN_FORDERUNGEN.name]: {
    name: PATH.BEGEHREN_FORDERUNGEN.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.BEGEHREN,
  },

  [PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.name]: {
    name: PATH.BEGEHREN_BEGRUENDUNGSZEILE_LIST.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEGRUENDUNGSZEILE,
  },
  [PATH.BEGEHREN_AENDERUNGSZEILE_LIST.name]: {
    name: PATH.BEGEHREN_AENDERUNGSZEILE_LIST.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.AENDERUNGSZEILE,
  },
  [PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.name]: {
    name: PATH.BEGEHREN_EIGENTUMSANTEIL_ZEILE_LIST.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.EIGENTUMSANTEIL_ZEILE,
  },

  [PATH.FORDERUNGEN.name]: {
    name: PATH.FORDERUNGEN.name,
    value: null,
    isList: false,
    flagTyp: null,
  },
  [PATH.ANSPRUCH.name]: {
    name: PATH.ANSPRUCH.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.ANSPRUCH,
  },
  [PATH.ZINSEN.name]: {
    name: PATH.ZINSEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.ZINSEN,
  },
  [PATH.ERGAENZUNGEN.name]: {
    name: PATH.ERGAENZUNGEN.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.ERGAENZUNGEN,
  },
  [PATH.BEWEISE.name]: {
    name: PATH.BEWEISE.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.BEWEISE,
  },
  [PATH.BESONDERE_ZUSTAENDIGKEIT.name]: {
    name: PATH.BESONDERE_ZUSTAENDIGKEIT.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.BESONDERE_ZUSTAENDIGKEIT,
  },
  [PATH.SONSTIGE_KOSTEN.name]: {
    name: PATH.SONSTIGE_KOSTEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.SONSTIGE_KOSTEN,
  },

  [PATH.TITEL_KOSTEN.name]: {
    name: PATH.TITEL_KOSTEN.name,
    value: null,
    isList: false,
    flagTyp: null,
  },
  [PATH.TITEL.name]: {
    name: PATH.TITEL.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.TITEL,
  },
  [PATH.KOSTEN.name]: {
    name: PATH.TITEL.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.SONSTIGE_KOSTEN,
  },

  [PATH.DETAILS.name]: {
    name: PATH.DETAILS.name,
    value: null,
    isList: false,
    flagTyp: null,
  },

  [PATH.FIRMA.name]: {
    name: PATH.FIRMA.name,
    value: null,
    isList: false,
    flagTyp: INHALT_ELEMENT_TYPS.FIRMA,
  },
  [PATH.RECHTSTATSACHEN.name]: {
    name: PATH.RECHTSTATSACHEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.RECHTSTATSACHE,
  },
  [PATH.PERSONEN.name]: {
    name: PATH.PERSONEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.PERSON,
  },
  [PATH.FUNKTIONEN.name]: {
    name: PATH.FUNKTIONEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.FUNKTION,
  },
  [PATH.ZWEIGNIEDERLASSUNGEN.name]: {
    name: PATH.ZWEIGNIEDERLASSUNGEN.name,
    value: null,
    isList: true,
    flagTyp: INHALT_ELEMENT_TYPS.ZWEIGNIEDERLASSUNG,
  },

  [PATH.KONTAKT_DATEN.label]: {
    name: PATH.KONTAKT_DATEN.label,
    value: null,
    isList: true,
    flagTyp: null,
  },
  [PATH.KONTEN.label]: {
    name: PATH.KONTEN.label,
    value: null,
    isList: true,
    flagTyp: null,
  },
  [PATH.LEGITIMATION.label]: {
    name: PATH.LEGITIMATION.label,
    value: null,
    isList: false,
    flagTyp: null,
  },
};
