import getFormattedMessage, { isUnknownError } from '../FormatMessage/formattedMessage';
import modalFactory from '../Modal/modalFactory';
import ConfirmationModal from '../Modal/ConfirmationModal';
import UnexpectedErrorModal from '../Modal/UnexpectedErrorModal';
import {
  BAD_REQUEST,
  EXPECTATION_FAILED, HINT,
  INTERNAL_SERVER_ERROR,
  TIME_OUT,
  UNPROCCESSABLE_ENTITY,
} from './http.status.codes';
import { getNow } from '../../utils/general.utils';
import {
  getDefalutTimeout, getEingangAbholenTimeout, getFbReadAuszugTimeout, getFbVisualisierungTimeout,
  getFileUploadTimeout,
  getSchriftsatzSendenTimeout,
  getSchriftsatzValidateTimeout,
  getWaitingLoginTimeout,
} from '../../constants/js/application.config';
import { TIMEOUT_TYPE } from './fetchWithTimeout';

/**
 * rest error handler. displays REST errors as toast message in dev and as modal in prod build
 * @param {String} url rest url
 * @param {String} status rest status code
 * @param {Object} errResponse response of the failed rest call
 * @param {Object} headers headers
 * @returns {undefined}
 */
export function handleRestError(url, status = INTERNAL_SERVER_ERROR, errResponse, headers) {
  console.log(errResponse);  // eslint-disable-line
  console.log(headers);  // eslint-disable-line
  console.log(`Error Request Url: ${url}, Status: ${status}`); // eslint-disable-line
  if (errResponse) {
    if (_isExpectedError(errResponse, status)) {
      if (!_handleUnknownErrors(errResponse)) {
        _handleExpectedErrors(errResponse);
      }
    } else {
      _handleUnexpectedErrors(status.toString(), errResponse);
    }
  } else if (status === BAD_REQUEST) {
    _handleExtraBadRequest();
  }
  return null;
}

/**
 * business rules validation error. display a hinweis modal to inform the user.
 * these are controlled events!
 * @param {Object} errResponse response of the failed rest call
 * @return {Boolean} has unknown error
 */
function _handleUnknownErrors(errResponse) { // eslint-disable-line
  const body = errResponse.body;
  const { errorDetailCode, errorCode, logNr } = body || errResponse;

  let errId = '';
  if (errorDetailCode?.id) {
    errId = errorDetailCode?.id;
  } else if (errorCode?.id) {
    errId = errorCode?.id;
  }

  if (isUnknownError(errId)) {
    modalFactory.open(
      UnexpectedErrorModal,
      {
        logText: errId,
        uhrzeit: getNow(),
        logNr: errResponse && logNr,
      },
    );
    return true;
  }
  return false;
}

/**
 * business rules validation error. display a hinweis modal to inform the user.
 * these are controlled events!
 * @param {Object} errResponse response of the failed rest call
 * @return {undefined}
 */
function _handleExpectedErrors(errResponse) { // eslint-disable-line
  let errMsg = JSON.stringify(errResponse);
  if (errResponse?.body?.errorDetailCode?.id) {
    errMsg = getFormattedMessage(errResponse.body.errorDetailCode);
  } else if (errResponse.errorDetailCode) {
    errMsg = getFormattedMessage(errResponse.errorDetailCode);
  } else if (errResponse.errorCode) {
    errMsg = getFormattedMessage(errResponse.errorCode);
  }

  modalFactory.open(
    ConfirmationModal,
    {
      header: 'Hinweis',
      body: errMsg,
      disableClose: true,
    },
  );
}

/**
 * all other 3xx,  4xx, 5xx and 9xx status codes represent unexpected errors.
 * therefore display an "unexpected error modal"
 * @param {String} errorStatus errorStatus
 * @param {Object} errResponse response of the failed rest call
 * @return {undefined}
 */
function _handleUnexpectedErrors(errorStatus, errResponse) { // eslint-disable-line
  if (errorStatus.startsWith('3') || errorStatus.startsWith('4') || errorStatus.startsWith('5')) {
    let logNr = '';
    if (errResponse) {
      logNr = errResponse.logNr;
      if (errResponse?.body?.logNr) {
        logNr = errResponse.body.logNr;
      }
    }
    modalFactory.open(
      UnexpectedErrorModal,
      {
        logNr,
        uhrzeit: getNow(),
      },
    );
  }
}

/**
 * handle extra bad request
 * @private
 * @return {undefined}
 */
function _handleExtraBadRequest() { // eslint-disable-line
  modalFactory.open(
    UnexpectedErrorModal,
    {
      logNr: 'Bad Request',
      uhrzeit: getNow(),
    },
  );
}

/**
 * validate if this is a expected error
 * @param {Object} errResponse response of the failed rest call
 * @param {String} status http status
 * @return {boolean} yes or no
 * @private
 */
function _isExpectedError(errResponse, status) { // eslint-disable-line
  let retval = status === EXPECTATION_FAILED
    || status === UNPROCCESSABLE_ENTITY
    || status === TIME_OUT
    || status === BAD_REQUEST
    || status === HINT;
  if (status === INTERNAL_SERVER_ERROR && errResponse?.body?.errorDetailCode?.id) {
    retval = true;
  }
  return retval;
}

/**
 * get timeout by timeoutType
 * @param {String} timeoutType timeoutType
 * @param {Boolean} hasFile hasFile
 * @returns {number} timeout
 */
export function getTimeout(timeoutType, hasFile) {
  let timeout = getDefalutTimeout();

  if (hasFile || timeoutType === TIMEOUT_TYPE.UPLOAD_FILE) {
    timeout = getFileUploadTimeout();
  } else if (timeoutType === TIMEOUT_TYPE.EINGANG_ABHOLEN) {
    timeout = getEingangAbholenTimeout();
  } else if (timeoutType && timeoutType === TIMEOUT_TYPE.LOGIN) {
    timeout = getWaitingLoginTimeout();
  } else if (timeoutType && timeoutType === TIMEOUT_TYPE.VALIDATE) {
    timeout = getSchriftsatzValidateTimeout();
  } else if (timeoutType && timeoutType === TIMEOUT_TYPE.SENDEN) {
    timeout = getSchriftsatzSendenTimeout();
  } else if (timeoutType && timeoutType === TIMEOUT_TYPE.FB_READ_AUSZUG) {
    timeout = getFbReadAuszugTimeout();
  } else if (timeoutType && timeoutType === TIMEOUT_TYPE.FB_VISUALISIERUNG) {
    timeout = getFbVisualisierungTimeout();
  }

  return timeout;
}

/**
 * doNotError_ObjectOptimisticLockingFailureException
 * @param {Object} json error json
 * @return {boolean} _doInterceptError
 */
export function doNotError_ObjectOptimisticLockingFailureException(json) {
  return json?.exceptionName === 'ObjectOptimisticLockingFailureException';
}
