import React from 'react';
import PropTypes from 'prop-types';
import { Bridge } from '@auth/auth-bridge';
import { Link } from 'react-router-dom';
import { LOGIN_REDIRECT, LOGOUT_REDIRECT, withConnectedUserData } from '@auth/auth-oidc-integration';
import Footer from './Footer';
import ModalContainer from '../common/Modal/ModalContainer';
import PATH from '../constants/path';
import AppWindowLayout from './App';
import NonAppWindowLayout from './NonApp';
import { config } from '../config';

/**
 * Layout component
 * @param {Object} props props
 * @constructor
 */
function Layout(props) {
  const {
    location,
    loggedIn,
    userProfile,
  } = props;

  const isLandingPage = location.pathname === PATH.LANDING_PAGE.value;

  const layoutContent = (isLandingPage || !loggedIn)
    ? <NonAppWindowLayout>{props.children}</NonAppWindowLayout>
    : <AppWindowLayout>{props.children}</AppWindowLayout>;

  const bridgeComponent = (isLandingPage || !loggedIn)
    ? (
      <div className="sticky">
        <Bridge
          loggedIn={loggedIn}
          kontoVisible={false}
          linkComponent={Link}
          environment={config.environment}
          loginParams={{ to: LOGIN_REDIRECT }}
          userId={userProfile && `${userProfile.uid}`}
          logoutParams={{ to: LOGOUT_REDIRECT }}
          produkteVisible={config.bridge.produkteVisible}
        />
      </div>
    ) : (
      <Bridge
        loggedIn={loggedIn}
        linkComponent={Link}
        environment={config.environment}
        kontoVisible={config.bridge.kontoVisible}
        loginParams={{ to: LOGIN_REDIRECT }}
        userId={userProfile && `${userProfile.uid}`}
        logoutParams={{ to: LOGOUT_REDIRECT }}
        produkteVisible={config.bridge.produkteVisible}
      />
    );

  return (
    <>
      <ModalContainer />
      {bridgeComponent}
      {layoutContent}
      <footer>
        <Footer />
      </footer>
    </>
  );
}

Layout.defaultProps = {
  userProfile: null,
  location: null,
  children: null,
  loggedIn: false,
};

Layout.propTypes = {
  userProfile: PropTypes.object,
  loggedIn: PropTypes.bool,
  children: PropTypes.node,
  location: PropTypes.object,
};

export default withConnectedUserData(Layout);
