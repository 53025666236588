import EA from '../beteiligte.ergaenzende.angabe';
import HAFTUNG from '../beteiligte.haftung';
import APP_TYPE from '../schriftsatz.application.type';
import {
  HELP_URL_EUM_BETEILIGTE_ITEM,
  HELP_URL_EUM_BETEILIGTE_LIST,
  HELP_URL_GB_BETEILIGTE_ITEM,
  HELP_URL_GB_BETEILIGTE_LIST, HELP_URL_STV_BETEILIGTE_ITEM, HELP_URL_STV_BETEILIGTE_LIST,
  HELP_URL_VJ_BETEILIGTE_ITEM,
  HELP_URL_VJ_BETEILIGTE_LIST,
  HELP_URL_VWGH_BETEILIGTE_ITEM,
  HELP_URL_VWGH_BETEILIGTE_LIST,
} from './help.url.constaints';
import RECHTSGRUND from '../beteiligte.rechtsgrund';
import ROLLE from '../beteiligte.rolle';
import VERTRETER_DURCH from '../beteiligte.vertreter.durch';

export const VERTRETER_DURCH_DEFAULT_OPTIONS = [
  {
    value: null,
    label: 'Auswählen...',
  },
];

export const VERTRETER_DURCH_GERICHTHOEFE = [
  {
    value: null,
    label: 'Auswählen...',
  },
  {
    value: VERTRETER_DURCH.EINBRINGER.value,
    label: VERTRETER_DURCH.EINBRINGER.label,
  },
  {
    value: VERTRETER_DURCH.RECHTSANWALT.value,
    label: VERTRETER_DURCH.RECHTSANWALT.label,
  },
  {
    value: VERTRETER_DURCH.SONSTIGER.value,
    label: VERTRETER_DURCH.SONSTIGER.label,
  },
];

export const VERTRETER_DURCH_WITHOUT_RECHTANWALT = [
  {
    value: VERTRETER_DURCH.EINBRINGER.value,
    label: VERTRETER_DURCH.EINBRINGER.label,
  },
  {
    value: VERTRETER_DURCH.SONSTIGER.value,
    label: VERTRETER_DURCH.SONSTIGER.label,
  },
];

export const VERTRETER_DURCH_PERSOENLICHEN_VERTRETER = [
  {
    value: null,
    label: 'Auswählen...',
  },
  {
    value: VERTRETER_DURCH.PERSOENLICHEN_VERTRETER.value,
    label: VERTRETER_DURCH.PERSOENLICHEN_VERTRETER.label,
  },
];

export const BET_ROLLE_DEFAULT_OPTIONS = [
  {
    value: ROLLE.ANTRAGSTELLER.value,
    label: ROLLE.ANTRAGSTELLER.label,
  },
  {
    value: ROLLE.ANTRAGSGEGNER.value,
    label: ROLLE.ANTRAGSGEGNER.label,
  },
  {
    value: ROLLE.MITBETEILIGTE_PARTEI.value,
    label: ROLLE.MITBETEILIGTE_PARTEI.label,
  },
];

export const BET_ROLLE_VFVWBW_OPTIONS = [
  {
    value: ROLLE.ANTRAGSTELLER_BESCHWERDEFUEHRER.value,
    label: ROLLE.ANTRAGSTELLER_BESCHWERDEFUEHRER.label,
  },
  {
    value: ROLLE.ANTRAGSGEGNER_BESCHWERDEGEGNER.value,
    label: ROLLE.ANTRAGSGEGNER_BESCHWERDEGEGNER.label,
  },
  {
    value: ROLLE.MITBETEILIGTE_PARTEI.value,
    label: ROLLE.MITBETEILIGTE_PARTEI.label,
  },
];

export const BET_ROLLE_VJ_KLAGE_OPTIONS = [
  {
    value: ROLLE.KLAEGER.value,
    label: ROLLE.KLAEGER.label,
  },
  {
    value: ROLLE.BEKLAGTER.value,
    label: ROLLE.BEKLAGTER.label,
  },
  {
    value: ROLLE.ZEUGE.value,
    label: ROLLE.ZEUGE.label,
  },
];

export const BET_ROLLE_VJ_ERSTEEINGABE_OPTIONS = [
  {
    value: ROLLE.ERSTE_PARTEI.value,
    label: ROLLE.ERSTE_PARTEI.label,
  },
  {
    value: ROLLE.ZWEITE_PARTEI.value,
    label: ROLLE.ZWEITE_PARTEI.label,
  },
];

export const BET_ROLLE_VJ_FOLGEEINGABE_OPTIONS = [
  {
    value: ROLLE.SONSTIGER_BETEILIGTER.value,
    label: '1. Kläger',
  },
  {
    value: ROLLE.SONSTIGER_BETEILIGTER.value,
    label: '2. Kläger',
  },
  {
    value: ROLLE.SONSTIGER_BETEILIGTER.value,
    label: '1. Beklagter',
  },
  {
    value: ROLLE.SONSTIGER_BETEILIGTER.value,
    label: '2. Beklagter',
  },
];

export const BET_ROLLE_EXEKUTION_OPTIONS = [
  {
    value: ROLLE.BETREIBENDER.value,
    label: ROLLE.BETREIBENDER.label,
  },
  {
    value: ROLLE.VERPFLICHTETER.value,
    label: ROLLE.VERPFLICHTETER.label,
  },
];

export const BET_ROLLE_EXEKUTION_WITH_DRITTSCHULDNER_OPTIONS = [
  {
    value: ROLLE.BETREIBENDER.value,
    label: ROLLE.BETREIBENDER.label,
  },
  {
    value: ROLLE.VERPFLICHTETER.value,
    label: ROLLE.VERPFLICHTETER.label,
  },
  {
    value: ROLLE.DRITTSCHULDNER.value,
    label: ROLLE.DRITTSCHULDNER.label,
  },
];

export const BET_ROLLE_KUENDIGUNG_OPTIONS = [
  {
    value: ROLLE.KUENDIGER.value,
    label: ROLLE.KUENDIGER.label,
  },
  {
    value: ROLLE.GEKUENDIGTER.value,
    label: ROLLE.GEKUENDIGTER.label,
  },
];

export const BET_ROLLE_VERFUEGUNG_OPTIONS = [
  {
    value: ROLLE.GEFAEHRDETE_PARTEI.value,
    label: ROLLE.GEFAEHRDETE_PARTEI.label,
  },
  {
    value: ROLLE.GEGNER_GEFAEHRDETE_PARTEI.value,
    label: ROLLE.GEGNER_GEFAEHRDETE_PARTEI.label,
  },
];

export const BET_ROLLE_VJ_KARTELLERSTANTRAG_OPTIONS = [
  {
    value: ROLLE.ANTRAGSTELLER.value,
    label: ROLLE.ANTRAGSTELLER.label,
  },
  {
    value: ROLLE.ANTRAGSGEGNER.value,
    label: ROLLE.ANTRAGSGEGNER.label,
  },
];

export const BET_ROLLE_GRUNDBUCH_OTPIONS = [
  {
    value: ROLLE.ANTRAGSTELLER.value,
    label: ROLLE.ANTRAGSTELLER.label,
  },
  {
    value: ROLLE.BETEILIGTER.value,
    label: ROLLE.BETEILIGTER.label,
  },
];

export const BET_ROLLE_GRUNDBUCH_WITHOUT_ANTRAGSTELLER_OPTIONS = [
  {
    value: ROLLE.BETEILIGTER.value,
    label: ROLLE.BETEILIGTER.label,
  },
];

export const BET_ROLLE_EUM_OPTIONS = [
  {
    value: ROLLE.ANTRAGSTELLER.value,
    label: ROLLE.ANTRAGSTELLER.label,
  },
  {
    value: ROLLE.ANTRAGSGEGNER.value,
    label: ROLLE.ANTRAGSGEGNER.label,
  },
  {
    value: ROLLE.GESETZLICHER_VERTRETER.value,
    label: ROLLE.GESETZLICHER_VERTRETER.label,
  },
  {
    value: ROLLE.ABTRETENDER_GLAEUBIGER.value,
    label: ROLLE.ABTRETENDER_GLAEUBIGER.label,
  },
];

export const RECHTSGRUND_OPTIONS = [
  {
    value: null,
    label: 'Keine Auswahl',
  },
  {
    value: RECHTSGRUND.BESCHRAENKT_PFAENDBARE_FORDERUNG.value,
    label: RECHTSGRUND.BESCHRAENKT_PFAENDBARE_FORDERUNG.label,
  },
  {
    value: RECHTSGRUND.UNTERHALTSFORDERUNG.value,
    label: RECHTSGRUND.UNTERHALTSFORDERUNG.label,
  },
  {
    value: RECHTSGRUND.UNBESCHRAENKT_PFAENDBARE_FORDERUNG.value,
    label: RECHTSGRUND.UNBESCHRAENKT_PFAENDBARE_FORDERUNG.label,
  },
];

export const BETEILIGTE_LIST_HELP_URLS = {
  [APP_TYPE.VF]: HELP_URL_VWGH_BETEILIGTE_LIST,
  [APP_TYPE.VW]: HELP_URL_VWGH_BETEILIGTE_LIST,
  [APP_TYPE.BW]: HELP_URL_VWGH_BETEILIGTE_LIST,
  [APP_TYPE.FB]: '',
  [APP_TYPE.TLDZ]: HELP_URL_VJ_BETEILIGTE_LIST,
  [APP_TYPE.VJ]: HELP_URL_VJ_BETEILIGTE_LIST,
  [APP_TYPE.STA]: '',
  [APP_TYPE.GT]: HELP_URL_GB_BETEILIGTE_LIST,
  [APP_TYPE.EUM]: HELP_URL_EUM_BETEILIGTE_LIST,
  [APP_TYPE.STV]: HELP_URL_STV_BETEILIGTE_LIST,
};

export const BETEILIGTE_HELP_URLS = {
  [APP_TYPE.VF]: HELP_URL_VWGH_BETEILIGTE_ITEM,
  [APP_TYPE.VW]: HELP_URL_VWGH_BETEILIGTE_ITEM,
  [APP_TYPE.BW]: HELP_URL_VWGH_BETEILIGTE_ITEM,
  [APP_TYPE.FB]: '',
  [APP_TYPE.TLDZ]: HELP_URL_VJ_BETEILIGTE_ITEM,
  [APP_TYPE.VJ]: HELP_URL_VJ_BETEILIGTE_ITEM,
  [APP_TYPE.STA]: '',
  [APP_TYPE.GT]: HELP_URL_GB_BETEILIGTE_ITEM,
  [APP_TYPE.EUM]: HELP_URL_EUM_BETEILIGTE_ITEM,
  [APP_TYPE.STV]: HELP_URL_STV_BETEILIGTE_ITEM,
};

export const BETEILIGTE_ERGAENZENDE_ANGABE_OPTIONS = [
  {
    value: EA.VERZICHT_AUF_DRITTSCHULDNERERKLAERUNG.value,
    label: EA.VERZICHT_AUF_DRITTSCHULDNERERKLAERUNG.label,
  },
  {
    value: EA.EXEKUTIONSVOLLZUG_MIT_BETEILIGUNG.value,
    label: EA.EXEKUTIONSVOLLZUG_MIT_BETEILIGUNG.label,
  },
  {
    value: EA.VERZICHT_AUF_VERMOEGENSVERZEICHNIS.value,
    label: EA.VERZICHT_AUF_VERMOEGENSVERZEICHNIS.label,
  },
  {
    value: EA.VERZICHT_AUF_AUFSPERRDIENST.value,
    label: EA.VERZICHT_AUF_AUFSPERRDIENST.label,
  },
  {
    value: EA.KOPIE_PFAENDUNGSPROTOKOLL.value,
    label: EA.KOPIE_PFAENDUNGSPROTOKOLL.label,
  },
];

export const BETEILIGTE_HAFTUNG_OPTIONS = [
  {
    value: HAFTUNG.BUERGE.value,
    label: HAFTUNG.BUERGE.label,
  },
  {
    value: HAFTUNG.FAHRZEUGHALTER.value,
    label: HAFTUNG.FAHRZEUGHALTER.label,
  },
  {
    value: HAFTUNG.PERSOENLICH_HAFTENDER_GESELLSCHAFTER.value,
    label: HAFTUNG.PERSOENLICH_HAFTENDER_GESELLSCHAFTER.label,
  },
  {
    value: HAFTUNG.VERSICHERTER.value,
    label: HAFTUNG.VERSICHERTER.label,
  },
  {
    value: HAFTUNG.BUERGE_UND_ZAHLER.value,
    label: HAFTUNG.BUERGE_UND_ZAHLER.label,
  },
];

export const BET_ROLLE_THB_VERZ_PARTEI_OPTION = [
  {
    value: ROLLE.VERZICHTENDE_PARTEI.value,
    label: ROLLE.VERZICHTENDE_PARTEI.label,
  },
];

export const BET_ROLLE_THB_BEGUENSTIGTER_OPTION = [
  {
    value: ROLLE.BEGUENSTIGTER.value,
    label: ROLLE.BEGUENSTIGTER.label,
  },
];

export const BET_ROLLE_THB_GELDBEI_BEGUENSTIG_OPTION = [
  {
    value: ROLLE.TREUGEBER_BEGUENSTIGTER.value,
    label: ROLLE.TREUGEBER_BEGUENSTIGTER.label,
  },
  {
    value: ROLLE.TREUGEBER_GELDBEISTELLER.value,
    label: ROLLE.TREUGEBER_GELDBEISTELLER.label,
  },
];

export const BET_ROLLE_THB_TREUGEBER_DRITTFINANZIERER_OPTION = [
  {
    value: ROLLE.TREUGEBER_BEGUENSTIGTER.value,
    label: ROLLE.TREUGEBER_BEGUENSTIGTER.label,
  },
  {
    value: ROLLE.TREUGEBER_GELDBEISTELLER.value,
    label: ROLLE.TREUGEBER_GELDBEISTELLER.label,
  },
  {
    value: ROLLE.DRITTFINANZIERER.value,
    label: ROLLE.DRITTFINANZIERER.label,
  },
];

export const BET_ROLLE_THB_KVA_OPTION = [
  {
    value: ROLLE.TREUGEBER_BEGUENSTIGTER.value,
    label: ROLLE.TREUGEBER_BEGUENSTIGTER.label,
  },
  {
    value: ROLLE.TREUGEBER_GELDBEISTELLER.value,
    label: ROLLE.TREUGEBER_GELDBEISTELLER.label,
  },
  {
    value: ROLLE.SONSTIGER_BEGUENSTIGTER.value,
    label: ROLLE.SONSTIGER_BEGUENSTIGTER.label,
  },
  {
    value: ROLLE.TREUHAENDER_BEGUENSTIGTER.value,
    label: ROLLE.TREUHAENDER_BEGUENSTIGTER.label,
  },
];
