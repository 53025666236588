import React from 'react';
import PropTypes from 'prop-types';
import mzAlert from '../../assets/icons/mz-alert.svg';

/**
 * AlertIcon
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function AlertIcon(props) {
  const { className, onClick, title } = props;
  return (
    <img // eslint-disable-line
      title={title}
      width={23}
      src={mzAlert}
      alt="alert icon"
      id="manz-alert"
      onClick={onClick}
      aria-label="alert icon"
      className={className}
    />
  );
}

AlertIcon.defaultProps = {
  title: null,
  className: null,
};

AlertIcon.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default AlertIcon;
