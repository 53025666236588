import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import {
  createTooltipBySchriftsatzStatus,
  formatPagingParameters,
  isNotInitialedSchriftsatzSearchParameters,
  isSendable,
  parseSchriftsatzSearchInitialParametersByType,
} from '../utils/schriftsatz.utils';
import SchriftsatzBadge from '../common/Badge/SchriftsatzBadge';
import SchriftverkehrTables from '../common/SchriftverkehrTables/SchriftverkehrTables';
import {
  AUSGANG,
  AUSGANG_DEFAULT_SORT,
  AUSGANG_AUSGANG_TABLE_COLS,
  AUSGANG_RESPONSIVE_AUSGANG_TABLE_COLS,
  SCHRIFTVERKEHR_SEARCH_TEMPLATE,
} from '../constants/js/schriftsatz.constants';
import { setPagingAndSort } from '../common/Table/utils/table.utils';
import { getUrlParamString } from '../utils/url.utils';
import Help from '../common/Form/Help';
import { HELP_URL_AUSGANG } from '../constants/js/help.url.constaints';
import AusgangSelect from '../common/Form/AusgangSelect';
import DownloadFile from '../common/Download/DownloadFile';
import getUrl from '../common/Rest/rest.utils';
import Rest from '../common/Rest';
import INHALT_ART from '../constants/schriftsatz.inhalt.art';
import SCHRIFTSATZ_STATUS from '../constants/schriftsatz.status';
import { convertFromGMTDateToString } from '../utils/general.utils';
import { SORT_ASC } from '../constants/js/sort.constants';
import PollingEventEmitter from '../common/Events/PollingEventEmitter';
import { EVENT_RELOAD_AUSGANG_LIST } from '../constants/js/events.constants';
import TABLE_TYPE from '../constants/table.type.json';

/**
 * component
 */
class Ausgang extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    const searchParams = {
      page: 0,
      size: 10,
      sort: `status,${SORT_ASC}`,
    };

    const preAusgangSearchParams = parseSchriftsatzSearchInitialParametersByType(this.props, AUSGANG);

    let ausgangSearchParams = {
      ...searchParams,
      template: SCHRIFTVERKEHR_SEARCH_TEMPLATE.WORK_IN_PROGRESS,
    };
    if (!isNotInitialedSchriftsatzSearchParameters(preAusgangSearchParams)) {
      ausgangSearchParams = preAusgangSearchParams;
    }

    this.state = {
      ausgangSearchParams,
      selectedAusgangIds: [],
    };

    PollingEventEmitter.on(EVENT_RELOAD_AUSGANG_LIST, () => this.handleReloadAusgaenge());

    this.handleReload = this.handleReload.bind(this);
    this.handleChangeTable = this.handleChangeTable.bind(this);
    this.handleReloadAusgaenge = this.handleReloadAusgaenge.bind(this);
    this.handleSelectOrRemoveAll = this.handleSelectOrRemoveAll.bind(this);
    this.handleGetAusgangTableData = this.handleGetAusgangTableData.bind(this);
    this.handleGetSelectedAusgangIds = this.handleGetSelectedAusgangIds.bind(this);
    this.handleSelectSendableAusgang = this.handleSelectSendableAusgang.bind(this);
  }

  /**
   * componentWillUnmount
   * @return {undefined}
   */
  componentWillUnmount() {
    PollingEventEmitter.off(EVENT_RELOAD_AUSGANG_LIST, () => this.handleReloadAusgaenge());
  }

  /**
   * get ausgang table data by schriftsaetze
   * @param {Array} schriftsaetze schriftsaetze
   * @return {Array} ausgang table data
   * @private
   */
  handleGetAusgangTableData(schriftsaetze) { // eslint-disable-line
    const { selectedAusgangIds } = this.state;
    const ausgangTableData = [];
    if (schriftsaetze && schriftsaetze.content) {
      schriftsaetze.content.forEach((ausgang, idx) => {
        const disabledSend = (
          ausgang.status !== SCHRIFTSATZ_STATUS.GEPRUEFT.value
          && ausgang.status !== SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value
        ) || ausgang.hinverkehrAktiv === false;

        const isUebertragungsfehler = ausgang.status === SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value;

        const data = {};

        data.id = idx + 1;
        data.typ = 'Ausgang';
        data.schriftsatzId = ausgang?.id;
        data.statusValue = ausgang?.status;
        data.inhaltArt = ausgang?.inhaltArt;
        data.aktenzeichen = ausgang?.aktenzeichen;
        data.einbringerLabel = ausgang?.einbringerLabel;
        data.tooltip = createTooltipBySchriftsatzStatus(ausgang?.status);
        data.erstellt = convertFromGMTDateToString(ausgang?.erstellungsZeitpunkt);
        data.art = INHALT_ART[ausgang?.inhaltArt] ? INHALT_ART[ausgang?.inhaltArt].label : null;

        data.status = (
          <SchriftsatzBadge
            key="SchriftsatzBadge"
            schriftsatzStatus={ausgang?.status}
          />
        );

        data.senden = (
          <div
            key="select-d-lg-block"
            className="d-flex align-items-center"
          >
            <span className={`pr-2 ${disabledSend ? 'manz-disabled' : ''} ${isUebertragungsfehler ? 'uebertragungsfehler-send-btn-text-blink' : ''}`}>Senden</span>
            <AusgangSelect
              id={ausgang.id}
              key="AusgangSelect"
              disabled={disabledSend}
              isUebertragungsfehler={isUebertragungsfehler}
              checked={selectedAusgangIds.includes(ausgang?.id)}
              onClick={(e) => this.handleSelectSendableAusgang(e, ausgang)}
            />
          </div>
        );

        ausgangTableData.push(data);
      });
    }
    return ausgangTableData;
  }

  /**
   * select all sendable ausgaenge or remove all sendable ausgaenge ids from selected list
   * @param {boolean} isSelectAll isSelectAll
   * @param {Array} selectedAusgangIds all sendable ausgaenge id
   * @return {undefined}
   */
  handleSelectOrRemoveAll(isSelectAll, selectedAusgangIds) {
    if (isSelectAll) {
      this.setState({ selectedAusgangIds });
    } else {
      this.setState({ selectedAusgangIds: [] });
    }
  }

  /**
   * change table
   * @param {number} page page
   * @param {number} size size
   * @param {Object} sort sort
   * @param {String} type ausgang or eingang
   * @return {undefined}
   */
  handleChangeTable(page, size, sort, type) {
    const { location, history, match } = this.props;
    let newSort = sort;
    if (sort && !sort?.id) {
      newSort = AUSGANG_DEFAULT_SORT;
    }

    const params = qs.parse(location.search.slice(1));
    const payload = setPagingAndSort(params, page, size, newSort, type);

    payload.template = SCHRIFTVERKEHR_SEARCH_TEMPLATE.WORK_IN_PROGRESS;

    history.push({
      pathname: match.path,
      search: getUrlParamString(payload),
    });

    this.setState({
      ausgangSearchParams: formatPagingParameters(payload, AUSGANG),
      selectedAusgangIds: [],
    });
  }

  /**
   * select a sendable ausgang/schriftsatz
   * @param {Object} event event
   * @param {Object} schriftsatz schriftsatz
   * @return {undefined}
   */
  handleSelectSendableAusgang(event, schriftsatz) {
    event.stopPropagation();
    event.preventDefault();

    if (isSendable(schriftsatz)) {
      const { selectedAusgangIds } = this.state;
      const idx = selectedAusgangIds.findIndex((v) => v === schriftsatz?.id);
      if (idx !== -1) {
        selectedAusgangIds.splice(idx, 1);
      } else {
        selectedAusgangIds.push(schriftsatz?.id);
      }
      this.setState({ selectedAusgangIds });
    }
  }

  /**
   * reload
   * @return {undefined}
   */
  handleReload() {
    this.setState({ selectedAusgangIds: [] });
  }

  /**
   * get select ausgang ids for children component
   * @return {Array} select ausgang ids
   */
  handleGetSelectedAusgangIds() {
    return this.state.selectedAusgangIds;
  }

  /**
   * reload ausgaenge
   * @return {undefined}
   */
  handleReloadAusgaenge() {
    const { ausgangSearchParams } = this.state;
    this.handleChangeTable(ausgangSearchParams.page, ausgangSearchParams.size, ausgangSearchParams.sort, AUSGANG);
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { ausgangSearchParams } = this.state;

    return (
      <div>
        <Help
          key="Help"
          href={HELP_URL_AUSGANG}
        />

        <SchriftverkehrTables
          showAusgangSendButton
          showEingangList={false}
          key="SchriftverkehrTables"
          onReloadTable={this.handleReload}
          tableType={TABLE_TYPE.AUSGANG}
          onChangeTable={this.handleChangeTable}
          ausgangSearchParams={ausgangSearchParams}
          ausgangTableCols={AUSGANG_AUSGANG_TABLE_COLS}
          onFormatAusgangTableData={this.handleGetAusgangTableData}
          onGetCurrentSelectedAusgangIds={this.handleGetSelectedAusgangIds}
          onCallbackSelectOrRemoveAllSendableAusgang={this.handleSelectOrRemoveAll}
          responsiveAusgangTableCols={AUSGANG_RESPONSIVE_AUSGANG_TABLE_COLS}
          ausgangButtons={(
            <DownloadFile
              key="DownloadFile"
              filename="protokoll.pdf"
              btnText="Protokoll drucken"
              className="btn btn-secondary"
              requestUrl={getUrl(Rest.urls.schriftverkehrJournal, {})}
            />
          )}
        />

      </div>
    );
  }
}

Ausgang.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export { Ausgang as AusgangTest };
export default withRouter(Ausgang);
