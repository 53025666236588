import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import {
  formatPagingParameters,
  isNotInitialedSchriftsatzSearchParameters,
  parseSchriftsatzSearchInitialParametersByType,
} from '../utils/schriftsatz.utils';
import SchriftsatzBadge from '../common/Badge/SchriftsatzBadge';
import { setPagingAndSort } from '../common/Table/utils/table.utils';
import { getUrlParamString } from '../utils/url.utils';
import SchriftverkehrTables from '../common/SchriftverkehrTables/SchriftverkehrTables';
import {
  EINGANG,
  EINGANG_DEFAULT_SORT,
  EINGANG_EINGANG_TABLE_COLS,
  EINGANG_RESPONSIVE_EINGANG_TABLE_COLS,
  SCHRIFTVERKEHR_SEARCH_TEMPLATE,
} from '../constants/js/schriftsatz.constants';
import Help from '../common/Form/Help';
import { HELP_URL_EINGANG } from '../constants/js/help.url.constaints';
import DownloadFile from '../common/Download/DownloadFile';
import Empfangen from '../common/SendenEmpfangen/Empfangen';
import getUrl from '../common/Rest/rest.utils';
import Rest from '../common/Rest';
import SCHRIFTSATZ_INHALT_ART from '../constants/schriftsatz.inhalt.art';
import ERLEDIGUNG_INHALT_ART from '../constants/erledigung.inhalt.art';
import { convertFromGMTDateToString } from '../utils/general.utils';
import { SORT_DESC } from '../constants/js/sort.constants';
import PollingEventEmitter from '../common/Events/PollingEventEmitter';
import { EVENT_RELOAD_EINGANG_LIST } from '../constants/js/events.constants';
import TABLE_TYPE from '../constants/table.type.json';

/**
 * component
 */
class Eingang extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    const searchParams = {
      page: 0,
      size: 10,
      sort: `createDate,${SORT_DESC}`,
    };

    const preEingangSearchParams = parseSchriftsatzSearchInitialParametersByType(this.props, EINGANG);

    let eingangSearchParams = {
      ...searchParams,
      template: SCHRIFTVERKEHR_SEARCH_TEMPLATE.TODO,
    };

    if (!isNotInitialedSchriftsatzSearchParameters(preEingangSearchParams)) {
      eingangSearchParams = preEingangSearchParams;
    }

    this.state = {
      eingangSearchParams,
    };

    PollingEventEmitter.on(EVENT_RELOAD_EINGANG_LIST, () => this.handleReloadEingaenge());

    this.handleGetEingangTableData = this.handleGetEingangTableData.bind(this);
    this.handleChangeTable = this.handleChangeTable.bind(this);
    this.handleReloadEingaenge = this.handleReloadEingaenge.bind(this);
  }

  /**
   * componentWillUnmount
   * @return {undefined}
   */
  componentWillUnmount() {
    PollingEventEmitter.off(EVENT_RELOAD_EINGANG_LIST, () => this.handleReloadEingaenge());
  }

  /**
   * get eingang table data by erledigungen
   * @param {Array} erledigungen erledigungen
   * @return {Array} eingang table data
   * @private
   */
  handleGetEingangTableData(erledigungen) { // eslint-disable-line
    const eingangTableData = [];
    if (erledigungen && erledigungen.content) {
      erledigungen.content.forEach((eingang, idx) => {
        const schriftsatzInhaltArt = SCHRIFTSATZ_INHALT_ART[eingang.inhaltArt];
        const erledigungInhaltArt = ERLEDIGUNG_INHALT_ART[eingang.inhaltArt];
        // eslint-disable-next-line no-nested-ternary
        const inhaltArt = schriftsatzInhaltArt ? schriftsatzInhaltArt.label : (erledigungInhaltArt ? erledigungInhaltArt.label : '');

        const data = {};

        data.id = idx + 1;
        data.art = inhaltArt;
        data.isEingang = true;
        data.substringLength = 40;
        data.isSubstringContent = true;
        data.schriftsatzId = eingang?.id;
        data.statusValue = eingang?.status;
        data.inhaltArt = eingang?.inhaltArt;
        data.gerichtLabel = eingang?.gerichtLabel;
        data.aktenzeichen = eingang?.aktenzeichen;
        data.einbringerLabel = eingang?.einbringerLabel;
        data.sender = eingang?.sender || eingang?.gerichtLabel || '';
        data.erstellt = convertFromGMTDateToString(eingang?.bereitstellZeitpunkt);
        data.einbringungsZeitpunkt = convertFromGMTDateToString(eingang?.einbringungsZeitpunkt);

        data.status = (
          <div className="d-block">
            <SchriftsatzBadge
              key="SchriftsatzBadge"
              schriftsatzStatus={eingang?.status}
            />
          </div>
        );

        eingangTableData.push(data);
      });
    }
    return eingangTableData;
  }

  /**
   * change table
   * @param {number} page page
   * @param {number} size size
   * @param {Object} sort sort
   * @param {String} type eingang or eingang
   * @return {undefined}
   */
  handleChangeTable(page, size, sort, type) {
    const { location, history, match } = this.props;
    let newSort = sort;
    if (sort && !sort?.id) {
      newSort = EINGANG_DEFAULT_SORT;
    }
    const params = qs.parse(location.search.slice(1));
    const payload = setPagingAndSort(params, page, size, newSort, type);

    payload.template = SCHRIFTVERKEHR_SEARCH_TEMPLATE.TODO;

    history.push({
      pathname: match.path,
      search: getUrlParamString(payload),
    });

    this.setState({ eingangSearchParams: formatPagingParameters(payload, EINGANG) });
  }

  /**
   * reload eingaenge
   * @return {undefined}
   */
  handleReloadEingaenge() {
    const { eingangSearchParams } = this.state;
    this.handleChangeTable(eingangSearchParams.page, eingangSearchParams.size, eingangSearchParams.sort, EINGANG);
  }

  /**
   * get download protokoll rest request url
   * @return {string} url
   * @private
   */
  _getDownloadProtokollRequestUrl() { // eslint-disable-line
    return getUrl(Rest.urls.schriftverkehrJournal, {});
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { eingangSearchParams } = this.state;

    return (
      <div>
        <Help
          key="Help"
          href={HELP_URL_EINGANG}
        />

        <SchriftverkehrTables
          showAusgangList={false}
          key="SchriftverkehrTables"
          tableType={TABLE_TYPE.EINGANG}
          onChangeTable={this.handleChangeTable}
          eingangSearchParams={eingangSearchParams}
          eingangTableCols={EINGANG_EINGANG_TABLE_COLS}
          onFormatEingangTableData={this.handleGetEingangTableData}
          responsiveEingangTableCols={EINGANG_RESPONSIVE_EINGANG_TABLE_COLS}
        />

        <div className="d-flex justify-content-end">
          <DownloadFile
            key="DownloadFile"
            filename="protokoll.pdf"
            btnText="Protokoll drucken"
            className="btn btn-secondary mr-2"
            requestUrl={this._getDownloadProtokollRequestUrl()}
          />
          <Empfangen
            key="Empfangen"
            className="float-right"
            onReload={this.handleReloadEingaenge}
          />
        </div>

      </div>
    );
  }
}

Eingang.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export { Eingang as EingangTest };
export default withRouter(Eingang);
