import React from 'react';
import PropTypes from 'prop-types';

/**
 * empfangen button component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function EmpfangenButton(props) {
  const {
    id,
    title,
    onClick,
    disabled,
    className,
  } = props;

  const cName = ['btn', 'btn-primary'];
  if (className) {
    cName.push(className);
  }

  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      disabled={disabled}
      aria-label="Empfangen"
      className={cName.join(' ')}
    >
      {title}
    </button>
  );
}

EmpfangenButton.defaultProps = {
  id: null,
  onClick: null,
  disabled: false,
  className: null,
  title: 'Empfangen',
};

EmpfangenButton.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default EmpfangenButton;
