import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as NextSVG } from '../../assets/icons/mz-arrow-right.svg';
import { ReactComponent as PrevSVG } from '../../assets/icons/mz-arrow-left.svg';

/**
 * simple paging control for table
 * @param {Object} props props
 * @returns {JSX} component jsx
 */
function Paging(props) {
  const {
    total,
    page,
    pageSize,
    onPageChange,
    disablePrevBtn,
    disableNextBtn,
  } = props;

  const pages = Math.ceil(total / pageSize);

  const prev = (
    <button
      type="button"
      key="paging-prev"
      title="Vorige Seite"
      aria-label="Vorige Seite Button"
      onClick={() => onPageChange(page - 1)}
      disabled={disablePrevBtn || page === 0}
      className="btn btn-tertiary btn-sm icon-button"
    >
      <PrevSVG title="Vorige Seite" />
    </button>
  );

  const next = (
    <button
      type="button"
      key="paging-next"
      title="Nächste Seite"
      aria-label="Nächste Seite Button"
      onClick={() => onPageChange(page + 1)}
      className="btn btn-tertiary btn-sm icon-button"
      disabled={disableNextBtn || page >= (pages - 1)}
    >
      <NextSVG title="Nächste Seite" />
    </button>
  );

  const currentPage = pages === 0 ? 0 : (page + 1);

  return (
    <div className="btn-group">
      {prev}
      <div className="pages-text">
        <span>{currentPage}</span>
        <span> von </span>
        <span>{pages}</span>
      </div>
      {next}
    </div>
  );
}

Paging.defaultProps = {
  disablePrevBtn: false,
  disableNextBtn: false,
};

Paging.propTypes = {
  disablePrevBtn: PropTypes.bool,
  disableNextBtn: PropTypes.bool,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Paging;
