import React from 'react';
import PropTypes from 'prop-types';
import mzAlert from '../../assets/icons/mz-alert-red.svg';

/**
 * AlertRedIcon
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function AlertRedIcon(props) {
  const {
    title,
    width,
    height,
    onClick,
    className,
  } = props;
  return (
    <img // eslint-disable-line
      title={title}
      src={mzAlert}
      alt="alert icon"
      width={width}
      id="manz-alert"
      height={height}
      onClick={onClick}
      aria-label="alert icon"
      className={className}
    />
  );
}

AlertRedIcon.defaultProps = {
  title: null,
  width: 30,
  height: 30,
  onClick: null,
  className: null,
};

AlertRedIcon.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default AlertRedIcon;
