import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-oPadding.svg';
import { subString } from '../../utils/general.utils';

/**
 * upload redux form component with drag & drop feature
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function Upload(props) {
  const {
    id,
    input,
    name, // eslint-disable-line
    tooltip,
    disabled,
    className,
    meta: {
      error,
      invalid,
      submitFailed,
    },
  } = props;

  let btnText = 'Datei auswählen';
  // check for array if file got uploaded else display the text
  if (input.value && Array.isArray(input.value)) {
    btnText = input.value[0].name;
  } else if (input.value) {
    btnText = input.value;
  }

  const classNames = className ? className.split(' ') : [];
  if (error && submitFailed) {
    classNames.push('form-validation-error');
  }
  classNames.push('pdf-upload');

  return (
    <div className={classNames.join(' ')}>
      <Dropzone
        onDrop={(filesToUpload) => input.onChange(filesToUpload)}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            id={id}
            title={tooltip}
            {...getRootProps({
              className: `form-drop-zone dropzone ${disabled ? 'disabled' : ''}`,
              disabled,
            })}
          >
            <input
              {...getInputProps()}
              disabled={disabled}
              aria-label="dropzone"
            />
            <button
              type="button"
              disabled={disabled}
              aria-label={btnText}
              className={`btn btn-sm btn-secondary upload-btn ${disabled ? 'manz-disabled' : ''}`}
            >
              {subString(btnText, 45)}
            </button>
          </div>
        )}
      </Dropzone>

      { invalid && submitFailed && error && (
        <span className="error">
          <InfoSVG title="" />
          {' '}
          {error}
        </span>
      )}
    </div>
  );
}

Upload.defaultProps = {
  id: '',
  name: '',
  tooltip: '',
  meta: null,
  input: null,
  disabled: false,
  className: null,
};

Upload.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Upload;
