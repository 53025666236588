const EXIST_EMPFANGEN_JOB = 'EXIST_EMPFANGEN_JOB';

/**
 * initErledigungEmpfangenJob
 * @return {undefined}
 */
export async function initErledigungEmpfangenJob() {
  await sessionStorage.setItem(EXIST_EMPFANGEN_JOB, false);
}

/**
 * setErledigungEmpfangenJob
 * @param {Boolean} existEmpfangenJob existEmpfangenJob
 * @return {undefined}
 */
export async function setErledigungEmpfangenJob(existEmpfangenJob) {
  await sessionStorage.setItem(EXIST_EMPFANGEN_JOB, existEmpfangenJob);
}

/**
 * setErledigungEmpfangenJob
 * @return {undefined}
 */
export async function existErledigungEmpfangenJob() {
  return await sessionStorage.getItem(EXIST_EMPFANGEN_JOB);
}
