import React from 'react';
import modalFactory from './modalFactory';
import PortalWrapper from '../Portal';
import PortalBackdrop from '../Portal/PortalBackdrop';

/**
 * a modal component that can be inserted anywhere in the application, to display modal dialogs.
 * modal dialogs are instantiated with the modalFactory (see makeRestCall for a simple exmpale).
 */
class ModalContainer extends React.Component {
  /**
   * constructor registers the modal container to the factory
   */
  constructor() {
    super();

    this.modalClass = null;
    this.modalProps = null;
    this.componentRef = this;

    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    modalFactory.registerContainer(this.componentRef);
  }

  /**
   * makes sure to remove the key event listener!
   * @returns {undefined}
   */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  /**
   * key event handler - closes the modal if the ESC key has been pressed
   * @param {Object} e js key event obj
   * @returns {undefined}
   */
  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  }

  /**
   * shows/displays a modal dialog
   * @param {Object} modalClass class/component of the modal, which should be instantiated
   * @param {Object} props properties for the modal
   * @param {Object} params additional parameters. those params are returned
   * @returns {undefined}
   */
  show(modalClass, props, params) {
    document.addEventListener('keydown', this.handleKeyDown, false);

    const p = {
      ...props,
      onClose: this.close,
    };

    if (params) {
      p.params = {
        ...params,
      };
    }

    this.modalClass = modalClass;
    this.modalProps = p;
    this.setState({});
  }

  /**
   * shows/displays a modal dialog and returns a promise of the modal to wait for user
   * interaction without using an extra callback. usefull for async/await syntax
   * @param {Object} modalClass class/component of the modal, which should be instantiated
   * @param {Object} props properties for the modal
   * @param {Object} params additional parameters. those params are returned
   * @returns {Promise} promise
   */
  async showWithPromise(modalClass, props, params) {
    document.addEventListener('keydown', this.handleKeyDown, false);

    return new Promise((resolve) => {
      const handleClose = () => {
        this.close();
        resolve(null);
      };

      const p = {
        ...props,
        resolve,
        onClose: handleClose,
      };

      if (params) {
        p.params = {
          ...params,
        };
      }

      this.modalClass = modalClass;
      this.modalProps = p;

      this.setState({}); // force render
    });
  }

  /**
   * close the modal dialog. each modal has to call this function on its own
   * @returns {undefined}
   */
  close() {
    document.removeEventListener('keydown', this.handleKeyDown, false);

    this.modalClass = null;
    this.modalProps = null;
    this.setState({});
  }

  /**
   * render
   * @returns {JSX} component JSX i.e. modal
   */
  render() {
    if (this.modalClass) {
      const modal = React.createElement(this.modalClass, this.modalProps);

      return (
        <PortalWrapper
          key="portal"
        >
          <PortalBackdrop
            key="portal-backdrop"
            onBackdropClick={this.close}
          />
          <div
            key="portal-content"
            className="manz-modal"
          >
            {modal}
          </div>
        </PortalWrapper>
      );
    }

    return null;
  }
}

export default ModalContainer;
