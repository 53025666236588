import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import { goToAnchor } from 'react-scrollable-anchor';
import s from './Navigation.module.scss';
import { inViewport } from '../../../../utils/landing.page.utils';
import { LANDING_PAGE } from '../../../../constants/path';
import {
  SECTION_FUNKTIONEN,
  SECTION_KONTAKT,
  SECTION_PAKETE,
  SECTION_USECASES,
} from '../../../../LandingPage/sections';
/**
 * home site component
 * display the landing page if not authenticates otherwise redirects to upload
 * @returns {JSX} component JSX
 * @param {Object} props props
 */
function Navigation(props) {
  const {
    handleCloseMenu,
  } = props;
  const LANDING_PAGE_URL = LANDING_PAGE.value;
  return (
    <nav className={s['main-nav']}>
      <ul>
        <li className={inViewport(SECTION_USECASES) ? s.active : ''}>
          <HashLink
            to={`${LANDING_PAGE_URL}#${SECTION_USECASES}`}
            onClick={() => {
              handleCloseMenu();
              goToAnchor(SECTION_USECASES, false);
            }}
          >
            Use Cases
          </HashLink>
        </li>
        <li className={inViewport(SECTION_FUNKTIONEN) ? s.active : ''}>
          <HashLink
            to={`${LANDING_PAGE_URL}#${SECTION_FUNKTIONEN}`}
            onClick={() => {
              handleCloseMenu();
              goToAnchor(SECTION_FUNKTIONEN, false);
            }}
          >
            Funktionen
          </HashLink>
        </li>
        <li className={inViewport(SECTION_PAKETE) ? s.active : ''}>
          <HashLink
            to={`${LANDING_PAGE_URL}#${SECTION_PAKETE}`}
            onClick={() => {
              handleCloseMenu();
              goToAnchor(SECTION_PAKETE, false);
            }}
          >
            Pakete
          </HashLink>
        </li>
        <li className={inViewport(SECTION_KONTAKT) ? s.active : ''}>
          <HashLink
            to={`${LANDING_PAGE_URL}#${SECTION_KONTAKT}`}
            onClick={() => {
              handleCloseMenu();
              goToAnchor(SECTION_KONTAKT, false);
            }}
          >
            Kontakt
          </HashLink>
        </li>

        {/* <li className="d-block d-lg-none">
          <HashLink to={FAQ_PAGE_ROOT} onClick={handleCloseMenu}>
            FAQ
          </HashLink>
        </li>
        { appAuthorized && (
          <li className="d-block d-lg-none">
            <a href={URL_BENUTZERHANDBUCH} target="_blank" rel="noreferrer noopener">Benutzerhandbuch</a>
          </li>
        )} */}
      </ul>
    </nav>
  );
}

Navigation.defaultProps = {
  handleCloseMenu: () => {},
};
Navigation.propTypes = {
  handleCloseMenu: PropTypes.func,
};
export default Navigation;
