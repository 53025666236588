import React from 'react';
import PropTypes from 'prop-types';
import ModalHeader from './ModalHeader';
import { EVENT_ABORT_JUSTIZBOX_UPLOAD } from '../../constants/js/events.constants';
import PollingEventEmitter from '../Events/PollingEventEmitter';

/**
 * Represents a modal displayed when an upload process is not finished
 *
 * @class NotAuthorizedModal
 * @extends React.Component
 */
class NotAuthorizedModal extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  /**
   * confirm button action either executes a given callback or resolves the promise
   * @returns {undefined}
   */
  handleConfirm() {
    const {
      resolve,
      params,
      onClose,
      onConfirm,
    } = this.props;

    PollingEventEmitter.emit(EVENT_ABORT_JUSTIZBOX_UPLOAD);

    if (resolve) {
      resolve(params || true);
    }
    if (onConfirm) {
      onConfirm(params || true);
    }
    onClose();
  }

  /**
   * confirmation modal render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      onClose,
    } = this.props;

    return [
      <ModalHeader
        key="modal-header"
        onClose={onClose}
      >
        <div className="font-size-21px">
          Achtung!
        </div>
      </ModalHeader>,
      <div
        key="modal-body"
        className="manz-modal-body "
      >
        <div>
          <div className="font-weight-bold">
            Sie Haben keine Berechtigung für die webERV-Software. Bitte wenden Sie sich an unsere MANZ Hotline oder Ihren Administrator.
          </div>
          <br />
          <div className="font-weight-bold">
            Hotline:
          </div>
          <div>
            Mo - Fr von 8 bis 18 Uhr
          </div>
          <div>
            Tel.: +43 1 531 61-11
          </div>
          <div>
            <span>
              {'E-Mail: '}
            </span>
            <a href="mailto:hotline@manz.at" className="full-width manz-link" title="hotline@manz.at" aria-label="hotline@manz.at">hotline@manz.at</a>
          </div>
        </div>
      </div>,
      <div
        key="modal-footer"
        className="manz-modal-footer"
      >
        <button
          type="button"
          onClick={onClose}
          aria-label="OK Button"
          className="btn btn-sm btn-secondary"
        >
          OK
        </button>
      </div>,
    ];
  }
}

NotAuthorizedModal.defaultProps = {
  resolve: null,
  params: null,
  onConfirm: null,
};

NotAuthorizedModal.propTypes = {
  resolve: PropTypes.func,
  params: PropTypes.object,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default NotAuthorizedModal;
