import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import Erledigung from './Erledigung';

/**
 * ErledigungRoutes
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function ErledigungRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={Erledigung} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

ErledigungRoutes.defaultProps = {
  match: null,
};

ErledigungRoutes.propTypes = {
  match: PropTypes.object,
};

export default ErledigungRoutes;
