import React from 'react';
import { Link } from 'react-router-dom';
import Menu from 'react-burger-menu/lib/menus/slide';
import webErvLogo from '../../../assets/logo-manz-webERV.svg';
import { ReactComponent as TelSVG } from '../../../assets/icons/mz-tel.svg';
import iconCross from '../../../assets/icons/mz-cross-thick-gray.svg';
import iconBurger from '../../../assets/icons/mz-burger-menu.svg';
import PATH from '../../../constants/path';
import Navigation from './Navigation/Navigation';
import { isMobileView } from '../../../utils/landing.page.utils';

/**
 * Header
 * @returns {JSX} component JSX
 */
class Header extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      showBurgerMenu: false,
      viewMobile: isMobileView(),
    };

    // method for viewMobile
    this.handleResize = this.handleResize.bind(this);
    // methods for showBurgerMenu
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleShouldMenuOpen = this.handleShouldMenuOpen.bind(this);
  }

  /**
   * event handler
   * @returns {undefined}
   */
  handleShouldMenuOpen() {
    const { showBurgerMenu, viewMobile } = this.state;
    if (
      showBurgerMenu === false
      && viewMobile === true
    ) {
      this.setState(() => ({
        showBurgerMenu: true,
      }));
    }
  }

  /**
   * This keeps your state in sync with the opening/closing of the menu
   * via the default means, e.g. clicking the X, pressing the ESC key etc.
   * see https://github.com/negomi/react-burger-menu/wiki/FAQ#i-want-to-control-the-open-state-programmatically-but-i-dont-understand-how-to-use-the-isopen-prop
   * @param {Object} state state
   * @returns {undefined}
   */
  handleStateChange(state) {
    this.setState(() => ({
      showBurgerMenu: state.isOpen,
    }));
  }

  /**
   * close the burger menu
   * @returns {undefined}
   */
  handleCloseMenu() {
    this.setState(() => ({
      showBurgerMenu: false,
    }));
  }

  /**
   * open the burger menu
   * @returns {undefined}
   */
  handleOpenMenu() {
    this.setState(() => ({
      showBurgerMenu: true,
    }));
  }

  /**
   * handle resize - hide burger menu if screen is larger than md
   * @returns {undefined}
   */
  handleResize() {
    const { viewMobile } = this.state;
    if (viewMobile !== isMobileView()) {
      this.setState(() => ({
        viewMobile: isMobileView(),
      }));
    }
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const { showBurgerMenu, viewMobile } = this.state;

    const navigationMenuContent = !viewMobile ? (<div className="d-none d-lg-flex" key="header-navigation"><Navigation /></div>) : null;

    const burgerMenuContent = viewMobile ? [
      <Menu
        right
        key="normalmenu"
        customCrossIcon={false}
        customBurgerIcon={false}
        isOpen={showBurgerMenu}
        onStateChange={(state) => this.handleStateChange(state)}
        className={`${viewMobile ? ' visible ' : ' '}  ${showBurgerMenu && viewMobile ? ' isOpen ' : '  '}`}
      >
        <div className="burgerMenuHeader bg-initial">
          <img
            aria-label="logo"
            className="logo"
            src={webErvLogo}
            alt="linkbutler-logo"
            id="burger-menu-logo"
          />
          <img // eslint-disable-line
            alt="close icon"
            src={iconCross}
            className="icon-xl"
            id="burger-menu-close-button"
            onClick={this.handleCloseMenu}
            aria-label="burger menu close button"
          />
        </div>

        <Navigation handleCloseMenu={this.handleCloseMenu} />

      </Menu>,
      <div key="burgermenu" className="burger-logo">
        <button
          type="button"
          id="burger-menu-open-button"
          aria-label="burgermenu button"
          onClick={this.handleOpenMenu}
        >
          <img
            src={iconBurger}
            className="icon-xl"
            alt="open menu icon"
            aria-label="open menu icon"
          />
        </button>
      </div>,
    ] : null;

    return (
      <div className="headerWrapper sticky margin-bridge headerLandingPage">
        <div className="header">
          <div className="rolleStyle">
            <Link to={PATH.ROOT.value} className="logo">
              <img
                className="logo"
                alt="web-erv-logo"
                src={webErvLogo}
                aria-label="weberv logo"
              />
            </Link>
            { navigationMenuContent }
            { burgerMenuContent }
            <div className="icon-menu d-none d-lg-flex">
              <nav>
                <ul className="nav-icons">
                  <li className="nav-icon">
                    <TelSVG className="icon icon-hover" title="" />
                    <div className="submenu">
                      <ul>
                        <li><b>Hotline</b></li>
                        <li><a href="tel:+4315316111" className="full-width manz-link" title="+43 1 531 61 11" aria-label="+43 1 531 61 11">+43 1 531 61 11</a></li>
                        <li><a href="mailto:hotline@manz.at" className="full-width manz-link" title="hotline@manz.at" aria-label="hotline@manz.at">hotline@manz.at</a></li>
                      </ul>
                    </div>
                  </li>
                  {/*
                  <li className="nav-icon">
                    <HelpSVG className="icon icon-hover" />
                    <div className="submenu">
                      <ul>
                        <li>
                           eslint-disable-next-line
                          <a href="/" title="FAQ" className="full-width">FAQ</a>
                        </li>
                        <li>
                           eslint-disable-next-line
                          <a
                            href="/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Benutzerhandbuch
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
