import React from 'react';
import PropTypes from 'prop-types';
import ModalHeader from './ModalHeader';
import { EVENT_ABORT_JUSTIZBOX_UPLOAD } from '../../constants/js/events.constants';
import PollingEventEmitter from '../Events/PollingEventEmitter';

/**
 * Represents a modal displayed when an upload process is not finished
 *
 * @class ErvBoxUploadNotFinishModal
 * @extends React.Component
 */
class ErvBoxUploadNotFinishModal extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  /**
   * confirm button action either executes a given callback or resolves the promise
   * @returns {undefined}
   */
  handleConfirm() {
    const {
      resolve,
      params,
      onClose,
      onConfirm,
    } = this.props;

    PollingEventEmitter.emit(EVENT_ABORT_JUSTIZBOX_UPLOAD);

    if (resolve) {
      resolve(params || true);
    }
    if (onConfirm) {
      onConfirm(params || true);
    }
    onClose();
  }

  /**
   * confirmation modal render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      onClose,
    } = this.props;

    return [
      <ModalHeader
        key="modal-header"
        onClose={onClose}
      >
        <div className="font-size-21px">
          Achtung! Der ERV-Box-Upload zu Manz ist noch nicht abgeschlossen!
        </div>
      </ModalHeader>,
      <div
        key="modal-body"
        className="manz-modal-body "
      >
        <div className="ervbox-upload-not-finish">
          <div>
            <span>
              Wenn Sie den Reiter “Dokumente” verlassen,
            </span>
            <span className="font-weight-bold">
              {' könnte der Upload abgebrochen werden'}
            </span>
            <span>
              !
            </span>
          </div>
          <div className="abbrechen">
          Wollen Sie den Reiter wirklich verlassen?
          </div>
          <div className="buttons">
            <button
              type="button"
              aria-label="Ja Button"
              id="confirmation-ok-btn"
              onClick={this.handleConfirm}
              className="btn btn-sm btn-primary btn"
            >
              JA
            </button>
            <button
              type="button"
              onClick={onClose}
              aria-label="Nein Button"
              id="confirmation-cancel-btn"
              className="btn btn-sm btn-secondary btn"
            >
              NEIN
            </button>
          </div>
        </div>
      </div>,
    ];
  }
}

ErvBoxUploadNotFinishModal.defaultProps = {
  resolve: null,
  params: null,
  onConfirm: null,
};

ErvBoxUploadNotFinishModal.propTypes = {
  resolve: PropTypes.func,
  params: PropTypes.object,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default ErvBoxUploadNotFinishModal;
