import React from 'react';
import { Field } from 'redux-form';
import SaveButton from './SaveButton';
import Checkbox from '../Form/Checkbox';

/**
 * NewsBtnGroup
 * @returns {JSX} component
 */
function NewsBtnGroup() {
  return (
    <div className="newsModal-btns">
      <div className="row newsModal-btns-label">
        <label>
          Diese Information nicht mehr anzeigen
        </label>
        <div className="newsModal-btns-check">
          <Field
            name="avoidNews"
            component={Checkbox}
          />
        </div>
      </div>
      <div className="newsModal-save-btn float-right">
        <SaveButton
          btnText="Gelesen"
          id="news-abschliessen"
          className="btn btn-blue"
        />
      </div>
    </div>
  );
}

export default NewsBtnGroup;
