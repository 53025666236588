import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-oPadding.svg';

/**
 * text redux form component
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
class Text extends React.Component {
  /**
   * constructor - nothing special here
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * change value
   * @param {Object} e event
   * @return {undefined}
   */
  handleChange(e) {
    const value = e.target.value;
    const { input, onInterceptChange } = this.props;
    input.onChange(value || null);

    if (onInterceptChange) {
      onInterceptChange(value);
    }
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      label,
      input,
      tooltip,
      disabled,
      required,
      ariaLabel,
      className,
      minLength,
      placeholder,
      maxLength,
      formatValue,
      isErrorBreak,
      disabledText,
      requiredColor,
      classNameInput,
      isErrorTextRight,
      onInterceptChange,
      meta: {
        error,
        invalid,
        warning,
        submitFailed,
      },
      ...rest
    } = this.props;

    const classNames = className ? className.split(' ') : [];
    if ((error && submitFailed) || warning) {
      classNames.push('form-validation-error');
    }

    if (formatValue) {
      input.value = formatValue(input.value);
    }

    return (
      <div className={classNames.join(' ')}>
        <input
          {...input}
          {...rest}
          type="text"
          title={tooltip}
          disabled={disabled}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          aria-label={ariaLabel || label}
          onChange={this.handleChange}
          className={`${disabledText ? 'input-text-disabled' : 'input-text'} form-control form-control-sm ${requiredColor ? 'required-color' : ''} ${classNameInput}`}
        />
        {
          invalid && submitFailed && error && (
            <span
              className={`error ${isErrorTextRight ? 'form-error-msg-right' : ''} ${isErrorBreak ? 'error-break' : ''}`}
            >
              <InfoSVG
                title=""
              />
              {' '}
              {error}
            </span>
          )
        }
        {
          warning && (
            <span className={`error ${isErrorTextRight ? 'form-error-msg-right' : ''}`}>
              <InfoSVG title="" />
              {' '}
              {warning}
            </span>
          )
        }
      </div>
    );
  }
}

Text.defaultProps = {
  meta: null,
  label: null,
  input: null,
  tooltip: null,
  disabled: false,
  required: false,
  ariaLabel: null,
  className: null,
  minLength: null,
  maxLength: null,
  placeholder: null,
  formatValue: null,
  classNameInput: '',
  disabledText: false,
  isErrorBreak: false,
  requiredColor: false,
  isErrorTextRight: false,
  onInterceptChange: null,
};

Text.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  formatValue: PropTypes.func,
  disabledText: PropTypes.bool,
  isErrorBreak: PropTypes.bool,
  placeholder: PropTypes.string,
  requiredColor: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  isErrorTextRight: PropTypes.bool,
  classNameInput: PropTypes.string,
  onInterceptChange: PropTypes.func,
};

export default Text;
