export const INHATL_ELEMENT_TYPS = {
  VORBRINGEN: 'VORBRINGEN',
  VORBRINGEN_SACHVERHALT: 'VORBRINGEN_SACHVERHALT',
  VORBRINGEN_KUMULIER_ANTRAG: 'VORBRINGEN_KUMULIER_ANTRAG',

  ANSPRUCH: 'ANSPRUCH',
  ANSPRUCH_NAME: 'ansprueche',

  EUM_ANSPRUCH: 'EUM_ANSPRUCH',
  EUM_ANSPRUCH_NAME: 'ansprueche',

  ZINSEN: 'ZINSEN',
  ZINSEN_NAME: 'zinsen',

  EUM_ZINSEN: 'EUM_ZINSEN',
  EUM_ZINSEN_NAME: 'zinsen',

  ERGAENZUNGEN: 'ERGAENZUNGEN',

  EUM_ERGAENZUNGEN: 'EUM_ERGAENZUNGEN',

  BEWEISE: 'BEWEISE',
  BEWEISE_NAME: 'beweise',

  EUM_BEWEISE: 'EUM_BEWEISE',
  EUM_BEWEISE_NAME: 'beweise',

  BESONDERE_ZUSTAENDIGKEIT: 'BESONDERE_ZUSTAENDIGKEIT',

  EUM_BESONDERE_ZUSTAENDIGKEIT: 'EUM_BESONDERE_ZUSTAENDIGKEIT',

  SONSTIGE_KOSTEN: 'SONSTIGE_KOSTEN',
  SONSTIGE_KOSTEN_NAME: 'sonstigeKosten',

  EUM_SONSTIGE_KOSTEN: 'EUM_SONSTIGE_KOSTEN',
  EUM_SONSTIGE_KOSTEN_NAME: 'sonstigeKosten',

  TITEL: 'TITEL',
  TITEL_NAME: 'titel',

  KOSTEN: 'KOSTEN',
  KOSTEN_NAME: 'kosten',

  FIRMA: 'FIRMA',
  FIRMA_NAME: 'firma',

  RECHTSTATSACHE: 'RECHTSTATSACHE',
  RECHTSTATSACHE_NAME: 'rechtstatsachen',

  RECHTSTATSACHE_V2: 'RECHTSTATSACHE_V2',
  RECHTSTATSACHE_V2_NAME: 'rechtstatsachen',

  PERSON: 'PERSON',
  PERSON_NAME: 'person',

  FUNKTION: 'FUNKTION',
  FUNKTION_NAME: 'funktion',

  ZWEIGNIEDERLASSUNG: 'ZWEIGNIEDERLASSUNG',
  ZWEIGNIEDERLASSUNG_NAME: 'zweigniederlassung',

  BEGEHREN: 'BEGEHREN',
  BEGRUENDUNGSZEILE: 'BEGRUENDUNGSZEILE',
  AENDERUNGSZEILE: 'AENDERUNGSZEILE',

  EIGENTUMSANTEIL_ZEILE: 'EIGENTUMSANTEIL_ZEILE',

  GRUNDBUCH_AUSZUG: 'GRUNDBUCH_AUSZUG',
};
