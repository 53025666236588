import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import AsyncSelect from '../common/Form/AsyncSelect';
import { requiredField } from '../utils/validator.utils';
import {
  erledigungAbgerufenAmTooltip,
  erledigungAktenzeichenTooltip,
  erledigungBereitgestelltAmTooltip,
  erledigungBetreffTooltip,
  erledigungEmpfaengerTooltip,
  erledigungGerichtTooltip,
  erledigungGeschaeftszahlTooltip,
  erledigungInterneInfoTooltip,
  erledigungMsgIdTooltip,
  erledigungSchriftsatzartTooltip,
  erledigungStatusTooltip,
  erledigungZusatzInfoTooltip,
} from '../constants/js/tooltip.constaints';
import { AKTENZEICHEN_ASYNC_SETTINGS } from '../constants/js/form.constants';
import FORM from '../constants/form';
import Text from '../common/Form/Text';
import SimpleTable from '../common/Table/SimpleTable';
import { SORT_DIRECTION } from '../constants/js/sort.constants';
import Help from '../common/Form/Help';
import { HELP_URL_ERLEDIGUNG } from '../constants/js/help.url.constaints';
import DeleteButton from '../common/Buttons/DeleteButton';
import SaveButton from '../common/Buttons/SaveButton';
import { createEmpfaengerText, getErledigungWeiterleitenHinweis } from '../utils/erledigung.utils';
import withRestClient from '../common/Rest/withRestClient';
import getUrl from '../common/Rest/rest.utils';
import Rest from '../common/Rest';
import makeRestCall from '../common/Rest/makeRestCall';
import DownloadFile from '../common/Download/DownloadFile';
import INHALT_ART from '../constants/erledigung.inhalt.art';
import {
  addActionToAsyncSettings,
  convertFromGMTDateToString,
  convertFromGMTDateWithTimeToString, getReadableFileSizeString,
} from '../utils/general.utils';
import { NO_CONTENT, OK } from '../common/Rest/http.status.codes';
import TextView from '../common/Form/TextView';
import MessageID from './MessageID';
import ToggleSwitch from '../common/Form/ToggleSwitch';
import { isLargePage } from '../common/Site/Site.uitls';
import MobileHeader from './MobileHeader';
import PollingEventEmitter from '../common/Events/PollingEventEmitter';
import { EVENT_COUNT_GS_AUSGANG_UG_EINGANG } from '../constants/js/events.constants';
import {
  ERLEDIGUNG_DOCUMENT_DEFAULT_SORT,
  ERLEDIGUNG_DOCUMENT_RESP_TABLE_COLS,
  ERLEDIGUNG_STATUS_OPTIONS,
  ERLEDIGUNG_DOCUMENT_TABLE_COLS,
} from '../constants/js/erledigung.constants';
import PATH from '../constants/path.json';
import AntwortenButton from '../common/Buttons/AntwortenButton';
import ErledigungWeiterleitenButton from '../common/Buttons/ErledigungWeiterleitenButton';
import LoadingSpinner from '../common/Spinner/Loading/LoadingSpinner';
import TABLE_TYPE from '../constants/table.type.json';
import ProgressBarWithCountDown from '../common/ProgressBar/ProgressBarWithCountDown';
import {
  getPollingTimeErledigungDokumenteDownloadStatus,
} from '../constants/js/application.config';

/**
 * Erledigung (Rückvekehr) component
 */
class Erledigung extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      downloading: false,
      downloadStart: null,
    };

    this.handleAntworten = this.handleAntworten.bind(this);
    this.handleDeleteForm = this.handleDeleteForm.bind(this);
    this.handleWeiterleiten = this.handleWeiterleiten.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleToggleStatus = this.handleToggleStatus.bind(this);
    this.handleResetDownloadingState = this.handleResetDownloadingState.bind(this);
  }

  /**
   * submit form
   * @return {undefined}
   */
  async handleSubmitForm() { // eslint-disable-line
    const { erledigung, formValues, history } = this.props;
    const {
      id,
      aktid,
      aktname,
      isGelesen,
      interneInfo,
    } = formValues;

    const url = getUrl(Rest.urls.erledigungenById, {
      erledigungId: erledigung?.id,
    });

    const payload = {
      erledigungId: id,
      aktName: aktname,
      interneInfo: interneInfo || '',
      status: isGelesen ? 'KONTROLLIERT' : 'NEU',
    };

    if (aktid) {
      payload.aktId = aktid;
    }

    const patchUrl = `${url}?${qs.stringify(payload)}`;

    const result = await makeRestCall('PUT', patchUrl);

    PollingEventEmitter.emit(EVENT_COUNT_GS_AUSGANG_UG_EINGANG);

    if (result.status === OK) {
      history.push(PATH.ROOT.value);
    }
  }

  /**
   * get download url of a anhang
   * @param {Object} dokument dokument
   * @return {undefined}
   */
  _getDownloadDokumentUrl(dokument) {
    const { erledigung } = this.props;
    return getUrl(Rest.urls.erledigungDokumentByIdFile, {
      erledigungId: erledigung?.id,
      dokumentId: dokument?.id,
    });
  }


  /**
   * get download url of all anhang
   * @return {undefined}
   */
  _getDownloadAllDokumentUrl() {
    const { erledigung } = this.props;
    if (erledigung) {
      return getUrl(Rest.urls.erledigungDokumentAllFile, {
        erledigungId: erledigung?.id,
      });
    }
    return null;
  }

  /**
   * delete a erledigung
   * @return {undefined}
   */
  async handleDeleteForm() { // eslint-disable-line
    const { erledigung, history } = this.props;
    const url = getUrl(Rest.urls.erledigungenById, {
      erledigungId: erledigung?.id,
    });

    const restResult = await makeRestCall('DELETE', url);

    if (restResult.status === OK || restResult.status === NO_CONTENT) {
      history.goBack();
    }
  }

  /**
   * toggles the status of the erledigung
   * @returns {undefined}
   */
  handleToggleStatus() {
    const { formValues, change } = this.props;
    if (formValues) {
      change('isGelesen', !formValues.isGelesen);
    }
  }

  /**
   * antworten
   * @return {Promise<void>} undefined
   */
  async handleAntworten() {
    this.setState({ loading: true });
    await this._handleErledigungToTldz(true);
    this.setState({ loading: false });
  }

  /**
   * weiterleiten
   * @return {Promise<void>} undefined
   */
  async handleWeiterleiten() {
    this.setState({ loading: true });
    await this._handleErledigungToTldz(false);
    this.setState({ loading: false });
  }

  /**
   * convert from erledigung to tldz
   * @param {Boolean} isAntwort isAntwort
   * @return {Promise<void>} undefined
   * @private
   */
  async _handleErledigungToTldz(isAntwort) {
    const { erledigung, history } = this.props;
    if (erledigung?.id) {
      let copyErvboxDokumente = false;

      if (await getErledigungWeiterleitenHinweis(erledigung)) {
        copyErvboxDokumente = true;
      }

      const url = getUrl(Rest.urls.schriftverkehrErledigungToTldz, {
        erledigungId: erledigung.id,
        isAntwort,
        copyErvboxDokumente,
      });

      const result = await makeRestCall('POST', url);

      if (result.status === OK && result?.body?.id && result?.body?.inhaltArt === INHALT_ART.TLDZ.value) {
        history.push({
          pathname: `${PATH.SCHRIFTSATZ.value}${PATH.TLDZ.value}`,
          search: `?schriftsatzId=${result.body?.id}`,
        });
      }
    }
  }

  /**
   * handleResetDownloadingState
   * @param {Boolean} downloading downloading
   * @return {undefined}
   */
  handleResetDownloadingState(downloading) {
    const { downloadStart } = this.state;
    this.setState({ downloading });
    if (downloading) {
      this.setState({ downloadStart: new Date().getTime() });
    } else {
      console.log('Download in Sekunden: ' + ((new Date().getTime() - downloadStart) / 1000)); // eslint-disable-line
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      change,
      pristine,
      erledigung,
      submitting,
      formValues,
      handleSubmit,
      globalDisabled,
    } = this.props;
    const { loading, downloading } = this.state;

    const aktenzeichenAsyncSettings = addActionToAsyncSettings(AKTENZEICHEN_ASYNC_SETTINGS, change);
    const isMobileView = !isLargePage();
    const isShowAntwortButton = (erledigung && erledigung.inhaltArt && (erledigung.inhaltArt === INHALT_ART.TLDZ.value || erledigung.inhaltArt === INHALT_ART.DIREKTVERKEHR.value || erledigung.inhalt === INHALT_ART.EZUSTELLUNG.value));

    const tableData = [];
    if (erledigung && erledigung.dokumente && erledigung.dokumente.length > 0) {
      erledigung.dokumente.forEach((dokument) => {
        if (dokument) {
          const data = {};
          const datum = convertFromGMTDateToString(dokument.date);
          data.reihung = dokument.reihung;

          const artLabel = dokument.artLabel ? dokument.artLabel : '';

          const firstLine = (
            <>
              {artLabel}
              {' - '}
              {dokument.bezeichnung}
            </>
          );
          const secondLine = (dokument.bemerkung || dokument.zeichen) && (
            <>
              <br />
              {dokument.bemerkung}
              {dokument.zeichen && ` - ${dokument.zeichen}`}
            </>
          );
          const thirdLine = (dokument.filename || dokument.filesize) && (
            <>
              <br />
              {dokument.filename && `${dokument.filename}`}
              {dokument.filesize && ` (${getReadableFileSizeString(dokument.filesize, 1)})`}
            </>
          );

          data.label = (
            <div>
              {datum}
              {' '}
              {firstLine}
              {secondLine}
              {thirdLine}
            </div>
          );

          const fileName = dokument.filename;

          data.download = (
            <DownloadFile
              key="DownloadFile"
              filename={fileName}
              btnText="Download"
              className="btn-secondary btn-sm"
              onResetDownloadingState={this.handleResetDownloadingState}
              requestUrl={this._getDownloadDokumentUrl(dokument)}
            />
          );
          data.downloadMobil = (
            <DownloadFile
              filename={fileName}
              btnText={firstLine}
              className="documentDownloadMobile"
              key="DownloadFile-documentDownloadMobile"
              requestUrl={this._getDownloadDokumentUrl(dokument)}
            />
          );
          tableData.push(data);
        }
      });
    }

    const schriftsatzArt = (erledigung && erledigung.inhaltArt && INHALT_ART[erledigung.inhaltArt] && INHALT_ART[erledigung.inhaltArt].label) || '';

    const isErledigung = erledigung && erledigung.applikationsTyp;
    const showForTLDZ = (isErledigung && erledigung.applikationsTyp === 'TLDZ');
    const showForGW = (isErledigung && erledigung.applikationsTyp === 'GW');
    const showForDVS = (isErledigung && erledigung.applikationsTyp === 'DVS');
    const showForSBG = (isErledigung && erledigung.applikationsTyp === 'SBG');
    const showForSTV = (isErledigung && erledigung.applikationsTyp === 'STV');
    const showAbsender = showForTLDZ || showForGW || showForDVS || showForSBG || showForSTV;

    let zusatzInfoID = '';
    if (isErledigung && erledigung.applikationsTyp === 'FB') {
      zusatzInfoID = 'erledigung-firmenbuchNummer';
    } else if (showAbsender) {
      zusatzInfoID = 'erledigung-zusatzInfo';
    } else if (erledigung?.zusatzinfo) {
      zusatzInfoID = 'erledigung-fehler';
    }

    const statusValue = formValues && formValues.isGelesen ? ERLEDIGUNG_STATUS_OPTIONS[1] : ERLEDIGUNG_STATUS_OPTIONS[0];

    const anhaengeMobil = isMobileView ? tableData.map((row) => (row.downloadMobil)) : '';

    return (
      <div className="erledigung">
        {loading === true && <LoadingSpinner key="spinner" />}

        <Prompt
          message="-"
          key="Prompt"
          when={!pristine && !submitting}
        />

        {!isMobileView && (
          <div className="form-row align-items-center mb-3">
            <h2 className="col-12 col-sm-auto mb-sm-0">Erledigung</h2>
            <Field
              name="msgId"
              className="col"
              id="erledigung-msgId"
              component={MessageID}
              tooltip={erledigungMsgIdTooltip}
            />
          </div>
        )}

        <form onSubmit={handleSubmit(this.handleSubmitForm)}>
          <div className="d-none d-lg-block">
            <Help
              key="Help"
              href={HELP_URL_ERLEDIGUNG}
            />
          </div>

          <div className="row">
            <div className="col-12 col-lg-8 col-xl-6 mb-3">
              <div className="form-row mb-2 justify-content-between d-md-none">
                {isMobileView ? (
                  <MobileHeader
                    pristine={pristine}
                    key="MobileHeader"
                    erledigung={erledigung}
                    globalDisabled={globalDisabled}
                    onSubmit={this.handleSubmitForm}
                    handleDeleteForm={this.handleDeleteForm}
                  />
                ) : ''}
              </div>

              <div className="form-row form-group container-gray pl-0 justify-content-center justify-content-md-start">
                <label
                  className="col-form-label col-auto col-sm-4 pt-0"
                >
                  Bereitgestellt am
                </label>
                <div className="col-auto col-md-8">
                  <Field
                    component={TextView}
                    name="bereitstellZeitpunkt"
                    id="erledigung-bereitgestelltAm"
                    tooltip={erledigungBereitgestelltAmTooltip}
                    formatValue={convertFromGMTDateWithTimeToString}
                  />
                </div>
                <div className="col-form-label col-12 col-sm-4 pt-0" />
                <div className="col-12 col-sm-8">
                  <div className="manz-red text-align-md-center">Die Frist beginnt am nächsten Werktag!</div>
                </div>
              </div>

              <div className="downloadContainer">
                {anhaengeMobil}
              </div>

              <div className="form-group form-row mt-4">
                <label
                  className="col-form-label col-12 col-sm-4 label-required"
                >
                  Aktenzeichen
                </label>
                <div className="col-12 col-sm-8">
                  <Field
                    autoFocus
                    name="aktname"
                    maxLength={25}
                    id="erledigung-aktname"
                    ariaLabel="Aktenzeichen"
                    disabled={globalDisabled}
                    component={AsyncSelect}
                    validate={requiredField}
                    asyncSettings={aktenzeichenAsyncSettings}
                    tooltip={erledigungAktenzeichenTooltip}
                  />
                </div>
              </div>

              <div className="form-row form-group">
                <label className="col-form-label col-auto col-sm-4">
                  Status
                </label>
                <div className="col-6 col-sm-auto">
                  <Field
                    type="checkbox"
                    name="isGelesen"
                    disabled={globalDisabled}
                    component={ToggleSwitch}
                    suffixLabel={statusValue.label}
                    tooltip={erledigungStatusTooltip}
                    onCallbackClick={this.handleToggleStatus}
                  />
                </div>
                <div className="col-12 col-sm-5 d-flex align-items-center">
                  <Field
                    component={TextView}
                    name="latestStatusChange"
                    id="erledigung-latestStatusChange"
                  />
                </div>
              </div>

              <div className="form-row form-group">
                <label
                  className="col-form-label col-12 col-sm-4"
                >
                  Interne Information
                </label>
                <div className="col-12 col-sm-8">
                  <Field
                    maxLength={255}
                    name="interneInfo"
                    component={Text}
                    id="erledigung-interneInfo"
                    ariaLabel="Interne Information"
                    tooltip={erledigungInterneInfoTooltip}
                  />
                </div>
              </div>

              <div className="form-row mt-3">
                <label
                  className="col-form-label col-auto col-sm-4"
                >
                  Abgerufen am
                </label>
                <div className="col-auto col-sm-8 d-flex align-items-center">
                  <Field
                    name="abholZeitpunkt"
                    component={TextView}
                    id="erledigung-abgerufenAm"
                    tooltip={erledigungAbgerufenAmTooltip}
                    formatValue={convertFromGMTDateWithTimeToString}
                  />
                </div>
              </div>

              <div className="form-row">
                <label
                  className="col-form-label col-auto col-sm-4"
                >
                  {showAbsender ? 'Absender' : 'Empfänger'}
                </label>
                <div className="col-auto col-sm-8 d-flex align-items-center">
                  {showAbsender ? (
                    <Field
                      name="sender"
                      id="erledigung-absender"
                      component={TextView}
                      tooltip={erledigungEmpfaengerTooltip}
                    />
                  ) : (
                    <Field
                      name="empfaenger"
                      component={TextView}
                      id="erledigung-empfaenger"
                      tooltip={erledigungEmpfaengerTooltip}
                      val={createEmpfaengerText(erledigung)}
                    />
                  )}
                </div>
              </div>

              <div className="form-row">
                <label
                  className="col-form-label col-auto col-sm-4"
                >
                  Schriftsatzart
                </label>
                <div className="col-auto col-sm-8 d-flex align-items-center">
                  <Field
                    val={schriftsatzArt}
                    component={TextView}
                    id="erledigung-schriftsatzart"
                    name="erledigung-schriftsatzart"
                    tooltip={erledigungSchriftsatzartTooltip}
                  />
                </div>
              </div>

              <div className="form-row">
                <label
                  className="col-form-label col-auto col-sm-4"
                >
                  Betreff
                </label>
                <div className="col-auto col-sm-8 col-form-label text-left">
                  <Field
                    name="betreff"
                    id="erledigung-betreff"
                    component={TextView}
                    tooltip={erledigungBetreffTooltip}
                  />
                </div>
              </div>

              <div className="form-row ">
                <label
                  className="col-form-label col-auto col-sm-4"
                >
                  Gericht
                </label>
                <div className="col-auto col-sm-8 d-flex align-items-center">
                  <Field
                    name="gerichtLabel"
                    id="erledigung-gericht"
                    component={TextView}
                    tooltip={erledigungGerichtTooltip}
                  />
                </div>
              </div>

              <div className="form-row">
                <label
                  className="col-form-label col-auto col-sm-4"
                >
                  Geschäftszahl
                </label>
                <div className="col-auto col-sm-8 d-flex align-items-center">
                  <Field
                    id="erledigung-gz"
                    name="geschaeftszahl"
                    component={TextView}
                    tooltip={erledigungGeschaeftszahlTooltip}
                  />
                </div>
              </div>

              {isMobileView && (
                <div className="form-row form-group">
                  <label
                    className="col-form-label col-auto col-sm-4"
                  >
                    MessageID
                  </label>
                  <div className="col-auto d-flex align-items-center form-row">
                    <Field
                      name="msgId"
                      className="col"
                      id="erledigung-msgId"
                      component={MessageID}
                      tooltip={erledigungMsgIdTooltip}
                    />
                  </div>
                </div>
              )}

              {zusatzInfoID && (
                <div className="form-row">
                  <label
                    className="col-form-label col-12 col-sm-4"
                  >
                    Zusatzinformation
                  </label>
                  <div className="col-12 col-sm-8 col-form-label text-left">
                    <Field
                      id={zusatzInfoID}
                      name="zusatzinfo"
                      component={TextView}
                      tooltip={erledigungZusatzInfoTooltip}
                    />
                  </div>
                </div>
              )}
            </div>

            {!isMobileView && (
              <>
                <div className="col-xl-1" />
                <div className="col-xl-5">
                  <div className="d-flex justify-content-between align-items-center pr-sm">
                    <h6 className="mt-3 mb-3">
                      Anhänge
                    </h6>
                    {tableData.length > 0 && (
                      <DownloadFile
                        key="DownloadFile"
                        filename="Anhänge.zip"
                        btnText="Alle Anhänge als ZIP-Download"
                        className="btn-secondary btn-sm height-auto"
                        requestUrl={this._getDownloadAllDokumentUrl()}
                        onResetDownloadingState={this.handleResetDownloadingState}
                      />
                    )}
                  </div>

                  {downloading && (
                    <div className="justizbox-progress-bar">
                      <ProgressBarWithCountDown
                        key="ProgressBarWithCountDown"
                        titel="Download gestartet... Daten treffen in ca. "
                        duration={getPollingTimeErledigungDokumenteDownloadStatus()}
                      />
                    </div>
                  )}

                  <SimpleTable
                    withoutPaging
                    canResponsive
                    noTableHeader
                    emptyListText=""
                    className="table"
                    key="SimpleTable"
                    tableData={tableData}
                    total={tableData.length}
                    responsiveSortable={false}
                    sortDirection={SORT_DIRECTION.ASC}
                    tableType={TABLE_TYPE.ERLEDIGUNG_DOKUMENT}
                    sortedBy={ERLEDIGUNG_DOCUMENT_DEFAULT_SORT}
                    tableColumns={ERLEDIGUNG_DOCUMENT_TABLE_COLS}
                    responsiveTableColumns={ERLEDIGUNG_DOCUMENT_RESP_TABLE_COLS}
                  />
                </div>
              </>
            )}

            {!isMobileView ? (
              <div className="col text-right">

                {isShowAntwortButton && (
                  <AntwortenButton
                    onClick={this.handleAntworten}
                  />
                )}

                <ErledigungWeiterleitenButton
                  onClick={this.handleWeiterleiten}
                />

                <SaveButton
                  key="SaveButton"
                  disabled={pristine}
                  submitting={submitting}
                  id="erledigung-save-button"
                />
              </div>
            ) : ''}
          </div>
        </form>

        {!isMobileView && (
          <>
            <div className="schriftsatz-divider" />
            <div className="d-flex justify-content-center">
              <DeleteButton
                type="button"
                key="DeleteButton"
                disabled={globalDisabled}
                id="erledigung-delete-button"
                isDoubleConfirmationRequired
                onClick={this.handleDeleteForm}
                btnText="Gesamte Erledigung löschen"
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

Erledigung.defaultProps = {
  pristine: false,
  erledigung: null,
  formValues: null,
  submitting: false,
  globalDisabled: false,
};

Erledigung.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  erledigung: PropTypes.object,
  formValues: PropTypes.object,
  globalDisabled: PropTypes.bool,
  change: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

/**
 * mapStateToProps function
 * @param {Object} state redux state
 * @param {Object} ownProps component props
 * @returns {Object} mapped state to props
 */
export function mapStateToProps(state, ownProps) { // eslint-disable-line
  const erl = ownProps.erledigung;
  let form = {};

  if (erl && form.isGelesen === undefined) {
    form = {
      ...erl,
      isGelesen: !!((erl && erl.status === 'KONTROLLIERT')),
    };
  }

  return {
    initialValues: {
      ...form,
    },
    formValues: getFormValues(FORM.ERLEDIGUNG)(state),
  };
}

export { Erledigung as ErledigungTest };

let erledigung = reduxForm({ // eslint-disable-line
  form: FORM.ERLEDIGUNG,
  enableReinitialize: true,
})(Erledigung);

erledigung = connect(mapStateToProps)(erledigung);

export default withRouter(withRestClient({
  erledigung: {
    urlTemplate: Rest.urls.erledigungenById,
    disabled: (params) => params.erledigungId === undefined,
    initialParameters: (props) => {
      const q = qs.parse(props.location.search.slice(1));
      return {
        erledigungId: q.erledigungId,
      };
    },
  },
})(erledigung));
