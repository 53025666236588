// eslint-disable-next-line import/no-cycle
import makeRestCall from './makeRestCall';

const MAX_TOTAL_TIME = 5000; // maximum time retries are tried
const MAX_RETRIES = 3;

/**
 * request info class. stores information about requests that are queued or in progress
 */
class RequestInfo {
  /**
   * init
   * @param {String} compiledUrl complete url with params set
   * @param {String} urlTemplate url template without params set
   */
  constructor(compiledUrl, urlTemplate) {
    this.compiledUrl = compiledUrl;
    this.urlTemplate = urlTemplate;
    this.subscribers = [];
    this.inProgress = false;
    this.done = false;
    this.timeIsUp = false;
    this.retries = 0;
    this.progressStart = null;
  }

  /**
   * adds a subscriber (-> component) to a request. if the component is already subscribed to the
   * a request return false
   * @param {number} componentId id of the component that subscribes to a request
   * @param {number} requestId id of the request to subscribe
   * @returns {boolean} true if the subscribtion was successful
   */
  addSubscriber(componentId, requestId) {
    const index = this.subscribers.findIndex((s) => s.componentId === componentId);
    if (index === -1) {
      this.subscribers.push({
        componentId,
        requestId,
      });
      return true;
    }
    return false;
  }

  /**
   * execute the request. automatically retries if the request was unsuccessful
   * @returns {undefined}
   */
  async executeRequest() {
    this.inProgress = true;
    let done = false;
    const timer = setTimeout(() => {
      this.timeIsUp = true;
    }, MAX_TOTAL_TIME);

    let result = null;

    do {
      result = await makeRestCall('GET', this.compiledUrl);
      if (result.error) {
        this.retries += 1;
      } else {
        done = true;
      }
    } while (!done && !this.timeIsUp && this.retries < MAX_RETRIES);

    clearTimeout(timer);
    this.done = true;

    if (result.error) {
      return {
        error: true,
        data: result,
      };
    }

    return {
      error: false,
      data: result.body,
    };
  }

  /**
   * Remove a request id from the subscribers list
   * @param {number} requestId the request id to remove
   * @returns {boolean} if a request was canceled
   */
  cancelId(requestId) {
    let foundOne = false;
    for (let i = this.subscribers.length - 1; i >= 0; i -= 1) {
      if (this.subscribers[i].requestId === requestId) {
        foundOne = true;
        this.subscribers.splice(i, 1);
        break;
      }
    }
    return foundOne;
  }
}

export default RequestInfo;
