import React from 'react';
import modalFactory from '../common/Modal/modalFactory';
import ConfirmationModal from '../common/Modal/ConfirmationModal';
import getFormattedMessage from '../common/FormatMessage/formattedMessage';

/**
 * create erledigung empfaenger text
 * @param {Object} erledigung erledigung
 * @return {string} empfaenger text
 */
export function createEmpfaengerText(erledigung) {
  let retval = '';
  if (erledigung && erledigung.einbringer) {
    const einbringer = erledigung.einbringer;
    if (einbringer.name1) {
      retval += `${einbringer.name1}, `;
    }
    if (einbringer.name2) {
      retval += `${einbringer.name2}, `;
    }
    if (einbringer.adresse) {
      const adresse = einbringer.adresse;
      retval += `${adresse.strasse} ${adresse.nr}, `;
      retval += `${adresse.plz} ${adresse.ort}`;
    }
  }
  return retval;
}

/**
 * getErledigungWeiterleitenHinweis
 * @param {Object} erledigung erledigung
 * @return {Promise<undefined>} getErledigungWeiterleitenHinweis
 */
export async function getErledigungWeiterleitenHinweis(erledigung) {
  const body = (
    <div>
      <div>
        {`Sie haben zum Weiterleiten eine Direktzustellung von "${erledigung.aktname}" erstellt. Bitte geben Sie den gewünschten Empfänger ein, damit der Schriftsatz an die gewünschte Stelle weitergeleitet wird.`}
      </div>
      <br />

      <div className="font-weight-bold">
        Die ERV-Box Anhänge werden für Sie erneut hochgeladen, wobei Kosten für den erneuten Upload entstehen.
      </div>

      <div className="">
        {'Die Zusatzkosten beim Upload entnehmen Sie bitte den '}
        <span>
          <a href="https://www.manz.at/fileadmin/media/produkte/weberv-software/manz-weberv-service-entgeltbestimmungen.pdf" target="_blank" rel="noopener noreferrer">Entgeltbestimmungen</a>
        </span>
        .
      </div>
      <br />

      <div className="font-weight-bold">
        Möchten Sie die ERV-Box Anhänge erneut hochladen?
      </div>
    </div>
  );


  return await modalFactory.openWithPromise(ConfirmationModal, {
    header: getFormattedMessage({ id: 'hinweis.header' }),
    body,
  });
}
