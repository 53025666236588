import React from 'react';
import s from './RedCircle.module.scss';
/**
 * RedCircle component
 * @returns {React.Component} - React component
 */
function RedCircle() {
  return (
    <div className={s.redCircle}>
      <div>
        Webbrowser
        <br />
        starten und
        <br />
        loslegen!
      </div>
    </div>
  );
}

export default RedCircle;
