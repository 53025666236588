import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-oPadding.svg';

/**
 * checkbox redux form component
 * @param {Object} props props
 * @returns {JSX} component JSX
 */
function ToggleSwitch(props) {
  const {
    id,
    titel,
    input,
    tooltip,
    disabled,
    className,
    suffixLabel,
    isBlueThema,
    onCallbackClick,
    onInterceptChange,
    disabledToggleSwitch,
    meta: {
      error,
      invalid,
      submitFailed,
    },
  } = props;

  const classNames = className ? className.split(' ') : [];
  if (error && submitFailed) {
    classNames.push('form-validation-error');
  }

  let label = input.value ? 'Ja' : 'Nein';
  if (suffixLabel) {
    label = suffixLabel;
  }

  return (
    <div
      title={tooltip}
      id={`toggle-switch-div-${id}`}
      className={`${classNames.join(' ')} ${isBlueThema ? 'beispiel' : ''} d-flex align-items-center h-100 toggle-switch`}
    >
      <Toggle
        title={tooltip}
        icons={false}
        disabled={disabled}
        checked={!!input.value}
        aria-label={titel || label}
        defaultChecked={!!input.value}
        id={`toggle-switch-${id.toString()}`}
        onClick={() => {
          const val = !!input.value;
          input.onChange(!val);
          if (onCallbackClick) {
            onCallbackClick();
          }
          if (onInterceptChange) {
            onInterceptChange(!val);
          }
        }}
      />
      <span id={`toggle-switch-label-${id}`} className={`${disabledToggleSwitch ? 'titel-disabled' : 'switch-label'}`}>{titel || label}</span>

      {
        invalid && submitFailed && error && (
          <span className="error">
            <InfoSVG title="" />
            &nbsp;
            {error}
          </span>
        )
      }
    </div>
  );
}

ToggleSwitch.defaultProps = {
  id: '',
  titel: null,
  meta: null,
  input: null,
  tooltip: null,
  disabled: false,
  className: null,
  suffixLabel: null,
  isBlueThema: false,
  onCallbackClick: null,
  onInterceptChange: null,
  disabledToggleSwitch: false,
};

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  titel: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  suffixLabel: PropTypes.string,
  isBlueThema: PropTypes.bool,
  onCallbackClick: PropTypes.func,
  onInterceptChange: PropTypes.func,
  disabledToggleSwitch: PropTypes.bool,
};

export default ToggleSwitch;
