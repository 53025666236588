import React from 'react';
import PropTypes from 'prop-types';
import filesaver from 'file-saver';
import { getRestRoot } from '../Rest/rest.utils';
import LoadingSpinner from '../Spinner/Loading/LoadingSpinner';
import { handleRestError } from '../Rest/RestError.utils';
import { ReactComponent as DownloadSVG } from '../../assets/icons/mz-download.svg';
import { OK } from '../Rest/http.status.codes';
import { arrayHasItem } from '../../utils/general.utils';
import LoadingSpinnerCenter from '../Spinner/Loading/LoadingSpinnerCenter';

/**
 * DownloadFile component
 */
class DownloadFile extends React.Component {
  /**
   * constuctor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleDownload = this.handleDownload.bind(this);
  }

  /**
   * async download job
   * @return {Promise<void>} undefined
   */
  async handleDownload() {
    const { isMultiDownload } = this.props;
    if (isMultiDownload) {
      await this.downloadMultiFile();
    } else {
      await this.downloadSingleFile();
    }
  }

  /**
   * SingleFileDownload
   * @return {Promise<void>} undefined
   * @constructor
   */
  async downloadMultiFile() {
    const { requestUrl, filename, onResetDownloadingState } = this.props;

    this.setState({ loading: true });

    if (onResetDownloadingState) {
      onResetDownloadingState(true);
    }

    if (arrayHasItem(requestUrl)) {
      for (let i = 0; i < requestUrl.length; i += 1) {
        await this.handleDownloadFile(requestUrl[i], `${filename}_${i + 1}.pdf`); // eslint-disable-line
      }
    }

    this.setState({ loading: false });

    if (onResetDownloadingState) {
      onResetDownloadingState(false);
    }
  }

  /**
   * SingleFileDownload
   * @return {Promise<void>} undefined
   * @constructor
   */
  async downloadSingleFile() {
    const { requestUrl, filename, onResetDownloadingState } = this.props;

    this.setState({ loading: true });

    if (onResetDownloadingState) {
      onResetDownloadingState(true);
    }

    await this.handleDownloadFile(requestUrl, filename);

    this.setState({ loading: false });

    if (onResetDownloadingState) {
      onResetDownloadingState(false);
    }
  }

  /**
   * handleDownloadFile
   * @param {String} requestUrl requestUrl
   * @param {String} filename filename
   * @return {Promise<void>} undefined
   */
  // eslint-disable-next-line class-methods-use-this
  async handleDownloadFile(requestUrl, filename) { // eslint-disable-line
    const restRoot = getRestRoot();
    const url = `${restRoot}${requestUrl}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache', // eslint-disable-line
      },
    });

    if (response.status === OK) {
      filesaver.saveAs(await response.blob(), filename);
    } else {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        json = null;
      }
      handleRestError(url, response.status, json);
    }
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      btnText,
      btnTitle,
      isCenter,
      disabled,
      className,
      classNameSVG,
    } = this.props;
    const { loading } = this.state;

    let title = btnText || '';

    if (btnTitle) {
      title = btnTitle;
    }

    const clazz = isCenter ? 'd-flex justify-content-center align-items-center' : '';

    return (
      <div className={clazz}>
        {loading && (
          <div className="position-absolute">
            <LoadingSpinner key="spinner" />
          </div>
        )}

        <LoadingSpinnerCenter display={loading} />

        <button
          title={title}
          type="button"
          disabled={disabled}
          aria-label="Download Button"
          onClick={this.handleDownload}
          className={`${className} icon-button btn`}
        >
          <DownloadSVG className={classNameSVG} title={title} />
          {btnText && (
            <span className="label">{btnText}</span>
          )}
        </button>
      </div>
    );
  }
}

DownloadFile.defaultProps = {
  btnText: '',
  isCenter: true,
  disabled: false,
  requestUrl: null,
  classNameSVG: null,
  isMultiDownload: false,
  btnTitle: 'Downloaden',
  filename: 'dokument.pdf',
  onResetDownloadingState: null,
  className: 'btn btn-sm btn-secondary',
};

DownloadFile.propTypes = {
  isCenter: PropTypes.bool,
  disabled: PropTypes.bool,
  btnTitle: PropTypes.string,
  btnText: PropTypes.string,
  filename: PropTypes.string,
  className: PropTypes.string,
  requestUrl: PropTypes.string,
  classNameSVG: PropTypes.string,
  isMultiDownload: PropTypes.bool,
  onResetDownloadingState: PropTypes.func,
};

export default DownloadFile;
