import React from 'react';
import PropTyes from 'prop-types';
import HelpIcon from '../Icons/HelpIcon';
import modalFactory from '../Modal/modalFactory';
import HelpModal from './HelpModal';
import { getMrsUrl } from '../../utils/help.utils';

/**
 * Help component
 * @param {Object} props props
 * @return {JSX} component
 * @constructor
 */
class Help extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * click
   * @param {Object} event event
   * @return {undefined}
   */
  handleClick(event) {
    const { href } = this.props;

    event.stopPropagation();
    event.preventDefault();

    modalFactory.open(
      HelpModal,
      {
        size: 'lg',
        hilfeTitel: 'Hilfe',
        hilfePathname: href,
      },
    );
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    // eslint-disable-next-line no-unused-vars
    const { href, className, isNewSchriftsatz } = this.props;

    return (
      <a
        title="Hilfe"
        target="_blank"
        aria-label="Hilfe"
        rel="noopener noreferrer"
        href={getMrsUrl(href)}
        className={`${className} help-icon manz-link`}
        onClick={(e) => this.handleClick(e)}
      >
        <HelpIcon />
      </a>
    );
  }
}

Help.defaultProps = {
  href: null,
  className: '',
  isNewSchriftsatz: true,
};

Help.propTypes = {
  href: PropTyes.string,
  className: PropTyes.string,
  isNewSchriftsatz: PropTyes.bool,
};

export default Help;
