import invariant from 'invariant';
import ComponentInfo from './ComponentInfo';
// eslint-disable-next-line import/no-cycle
import RequestInfo from './RequestInfo';
import RestCache from './RestCache';

const DEFAULT_MAX_AGE = 1000; // default max age to treat cached data as "fresh"
const IN_PROGRESS_MAX_AGE = 10; // maximum age of an in progress request in ms to avoid a duplicate
const MAX_SIMULTANEOUS_REQUESTS = 10;

/**
 * a scheduler for handling rest requests. allows only a maximum amount of connections
 * automatically sends requests retries and handles errors
 */
class RequestScheduler {
  /**
   * init scheduler -> create new cache
   */
  constructor() {
    this.activeComponents = {};
    this.pendingQueue = [];
    this.inProgress = [];

    this.nextComponentId = 0;
    this.nextRequestId = 0;

    this.cache = new RestCache();
  }

  /**
   * registers a react component to the scheduler and adds the component to the active
   * component
   * @param {Object} componentRef component reference object
   * @returns {number} component id
   */
  registerComponent(componentRef) {
    const componentId = this.nextComponentId;
    this.nextComponentId += 1;
    this.activeComponents[componentId] = new ComponentInfo(componentRef);
    return componentId;
  }

  /**
   * unregisters a react component from the scheduler
   * @param {number} componentId component id
   * @returns {undefined}
   */
  unregisterComponent(componentId) {
    invariant(
      componentId in this.activeComponents,
      'RestCache.unregisterComponent: called with a non existent component id',
    );

    const activeComp = this.activeComponents[componentId];
    const toCancel = activeComp.cancelAll();

    this.cancelRequests(toCancel);
    delete this.activeComponents[componentId];
  }

  /**
   * request data adds requests to the scheduler and checks the queue for requests
   * @param {number} componentId componentId from the component that triggered the request
   * @param {String} key the component property key
   * @param {String} compiledUrl complete url with params set
   * @param {String} urlTemplate url template without params set
   * @param {Object} parameters url params
   * @param {number} maxAge max age to deduplicate requests
   * @returns {Object} an object containing possible cached data and if the data is
   * to be treated as "fresh"
   */
  requestData(componentId, key, compiledUrl, urlTemplate, parameters, maxAge = DEFAULT_MAX_AGE) {
    if (componentId in this.activeComponents) {
      // invariant(componentId in this.activeComponents, 'RestCache.getData: called with non existent component id');

      const result = this.cache.getData(compiledUrl, maxAge);
      if (!result.fresh) {
        this.addRequest(componentId, key, compiledUrl, urlTemplate, parameters, maxAge);
        this.checkQueue();
      }
      return result;
    }

    return {};
  }

  /**
   * adds a rest request to the scheduler
   * @param {number} componentId componentId from the component that triggered the request
   * @param {String} key the component property key
   * @param {String} compiledUrl complete url with params set
   * @param {String} urlTemplate url template without params set
   * @param {Object} params url params
   * @param {number} maxAge max age to deduplicate requests
   * @returns {undefined}
   */
  addRequest(componentId, key, compiledUrl, urlTemplate, params, maxAge) {
    const requestId = this.nextRequestId;
    this.nextRequestId += 1;
    // check if the request is present in the pending queue
    let requestInfo = this.pendingQueue.find(
      (info) => info.compiledUrl === compiledUrl && info.urlTemplate === urlTemplate,
    );
    if (!requestInfo) {
      requestInfo = this.findLatestInProgress(compiledUrl, urlTemplate);
      if (requestInfo) {
        const now = Date.now();
        // if the request has a big maxAge anyways deduplicate with in progress
        // requests
        const ma = maxAge > IN_PROGRESS_MAX_AGE ? maxAge : IN_PROGRESS_MAX_AGE;
        if (now - requestInfo.progressStart > ma) {
          requestInfo = null;
        }
      }
      if (!requestInfo) {
        requestInfo = new RequestInfo(compiledUrl, urlTemplate);
        this.pendingQueue.push(requestInfo);
      }
    }

    if (requestInfo.addSubscriber(componentId, requestId)) {
      if (componentId !== null) {
        const componentInfo = this.activeComponents[componentId];
        const toCancel = componentInfo.addPendingRequest(requestId, key, compiledUrl, params);
        this.cancelRequests(toCancel);
      }
    }
  }

  /**
   * finds the latest request in progress
   * @param {String} compiledUrl complete url with params set
   * @param {String} urlTemplate url template without params set
   * @returns {Object} request info
   */
  findLatestInProgress(compiledUrl, urlTemplate) {
    for (let i = 0; i < this.inProgress.length; i += 1) {
      const info = this.inProgress[i];
      if (info.compiledUrl === compiledUrl && info.urlTemplate === urlTemplate) {
        return info;
      }
    }
    return null;
  }

  /**
   * checks the scheduler queue and triggers requests. allows only a maximum amount
   * of parallel connections
   * @returns {undefined}
   */
  checkQueue() {
    // remove done requests
    for (let i = this.inProgress.length - 1; i >= 0; i -= 1) {
      if (this.inProgress[i].done) {
        this.inProgress.splice(i, 1);
      }
    }
    const now = Date.now();
    while (this.inProgress.length < MAX_SIMULTANEOUS_REQUESTS && this.pendingQueue.length > 0) {
      const requestInfo = this.pendingQueue.shift();
      if (requestInfo.subscribers.length) {
        requestInfo.progressStart = now;
        this.inProgress.push(requestInfo);
        this.triggerRequest(requestInfo);
      }
    }
  }

  /**
   * trigger requests -> rest request
   * @param {Object} requestInfo request info object used for rest execution
   * @returns {undefined}
   */
  async triggerRequest(requestInfo) {
    const result = await requestInfo.executeRequest();
    if (result.error) {
      this.cache.clearData(requestInfo.compiledUrl);
    } else {
      this.cache.putData(requestInfo.compiledUrl, requestInfo.urlTemplate, result.data);
    }

    for (let i = 0; i < requestInfo.subscribers.length; i += 1) {
      const sub = requestInfo.subscribers[i];
      if (sub.componentId !== null) {
        invariant(sub.componentId in this.activeComponents, 'RequestScheduler.triggerRequest: '
          + 'trying to receive a request for a non existent component');
        this.activeComponents[sub.componentId].receiveResult(sub.requestId, result);
      }
    }
    this.checkQueue(); // at least one request is finished check the queue
  }

  /**
   * cancels a request from the pending queue
   * @param {Array} toCancel array of requets to cancel
   * @returns {undefined}
   */
  cancelRequests(toCancel) {
    for (let i = 0; i < toCancel.length; i += 1) {
      const tc = toCancel[i];
      const ri = this.pendingQueue.find((info) => info.compiledUrl === tc.compiledUrl);
      if (!ri || !ri.cancelId(tc.requestId)) {
        const ipris = this.inProgress.filter((info) => info.compiledUrl === tc.compiledUrl);
        let foundIt = false;
        for (let j = 0; j <= ipris.length; j += 1) {
          if (ipris[j].cancelId(tc.requestId)) {
            foundIt = true;
            break;
          }
        }
        invariant(foundIt, 'RequestScheduler.cancelRequests: '
          + 'trying to cancel a request which is neither pending nor in progress:'
          + ` ${tc.compiledUrl}`);
      }
    }
  }

  /**
   * clear all from the cache
   * @returns {undefined}
   */
  clearCache() {
    this.cache.clearAll();
  }

  /**
   * clear a single cached response from the cache
   * @param {String} urlTemplate url template used as key to delete data from cache
   * @returns {undefined}
   */
  clearTemplate(urlTemplate) {
    this.cache.clearTemplate(urlTemplate);
  }
}

const requestScheduler = new RequestScheduler();
window.debug_requestScheduler = requestScheduler;

export default requestScheduler;
