import EventEmitter from 'events';

/**
 * PollingEventEmitter
 */
class PollingEventEmitter extends EventEmitter {
  /**
   * constructor
   * @returns {undefined}
   */
  constructor() { // eslint-disable-line no-useless-constructor
    super();
  }
}

export default new PollingEventEmitter();
