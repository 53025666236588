import React from 'react';
import PropTypes from 'prop-types';

/**
 * component
 */
class Polling extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      secondsRemaining: 0,
    };

    this.internal = null;
    this.mounted = false;
    this.triggerAction = this.triggerAction.bind(this);
  }

  /**
   * start the interval on mount
   * @returns {undefined}
   */
  componentDidMount() {
    this.mounted = true;
    clearInterval(this.internal);
    this.startInterval();
  }

  /**
   * clear the interval on unmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.interval);
  }

  /**
   * starts the progress interval
   * @returns {undefined}
   */
  startInterval() {
    if (this.mounted) {
      const { duration, intervalTimeout } = this.props;

      clearInterval(this.internal);
      this.setState(() => ({
        secondsRemaining: duration,
      }), () => {
        this.internal = setInterval(this.triggerAction, intervalTimeout);
      });
    }
  }

  /**
   * trigger action
   * @return {undefined}
   */
  triggerAction() {
    if (this.mounted) {
      const { action } = this.props;
      const { secondsRemaining } = this.state;

      if (secondsRemaining > 0) {
        const nextSecondsRemaining = secondsRemaining - 1;
        this.setState(() => ({
          secondsRemaining: nextSecondsRemaining,
        }));
      } else if (secondsRemaining <= 0) {
        this.setState(() => ({
          secondsRemaining: 0,
        }), () => {
          this.startInterval();
          action();
        });
      }
    }
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    return (
      <div />
    );
  }
}

Polling.defaultProps = {
  duration: 10,
  intervalTimeout: 1000,
};

Polling.propTypes = {
  duration: PropTypes.number,
  intervalTimeout: PropTypes.number,
  action: PropTypes.func.isRequired,
};

export default Polling;
